
import { AccordionState } from '../domain/accordion-state';
import { AccordionItemModel } from '../model/accordion-item-model';
export const addAccordionItemToState = (
  state: AccordionState,
  payload: {
    accordionItem: AccordionItemModel<any>;
  }
): void => {
  payload.accordionItem.isExpanded =
    payload.accordionItem.isExpanded || state.isExpandAll;
  state.accordionItemMap.set(payload.accordionItem.id, payload.accordionItem);
};
