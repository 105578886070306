/*
 * Filename:  WebContent\src\app\core\components\app-popup\app-popup-progress-bar-download\app-popup-progress-bar-download.component.ts
 * Path:  WebContent
 * Created Date: Tuesday, September 7th 2021, 11:21:34 am

 *
*
 */

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ProgressBarDownloadModel } from './progress-bar-download-model';

@Component({
  templateUrl: './app-popup-progress-bar-download.component.html',
  styleUrls: ['./app-popup-progress-bar-download.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupProgressBarDownloadComponent implements OnInit {
  @Input() model: ProgressBarDownloadModel;
  @Output() onChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onClose: EventEmitter<void> = new EventEmitter();
  ngOnInit(): void {
    this.model.progressChanges.subscribe(progress => {
      if (progress === 100) {
        setTimeout(() => {
          this.onClose.emit();
          this.model.reset();
        }, 1000);
      }
    });
  }

  public doCancel(): void {
    this.model.reset();
    this.onChange.emit(true);
  }
}
