<div
  class="app-select app-combo-box-tree p-0"
  [ngClass]="ISVIEW ? 'width-fit-content' : elementWidthClassName"
>
  <ng-container *ngIf="ISVIEW; then isViewTmpl; else defaultTmpl">
  </ng-container>
  <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
  </ng-container>
</div>
<ng-template #isViewTmpl>
  <ng-container *ngIf="!formControl.value"> - </ng-container>
  <ng-container *ngTemplateOutlet="listTmpl"> </ng-container>
</ng-template>
<ng-template #defaultTmpl>
  <div
    class="form-control app-select-label"
    [appDropDown]="comboBox"
    [disabled]="formControl.disabled"
    [ngClass]="[
      formControl.value && formControl.value.length > 0
        ? 'height-fit-content'
        : '',
      elementHeightClassName,
      isInvalid ? 'is-invalid' : '',
      isValid ? 'is-valid' : '',
      formControl.disabled ? 'disabled' : ''
    ]"
    (onClick)="doClick()"
  >
    <ng-container
      *ngIf="
        !formControl.value ||
        (formControl.value && formControl.value.length === 0)
      "
    >
      <div class="placeholder"
        >{{ (placeholder | translate) || ('app.chooseData' | translate) }}
      </div>
    </ng-container>
    <ng-container *ngTemplateOutlet="listTmpl"> </ng-container>
  </div>
  <small class="text-danger" *ngIf="isInvalid">
    {{ errors.message }}
  </small>
</ng-template>
<ng-template #listTmpl>
  <ng-container
    *ngIf="formControl.value && formControl.value.length > 0 && !isMultiple"
  >
    <ul class="tree-value" *ngIf="!isMultiple">
      <li *ngFor="let value of formControl.value">
        <ng-container *ngIf="valueTmpl">
          <ng-container
            *ngTemplateOutlet="valueTmpl; context: { $implicit: value }"
          >
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!valueTmpl">
          {{ value.name }}
        </ng-container>
      </li>
    </ul>
  </ng-container>
  <ng-container
    *ngIf="formControl.value && formControl.value.length > 0 && isMultiple"
  >
    <app-tagging
      *ngIf="!ISVIEW"
      [formControl]="formControl"
      [optionView]="optionView"
    ></app-tagging>
    <ul class="app-text-tree-list" *ngIf="ISVIEW">
      <li *ngFor="let value of formControl.value">
        <ng-container *ngIf="valueTmpl">
          <ng-container
            *ngTemplateOutlet="valueTmpl; context: { $implicit: value }"
          >
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!valueTmpl">
          {{ value.name }}
        </ng-container>
      </li>
    </ul>
  </ng-container>
</ng-template>
<ng-template #comboBox>
  <div class="drop-down-tree">
    <app-tree-x
      [model]="treeModel"
      (onChange)="onChangeTree($event)"
      [isLazy]="isLazy"
    >
      <ng-container *ngIf="optionTmpl">
        <ng-template #customText let-data>
          <ng-container
            *ngTemplateOutlet="optionTmpl; context: { $implicit: data }"
          >
          </ng-container>
        </ng-template>
      </ng-container>
    </app-tree-x>
    <div class="button-group button-group-right" *ngIf="!treeModel.isLoading">
      <app-button
        color="SECONDARY"
        (onClick)="activeDropDown.close()"
        mode="BYPASS"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button
        (onClick)="onChoose()"
        [disabled]="treeModel.value.length === 0"
        mode="BYPASS"
      >
        {{ 'app.button.choose' | translate }}</app-button
      >
    </div>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [symbolic]="symbolic"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
