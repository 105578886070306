import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  Optional,
  SimpleChanges
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { TextUtils } from '../../utils';
import { ActiveDropDown } from '../app-drop-down/active-drop-down.service';
import * as datePickerAction from './action/date-picker-action';
import { AppDatePickerXService } from './app-date-picker-x.service';
import { DatePickerDate, DatePickerState } from './model';
import { DatePickerTime } from './model/date-picker-time-model';
import { DatePickerDropDownView } from './type/date-picker-drop-down-view-type';
import { DatePickerValue } from './type/date-picker-value-type';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@Component({
  selector: 'app-date-picker-x',
  templateUrl: './app-date-picker-x.component.html',
  styleUrls: ['./app-date-picker-x.component.scss'],
  providers: [
    ...makeProvider(AppDatePickerXComponent),
    AppDatePickerXService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class AppDatePickerXComponent
  extends BaseValueAccessorComponent<any>
  implements OnChanges
{
  @Input() public maxDate: Date | string;
  @Input() public minDate: Date | string;
  @Input() public range: boolean;
  @Input() public time: boolean;

  public generatedId = TextUtils.generateRandomString();

  public state: DatePickerState;
  public isTimeLoaded = true;

  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    private appDatePickerService: AppDatePickerXService,
    private activeDropDown: ActiveDropDown
  ) {
    super('app-date-picker-x', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setInitializationState();
    this.stateValueChangesListener();
    this.dispatchFirstLoad();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      (this.state &&
        this.state.model &&
        simpleChanges.minDate &&
        simpleChanges.minDate.previousValue !==
          simpleChanges.minDate.currentValue) ||
      (this.state &&
        this.state.model &&
        simpleChanges.maxDate &&
        simpleChanges.maxDate.previousValue !==
          simpleChanges.maxDate.currentValue)
    ) {
      this.appDatePickerService.dispatch(
        new datePickerAction.SetDatePickerMinOrMaxDate({
          minDate: this.minDate,
          maxDate: this.maxDate
        })
      );
    }
  }

  private setInitializationState(): void {
    this.state = this.appDatePickerService.setState({
      maxDate: this.maxDate,
      minDate: this.minDate,
      range: this.range,
      time: this.time,
      formControl: this.formControl
    });
  }

  private stateValueChangesListener(): void {
    this.state.valueChanges.subscribe((value: DatePickerValue) => {
      this.formControl.patchValue(value);
      this.formControl.markAsTouched();
      this.onChange.emit(value);
    });
  }

  private dispatchFirstLoad(): void {
    this.appDatePickerService.dispatch(
      new datePickerAction.FirstLoadDatePicker()
    );
  }

  public doReset(): void {
    this.appDatePickerService.dispatch(new datePickerAction.ResetDatePicker());
    this.onChange.emit(this.formControl.value);
    this.isTimeLoaded = false;
    setTimeout(() => (this.isTimeLoaded = true));
  }

  public doClickDate(datePickerDate: DatePickerDate): void {
    this.appDatePickerService.dispatch(
      new datePickerAction.SetDatePickerDate({ datePickerDate })
    );
    this.range
      ? this.state.currentDateRange.start &&
        this.state.currentDateRange.end &&
        this.activeDropDown.close()
      : this.activeDropDown.close();
  }

  public doSetDatePickerTime(datePickerTime: DatePickerTime): void {
    this.appDatePickerService.dispatch(
      new datePickerAction.SetDatePickerTime({ datePickerTime })
    );
    this.activeDropDown.close();
    this.isTimeLoaded = false;
    setTimeout(() => (this.isTimeLoaded = true));
  }

  public doSetDatePickerMonth(month: number): void {
    this.appDatePickerService.dispatch(
      new datePickerAction.SetDatePickerMonth({ month })
    );
  }

  public doSetDatePickerYear(year: number): void {
    this.appDatePickerService.dispatch(
      new datePickerAction.SetDatePickerYear({ year })
    );
  }

  public doChangeView(view: DatePickerDropDownView): void {
    this.appDatePickerService.dispatch(
      new datePickerAction.SetDatePickerDropDownView({ view })
    );
  }

  public doSetDatePickerYearList(action: 'BACK' | 'NEXT'): void {
    this.appDatePickerService.dispatch(
      new datePickerAction.SetDatePickerYearList({ action })
    );
  }

  public onInputTime(
    event: InputEvent & { target: HTMLElement },
    type: 'HOURS' | 'MINUTES'
  ): void {
    this.appDatePickerService.dispatch(
      new datePickerAction.SetDatePickerTimeHtmlText({ event, type })
    );
  }

  public onFocusInTime(
    event: FocusEvent & { target: HTMLElement },
    type: 'HOURS' | 'MINUTES'
  ): void {
    this.appDatePickerService.dispatch(
      new datePickerAction.ResetDatePickerTimeHtmlText({ event, type })
    );
    event.target.innerText = event.target.innerText.trim();
  }

  public onFocusOutTime(
    event: InputEvent & { target: HTMLElement },
    type: 'HOURS' | 'MINUTES'
  ): void {
    this.appDatePickerService.dispatch(
      new datePickerAction.ChangeDatePickerTime({ event, type })
    );
  }

  public onMouseEnter(datePickerDate: DatePickerDate): void {
    this.appDatePickerService.dispatch(
      new datePickerAction.SetDatePickerOnRange({ datePickerDate })
    );
  }

  public onKeydownTime(event: KeyboardEvent & { target: HTMLElement }): void {
    const text: string = event.target.innerText;

    const key = event.key;
    if (
      text.length === 2 &&
      key &&
      !['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(key)
    ) {
      event.preventDefault();
    } else {
      event.stopPropagation();
    }
  }
}
