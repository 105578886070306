<div class="app-upload">
  <ng-container
    *ngIf="isSingle; then singleUploadTmpl; else multipleUploadTmpl"
  >
  </ng-container>
  <ng-template #singleUploadTmpl>
    <div
      *ngIf="(!ISVIEW && model.uploaderFileList.length === 0) || isUploading"
    >
      <div
        class="drag-drop-content-wrapper"
        uploadDragAndDrop
        (onChange)="onDragAndDropChange($event)"
      >
        <div
          class="content-border px-2"
          style="display: flex; flex-direction: column"
        >
          <div class="content-drag-drop">
            <span
              class="text mx-4"
              *ngIf="!isUploading || (isUploading && model.error)"
            >
              {{ 'app-upload.info.dragAndDrop' | translate }}
            </span>
          </div>

          <input
            #inputFileAdd
            type="file"
            (change)="onUploadChange($event)"
            multiple
            aria-label="choose-file"
            hidden
            [attr.id]="generatedId"
            [ngClass]="[
              elementHeightClassName,
              isInvalid ? 'is-invalid' : '',
              isValid ? 'is-valid' : ''
            ]"
            [attr.title]="
              ('app-upload.title.fileExtension' | translate) +
              model.options.allowedExtension +
              '\n' +
              ('app-upload.title.maxSize' | translate) +
              (model.options.maxSize / 1000000 | number: '1.2-2') +
              'MB'
            "
            [disabled]="disabled"
          />

          <div
            class="content-browse-file"
            style="display: inline-flex; align-items: center"
          >
            <em
              class="fas fa-cloud-upload-alt fa-3x"
              *ngIf="!isUploading || (isUploading && model.error)"
            ></em>
            <span style="padding: 0 0.5rem">
              <strong>
                <a
                  (click)="inputFileAdd.click()"
                  *ngIf="!isUploading || (isUploading && model.error)"
                  maxLine
                  [attr.title]="
                    ('app-upload.title.fileExtension' | translate) +
                    model.options.allowedExtension +
                    '\n' +
                    ('app-upload.title.maxSize' | translate) +
                    (model.options.maxSize / 1000000 | number: '1.2-2') +
                    'MB'
                  "
                  [disabled]="disabled"
                  ><h5>{{ 'app-upload.button.browseFile' | translate }}</h5></a
                >
              </strong>
            </span>
          </div>
          <span
            class="text-uploading fw-500 text-primary"
            *ngIf="isUploading && !model.error"
            >{{ 'app-upload.info.uploading' | translate }}</span
          >
          <div
            class="upload-progress-wrapper"
            *ngIf="isUploading && !model.error"
          >
            <span
              class="upload-progress"
              [ngStyle]="{
                width:
                  (model.uploaderFileList
                  | resolveUploadProgressValue: totalNewUploaded:progressValue
                  | async)
              }"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #multipleUploadTmpl>
    <div *ngIf="!ISVIEW">
      <div
        class="drag-drop-content-wrapper"
        uploadDragAndDrop
        (onChange)="onDragAndDropChange($event)"
      >
        <div class="content-border px-2">
          <em
            class="fas fa-cloud-upload-alt fa-4x"
            *ngIf="!isUploading || (isUploading && model.error)"
          >
          </em>
          <span
            class="text mx-4"
            *ngIf="!isUploading || (isUploading && model.error)"
          >
            {{ 'app-upload.info.dragAndDrop' | translate }}
          </span>
          <input
            #inputFileAdd
            type="file"
            (change)="onUploadChange($event)"
            multiple
            aria-label="choose-file"
            hidden
            [attr.id]="generatedId"
            [ngClass]="[
              elementHeightClassName,
              isInvalid ? 'is-invalid' : '',
              isValid ? 'is-valid' : ''
            ]"
            [disabled]="
              disabled ||
              model.uploaderFileList.length === model.options.maxFile
            "
          />
          <app-button
            (onClick)="inputFileAdd.click()"
            *ngIf="!isUploading || (isUploading && model.error)"
            [disabled]="
              disabled ||
              model.uploaderFileList.length === model.options.maxFile
            "
          >
            {{ 'app-upload.button.browseFile' | translate }}</app-button
          >
          <span
            class="text-uploading fw-500 text-primary"
            *ngIf="isUploading && !model.error"
            >{{ 'app-upload.info.uploading' | translate }}</span
          >
          <div
            class="upload-progress-wrapper"
            *ngIf="isUploading && !model.error"
          >
            <span
              class="upload-progress"
              [ngStyle]="{
                width:
                  (model.uploaderFileList
                  | resolveUploadProgressValue: totalNewUploaded:progressValue
                  | async)
              }"
            ></span>
          </div>
        </div>
      </div>
      <span class="file-requirements d-block my-3">
        {{ 'app-upload.info.maxFile' | translate }}
        {{ model.options?.maxFile }}
        {{ 'app-upload.info.format' | translate }}
        {{ model.options?.allowedExtension }}
      </span>
      <span class="file-requirements d-block my-3">
        {{ 'app-upload.info.maxSize' | translate }}
        {{
          model.options.fileType
            ? model.options.fileType.maxSize / 1000000
            : model.options.maxSize / 1000000
        }}MB
      </span>
    </div>
  </ng-template>

  <span class="upload-error d-block mt-3"></span>
  <div
    id="upload-error-info"
    class="alert alert-warn alert-dismissible show"
    role="alert"
    *ngIf="model.error"
  >
    <strong class="mr-2">
      <em class="fas fa-exclamation-triangle mr-2"></em>
    </strong>
    <span style="padding: 0 2rem">{{ model.error?.message }}</span>

    <button
      aria-label="Close"
      class="close"
      data-dismiss="alert"
      type="button"
      (click)="onClick()"
      style="display: block"
    >
      <span aria-hidden="true"
        ><em
          class="pir pi-times"
          style="top: auto; left: 0; font-size: 1.5rem; color: #000000"
        ></em
      ></span>
    </button>
  </div>

  <div
    id="upload-error-info"
    class="alert alert-danger alert-dismissible show"
    role="alert"
    *ngIf="isInvalid"
  >
    <strong class="mr-2">
      <em class="fas fa-exclamation-triangle mr-2"></em>
    </strong>
    <span style="padding: 0 2rem">{{ errors.message }}</span>

    <button
      aria-label="Close"
      class="close"
      data-dismiss="alert"
      type="button"
      (click)="onClick()"
      style="display: block"
    >
      <span aria-hidden="true"
        ><em
          class="pir pi-times"
          style="top: auto; left: 0; font-size: 1.5rem; color: #000000"
        ></em
      ></span>
    </button>
  </div>

  <div *ngIf="model.uploaderFileList.length > 0">
    <ul
      class="upload-content"
      [ngStyle]="ISVIEW || isSingle ? { 'margin-top': '-2rem' } : ''"
    >
      <li
        class="upload-item-list"
        *ngFor="let uploaderFile of model.uploaderFileList; let i = index"
      >
        <div class="upload-item" *ngIf="uploaderFile.status === 'UPLOADED'">
          <div class="item-content">
            <div class="item-content-image">
              <img
                [attr.src]="
                  uploaderFile.isNew
                    ? uploaderFile.src
                    : (uploaderFile | resolveUploadSrc | async)
                "
                [attr.alt]="uploaderFile.file.fileName"
                class="img-thumbnail"
                width="0"
                height="0"
              />
            </div>
            <div class="item-content-text">
              <a
                class="item-content-name"
                [attr.href]="uploaderFile | resolveUploadHref"
                (click)="doDownloadFile($event, uploaderFile)"
                maxLine
                [attr.title]="uploaderFile.file.fileName"
              >
                {{ uploaderFile.file.fileName }}
              </a>
              <p class="item-content-size">
                {{ uploaderFile.file.size / 1000000 | number: '1.2-2' }} MB
              </p>
            </div>
          </div>

          <ng-container
            *ngIf="
              isSingle;
              then singleItemActionTmpl;
              else multipleItemActionUploadTmpl
            "
          >
          </ng-container>

          <ng-template #singleItemActionTmpl>
            <div
              class="item-action"
              (click)="doDeleteFile(i)"
              *ngIf="!ISVIEW"
              style="align-self: flex-end"
            >
              <em
                class="pir pi-trash-alt"
                [attr.title]="'app.tooltip.delete' | translate"
              ></em>
            </div>
          </ng-template>

          <ng-template #multipleItemActionUploadTmpl>
            <div class="item-action" (click)="doDeleteFile(i)" *ngIf="!ISVIEW">
              <em class="pir pi-trash-alt"></em>
              <span>{{ 'app.tooltip.delete' | translate }}</span>
            </div>
          </ng-template>
        </div>
      </li>
    </ul>
  </div>
  <div
    *ngIf="ISVIEW && model.uploaderFileList.length === 0"
    style="margin-top: -1rem"
    >-</div
  >
</div>
