<app-layout [isLoading]="formLoading" [isEnableBackButton]="true">
  <div class="row">
    <div class="col-sm-4">
      <app-card [isLoading]="formLoading" header="Filter Report">
        <form
          class="form-horizontal"
          [formGroup]="formGroup"
          novalidate
          *ngIf="!formLoading"
        >
          <h4 class="separator">General</h4>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'report-recap-order-process.form.filter.orderRequireDate'
                | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-date-picker-x
                size="XL"
                formControlName="orderRequireDate"
                [range]="true"
              >
              </app-date-picker-x>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'report-recap-order-process.form.filter.orderStatus' | translate
            }}</label>
            <div class="col-sm-9">
              <app-dropdown-select
                size="XL"
                [optionList]="orderStatusOptionList"
                formControlName="orderStatusList"
                showLimitValue="2"
                type="CHOSEN"
                placeholder="{{
                  'report-recap-order-process.form.placeholder.orderStatus'
                    | translate
                }}"
              >
              </app-dropdown-select>
            </div>
          </div>
          <h4 class="separator">Type of Order Process</h4>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'report-recap-order-process.form.filter.orderDate' | translate
              }}
            </label>
            <div class="col-sm-9 text-left">
              <app-date-picker-x
                size="XL"
                formControlName="orderDate"
                [range]="true"
              >
              </app-date-picker-x>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'report-recap-order-process.form.filter.orderType' | translate
            }}</label>
            <div class="col-sm-9">
              <app-dropdown-select
                size="XL"
                [optionList]="orderTypeOptionList"
                formControlName="orderTypeList"
                showLimitValue="2"
                type="CHOSEN"
                placeholder="{{
                  'report-recap-order-process.form.placeholder.orderType'
                    | translate
                }}"
              >
              </app-dropdown-select>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'report-recap-order-process.form.filter.organization' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box-tree
                size="XL"
                formControlName="organizationList"
                stringUrl="/organization"
                header="Organization"
                [isLazy]="true"
              >
              </app-combo-box-tree>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'report-recap-order-process.form.filter.minOrderCost' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                size="XL"
                placeholder="{{
                  'report-recap-order-process.form.placeholder.minOrderCost'
                    | translate
                }}"
                [optionList]="currencyOptionList"
                formControlName="minimumAmount"
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'report-recap-order-process.form.filter.maxOrderCost' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                size="XL"
                placeholder="{{
                  'report-recap-order-process.form.placeholder.maxOrderCost'
                    | translate
                }}"
                [optionList]="currencyOptionList"
                formControlName="maximumAmount"
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'report-recap-order-process.form.filter.shippingAddress'
                | translate
            }}</label>
            <div class="col-sm-9">
              <app-dropdown-select
                size="XL"
                [optionList]="shippingAddressOptionList"
                formControlName="shippingAddressList"
                showLimitValue="2"
                type="CHOSEN"
                placeholder="{{
                  'report-recap-order-process.form.placeholder.shippingAddress'
                    | translate
                }}"
              >
              </app-dropdown-select>
            </div>
          </div>
          <div class="button-group half-button">
            <app-button
              mode="BYPASS"
              color="LIGHT"
              [outline]="true"
              (onClick)="doReset()"
            >
              <em class="fas fa-sync-alt"></em>
              {{ 'app.button.reset' | translate }}
            </app-button>
            <app-button
              mode="BYPASS"
              color="PRIMARY"
              (onClick)="doGenerate()"
              [disabled]="formSaving"
            >
              {{ 'app.button.generate' | translate }}
            </app-button>
          </div>
        </form>
      </app-card>
    </div>
    <div class="col-sm-8">
      <app-card [isLoading]="formLoading" [isSaving]="formSaving">
        <app-table-xx
          header="report-recap-order-process.detail.result.title"
          [model]="tableResponse"
          stringUrl="/report-recap-order-process/index"
          [isShowPopupModifyTable]="isShowPopupModifyTable"
          [isFullViewTable]="isFullViewTable"
        >
          <ng-template #headerButtons>
            <app-button
              [outline]="true"
              [borderLess]="true"
              (onClick)="doCustomSetting()"
              *ngIf="reportBuilderId"
            >
              <em
                class="pir pi-cog mr-1"
                style="top: auto; left: auto; font-size: 16px; font-weight: 500"
              ></em>
              <span class="text-underline">
                {{ 'app.button.customSetting' | translate }}
              </span>
            </app-button>
            <app-button
              [outline]="true"
              [borderLess]="true"
              (onClick)="isShowPopupModifyTable = !isShowPopupModifyTable"
            >
              <em class="pir pi-cog mr-1"> </em>
              {{ 'app-table.button.modifyTable' | translate }}
            </app-button>
            <app-button
              [outline]="true"
              [borderLess]="true"
              (onClick)="isFullViewTable = !isFullViewTable"
            >
              <em
                class="fas mr-1"
                [ngClass]="
                  isFullViewTable ? 'pir pi-compress-alt' : 'pir pi-expand-alt'
                "
              ></em>
              {{
                (isFullViewTable
                  ? 'app-table.button.minimize'
                  : 'app-table.button.maximize'
                ) | translate
              }}
            </app-button>
            <app-button
              (onClick)="doExport()"
              [outline]="true"
              [borderLess]="true"
            >
              <em class="pir pi-file-download mr-1"></em>
              <span class="text-underline">{{
                'report-recap-order-process.detail.downloadReport' | translate
              }}</span>
            </app-button>
          </ng-template>
        </app-table-xx>
      </app-card>
    </div>
  </div>
</app-layout>
