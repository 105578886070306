/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\drop-down-select-plugin\drop-down-select-plugin.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 10:52:07 pm

 *
*
 */

import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../vendor/vendor.module';
import { AppDropdownSelectModule } from '../../../app-dropdown-select/app-dropdown-select.module';
import { TableDropDownSelectPluginComponent } from './drop-down-select-plugin.component';
@NgModule({
  imports: [VendorModule, AppDropdownSelectModule],
  declarations: [TableDropDownSelectPluginComponent],
  exports: [TableDropDownSelectPluginComponent]
})
export class TableDropDownSelectPluginModule {}
