import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../core/shared/shared.module';
import { AppLandingPageCardModule } from '../../themes/promise-portal/components/app-landing-page-card/app-landing-page-card.module';
import { RegistrationConfirmationComponent } from './registration-confirmation.component';
import { RegistrationEmailVerificationComponent } from './registration-email-verification.component';
import { RegistrationFormComponent } from './registration-form.component';
import { RegistrationTOCComponent } from './registration-toc.component';
import { RegistrationVendorPolicyComponent } from './registration-vendor-policy.component';
import { RegistrationComponent } from './registration.component';
export const routes: Routes = [
  {
    path: '',
    component: RegistrationComponent,
    data: { breadcrumb: '' },
    children: [
      { path: '', component: RegistrationFormComponent },
      {
        path: 'confirmation',
        component: RegistrationConfirmationComponent,
        data: { breadcrumb: 'confirmation' }
      },
      {
        path: 'vendor-policy',
        component: RegistrationVendorPolicyComponent,
        data: { breadcrumb: '' }
      },
      {
        path: 'toc',
        component: RegistrationTOCComponent,
        data: { breadcrumb: '' }
      },
      {
        path: 'email-verification',
        component: RegistrationEmailVerificationComponent,
        data: { breadcrumb: '' }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppLandingPageCardModule
  ],
  declarations: [
    RegistrationComponent,
    RegistrationFormComponent,
    RegistrationConfirmationComponent,
    RegistrationVendorPolicyComponent,
    RegistrationTOCComponent,
    RegistrationEmailVerificationComponent
  ],
  providers: []
})
export class RegistrationModule {}
