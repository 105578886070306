<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-x
        header="official-report.index.title"
        [model]="tableResponse"
        stringUrl="/official-report/index"
        (onClick)="doEdit($event)"
      >
      </app-table-x>
    </div>
  </div>
</app-layout>
