import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { User } from '../../../bean/user';
import { OptionListModel } from '../../../model/option-list-model';

@Component({
  templateUrl: './app-popup-add-user.component.html'
})
export class AppPopupAddUserComponent extends BaseComponentComponent {
  @Input() public userList: User[];
  @Input() public userTempList: User[];
  @Input() public assigneeRoleIdList: number[];
  @Input() public isSelectAllDisabled: boolean;
  @Output() public onChange: EventEmitter<User[]> = new EventEmitter();

  public userOptionList: OptionListModel<User> = new OptionListModel(true);

  constructor(public ngbActiveModal: NgbActiveModal) {
    super('app-popup-add-user');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.formGroup.patchValue({ userList: this.userList });
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      userList: [null],
      organization: [null]
    });
  }

  public setFormGroup(): void {
    if (this.assigneeRoleIdList && this.assigneeRoleIdList.length > 0) {
      this.httpClientService
        .get<User[]>(
          '/app-popup-add-user/get-user-list/' + this.assigneeRoleIdList
        )
        .subscribe((userList: User[]) => {
          this.userOptionList.setRequestValues(userList);
          this.userTempList = userList;
          this.setStateReady();
        });
    } else {
      this.setStateReady();
    }
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.organization) {
      const organizationList = this.formGroup.value.organization;
      const userFilteredList = this.userTempList.filter(
        u =>
          u.organization.id === organizationList[organizationList.length - 1].id
      );
      this.userOptionList.setRequestValues(userFilteredList);
    } else {
      this.userOptionList.setRequestValues(this.userTempList);
    }
  }

  public doCancel(): void {
    this.ngbActiveModal.close();
  }

  public doAdd(): void {
    if (this.formGroup.value.userList) {
      this.onChange.emit(this.formGroup.value.userList);
    } else {
      this.global.alertService.showError(
        'app-popup-add-user.validasi.selectUser.msg',
        '.popup-add-user'
      );
    }
  }
}
