import { PicklistOptions } from '../interface/pick-list-options';
export class PickListModel<T> {
  public sourceTitle: string;
  public targetTitle: string;
  public searchSourcePlaceholder: string;
  public searchTargetPlaceholder: string;
  public randomNumber: number = Math.random();
  constructor(
    public moduleCode: string,
    public sourceItemList: Array<T>,
    public targetItemList: Array<T>,
    public viewField: string = 'name',
    public options?: PicklistOptions
  ) {
    this.sourceTitle =
      options && options.sourceTitle
        ? options.sourceTitle
        : moduleCode + '.appPickList.sourceTitle';
    this.targetTitle =
      options && options.targetTitle
        ? options.targetTitle
        : moduleCode + '.appPickList.targetTitle';
    this.searchSourcePlaceholder =
      options && options.searchSourcePlaceholder
        ? options.searchSourcePlaceholder
        : moduleCode + '.appPickList.sourceSearchPlaceholder';
    this.searchTargetPlaceholder =
      options && options.searchSourcePlaceholder
        ? options.searchSourcePlaceholder
        : moduleCode + '.appPickList.sourceSearchPlaceholder';
  }
  public modifyId(): void {
    this.sourceItemList.forEach(item => {
      if (item['id'] !== null && item['id'] !== undefined && typeof(item['id']) === 'number') {
        item['id'] = item['id'] + this.randomNumber;
      }
    });
    this.targetItemList.forEach(item => {
      if (item['id'] !== null && item['id'] !== undefined && typeof(item['id']) === 'number') {
        item['id'] = item['id'] + this.randomNumber;
      }
    });
  }
  public resetTargetItemListId(): void {
    this.targetItemList.forEach(item => {
      if (item['id'] !== null && item['id'] !== undefined && typeof(item['id']) === 'number') {
        item['id'] = item['id'] - this.randomNumber;
      }
    });
  }
}
