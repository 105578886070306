/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\domain\table-state.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:39:06 am

 *
*
 */

import { EventEmitter, Injector } from '@angular/core';
import { ServiceLocator } from '../../../services/service-locator';
import { PaginationModel } from '../../app-pagination/model/pagination-model';
import { createPerPageFilterList } from '../helper/create-per-page-filter-list-helper';
import { createTableColumnList } from '../helper/create-table-column-list-helper';
import { createTableRowColumnList } from '../helper/create-table-row-column-list-helper';
import { TableEvent } from '../interface/table-event';
import { TablePlugin } from '../interface/table-plugin';
import { TableColumnModel } from '../model/table-column-model';
import { TableRequestModel } from '../model/table-request-model';
import { TableResponseModel } from '../model/table-response-model';
import { SelectedTableRow } from './selected-table-row';
import { TableRecordTemp } from './table-record-temp';
import { TableRow } from './table-row';
export class TableState {
  // injector digunakan untuk mengakses komponen atau service dalam level function.
  // misal: pada level dynamic component
  public injector: Injector;
  // Table response model yang dilempar dari module
  public model: TableResponseModel<any>;
  // pagination digunakan untuk keperluan pagination pada table
  public pagination: PaginationModel;
  // sort order untuk mengetahui current order dari column pada table
  public sortOrder: 'ASC' | 'DESC' | '';
  // string url adalah endpoint yang digunakan untuk mendapatkan tamble record
  // apabila dari module menyertakan url module
  public stringUrl: string;
  // is server side adalah flag untuk menandakan bahwa record dari table ini
  // diambil dari backend
  public isServerSide: boolean;
  // table request mereupakan data yang nantinya akan dikirim ke backend sebagai body
  public tableRequest: TableRequestModel;
  // plugins digunakan untuk menampung plugin options yang didaftar di plugin folder
  public plugins: Array<TablePlugin>;
  // is loading merupakan flag kalau data sementara ditunggu dari backend
  public isLoading: boolean;
  // row list adalah list row yang berisi record dan beberapa option dasat untuk table row
  public rowList: Array<TableRow>;
  // selected record merupakan record yang telah di pilih pada table
  public selectedRow: SelectedTableRow = new SelectedTableRow();
  // is expanded all digunakan untuk expand dan collapse semua table row
  public isExpandedAll: boolean;
  // event digunakan untuk menghandle event yang terjadi pada table ("e.g: table plugin")
  // dan dikirimkan ke module
  public event: EventEmitter<TableEvent> = new EventEmitter();
  // table record temp digunakan untuk penyimpanan sementara, apabila di dalam table
  // terdapat inputan dan telah melakukan perubahan. Hasil perubahan akan disimpan disini.
  public recordTemp: TableRecordTemp = new TableRecordTemp();
  // isView digunakan apabila module menginginkan table hanya untuk view, tidak untuk editable.
  // lebih tepatnya dipake di plugin inputan
  public isView: boolean;
  // is multiple select digunakan untuk memilih lebih dari satu row ("checkbox") atau
  // hanya satu row ("radio") pada table
  public isMultipleSelect: boolean;
  // is show pagination digunakan untuk menentukan apakah table menampilkan pagination atau tidak
  // jika tidak, maka table akan menggunakan lazyload dan pagination pada table akan di-hide
  public isShowPagination: boolean;
  // flag yang digunakan untuk menandai bahwa data tidak ada ketika first load.
  // Ini digunakan untuk menyembunyikan filter, pagination, table dan hanya menampilkan no data
  public isNotDataWithFirstLoad: boolean;
  // is group digunakan untuk
  public isGroup: boolean;
  // table row column list
  public rowColumnList: Array<Array<TableColumnModel>>;
  // table column list
  public columnList: Array<TableColumnModel>;
  // perPage filter list
  public perPageFilterList: Array<number>;
  // sort by column digunakan menyimpan sementara table di sort terakhir dengan menggunakan
  // column apa
  public sortByColumn: TableColumnModel;

  public setRowList(rowList: Array<TableRow>): void {
    this.isNotDataWithFirstLoad =
      this.isNotDataWithFirstLoad && rowList.length === 0;
    this.rowList = rowList;
  }

  public addRowList(rowList: Array<TableRow>): void {
    this.rowList.push(...rowList);
  }

  public setStateLoading(): void {
    this.isLoading = true;
  }

  public setStateReady(): void {
    this.isLoading = false;
  }

  public static create(
    model: TableResponseModel<any>,
    stringUrl: string,
    isView: boolean,
    isMultipleSelect: boolean,
    isShowPagination: boolean
  ): TableState {
    const state = new TableState();
    state.model = model;
    let perPage = 10; // as default
    if (model.options && model.options.recordPerPage) {
      perPage = model.options.recordPerPage;
    }
    state.pagination = new PaginationModel(true, perPage);
    state.pagination.setTotalRecords(model.getRecordList().length);
    state.perPageFilterList = createPerPageFilterList(perPage);
    state.stringUrl = stringUrl;
    state.isServerSide = !!stringUrl;
    state.injector = ServiceLocator.injector;
    state.isView = isView;
    state.selectedRow.setSelectedRowList(model.selectedRow.selectedRowList);
    state.isMultipleSelect = isMultipleSelect;
    state.isShowPagination = isShowPagination;
    state.isNotDataWithFirstLoad = true;
    state.isGroup = !!(model.options && model.options.group);
    state.rowColumnList = createTableRowColumnList(model.columnList, [
      model.columnList
    ]);
    state.columnList = createTableColumnList(model.columnList);
    if (state.isGroup) {
      state.columnList.push({
        field: state.model.options.group.field
      });
    }
    state.tableRequest = new TableRequestModel(state.columnList, perPage);
    return state;
  }
}
