import { BaseEntity } from '../base/base-entity';
import { DeliveryStatus } from '../bean/delivery-status';
import { SowPaymentTerm } from '../bean/sow-payment-term';
import { SowPaymentType } from '../bean/sow-payment-type';
import { StageOfWork } from '../bean/stage-of-work';
export class SowEntity extends BaseEntity {
  crudOperation: number;
  sowPaymentTermList: SowPaymentTerm[] = [];
  sowPaymentType: SowPaymentType = new SowPaymentType();
  stageOfWork: StageOfWork = new StageOfWork();
  deliveryStatus: DeliveryStatus = new DeliveryStatus();
  startDate: Date = new Date();
  endDate: Date = new Date();
  realizationDate: Date = new Date();
  deliveryPercentage: number;
  object: Object = new Object();
  objectName: string;
  objectId: number;
}
