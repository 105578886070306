/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */
import { Organization } from '../bean/organization';
export class BaseEntity {
  id: number;
  createdAt: Date;
  createdBy: number;
  updatedAt: Date;
  updatedBy: number;
  isDeleted: number;
  code: string;
  organization: Organization;
}
