import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { Module } from '../bean/module';
import { User } from '../bean/user';
import { WorkPlanStatus } from '../bean/work-plan-status';
import { WorkPlanWorklistStatus } from '../bean/work-plan-worklist-status';
import { Workflow } from '../bean/workflow';
import { ProcurementType } from './../bean/procurement-type';

export class WorkPlanEntity extends BaseWorkflowObjectEntity {
  totalPriceItem: string;
  processDayTotal: number;

  workflow: Workflow;
  user: User = new User();
  workPlanStatus: WorkPlanStatus = new WorkPlanStatus();
  procurementType: ProcurementType = new ProcurementType();
  currentModule: Module = new Module();
  workPlanWorklistStatus: WorkPlanWorklistStatus = new WorkPlanWorklistStatus();
  createdDate: Date = new Date();
  workTargetDate: Date = new Date();
  realizationDate: Date = new Date();
  amount: number;
  goodsAmount: number;
  serviceAmount: number;
  realizationAmount: number;
  name: string;
  description: string;
}
