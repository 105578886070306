import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { TreeModel } from '../../app-tree/model/tree-model';
@Component({
  templateUrl: './app-popup-tree.component.html'
})
export class AppPopupTreeComponent extends BaseComponentComponent {
  @Input() model: TreeModel;
  @Input() isLazy = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  constructor() {
    super('app-popup');
  }

  onInit(): void {
    this.setStateReady();
  }

  public doChoose(): void {
    if (
      this.model.onlyLastChild &&
      this.model.value &&
      [...this.model.value].pop().hasChild &&
      ((this.model.limitLevel &&
        this.model.value.length - 1 !== this.model.limitLevel) ||
        !this.model.limitLevel)
    ) {
      this.global.alertService.showError(
        'app.validation.chooseLastLevel',
        '.app-tree',
        { totalAlert: 1 }
      );
    } else {
      this.onChange.emit(this.model.value);
    }
  }
}
