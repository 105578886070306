<app-card legend="Confirmation" header="registration.titleConfirmation">
  <form
    class="form-horizontal main-row"
    [formGroup]="formGroup"
    *ngIf="!isEmpty"
  >
    <app-alert-x color="WARNING">{{
      'registration.alert.msg.confirmation.doubleCheck' | translate
    }}</app-alert-x>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'registration.form.vendorType' | translate
      }}</label>
      <div class="col-sm-7 text-left">
        <p>{{ registrationVendorRequest.vendorType.name }}</p>
      </div>
    </div>

    <div *ngIf="setCompanyNameLabelActive">
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'registration.form.companyName' | translate
        }}</label>
        <div class="col-sm-7 text-left">
          <p
            >{{ registrationVendorRequest.companyName }},
            {{ registrationVendorRequest.vendorEntity.name }}</p
          >
        </div>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <div
        *ngIf="setCompanyNameLabelActive; then ifBlock; else elseBlock"
      ></div>
      <ng-template #ifBlock>
        <label class="col-sm-3 control-label">{{
          'registration.form.picName' | translate
        }}</label>
        <div class="col-sm-7 text-left">
          <p>{{ registrationVendorRequest.name }}</p>
        </div>
      </ng-template>
      <ng-template #elseBlock>
        <label class="col-sm-3 control-label">{{
          'registration.form.personName' | translate
        }}</label>
        <div class="col-sm-7 text-left">
          <p
            >{{ registrationVendorRequest.name }},
            {{ registrationVendorRequest.vendorEntity.name }}</p
          >
        </div>
      </ng-template>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'registration.form.email' | translate
      }}</label>
      <div class="col-sm-7 text-left">
        <p>{{ registrationVendorRequest.email }}</p>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'registration.form.password' | translate
      }}</label>
      <div class="col-sm-4 text-left pr-0">
        <span>
          <ng-container *ngIf="!isShowPassword">
            <em
              *ngFor="let char of registrationVendorRequest.password.split('')"
              class="fas fa-circle"
              [ngStyle]="{ 'font-size': '12px', 'padding-right': '2px' }"
            ></em>
          </ng-container>
          <ng-container *ngIf="isShowPassword">
            {{ registrationVendorRequest.password }}
          </ng-container>
        </span>
      </div>
      <div
        class="col-sm-2 text-left px-0 d-inline-flex"
        [ngStyle]="{ 'font-size': '18px' }"
      >
        <em
          class="pir cursor-pointer"
          [ngClass]="isShowPassword ? 'pi-eye-slash' : 'pi-eye'"
          (click)="doTogglePassword()"
        ></em>
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'registration.phone' | translate
      }}</label>
      <div class="col-sm-7 text-left">
        <p>{{ phone }}</p>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'registration.form.registeredOn' | translate
      }}</label>
      <div class="col-sm-7 text-left">
        <p>{{ lastOrganization.name }}</p>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'registration.form.taxNumber' | translate
      }}</label>
      <div class="col-sm-7 text-left">
        <p>{{ taxNumber }}</p>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <div class="col-sm-12 text-left">
        <app-check-box formControlName="isCheckTOC">
          {{ 'registration.form.termsAndCondition1' | translate }}
          <a
            class="text-primary fw-500"
            (click)="doDisplayTermCondition($event)"
            [ngStyle]="{ 'text-decoration': 'underline' }"
            >{{ 'registration.form.termsAndCondition2' | translate }}</a
          >
        </app-check-box>
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <div class="col-sm-12 text-left">
        <app-check-box formControlName="isCheckVendorPolicy">
          {{ 'registration.form.vendorPolicy1' | translate }}
          <a
            class="text-primary fw-500"
            (click)="doDisplayVendorPolicy()"
            [ngStyle]="{ 'text-decoration': 'underline' }"
            >{{ 'registration.form.vendorPolicy2' | translate }}</a
          >
        </app-check-box>
      </div>
    </div>

    <div class="form-group mt-4">
      <app-captcha
        *ngIf="global.config.appInfoResponse.isShowCaptcha"
        formControlName="captcha"
        size="XL"
      ></app-captcha>
    </div>
  </form>
  <div class="button-group button-group-center">
    <div *ngIf="dissappear">
      <app-button color="SECONDARY" (onClick)="doBack()" class="mr-1"
        >{{ 'app.button.edit' | translate }}
      </app-button>
      <app-button
        color="PRIMARY"
        (onClick)="doSave()"
        [disabled]="!formGroup.valid"
        >{{
          'registration.button.continueRegistration' | translate
        }}</app-button
      >
    </div>
    <div *ngIf="appear">
      <app-button color="PRIMARY" (onClick)="doPortal()">{{
        'app.button.ok' | translate
      }}</app-button>
    </div>
  </div>
</app-card>
