import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipe/pipe.module';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppAccordionModule } from '../../app-accordion/app-accordion.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppChosenModule } from '../../app-chosen/app-chosen.module';
import { AppCountModule } from '../../app-count/app-count.module';
import { AppCurrencyModule } from '../../app-currency/app-currency.module';
import { AppDatePickerXModule } from '../../app-date-picker-x/app-date-picker-x.module';
import { AppDropdownSelectModule } from '../../app-dropdown-select/app-dropdown-select.module';
import { AppRadioModule } from '../../app-radio/app-radio.module';
import { AppTableXModule } from '../../app-table-x/components/app-table-x/app-table-x.module';
import { AppTaggingModule } from '../../app-tagging/app-tagging.module';
import { AppTextAreaModule } from '../../app-text-area/app-text-area.module';
import { AppTextFieldModule } from '../../app-text-field/app-text-field.module';
import { AppTextGeneratedModule } from '../../app-text-generated/app-text-generated.module';
import { AppToggleSwitchModule } from '../../app-toggle-switch/app-toggle-switch.module';
import { TableModule } from '../../table/table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupGuaranteeComponent } from './app-popup-guarantee.component';
import { AppPopupGuaranteeService } from './app-popup-guarantee.service';
@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppChosenModule,
    AppTextFieldModule,
    AppTextAreaModule,
    AppButtonModule,
    AppDropdownSelectModule,
    AppRadioModule,
    AppDatePickerXModule,
    AppToggleSwitchModule,
    AppAccordionModule,
    AppTableXModule,
    AppCountModule,
    AppCurrencyModule,
    AppTaggingModule,
    PipesModule,
    TableModule,
    AppTextGeneratedModule
  ],
  declarations: [AppPopupGuaranteeComponent],
  entryComponents: [AppPopupGuaranteeComponent],
  providers: [AppPopupGuaranteeService],
  exports: [AppPopupGuaranteeComponent]
})
export class AppPopupGuaranteeModule {}
