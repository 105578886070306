/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\widget.directive.ts
 
 * Created Date: Thursday, November 18th 2021, 11:00:41 am

 *
*
 */

import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AppDynamicComponentService } from '../../../core/services/app-dynamic-component.service';
import { Log } from '../../../core/services/logger';
import { WidgetItem } from './domain/widget-item';
import { WidgetService } from './widget.service';
@Directive({
  selector: '.widget-item-content[model]'
})
export class WidgetDirective implements OnInit {
  @Input() model: WidgetItem;
  constructor(
    public widgetService: WidgetService,
    private appDynamicComponentService: AppDynamicComponentService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    const widgetObject: { className: string; component: object } =
      this.widgetService.getWidget(
        this.model.widget.widget.module.code.toLowerCase()
      );
    if (widgetObject) {
      this.elementRef.nativeElement.parentElement.classList.add(
        widgetObject.className
      );
      const component = widgetObject.component;
      if (component) {
        this.appDynamicComponentService.create(
          component,
          this.elementRef.nativeElement,
          { model: this.model }
        );
      }
    } else {
      new Log(this).error(
        `Widget with code '${this.model.widget.widget.module.code.toLowerCase()}' is not registered on widget service`
      );
    }
  }
}
