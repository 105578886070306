

export interface AccordionItem<T> {
  header: string;
  selector?: string;
  componentInstance?: object;
  origin?: T;
}
export class AccordionItemModel<T> {
  id?: string;
  isExpanded?: boolean;
  selector?: string;
  header?: string;
  isLoading?: boolean;
  isLoaded?: boolean;
  componentInstance?: object;
  routerParams?: Map<string, any> = new Map();
  origin?: T;
  constructor(accordion: AccordionItem<T>) {
    this.isExpanded = false;
    this.isLoading = true;
    this.isLoaded = false;
    this.header = accordion.header;
    this.selector = accordion.selector;
    this.origin = accordion.origin;
    this.componentInstance = accordion.componentInstance || {};
  }

  setStateReady(): void {
    this.isLoading = false;
    this.isLoaded = true;
  }

  setComponentInstance(componentInstance: object = {}): void {
    const componentInstanceAssign = Object.assign(
      componentInstance,
      this.componentInstance
    );
    this.componentInstance = componentInstanceAssign;
  }
}
