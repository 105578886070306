import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Qc } from '../../core/bean/qc';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
@Component({
  templateUrl: './qc-outgoing.component.html'
})
export class QcOutgoingComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Qc>;
  public isLoading: boolean;

  constructor(
    translateService: TranslateService
  ) {
    super('qc', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'processDate',
        header: 'table.column.createdDate',
        format: FieldFormatEnum.ShortDate
      },
      { field: 'product', header: 'table.column.productName' },
      { field: 'destination', header: 'table.column.destination' }
     
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.global.routerParams.set('qcTypeId', 4);
    this.global.routerParams.set('backUrl', '/pages/qc-outgoing/');
    this.router.navigate(['/pages/qc/add']);
  }

  public doEdit(qc: Qc): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('qcTypeId', 4);
    this.global.routerParams.set('qcId', qc.id);
    this.global.routerParams.set('backUrl', '/pages/qc-outgoing/');
    this.router.navigate(['/pages/qc/edit']);
  }

  public doDelete(qc: Qc): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<Qc>>('/qc/delete', qc)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  
}
