<div class="app-tab  card card-secondary card-tabs" >
  <div class="card-header p-0 pt-1">
    <ul class="nav nav-tabs">
      <app-tab-item *ngFor="let tab of model && model.tabList" 
        [header]="tab.name" 
        [disabled]="tab.disabled"
        [selected]="model.currentTab.code === tab.code" 
        [isDynamic]="!!model"
        (onClick)="doChangeTab(tab)"></app-tab-item>
      <ng-content select="app-tab-item[header]"></ng-content>
    </ul>
  </div>
  <div class="tab-content p-3" [ngClass]="bordered ? 'bordered' : ''">
    <ng-content></ng-content>
    <ng-container *ngIf="model">
      <ng-container *ngTemplateOutlet="
          tabFooterTmpl;
          context: { $implicit: model.currentTab }
        "></ng-container>
    </ng-container>
  </div>
</div>