

import { AccordionState } from '../domain/accordion-state';
export const expandOrCollapseAllAccordionAction = (
  state: AccordionState,
  payload: { isExpandAll: boolean }
) => {
  state.isExpandAll = payload.isExpandAll;
  for (let key of state.accordionItemMap.keys()) {
    state.accordionItemMap.get(key).isExpanded = payload.isExpandAll;
  }
};
