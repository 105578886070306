/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\core\components\table\plugins\default-header-plugin\default-header-plugin.module.ts
 
 * Created Date: Wednesday, October 13th 2021, 10:56:03 am

 *
*
 */

import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../vendor/vendor.module';
import { TableDefaultHeaderPluginComponent } from './default-header-plugin.component';
@NgModule({
  imports: [VendorModule],
  declarations: [TableDefaultHeaderPluginComponent],
  exports: [TableDefaultHeaderPluginComponent]
})
export class TableDefaultHeaderPluginModule {}
