/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\action\set-widget-editable-action.ts
 
 * Created Date: Thursday, November 18th 2021, 11:11:17 am

 *
*
 */

import { WidgetState } from '../domain/widget-state';

export const setWidgetEditableAction = (state: WidgetState): void => {
  state.isShowWidgetEditable = !state.isShowWidgetEditable;
  state.setStateReady();
};
