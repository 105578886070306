import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TreeEvent, TreeModel } from '../../core/components/app-tree';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './item-category.component.html'
})
export class ItemCategoryComponent extends BaseModuleComponent {
  public treeModel: TreeModel = new TreeModel(this.moduleCode, 'WRITE');
  public isViewCode: boolean;
  constructor(translateService: TranslateService) {
    super('item-category', translateService);
  }

  public onInit(): void {
    this.buildTreeFormGroup();
    this.setStateReady();
  }

  public buildTreeFormGroup(): void {
    this.treeModel.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      description: [null, Validators.maxLength(512)],
      tempId: [null],
      parentId: [null],
      hasChild: [null]
    });
  }

  public doOnEventChange(treeEvent: TreeEvent): void {
    if (treeEvent.action === 'EDIT') {
      this.treeModel.formGroup.get('code').setIsView(true);
    } else {
      this.treeModel.formGroup.get('code').setIsView(false);
    }
    // this.isViewCode = treeEvent.action === 'EDIT';
    if (treeEvent.type === 'ON-SHOW-POPUP') {
      const id = this.formGroup.value.id;
      treeEvent.formGroup.get('code').setAsyncValidators(
        Validators.existsAsync('/item-category/isAlreadyExist', null, null, {
          id
        })
      );
    } else if (treeEvent.type === 'ON-CLOSE-POPUP') {
      treeEvent.formGroup.get('code').clearAsyncValidators();
    }
  }
}
