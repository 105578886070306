import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetLoginInformationComponent } from './widget-login-information.component';
@NgModule({
  imports: [VendorModule, WidgetCardModule],
  declarations: [WidgetLoginInformationComponent],
  exports: [WidgetLoginInformationComponent]
})
export class WidgetLoginInformationModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetLoginInformationComponent.moduleCode,
      WidgetLoginInformationComponent,
      'w-100'
    );
  }
}
