/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\hyperlink-plugin\hyperlink-plugin.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 11:08:16 pm

 *
*
 */

import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../vendor/vendor.module';
import { TableHyperlinkPluginComponent } from './hyperlink-plugin.component';
@NgModule({
  imports: [VendorModule],
  declarations: [TableHyperlinkPluginComponent],
  exports: [TableHyperlinkPluginComponent]
})
export class TableHyperlinkPluginModule {}
