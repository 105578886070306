<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="module.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label"
            >{{ 'module.form.moduleGroup' | translate }}
          </label>
          <div class="col-sm-4 text-left">
            {{ formGroup.controls.moduleGroupCode.value }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'module.form.code' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ formGroup.controls.code.value }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'module.form.name' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ formGroup.controls.name.value }}
          </div>
        </div>

        <div
          *ngIf="
            moduleType.code ===
              global.appConstant.core.MODULE_TYPE_CODE_MODULE ||
            moduleType.code === global.appConstant.core.MODULE_TYPE_CODE_WIDGET
          "
          class="form-group row text-sm-right required"
        >
          <label class="col-sm-3 control-label">{{
            'module.form.status' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box formControlName="isActive" [optionList]="statusList">
            </app-combo-box>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label"
            >{{ 'module.form.description' | translate }}
          </label>
          <div class="col-sm-4 text-left">
            {{ formGroup.controls.description.value }}
          </div>
        </div>

        <div
          *ngIf="formGroup.get('isImport').value === true"
          class="form-group row text-sm-right required"
        >
          <label class="col-sm-3 control-label">{{
            'module.form.file' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <app-upload
              size="XL"
              formControlName="templateFile"
              [fileUploader]="fileUploader"
            ></app-upload>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'module.form.moduleType' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ this.moduleType.name }}
          </div>
        </div>

        <div
          *ngIf="
            moduleType.code === global.appConstant.core.MODULE_TYPE_CODE_MODULE
          "
          class="form-group row text-sm-right"
        >
          <label class="col-sm-3 control-label"></label>
          <div class="col-sm-7 row text-left">
            <app-check-box
              [ngStyle]="{ 'padding-left': '3%' }"
              formControlName="isStage"
              >{{ 'module.form.stage' | translate }}
            </app-check-box>
            <app-check-box
              [ngStyle]="{ 'padding-left': '3%' }"
              formControlName="isStarter"
            >
              {{ 'module.form.starter' | translate }}
            </app-check-box>
            <app-check-box
              [ngStyle]="{ 'padding-left': '3%' }"
              formControlName="isNumberGenerator"
            >
              {{ 'module.form.generator' | translate }}</app-check-box
            >
            <app-check-box
              [ngStyle]="{ 'padding-left': '3%' }"
              formControlName="isSchedule"
            >
              {{ 'module.form.schedule' | translate }}</app-check-box
            >
            <app-check-box
              [ngStyle]="{ 'padding-left': '3%' }"
              formControlName="isVendorAvailable"
            >
              {{ 'module.form.vendorAvailable' | translate }}</app-check-box
            >
            <app-check-box
              [ngStyle]="{ 'padding-left': '3%' }"
              formControlName="isImport"
              [tooltip]="tooltipContent"
              >{{ 'module.form.import' | translate }}
            </app-check-box>
            <!-- <div class="custom-tooltip"> -->
            <ng-template #tooltipContent>
              <div [ngStyle]="{ width: '150px' }">
                <span class="d-block">{{
                  'module.tooltip.code' | translate
                }}</span>
              </div>
            </ng-template>
            <!-- </div> -->
          </div>
        </div>
        <div
          *ngIf="
            formGroup.get('isStage').value === true ||
            formGroup.get('isStarter').value === true
          "
          class="form-group row text-sm-right"
        >
          <label class="col-sm-3 control-label">
            {{ 'module.form.approvalController' | translate }}
          </label>
          <div class="col-sm-7 row text-left">
            <app-check-box
              [ngStyle]="{ 'padding-left': '3%' }"
              formControlName="isApprove"
              >{{ 'module.form.approve' | translate }}
            </app-check-box>
            <app-check-box
              [ngStyle]="{ 'padding-left': '3%' }"
              formControlName="isReject"
            >
              {{ 'module.form.reject' | translate }}
            </app-check-box>
            <app-check-box
              [ngStyle]="{ 'padding-left': '3%' }"
              formControlName="isHold"
            >
              {{ 'module.form.hold' | translate }}</app-check-box
            >
            <app-check-box
              [ngStyle]="{ 'padding-left': '3%' }"
              formControlName="isRevision"
            >
              {{ 'module.form.revision' | translate }}</app-check-box
            >
          </div>
        </div>
        <div
          *ngIf="formGroup.get('isSchedule').value === true"
          class="form-group row text-sm-right"
        >
          <label class="col-sm-3 control-label">
            {{ 'module.form.scheduleController' | translate }}
          </label>
          <div class="col-sm-7 row text-left">
            <app-check-box
              [ngStyle]="{ 'padding-left': '3%' }"
              formControlName="isStartDate"
              >{{ 'module.form.startdate' | translate }}
            </app-check-box>
            <app-check-box
              [ngStyle]="{ 'padding-left': '3%' }"
              formControlName="isEndDate"
            >
              {{ 'module.form.enddate' | translate }}
            </app-check-box>
            <app-check-box
              [ngStyle]="{ 'padding-left': '3%' }"
              formControlName="isLocation"
            >
              {{ 'module.form.location' | translate }}</app-check-box
            >
          </div>
        </div>
        <!-- <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">
            {{ 'module.form.delegation' | translate }}</label
          >
          <div class="col-sm-9 text-left">
            <app-radio position="HORIZONTAL" formControlName="delegation">
            </app-radio>
          </div>
        </div> -->
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">
            {{ 'module.form.hierarchy' | translate }}</label
          >
          <div class="col-sm-9 text-left">
            <app-radio
              position="HORIZONTAL"
              formControlName="hierarchy"
              [optionList]="hierarchyOptionList"
            >
            </app-radio>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <app-button
            class="mr-1"
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button
            *ngIf="
              moduleType.code ===
                global.appConstant.core.MODULE_TYPE_CODE_MODULE ||
              moduleType.code ===
                global.appConstant.core.MODULE_TYPE_CODE_WIDGET
            "
            (onClick)="doSave()"
            [disabled]="formSaving"
          >
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
