import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export const visibilityAnimations = (
  animateTime = '250ms'
): AnimationTriggerMetadata => {
  return trigger('visibility', [
    state('visible', style({ opacity: 1 })),
    state('hidden', style({ opacity: 0, display: 'none' })),
    transition('hidden => visible', animate(animateTime)),
    transition('visible => hidden', animate(animateTime))
  ]);
};