/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\currency-plugin\currency-plugin.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 9:33:57 pm

 *
*
 */

import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../vendor/vendor.module';
import { AppCurrencyModule } from '../../../app-currency/app-currency.module';
import { TableCurrencyPluginComponent } from './currency-plugin.component';
@NgModule({
  imports: [VendorModule, AppCurrencyModule],
  declarations: [TableCurrencyPluginComponent],
  exports: [TableCurrencyPluginComponent]
})
export class TableCurrencyPluginModule {}
