<app-popup header="app-popup-coa.title">
  <app-table
    [tableResponse]="tableResponse"
    [isServerSide]="true"
    stringUrl="/coa/index"
  >
    <ng-template #actionButtons let-rowData>
      <app-button color="PRIMARY" (onClick)="doChoose(rowData)">{{
        'app.button.select' | translate
      }}</app-button>
    </ng-template>
  </app-table>
</app-popup>
