import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import {
  Component,
  ContentChild,
  Input,
  OnChanges,
  TemplateRef
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ArrayUtils, TextUtils } from '../../utils';
import { PickItemEvent } from './events/pick-item-event';
import { PickListModel } from './model/pick-list-model';
@Component({
  selector: 'app-pick-list',
  templateUrl: './app-pick-list.component.html',
  styleUrls: ['./app-pick-list.component.scss']
})
export class AppPickListComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() public sourceTitle: string;
  @Input() public targetTitle: string;
  @Input() public searchSourcePlaceholder: string;
  @Input() public searchTargetPlaceholder: string;
  @Input() public isShowButtonMoveAllItem = true;
  @Input() public model: PickListModel<any>;
  @ContentChild('source') sourceTmpl: TemplateRef<any>;
  @ContentChild('target') targetTmpl: TemplateRef<any>;
  @ContentChild('item') itemTmpl: TemplateRef<any>;

  @ContentChild('headerSource') headerSourceTmpl: TemplateRef<any>;
  @ContentChild('headerTarget') headerTargetTmpl: TemplateRef<any>;

  public sourceItemList: Array<any> = new Array();
  public targetItemList: Array<any> = new Array();
  public pickItemEvent: PickItemEvent = new PickItemEvent();
  private searchString: string;
  public currentTarget: 'SOURCE' | 'TARGET';
  public generatedId: string = TextUtils.generateRandomString();
  constructor() {
    super('app-pick-list');
  }

  public onInit(): void {
    this.doSetModelOptions();
    this.doSetSourceListAndTargetList();
  }

  public doSetModelOptions(): void {
    if (this.model) {
      const {
        sourceTitle,
        targetTitle,
        searchSourcePlaceholder,
        searchTargetPlaceholder,
        isShowButtonMoveAllItem
      } = this;
      const options = {
        sourceTitle,
        targetTitle,
        searchSourcePlaceholder,
        searchTargetPlaceholder,
        isShowButtonMoveAllItem
      };
      Object.assign(this.model, options);
    }
  }

  ngOnChanges(): void {
    this.doSetSourceListAndTargetList();
    this.doSetModelOptions();
  }

  private doSetSourceListAndTargetList(): void {
    if (this.model) {
      this.sourceItemList = this.model.sourceItemList;
      this.targetItemList = this.model.targetItemList;
    }
  }

  public onSearch(searchString: string, target: 'SOURCE' | 'TARGET'): void {
    if (target === 'SOURCE') {
      this.sourceItemList = ArrayUtils.filterArrayByText(
        this.model.sourceItemList,
        searchString, this.model.viewField
      );
    } else {
      this.targetItemList = ArrayUtils.filterArrayByText(
        this.model.targetItemList,
        searchString, this.model.viewField
      );
    }
    this.currentTarget = target;
    this.searchString = searchString;
  }

  public drop(event: CdkDragDrop<string[]>): void {
    event.previousContainer === event.container
      ? moveItemInArray(
          event.container.data,
          event.previousIndex,
          event.currentIndex
        )
      : transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
  }

  public doMoveItem(item: any, target: 'SOURCE' | 'TARGET'): void {
    if (this.pickItemEvent.isPick) {
      if (target === 'SOURCE') {
        const index = this.model.targetItemList.findIndex(
          i => JSON.stringify(i) === JSON.stringify(item)
        );
        this.model.sourceItemList = [
          this.model.targetItemList[index],
          ...this.model.sourceItemList];
        this.model.targetItemList.splice(index, 1);
        this.targetItemList = this.model.targetItemList;
        this.sourceItemList = ArrayUtils.sortArray(this.model.sourceItemList);
      } else {
        const index = this.model.sourceItemList.findIndex(
          i => i.id === item.id
        );
        this.model.targetItemList = [
          this.model.sourceItemList[index],
          ...this.model.targetItemList
        ];
        this.model.sourceItemList.splice(index, 1);
        this.sourceItemList = this.model.sourceItemList;
        this.targetItemList = ArrayUtils.sortArray(this.model.targetItemList);
      }
      this.onSearch(
        this.searchString,
        target === 'SOURCE' ? 'TARGET' : 'SOURCE'
      );
    }
  }

  public doCheckColumn(event: any, item: any): void {
    if (event.target.checked) {
      const index = this.model.sourceItemList.findIndex(
        i => JSON.stringify(i) === JSON.stringify(item)
      );
      this.model.targetItemList.push(this.model.sourceItemList[index]);
      this.targetItemList = this.model.targetItemList;
      this.onSearch(this.searchString, 'SOURCE');
      item.isChecked = true;
    } else {
      const index = this.model.targetItemList.findIndex(
        i => JSON.stringify(i) === JSON.stringify(item)
      );
      if (this.model.sourceItemList.includes(this.model.targetItemList[index])) {
        this.model.targetItemList.splice(index, 1);
      }
      this.onSearch(this.searchString, 'SOURCE');
      item.isChecked = false;
    }
  }

  public doDeleteTargetItem(item: any, target: 'SOURCE' | 'TARGET'): void {
    const index = this.model.targetItemList.findIndex(
      i => JSON.stringify(i) === JSON.stringify(item)
    );
    if (target === 'SOURCE') {
      if (!this.model.sourceItemList.includes(this.model.targetItemList[index])) {
        this.model.sourceItemList.push(this.model.targetItemList[index]);
      }
      this.model.targetItemList.splice(index, 1);
    }
    this.onSearch(this.searchString, target === 'SOURCE' ? 'TARGET' : 'SOURCE');
  }

  public doMoveAllItemToSource(): void {
    this.model.sourceItemList = [
      ...this.model.sourceItemList,
      ...this.model.targetItemList
    ];
    this.model.targetItemList = [];
    this.targetItemList = this.model.targetItemList;
    this.sourceItemList = this.model.sourceItemList;
  }

  public doMoveAllItemToTarget(): void {
    this.model.targetItemList = [
      ...this.model.targetItemList,
      ...this.model.sourceItemList
    ];
    this.model.sourceItemList = [];
    this.sourceItemList = this.model.sourceItemList;
    this.targetItemList = this.model.targetItemList;
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
