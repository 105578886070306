/*
 * Filename:  WebContent\src\app\core\components\app-popup\app-popup-progress-bar-download\popup-progress-bar-download-model.ts
 * Path:  WebContent
 * Created Date: Tuesday, September 7th 2021, 5:00:02 pm

 *
*
 */
import { EventEmitter } from '@angular/core';
export class ProgressBarDownloadModel {
  progress = 0;
  progressChanges: EventEmitter<number> = new EventEmitter();
  public setProgress(progress: number): void {
    this.progress = progress;
    this.progressChanges.emit(progress);
  }

  public reset(): void {
    this.progress = 0;
    this.progressChanges.emit(0);
  }
}
