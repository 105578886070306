/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\drop-down-select-plugin\drop-down-select-plugin.component.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 10:51:57 pm

 *
*
 */

import { Component } from '@angular/core';
import { BaseTableFormPluginComponent } from '../../base/base-table-form-plugin.component';
import { DropDownSelectPlugin } from '../../interface/available-table-plugin';

@Component({
  templateUrl: './drop-down-select-plugin.component.html'
})
export class TableDropDownSelectPluginComponent extends BaseTableFormPluginComponent {
  public dropDownSelectPlugin: DropDownSelectPlugin;
  constructor() {
    super();
  }

  protected onInitBaseTableForm(): void {
    this.setInitializationPluginState();
  }

  private setInitializationPluginState(): void {
    this.dropDownSelectPlugin = this.tablePluginData
      .plugin as DropDownSelectPlugin;
  }
}
