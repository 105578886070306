/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\pipe\table-pipe.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Friday, September 10th 2021, 5:56:11 pm

 *
*
 */
import { NgModule } from '@angular/core';
import { ResolveIsShoWCheckBoxPipe } from './resolve-is-show-check-box.pipe';
import { ResolveTableRowIsSelectedPipe } from './resolve-table-row-is-selected.pipe';
import { ResolveTableRowPositionPipe } from './resolve-table-row-position.pipe';
@NgModule({
  declarations: [
    ResolveTableRowPositionPipe,
    ResolveTableRowIsSelectedPipe,
    ResolveIsShoWCheckBoxPipe
  ],
  exports: [
    ResolveTableRowPositionPipe,
    ResolveTableRowIsSelectedPipe,
    ResolveIsShoWCheckBoxPipe
  ]
})
export class TablePipeModule {}
