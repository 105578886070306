<div
  class="promise-table lpb-4"
  [@fullView]="isFullViewTable ? 'fullScreen' : 'default'"
  [ngClass]="[
    'promise-table-' + theme | lowercase,
    isShowPagination ? '' : 'promise-table-sticky',
    customClass ? customClass : ''
  ]"
>
  <div
    class="pt-header"
    *ngIf="
      !state.isNotDataWithFirstLoad ||
      (!state.isLoading && state.isNotDataWithFirstLoad)
    "
    [ngClass]="customClassHeader ? customClassHeader : ''"
  >
    <div
      class="pth-top lpb-1 lmb-1 border-bottom"
      *ngIf="
        header ||
        isShowButtonModifyTable ||
        isShowButtonMaximize ||
        headerButtonsTmpl
      "
    >
      <div class="table-title" *ngIf="header">
        <h3 class="title">{{ header | translate }}</h3>
      </div>
      <ng-container
        *ngTemplateOutlet="tableHeaderActionTemplate"
      ></ng-container>
    </div>
    <div
      class="pth-bottom lpb-1"
      *ngIf="
        !state.isNotDataWithFirstLoad &&
        (headerFilterGroupTemplate ||
          headerFilterTemplate ||
          isShowButtonExpandCollapse ||
          isShowSearch)
      "
    >
      <!-- filter section -->
      <ng-container *ngIf="headerFilterTemplate && alertWrapperTmpl">
        <ng-container *ngTemplateOutlet="alertWrapperTmpl"></ng-container>
      </ng-container>
      <div
        class="pthb-filter"
        [ngClass]="headerFilterTemplate && alertWrapperTmpl ? 'with-alert' : ''"
      >
        <div
          class="pthbf-left"
          *ngIf="
            headerFilterGroupTemplate ||
            headerFilterTemplate ||
            isShowButtonExpandCollapse
          "
        >
          <div class="app-table-filter-item" *ngIf="headerFilterGroupTemplate">
            <div
              class="pthbfl-item btn-filter"
              *ngIf="headerFilterGroupTemplate"
            >
              <app-button
                (onClick)="
                  isExpandedFilterGroupContent = !isExpandedFilterGroupContent
                "
              >
                <span class="filter-text">
                  {{ 'app-table.button.filters' | translate }}
                </span>
                <em
                  class="pir pi-chevron-circle-down pi-2x btn-icon-right"
                  [@rotate]="
                    !isExpandedFilterGroupContent ? 'default' : 'rotated'
                  "
                ></em>
              </app-button>
            </div>
          </div>
          <ng-container *ngIf="headerFilterTemplate">
            <ng-container
              *ngTemplateOutlet="headerFilterTemplate"
            ></ng-container>
          </ng-container>
          <div class="pthbfl-item" *ngIf="isShowButtonExpandCollapse">
            <div class="button-group button-group-left">
              <app-button
                color="PRIMARY"
                (onClick)="doExpandOrCollapseAllTableRow('EXPAND')"
                >{{ 'app-table.button.expandAll' | translate }}</app-button
              >
              <app-button
                color="PRIMARY"
                [outline]="true"
                (onClick)="doExpandOrCollapseAllTableRow('COLLAPSE')"
                >{{ 'app-table.button.collapseAll' | translate }}</app-button
              >
            </div>
          </div>
        </div>
        <div class="pthbf-right" *ngIf="isShowSearch">
          <div class="pthbfr-item">
            <div class="custom-search">
              <em class="pir pi-search"></em>
              <input
                #inputSearch
                type="text"
                class="form-control"
                style="box-shadow: 0px 0px 0px 0px"
                [placeholder]="'app-table.search' | translate"
                [attr.aria-label]="'app-table.search' | translate"
                (input)="onInputSearch($event.target.value)"
              />
              <em
                class="pir pi-times"
                (click)="doResetInputSearch(inputSearch)"
              ></em>
            </div>
          </div>
        </div>
      </div>
      <!-- filter group section -->
      <div
        class="pthb-filter d-block"
        [@expandedCollapsed]="
          !isExpandedFilterGroupContent ? 'collapsed' : 'expanded'
        "
      >
        <div class="lmt-1" *ngIf="alertWrapperTmpl">
          <ng-container *ngTemplateOutlet="alertWrapperTmpl"></ng-container>
        </div>
        <div
          class="pthbf-group"
          [ngClass]="alertWrapperTmpl ? 'with-alert' : 'lmt-1'"
        >
          <ng-container *ngTemplateOutlet="headerFilterGroupTemplate">
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <ng-container
    *ngIf="
      !headerFilterTemplate && !headerFilterGroupTemplate && alertWrapperTmpl
    "
  >
    <ng-container *ngTemplateOutlet="alertWrapperTmpl"></ng-container>
  </ng-container>
  <div
    class="pt-body"
    [ngClass]="[
      !headerFilterTemplate && !headerFilterGroupTemplate && alertWrapperTmpl
        ? 'with-alert'
        : '',
      customClassBody ? customClassBody : ''
    ]"
    [ngStyle]="{
      height:
        !isShowPagination && isShowScrollbar
          ? state.pagination.totalRecords < state.pagination.perPage
            ? 'fit-content'
            : '450px'
          : 'fit-content'
    }"
  >
    <div
      class="ptb-loading"
      *ngIf="!state.isNotDataWithFirstLoad && state.isLoading"
    >
      {{ 'app-table.processing' | translate }}
    </div>

    <ng-container
      *ngTemplateOutlet="
        state.isNotDataWithFirstLoad && !state.isLoading
          ? noDataTemplate
          : state.isNotDataWithFirstLoad && state.isLoading
          ? loadingTemplate
          : tableTemplate
      "
    ></ng-container>
  </div>
  <div
    class="pt-footer lmt-1"
    *ngIf="!state.isNotDataWithFirstLoad"
    [ngClass]="customClassFooter ? customClassFooter : ''"
  >
    <ng-container *ngTemplateOutlet="tableFooterTmpl"></ng-container>
    <ng-container *ngIf="isShowPagination">
      <app-pagination-x
        [model]="state.pagination"
        (onChange)="onChangePage($event)"
      >
        <ng-template #contentLeft>
          <div class="form-group d-flex align-items-center m-0">
            {{ 'app-table.show' | translate }}
            <select
              class="form-control form-control-sm"
              id="exampleFormControlSelect1"
              style="width: fit-content"
              (change)="onChangeFilterPerPage($event.target.value)"
              [ngStyle]="{ margin: '0 8px' }"
            >
              <option
                *ngFor="let page of state.perPageFilterList"
                [value]="page"
                [selected]="page === state.pagination.perPage"
                >{{ page }}</option
              >
            </select>
            {{ 'app-table.pagination.itemsOf' | translate }}
            {{ state.pagination.totalRecords }}
            {{ 'app-table.pagination.entries' | translate }}
          </div>
        </ng-template>
      </app-pagination-x>
    </ng-container>
  </div>
</div>

<!-- start: table header action -->
<ng-template #tableHeaderActionTemplate>
  <div
    class="table-header-action"
    *ngIf="isShowButtonModifyTable || isShowButtonMaximize || headerButtonsTmpl"
  >
    <div class="button-group button-group-right button-group-spacing-sm">
      <ng-container *ngIf="!state.isNotDataWithFirstLoad">
        <app-button
          *ngIf="isShowButtonModifyTable"
          (onClick)="doShowPopupModifyTable()"
        >
          <em class="pir pi-cog mr-1"> </em>
          {{ 'app-table.button.modifyTable' | translate }}
        </app-button>
        <app-button
          color="PRIMARY"
          *ngIf="isShowButtonMaximize"
          (onClick)="doSetFullViewTable()"
        >
          <em
            class="fas mr-1"
            [ngClass]="
              isFullViewTable ? 'pir pi-compress-alt' : 'pir pi-expand-alt'
            "
          ></em>
          {{
            (isFullViewTable
              ? 'app-table.button.minimize'
              : 'app-table.button.maximize'
            ) | translate
          }}
        </app-button>
      </ng-container>
      <ng-container *ngTemplateOutlet="headerButtonsTmpl"></ng-container>
    </div>
  </div>
</ng-template>
<!-- end: table header action -->

<!-- start: not data template -->
<ng-template #noDataTemplate>
  <div class="no-data-table-wrapper lpy-4">
    <ng-container *ngIf="noDataTmpl && !state.tableRequest.globalFilter">
      <ng-container *ngTemplateOutlet="noDataTmpl"></ng-container>
    </ng-container>

    <ng-container
      *ngIf="!noDataTmpl || (noDataTmpl && state.tableRequest.globalFilter)"
    >
      <div class="ndtw-icon">
        <div
          class="pvc"
          [ngClass]="
            state.tableRequest.globalFilter ? 'pv-search' : 'pv-file-list'
          "
        ></div>
      </div>
      <div class="ndtw-content">
        <ng-container *ngIf="state.tableRequest.globalFilter">
          <span class="ndtwc-text">
            {{ 'app-table.noResultsFor' | translate }} "{{
              state.tableRequest.globalFilter
            }}"
          </span>
        </ng-container>
        <ng-container *ngIf="!state.tableRequest.globalFilter">
          <span class="ndtwc-text">
            {{ 'app-table.noDataAvailable' | translate }}
          </span>
          <span class="ndtwc-text">
            {{ 'app-table.letsTryToAddList' | translate }}
          </span>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
<!-- end: no data template -->

<ng-template #loadingTemplate>
  <div class="promise-loading lpy-4">
    <div class="loading"></div>
  </div>
</ng-template>

<!-- start: Table Template -->
<ng-template #tableTemplate>
  <perfect-scrollbar (psYReachEnd)="onScrollEnd($event)" #perfectScrollBar>
    <table>
      <thead>
        <ng-container *ngTemplateOutlet="theadRowTemplate"></ng-container>
      </thead>
      <tbody>
        <ng-container *ngTemplateOutlet="tbodyRowTemplate"></ng-container>
      </tbody>
    </table>
  </perfect-scrollbar>
</ng-template>
<!-- end: Table template -->

<!-- start: thead row template -->
<ng-template #theadRowTemplate>
  <tr
    class="tr-action"
    *ngIf="
      (actionButtonsTemplate || isShowCheckBox) &&
      (state.selectedRow.selectedRowList.length > 0 ||
        state.selectedRow.isSelectAll) &&
      isMultipleSelect !== false
    "
  >
    <th class="th-action" colspan="9999">
      <div class="th-action-content">
        <span class="total-selected">
          {{
            state.selectedRow.isSelectAll
              ? 'All Record Selected'
              : state.selectedRow.selectedRowList.length + ' Items Selected'
          }}
        </span>
        <div class="action-button-section">
          <div
            class="button-group button-group-left btn-delete"
            *ngIf="actionButtonsTemplate"
          >
            <ng-container
              *ngTemplateOutlet="
                actionButtonsTemplate;
                context: {
                  $implicit: state.selectedRow.getValue()
                }
              "
            ></ng-container>
          </div>
        </div>
      </div>
    </th>
  </tr>
  <tr *ngFor="let columnList of state.rowColumnList; let i = index">
    <th
      class="col-action"
      rowspan="99"
      *ngIf="i === 0 && (actionButtonsTemplate || isShowCheckBox)"
    >
      <div class="table-title" *ngIf="headerAction">
        <h5 class="title">{{ headerAction | translate }}</h5>
      </div>
      <div class="th-content">
        <div
          class="custom-control custom-checkbox"
          *ngIf="
            isMultipleSelect !== false && !state.model?.options?.checkBoxFn
          "
        >
          <input
            type="checkbox"
            class="custom-control-input"
            [indeterminate]="
              state.selectedRow.selectedRowList.length > 0 &&
              state.selectedRow.selectedRowList.length !==
                state.pagination.totalRecords
            "
            [attr.id]="'customCheck' + generatedId + 0"
            [checked]="
              state.pagination.totalRecords > 0 &&
              state.selectedRow.selectedRowList.length ===
                state.pagination.totalRecords
            "
            (change)="onChangeSelectAllTableRecord($event.target.checked)"
          />
          <label
            class="custom-control-label"
            [attr.for]="'customCheck' + generatedId + 0"
          ></label>
        </div>
      </div>
    </th>
    <th
      class="th-group"
      rowspan="99"
      *ngIf="i === 0 && model.options?.group"
    ></th>
    <th
      table-th
      [ngClass]="[
        column.columnList || column.isSortable === false || !column.header
          ? ''
          : 'sort',
        j >= 0 && j < columnList.length - 1 && columnList[j + 1].columnList
          ? 'border-right border-white'
          : '',
        j > 0 && columnList[j - 1].columnList ? 'border-left border-white' : '',
        column.columnList ? 'border-bottom border-white' : '',
        column.className ? column.className : ''
      ]"
      *ngFor="let column of columnList; let j = index"
      (click)="column.isSortable !== false && doSort(column)"
      [attr.colspan]="column.columnList ? column.columnList.length : 1"
      [attr.rowspan]="!column.columnList ? state.rowColumnList.length - i : 1"
      [column]="column"
      [state]="state"
      [indexI]="i"
      [indexJ]="j"
    >
    </th>
  </tr>
</ng-template>
<!-- end: thead row template -->

<!-- start: tbody row template -->
<ng-template #tbodyRowTemplate>
  <tr class="tr-no-data" *ngIf="!state.isLoading && state.rowList.length === 0">
    <td colspan="9999999" rowspan="7" class="bg-white text-center">
      <ng-container *ngTemplateOutlet="noDataTemplate"></ng-container>
    </td>
  </tr>
  <ng-container *ngFor="let row of state.rowList; let i = index">
    <tr
      table-row
      [ngClass]="row.isExpanded ? 'show' : ''"
      [state]="state"
      [row]="row"
      [position]="i | resolveTableRowPosition: state.pagination"
    >
      <td class="col-action" *ngIf="actionButtonsTemplate || isShowCheckBox">
        <div class="td-content">
          <div
            class="custom-control"
            [ngClass]="
              isMultipleSelect === false ? 'custom-radio' : 'custom-checkbox'
            "
            [hidden]="state | resolveIsShowCheckBox: row"
            (click)="doClickInputCheckbox($event)"
          >
            <input
              [type]="isMultipleSelect === false ? 'radio' : 'checkbox'"
              [name]="generatedId"
              class="custom-control-input"
              [attr.id]="'customCheck' + generatedId + i + 2"
              (change)="onChangeSelectTableRecord($event.target.checked, row)"
              [indeterminate]="
                state.isGroup &&
                ((row.selectedRow.selectedRowList.length > 0 &&
                  row.selectedRow.selectedRowList.length !==
                    row.totalChildrenList) ||
                  (state.selectedRow.isSelectAll &&
                    row.selectedRow.unSelectedRowList.length > 0 &&
                    row.selectedRow.unSelectedRowList.length !==
                      row.totalChildrenList))
              "
              [checked]="
                (state.isGroup ? row.selectedRow : state.selectedRow)
                  | resolveTableRowIsSelected
                    : row
                    : (state.isGroup
                        ? row.selectedRow.isSelectAll
                        : state.selectedRow.isSelectAll)
              "
            />
            <label
              class="custom-control-label"
              [attr.for]="'customCheck' + generatedId + i + 2"
            ></label>
          </div>
        </div>
      </td>
      <td *ngIf="state.isGroup">
        <div class="td-content expand-collapse">
          <em
            class="fas fa-chevron-down cursor-pointer"
            (click)="doExpandOrCollapseTableRow(row)"
            [@rotate]="row.isExpanded ? 'rotated' : 'default'"
          ></em>
        </div>
      </td>
      <td
        table-td
        [attr.colspan]="state.isGroup ? 999 : 1"
        [ngClass]="model.options?.group ? '' : 'cursor-pointer'"
        *ngFor="
          let column of state.isGroup
            ? [model.options.group]
            : state.columnList;
          let j = index
        "
        [state]="state"
        [row]="row"
        [column]="column"
        [indexI]="i"
        [indexJ]="j"
      >
      </td>
    </tr>

    <!-- start: children -->
    <ng-container *ngIf="row.isExpanded">
      <tr
        *ngFor="let r of row.childrenList; let j = index"
        (click)="onClick.emit(r.record)"
      >
        <td class="col-action" *ngIf="actionButtonsTemplate || isShowCheckBox">
          <div class="td-content">
            <div
              class="custom-control"
              [ngClass]="
                isMultipleSelect === false ? 'custom-radio' : 'custom-checkbox'
              "
              (click)="doClickInputCheckbox($event)"
            >
              <input
                [type]="isMultipleSelect === false ? 'radio' : 'checkbox'"
                [name]="generatedId"
                class="custom-control-input"
                [attr.id]="'customCheck' + i + j + 2"
                (change)="
                  onChangeSelectTableRecord($event.target.checked, r, row)
                "
                [checked]="
                  state.selectedRow
                    | resolveTableRowIsSelected: r:row.selectedRow.isSelectAll
                "
              />
              <label
                class="custom-control-label"
                [attr.for]="'customCheck' + i + j + 2"
              ></label>
            </div>
          </div>
        </td>
        <td></td>
        <td
          table-td
          class="cursor-pointer"
          *ngFor="let column of model.columnList; let j = index"
          [row]="r"
          [column]="column"
          [state]="state"
          [indexI]="i"
          [indexJ]="j"
        >
        </td>
      </tr>
      <tr>
        <td
          colspan="99999"
          class="text-center"
          *ngIf="!row.isLoading && row.isShowButtonLoadMore"
        >
          <button
            class="btn btn-primary"
            (click)="doLoadTableRecordChildren(row)"
            >{{ 'app-table.button.loadMore' | translate }}</button
          >
        </td>
      </tr>
      <tr *ngIf="row.isLoading">
        <td colspan="99999">
          <div class="promise-loading">
            <div class="loading"></div>
          </div>
        </td>
      </tr>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="rowTemplate && state.rowList.length > 0">
    <ng-container
      *ngTemplateOutlet="rowTemplate; context: { $implicit: state }"
    ></ng-container>
  </ng-container>
</ng-template>
<!-- end: tbody row template -->
