<app-popup
  header="app-popup-guarantee.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="formGroup.isView"
>
  <div class="main-row-popup-guarantee">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-guarantee.form.guaranteeType' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-dropdown-select
            size="XL"
            [optionList]="guaranteeBondTypeOptionList"
            formControlName="guaranteeBondType"
            (onChange)="onChangeGuaranteeBondType()"
          ></app-dropdown-select>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'app-popup-guarantee.form.guarantorName' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="XL"
            formControlName="guarantorName"
            placeholder="{{ 'initiation.placeholder.inputText' | translate }}"
            maxLength="128"
            type="alphanumeric"
            include="-.\',"
          >
          </app-text-field>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-guarantee.form.number' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-generated
            size="XL"
            formControlName="code"
            maxLength="64"
            [moduleCode]="moduleCode"
            numberGeneratorCode="NUMBER"
            autofocus="true"
          ></app-text-generated>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-guarantee.form.percentageValue' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="XL"
            type="decimal"
            formControlName="percentageValue"
            maxLength="5"
            groupText="%"
            (onInput)="onInputPercentage()"
          >
          </app-text-field>
          <label *ngIf="formGroup.isView">%</label>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-guarantee.form.amount' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ amount | resolveNumberToCurrency }}
          <strong>IDR</strong>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'app-popup-guarantee.form.guaranteeDate' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          <app-date-picker-x
            size="XL"
            formControlName="submissionDate"
            [minDate]="minDate"
          ></app-date-picker-x>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-guarantee.form.period' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-date-picker-x
            size="XL"
            formControlName="date"
            [range]="true"
            minDate="currentDate"
            maxDate="endDateValidate"
          >
          </app-date-picker-x>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-guarantee.form.description' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-area
            size="XL"
            formControlName="description"
            maxLength="2000"
          ></app-text-area>
        </div>
      </div>

      <ng-template *ngIf="!formGroup.isView" #modalFooter let-activeModal>
        <div class="button-group button-group-center mb-5 mt-5">
          <app-button
            color="SECONDARY"
            [outline]="true"
            (onClick)="activeModal.close(true)"
            >{{ 'app.button.cancel' | translate }}</app-button
          >
          <app-button color="PRIMARY" (onClick)="doSave()">{{
            'app.button.add' | translate
          }}</app-button>
        </div>
      </ng-template>
    </form>
  </div>
</app-popup>
