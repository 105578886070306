/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\core\widgets\components\app-widget-card-x\app-widget-card-x.module.ts
 
 * Created Date: Saturday, October 23rd 2021, 11:02:46 am

 *
*
 */

import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppWidgetCardXComponent } from './app-widget-card-x.component';
@NgModule({
  imports: [VendorModule],
  declarations: [AppWidgetCardXComponent],
  exports: [AppWidgetCardXComponent]
})
export class AppWidgetCardXModule {}
