<div class="portal-news">
  <div class="promise-container promise-container-lg">
    <app-card header="portal.home.news.title">
      <app-portal-table
        [model]="model"
        [isShowButtonChangeView]="true"
        (onClick)="doOpenPopupNews($event)"
        [isLoading]="formLoading"
      >
        <ng-template #headerFilter>
          <app-portal-table-filter
            [recordList]="filterOptionList"
            [activeIndex]="0"
            (onClick)="onClickFilterTable($event)"
          >
            <ng-template #item let-data>
              {{ data.name | translate }}
            </ng-template>
          </app-portal-table-filter>
        </ng-template>
        <ng-template #row let-item>
          <div class="news-image">
            <!-- [attr.src]=" global.config.BACKEND_ADDRESS + '/' + model.stringUrl +
            '/file/view/' + image.uploadedFileName + '/IMG_ITEM_CATALOG' |
            resolveImgSrc | async " -->
            <img
              [src]="
                global.config.BACKEND_ADDRESS +
                '/news/file/view/' +
                item.imgFile?.uploadedFileName +
                '/' +
                global.appConstant.fileType.IMG_NEWS
              "
              alt="item-image"
            />
          </div>
          <div class="news-desc">
            <h4 class="news-title">{{ item.title }}</h4>
            <div
              #newsItemElement
              class="news-content"
              [innerHtml]="item.body | readMore: 128"
              maxLine="2"
            ></div>
            <div class="news-footer h4">
              <a
                href="#"
                (click)="doReadMore($event, item)"
                *ngIf="newsItemElement | isShowReadMore"
              >
                {{ 'app.button.readMore' | translate }}
              </a>
              <div class="nf-view text-secondary">
                <em class="pir pi-eye"></em>
                <span class="total-view">{{ item.viewCount }}</span>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template #notFound>
          {{ 'portal.home.news.notFound' | translate }}
        </ng-template>
      </app-portal-table>
    </app-card>
  </div>
</div>
