import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Qc } from '../../core/bean/qc';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { QcSearchOptionListResponse } from './model/qc-search-option-list-response';
import { RecapPrintService } from 'src/app/core/services/recap-print.service';


@Component({
  templateUrl: './qc.component.html'
})
export class QcComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Qc>;
  public isLoading: boolean;

  public qcTypeOptionList: OptionListModel<any> = new OptionListModel();
  public shiftOptionList: OptionListModel<any> = new OptionListModel().setValuePath('value');
  public qcUserOptionList: OptionListModel<any> = new OptionListModel().setValuePath('value');

  constructor(
    translateService: TranslateService,
    public recapPrintService: RecapPrintService
  ) {
    super('qc', translateService);
  }

  public onInit(): void {

    this.formGroup = this.formBuilder.group({
      dateFrom: [null],
      dateTo: [null],
      qcType: [null],
      shift: [null],
      qcUser: [null]
    });

    this.getOptionList();
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public getOptionList(): void {
    this.httpClientService
      .get<QcSearchOptionListResponse>('/qc/get-option-list')
      .subscribe((searchOption: QcSearchOptionListResponse) => {
      
        this.qcTypeOptionList.setRequestValues( searchOption.qcTypeOptionList).setValuePath('value').setViewPath('name');
        this.shiftOptionList.setRequestValues( searchOption.shiftOptionList).setValuePath('value').setViewPath('name');
        this.qcUserOptionList.setRequestValues( searchOption.qcUserOptionList ).setValuePath('value').setViewPath('name');

      });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [

      { field: 'qcType.code', header: 'table.column.qcType' },

      {
        field: 'processDate',
        header: 'table.column.createdDate',
        format: FieldFormatEnum.ShortDate
      },
      { field: 'shift', header: 'table.column.shiftName' },
      { field: 'product', header: 'table.column.productName' },
      { field: 'qcUser.name', header: 'table.column.qcUser' }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.global.routerParams.set('qcTypeId', 5);
    this.global.routerParams.set('backUrl', '/pages/qc/');
    this.router.navigate(['/pages/qc/add']);
  }

  public doEdit(qc: Qc): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('qcId', qc.id);
    this.global.routerParams.set('qcTypeId', 5);
    this.global.routerParams.set('backUrl', '/pages/qc/');
    this.router.navigate(['/pages/qc/edit']);
  }

  public doDelete(qc: Qc): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<Qc>>('/qc/delete', qc)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }


  public doApply(): void {
  
      this.tableResponse.setCustomData(this.formGroup.value);
      this.tableResponse.reload();
   
  }

  public doExport(): void {
    this.isLoading = true;
    this.recapPrintService
      .print('/qc/export', this.tableResponse.request)
      .subscribe(() => {
        this.isLoading = false;
      });
  }

}
