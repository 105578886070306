/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\helper\create-table-component-helper.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 10:53:18 pm

 *
*
 */
import { AppDynamicComponentService } from '../../../services/app-dynamic-component.service';
import { TablePluginData } from '../interface/table-plugin-data';
export const createTableComponent = (
  tablePluginData: TablePluginData,
  component: any,
  className: string
): HTMLElement => {
  const divElement = document.createElement('div');
  const field = tablePluginData.column.column.field;
  divElement.className = `${className} ${
    field ? 'field-' + field.replace(/\./g, '-').toLowerCase() : ''
  }`;
  const appDynamicComponent = tablePluginData.state.injector.get(
    AppDynamicComponentService
  );
  appDynamicComponent.create(component, divElement, {
    tablePluginData,
    state: tablePluginData.state
  });
  return divElement;
};
