import { NgModule } from '@angular/core';
import { SharedModule } from '../core/shared/shared.module';
import { AppLoginFormModule } from './login/app-login-form.module';
import { PortalRoutingModule } from './portal-routing.module';
import { PortalComponent } from './portal.component';
import { VendorModule } from '../core/vendor/vendor.module';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../core/pipe/pipe.module';
import { PopupLoginModule } from '../themes/promise-portal/components/popup-login/popup-login.module';
import { AppDropDownModule } from '../core/components/app-drop-down/app-drop-down.module';
import { AppButtonModule } from '../core/components/app-button/app-button.module';
@NgModule({
  imports: [
    PortalRoutingModule,
    SharedModule,
    AppLoginFormModule,
    VendorModule,
    RouterModule,
    AppButtonModule,
    AppDropDownModule,
    PopupLoginModule,
    PipesModule
  ],
  declarations: [PortalComponent]
})
export class PortalModule {}
