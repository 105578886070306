/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\base\base-table-form-plugin.component.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Friday, September 10th 2021, 7:00:42 pm

 *
*
 */

import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ArrayUtils } from '../../../utils';
import { createPluginFormcontrol } from '../helper';
import {
  addPluginFormControlToFormGroup,
  createFormControlKey
} from '../helper/add-plugin-formcontrol-to-formgroup-helper';
import { TablePluginFormOption } from '../interface/table-plugin-options';
import { BaseTablePluginComponent } from './base-table-plugin.component';

@Component({
  template: ''
})
export abstract class BaseTableFormPluginComponent extends BaseTablePluginComponent {
  public formGroup: FormGroup = new FormGroup({});
  public formControlName: string;
  public formControl: FormControl;
  protected abstract onInitBaseTableForm(): void;
  constructor() {
    super();
  }
  protected onInit(): void {
    this.setPluginFormControlState();
    this.setPluginFormControlNameState();
    this.setPluginFormControlIsViewState();
    this.setPluginFormControlIsDisabledState();
    this.setFormGroupState();
    this.onInitBaseTableForm();
    this.setColumnFormControl();
  }

  private setPluginFormControlState(): void {
    const record = this.state.recordTemp.recordMap.get(
      JSON.stringify(this.tablePluginData.row.record)
    );
    let value: any;
    if (record) {
      value = ArrayUtils.resolveFieldData(
        record,
        this.tablePluginData.column.column.field
      );
    }
    this.formControl = createPluginFormcontrol(this.tablePluginData, value);
  }

  private setPluginFormControlNameState(): void {
    this.formControlName = createFormControlKey(
      this.tablePluginData.column.column.field
    );
  }

  private setPluginFormControlIsViewState(): void {
    const tablePluginFormOption = this.tablePluginData
      .plugin as TablePluginFormOption;
    if (typeof tablePluginFormOption.isView !== 'undefined') {
      this.formControl.setIsView(tablePluginFormOption.isView);
    } else if (typeof this.tablePluginData.state.isView !== 'undefined') {
      this.formControl.setIsView(this.tablePluginData.state.isView);
    }
  }

  private setPluginFormControlIsDisabledState(): void {
    const tablePluginFormOption = this.tablePluginData
      .plugin as TablePluginFormOption;
    if (
      typeof tablePluginFormOption.isDisable !== 'undefined' &&
      tablePluginFormOption.isDisable
    ) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  private setFormGroupState(): void {
    addPluginFormControlToFormGroup(this.tablePluginData, this.formControl);
    this.formGroup = this.tablePluginData.row.formGroup;
  }

  public onChange(value: any): void {
    const plugin = this.tablePluginData.plugin as TablePluginFormOption;
    this.setUpdatedRecordValue(this.formControl.value);
    if (plugin.onChange) {
      plugin.onChange(value);
    }
  }

  public onInput(event: string | number): void {
    const plugin = this.tablePluginData.plugin as TablePluginFormOption;
    if (plugin.onInput) {
      this.setUpdatedRecordValue(this.formControl.value);
      plugin.onInput(event);
    }
  }

  private setColumnFormControl(): void {
    this.tablePluginData.column.formControl = this.formControl;
  }
}
