import { DatePickerState } from '../model';
export const setDatePickerTimeHtmlText = (
  state: DatePickerState,
  payload: {
    event: InputEvent & { target: HTMLElement };
    type: 'HOURS' | 'MINUTES';
  }
): void => {
  const { target } = payload.event;
  if (target.innerText.trim() !== '') {
    if (payload.type === 'HOURS') {
      if (isNaN(+target.innerText)) {
        target.innerText = state.currentTime.hoursString;
      } else {
        const hours = target.innerText;
        if (+hours >= 10) {
          target.innerText = +hours > 23 ? '23' : Math.abs(+hours).toString();
        }
      }
    } else {
      if (isNaN(+target.innerText)) {
        target.innerText = state.currentTime.minutesString;
      } else {
        const minutes = target.innerText;
        if (+minutes >= 10) {
          target.innerText =
            +minutes > 59 ? '59' : Math.abs(+minutes).toString();
        }
      }
    }
  }
};
