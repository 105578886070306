<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="lang.title" detail="app.action.{{ todo }}" [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'lang.form.code' | translate
            }}</label>
          <div class="col-sm-4 text-left">
            <div *ngIf="!isReadOnly">
              <app-text-field autofocus="true" size="XL" formControlName="code" type="code" maxLength="32">
              </app-text-field>
            </div>
            <div *ngIf="isReadOnly">
              {{ formGroup.controls.code.value }}
            </div>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'lang.form.name' | translate
            }}</label>
          <div class="col-sm-4 text-left">
            <div *ngIf="!isReadOnly">
              <input class="form-control validation-field" type="text" formControlName="name" [ngClass]="{
                  'is-invalid': submitted && formGroupControls.name.errors
                }" maxlength="32" aria-label="Language Name" />
              <small class="text-danger" *ngIf="
                  (submitted && formGroupControls.name.errors) ||
                  (formGroupControls.name.touched &&
                    formGroupControls.name.errors)
                ">{{ 'app.validation.required' | translate }}</small>
            </div>
            <div *ngIf="isReadOnly">
              {{ formGroup.controls.name.value }}
            </div>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'lang.form.status' | translate
            }}</label>
          <div class="col-sm-4 text-left">
            <div *ngIf="!isReadOnly">
              <select (change)="doChangeStatus($event)" class="form-control validation-field" id="isActive"
                formControlName="isActive" [ngClass]="{
                  'is-invalid': submitted && formGroupControls.isActive.errors
                }" aria-label="Language Status">
                <option [ngValue]="null">{{ 'lang.option.status' | translate }}
                </option>
                <option value="true">{{ 'app.status.active' | translate }}
                </option>
                <option value="false">{{ 'app.status.inActive' | translate }}
                </option>
              </select>
              <small class="text-danger" *ngIf="
                  (submitted && formGroupControls.isActive.errors) ||
                  (formGroupControls.isActive.touched &&
                    formGroupControls.isActive.errors)
                ">{{ 'app.validation.required' | translate }}</small>
            </div>
            <div *ngIf="isReadOnly">
              <span *ngIf="formGroup.controls.isActive.value">{{
                'app.status.active' | translate
                }}</span>
              <span *ngIf="!formGroup.controls.isActive.value">{{
                'app.status.inActive' | translate
                }}</span>
            </div>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'lang.form.flagIcon' | translate
            }}</label>
          <div class="col-sm-4 text-left">
            <span *ngIf="formGroup.get('resource').value != null">
              <img [ngStyle]="{ 'max-width': '36px' }" class="mr-2" [attr.src]="
                  global.config.BACKEND_ADDRESS +
                    '/resource/file/view/' +
                    formGroup.get('resource').value.imgFile?.uploadedFileName +
                    '/' + global.appConstant.fileType.IMG_RESOURCE
                    | resolveImgSrc
                    | async
                " [attr.alt]="fileName" width="auto" height="auto" />
            </span>
            <span *ngIf="!isReadOnly">
              <app-button size="SM" (onClick)="doChooseFlagIcon()" [disabled]="formSaving">
                {{ 'app.button.choose' | translate }}
              </app-button>
              <div>
                <small class="text-danger" *ngIf="
                    (submitted && formGroupControls.resource.errors) ||
                    (formGroupControls.resource.touched &&
                      formGroupControls.resource.errors)
                  ">{{ 'app.validation.required' | translate }}</small>
              </div>
            </span>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label"></label>
          <div class="col-sm-7 text-left">
            <app-check-box formControlName="isDefault">
              {{ 'lang.form.isDefault' | translate }}
            </app-check-box>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button color="SECONDARY" (onClick)="doCancel()" [disabled]="formSaving">
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>