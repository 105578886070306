/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widgets\widgets.service.ts
 
 * Created Date: Thursday, November 18th 2021, 9:56:36 am

 *
*
 */

import { Injectable, Injector } from '@angular/core';
import * as widgetAction from './action/widget-action';
import { dispatchWidget } from './action/widget-dispatch';
import { WidgetState } from './domain/widget-state';
import { WidgetResponseModel } from './model/widget-response-model';
@Injectable()
export class WidgetService {
  private state: WidgetState;
  private widgetMap: Map<
    string,
    {
      className: string;
      component: object;
    }
  > = new Map();
  constructor(private injector: Injector) {}

  public register(
    widgetSelector: string,
    widgetComponent: object,
    className: string
  ): void {
    this.widgetMap.set(widgetSelector, {
      className: className,
      component: widgetComponent
    });
  }

  public getWidget(selector: string): { className: string; component: object } {
    return this.widgetMap.get(selector);
  }

  public setState(model: WidgetResponseModel): WidgetState {
    this.state = WidgetState.create(this.injector, model);
    return this.state;
  }

  public dispatch(action: widgetAction.AllWidgetAction): void {
    this.state.setStateLoading();
    dispatchWidget(this.state, action);
  }
}
