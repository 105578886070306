/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\action\delete-widget-action.ts
 
 * Created Date: Thursday, November 18th 2021, 11:34:21 am

 *
*
 */

import { WidgetState } from '../domain/widget-state';
export const deleteWidgetAction = (
  state: WidgetState,
  payload: { index: number }
): void => {
  state.widgetList.splice(payload.index, 1);
  state.setStateReady();
};
