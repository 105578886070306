import { BaseEntity } from '../base/base-entity';
import { ItemCategory } from '../bean/item-category';
import { ItemType } from './../bean/item-type';
import { Uom } from './../bean/uom';
export class ItemEntity extends BaseEntity {
    uom: Uom;
    itemType: ItemType;
    itemCategory: ItemCategory;
    name: string;
    description: string;
}
