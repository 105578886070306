/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\date-picker-plugin\index.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 9:49:39 pm

 *
*
 */
import { createTableComponent } from '../../helper';
import { TablePlugin } from '../../interface/table-plugin';
import { TablePluginData } from '../../interface/table-plugin-data';
import { TableDatePickerPluginComponent } from './date-picker-plugin.component';
export const tableDatePickerPlugin: TablePlugin = {
  name: 'date-picker',
  before: (tablePluginData: TablePluginData): HTMLElement => {
    tablePluginData.element.addEventListener('click', (event: PointerEvent) =>
      event.stopPropagation()
    );
    return createTableComponent(
      tablePluginData,
      TableDatePickerPluginComponent,
      'date-picker-plugin-content'
    );
  }
};
