/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\action\search-table-action.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:16:03 am

 *
*
 */
import { TableRow } from '../domain/table-row';
import { TableState } from '../domain/table-state';
import { resolveTableRowList } from '../helper';
export const searchTableAction = (
  state: TableState,
  payload: { keyword: string }
): void => {
  if (state.isServerSide) {
    state.selectedRow.reset();
  }
  state.tableRequest.globalFilter = payload.keyword;
  resolveTableRowList(state).then((rowList: Array<TableRow>) => {
    state.setRowList(rowList);
    state.setStateReady();
  });
};
