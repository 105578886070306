import { Component, EventEmitter, Output } from '@angular/core';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { Coa } from '../../../bean/coa';
@Component({
  templateUrl: './app-popup-coa.component.html'
})
export class AppPopupCoaComponent extends BaseComponentComponent {
  @Output() onChange: EventEmitter<Coa> = new EventEmitter();
  public tableResponse: TableResponseModel<Coa>;
  constructor() {
    super('app-popup-coa');
  }

  onInit(): void {
    this.buildTableResponse();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      { field: 'name', header: 'table.column.name' }
    ]);
  }

  public doChoose(coa: Coa): void {
    this.onChange.emit(coa);
  }
}
