
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';
import * as widgetAction from './action/widget-action';
import { WidgetState } from './domain/widget-state';
import { WidgetResponseModel } from './model/widget-response-model';
import { WidgetService } from './widget.service';
@Component({
  selector: 'dashboard-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  animations: [
    trigger('bordered', [
      state('default', style({})),
      state(
        'bordered',
        style({
          border: '1px dashed var(--gray-400)',
          padding: ' 10px',
          borderRadius: '5px',
          marginBottom: '10px'
        })
      ),
      transition('default<=>bordered', animate('300ms'))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class WidgetComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() model: WidgetResponseModel;
  @Input() isLoading: boolean;

  public state: WidgetState;
  constructor(private widgetService: WidgetService) {
    super('dashboard');
  }

  onInit(): void {
    this.setInitializationState();
    this.loadWidget();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges.isLoading &&
      !simpleChanges.isLoading.firstChange &&
      !simpleChanges.isLoading.currentValue
    ) {
      this.loadWidget();
    }
  }

  private setInitializationState(): void {
    this.state = this.widgetService.setState(this.model);
  }

  private loadWidget(): void {
    if (this.model.stringUrl || !this.isLoading) {
      this.widgetService.dispatch(new widgetAction.FirstLoadWidget());
    }
  }

  public doShowWidgetEditTable(): void {
    this.widgetService.dispatch(new widgetAction.SetWidgetEditable());
  }

  public doAddWidget(): void {
    this.widgetService.dispatch(new widgetAction.AddWidget());
  }

  public doDeleteWidget(index: number): void {
    this.widgetService.dispatch(new widgetAction.DeleteWidget({ index }));
  }

  public doSaveWidget(): void {
    this.widgetService.dispatch(new widgetAction.SaveWidget());
  }

  public doRestoreWidget(): void {
    this.widgetService.dispatch(new widgetAction.RestoreWidget());
  }
}
