/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\action\expand-or-collapse-table-row-action.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:15:38 am

 *
*
 */
import { TableRow } from '../domain/table-row';
import { TableState } from '../domain/table-state';
import { resolveTableRowChildrenList } from '../helper/resolve-table-row-children-list-helper';
export const expandOrCollapseTableRow = (
  state: TableState,
  payload: { row: TableRow }
): void => {
  state.setStateReady();
  payload.row.childrenList.length === 0 && payload.row.setStateLoading();
  payload.row.isExpanded = !payload.row.isExpanded;
  payload.row.isExpanded &&
    payload.row.childrenList.length === 0 &&
    resolveTableRowChildrenList(state, payload.row).then(
      (value: { rowList: TableRow[]; totalRecord: number }) => {
        payload.row.childrenList = value.rowList;
        payload.row.isShowButtonLoadMore =
          payload.row.childrenList.length < value.totalRecord;
        payload.row.setStateReady();
      }
    );
};
