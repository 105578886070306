<div class="app-landing-page-card">
  <app-card>
    <div class="alpc-header">
      <div
        class="alpch-vector"
        *ngIf="vectorClassName"
        [ngClass]="vectorClassName"
      ></div>
      <h2 class="title" *ngIf="header">{{ header | translate }}</h2>
    </div>
    <div class="alpc-body">
      <ng-content></ng-content>
    </div>
    <div class="alpc-footer" *ngIf="footerTmpl">
      <ng-container *ngTemplateOutlet="footerTmpl"></ng-container>
    </div>
  </app-card>
</div>
