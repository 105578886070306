
export class MenuItem {
  id: number;
  code: string;
  title: string;
  routerLink?: string;
  icon?: string;
  hasSubMenu: boolean;
  parentId: number;
  write: boolean;
  moduleCode?: string;
  translateKey: string;
  childrens: MenuItem[];
  isActive: boolean;
  isExpanded: boolean;
}
