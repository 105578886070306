import { Component } from '@angular/core';

@Component({
  selector: 'app-metabase',
  template: '<iframe src="https://shorturl.at/enzES" frameborder="0"></iframe>',
  styles: [
    'iframe { width: 100%; height: 1550px; }', // Mengatur tinggi dan lebar iframe
    ':host { display: block; width: 100%; height: 100%; }' // Mengisi tinggi dan lebar komponen
  ]
})
export class AppMetabaseComponent {
   
  
    
  
  
  }