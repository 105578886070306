import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Contract } from 'src/app/core/bean/contract';
import { OrderPreparation } from 'src/app/core/bean/order-preparation';
import { PrItemReleased } from 'src/app/core/bean/pr-item-released';
import { PurchaseType } from 'src/app/core/bean/purchase-type';
import { Sow } from 'src/app/core/bean/sow';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { OptionListModel } from '../../../model/option-list-model';
import { TablePluginData } from '../../table/interface/table-plugin-data';
import { TableResponseModel } from '../../table/model/table-response-model';

@Component({
  templateUrl: './app-popup-choose-pr-released.component.html'
})
export class AppPopupChoosePrReleasedComponent extends BaseModuleComponent {
  @Input() isMultiple: boolean;
  @Input() prItemReleasedList: PrItemReleased[] = [];
  @Input() vendorId: number;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() baseModuleCode: String;
  @Input() orderPreparationList: OrderPreparation[] = [];
  @Input() contractBeanList: Contract[] = [];
  @Input() purchaseType: PurchaseType;

  public tableResponsePrItem: TableResponseModel<PrItemReleased>;
  public tableResponseOrderPreparation: TableResponseModel<OrderPreparation>;
  public tableResponseContract: TableResponseModel<Contract>;

  public groupByMode: any;
  public groupByOptionList: OptionListModel<any> = new OptionListModel(false);
  public selectSourceOptionList: OptionListModel<any> = new OptionListModel();
  public source: String;
  public sowList: Sow[] = [];

  constructor(
    translateService: TranslateService,
    public snackbarService: SnackbarService
  ) {
    super('app-popup-choose-pr-released', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    // this.setFormGroup();
    this.setOption();
    this.setGroupByMode();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      groupBy: [null],
      source: [this.global.appConstant.core.SOURCE_ORDER]
    });
    this.source = this.global.appConstant.core.SOURCE_ORDER;
  }

  public setGroupByMode(): void {
    this.groupByMode = 'vendor';
  }

  public setFormGroup(): void {
    const column = [
      {
        code: 'prNumber',
        name: this.translateService.instant(
          'app-popup-choose-pr.table.column.prNumber'
        )
      },
      {
        code: 'itemCategory',
        name: this.translateService.instant(
          'app-popup-choose-pr.table.column.itemCategory'
        )
      },
      {
        code: 'prDate',
        name: this.translateService.instant(
          'app-popup-choose-pr.table.column.prDate'
        )
      },
      {
        code: 'department',
        name: this.translateService.instant(
          'app-popup-choose-pr.table.column.departement'
        )
      }
    ];
    this.groupByOptionList.setRequestValues(column);
  }

  public setOption(): void {
    const selectSource = [
      {
        name: 'app-popup-choose-pr-released.source.order',
        value: this.global.appConstant.core.SOURCE_ORDER
      },
      {
        name: 'app-popup-choose-pr-released.source.orderContract',
        value: this.global.appConstant.core.SOURCE_ORDER_CONTRACT
      },
      {
        name: 'app-popup-choose-pr-released.source.contract',
        value: this.global.appConstant.core.SOURCE_CONTRACT
      },
      {
        name: 'app-popup-choose-pr-released.source.directPO',
        value: this.global.appConstant.core.SOURCE_DIRECT_PO
      }
    ];
    this.selectSourceOptionList.setRequestValues(selectSource);
  }

  public buildTableResponse(): void {
    if (this.source === this.global.appConstant.core.SOURCE_ORDER) {
      !this.tableResponsePrItem
        ? (this.tableResponsePrItem = new TableResponseModel(
            this.moduleCode,
            [
              {
                field: 'prItem.pr.code',
                header: 'table.column.prNumber',
                className: 'text-center',
                plugins: 'hyperlink'
              },
              {
                field: 'prItem.code',
                header: 'table.column.prLine',
                className: 'text-center'
              },
              {
                field: 'prItem.pr.requiredDate',
                header: 'table.column.prDate',
                className: 'text-center',
                plugins: 'date'
              },
              {
                field: 'prItem.pr.organization.name',
                header: 'table.column.departement',
                className: 'text-center'
              },
              {
                field: 'prItem.item.name',
                header: 'table.column.itemName'
              },
              {
                field: 'prItem.item.itemCategory.name',
                header: 'table.column.itemCategory'
              },
              {
                field: 'prItem.item.itemType.name',
                header: 'table.column.type',
                plugins: {
                  name: 'badge',
                  pill: true,
                  colorMap: {
                    MATERIAL: 'GOOD',
                    JASA: 'SERVICE'
                  },
                  colorField: 'prItem.item.itemType.code'
                }
              },
              {
                field: 'quantity',
                header: 'table.column.quantity',
                className: 'text-center',
                plugins: {
                  name: 'custom-plugin',
                  before: (tablePlugin: TablePluginData): string => {
                    return this.global.converterService.convertDecimal(
                      tablePlugin.value
                    );
                  }
                }
              },
              {
                field: 'prItem.item.uom.name',
                header: 'table.column.uom'
              },
              {
                field: 'price',
                header: 'table.column.price',
                plugins: {
                  name: 'default',
                  type: 'currency'
                }
              },
              {
                field: 'totalPrice',
                header: 'table.column.totalPrice',
                plugins: {
                  name: 'default',
                  type: 'currency'
                }
              }
            ],
            { group: { field: 'vendor.name' } }
          ))
        : '';

      let show = true;
      if (this.orderPreparationList && this.orderPreparationList.length !== 0) {
        show = false;
      }

      const customData = {
        groupByMode: this.groupByMode,
        vendorId: this.vendorId,
        baseModuleCode: this.baseModuleCode,
        show,
        isItemContract: false,
        purchaseTypeCode: this.purchaseType?.code
      };
      this.tableResponsePrItem.setCustomData(customData);

      this.tableResponsePrItem.options.group.isExpanded = true;
      this.tableResponsePrItem?.reload();
      if (this.prItemReleasedList && this.prItemReleasedList.length !== 0) {
        this.tableResponsePrItem.setSelectedRowList(this.prItemReleasedList);
      }
    } else if (
      this.source === this.global.appConstant.core.SOURCE_ORDER_CONTRACT
    ) {
      !this.tableResponseOrderPreparation
        ? (this.tableResponseOrderPreparation = new TableResponseModel(
            this.moduleCode,
            [
              {
                field: 'code',
                header: 'table.column.object'
              },
              {
                field: 'orderDate',
                header: 'table.column.orderDate',
                plugins: 'date'
              },
              {
                field: 'title',
                header: 'table.column.title'
              },
              {
                field: 'organization.name',
                header: 'table.column.departement'
              },
              {
                field: 'vendor.name',
                header: 'table.column.vendor'
              }
            ]
          ))
        : '';
      let show = true;
      if (
        this.prItemReleasedList &&
        this.prItemReleasedList.length !== 0 &&
        this.orderPreparationList.length === 0
      ) {
        show = false;
      }
      const customData = {
        vendorId: this.vendorId,
        baseModuleCode: this.baseModuleCode,
        show,
        purchaseTypeCode: this.purchaseType?.code
      };

      this.tableResponseOrderPreparation.setCustomData(customData);
      if (
        this.prItemReleasedList &&
        this.prItemReleasedList.length !== 0 &&
        this.orderPreparationList.length !== 0
      ) {
        this.tableResponseOrderPreparation.setSelectedRowList(
          this.orderPreparationList
        );
      }
    } else if (this.source === this.global.appConstant.core.SOURCE_DIRECT_PO) {
      let show = true;
      if (this.orderPreparationList && this.orderPreparationList.length !== 0) {
        show = false;
      }

      const customData = {
        groupByMode: this.groupByMode,
        vendorId: this.vendorId,
        baseModuleCode: this.baseModuleCode,
        show,
        isItemContract: true,
        purchaseTypeCode: this.purchaseType?.code
      };
      this.tableResponsePrItem.setCustomData(customData);

      this.tableResponsePrItem.options.group.isExpanded = true;
      this.tableResponsePrItem.reload();
      if (this.prItemReleasedList && this.prItemReleasedList.length !== 0) {
        this.tableResponsePrItem.setSelectedRowList(this.prItemReleasedList);
      }
    } else {
      !this.tableResponseContract
        ? (this.tableResponseContract = new TableResponseModel(
            this.moduleCode,
            [
              {
                field: 'contractPreparation.code',
                header: 'table.column.object'
              },
              {
                field: 'contractPreparation.orderDate',
                header: 'table.column.orderDate',
                plugins: 'date'
              },
              {
                field: 'contractPreparation.title',
                header: 'table.column.title'
              },
              {
                field: 'contractPreparation.organization.name',
                header: 'table.column.departement'
              },
              {
                field: 'contractPreparation.vendor.name',
                header: 'table.column.vendor'
              }
            ]
          ))
        : '';
      const customData = {
        vendorId: this.vendorId,
        baseModuleCode: this.baseModuleCode,
        purchaseTypeCode: this.purchaseType?.code
      };

      this.tableResponseContract.setCustomData(customData);
      if (this.prItemReleasedList && this.prItemReleasedList.length !== 0) {
        this.tableResponseContract.setSelectedRowList(this.contractBeanList);
      }
    }
  }

  // public doOnChangeGroupBy(): void {
  //   this.setGroupByMode();
  //   this.buildTableResponse();
  //   this.tableResponse.reload();
  // }

  public doChoose(): void {
    let tableResponse;
    let vendorIdList;

    if (
      this.source === this.global.appConstant.core.SOURCE_ORDER ||
      this.source === this.global.appConstant.core.SOURCE_DIRECT_PO
    ) {
      tableResponse = this.tableResponsePrItem;
      vendorIdList = new Set(
        tableResponse.selectedRow
          .getSelectedRecordList()
          .map(rec => rec.vendor.id)
      );
    } else if (
      this.source === this.global.appConstant.core.SOURCE_ORDER_CONTRACT
    ) {
      tableResponse = this.tableResponseOrderPreparation;
      vendorIdList = new Set(
        tableResponse.selectedRow
          .getSelectedRecordList()
          .map(rec => rec.vendor.id)
      );
    } else {
      tableResponse = this.tableResponseContract;
      vendorIdList = new Set(
        tableResponse.selectedRow
          .getSelectedRecordList()
          .map(rec => rec.contractPreparation.vendor.id)
      );
    }

    if (
      tableResponse.selectedRow.getSelectedRecordList().length !== 0 &&
      vendorIdList.size === 1
    ) {
      let prItemReleasedList;
      if (
        this.source === this.global.appConstant.core.SOURCE_ORDER ||
        this.source === this.global.appConstant.core.SOURCE_DIRECT_PO
      ) {
        prItemReleasedList = tableResponse.selectedRow
          .getSelectedRecordList()
          .filter(
            (record, i, arr) => arr.findIndex(r => r.id === record.id) === i
          );

        this.onChange.emit({
          prItemReleasedList,
          parentList: prItemReleasedList,
          source: this.source
        });
      } else if (
        this.source === this.global.appConstant.core.SOURCE_ORDER_CONTRACT
      ) {
        const orderPreparationId = tableResponse.selectedRow
          .getSelectedRecordList()
          .map(rec => rec.id);
        this.httpClientService
          .post<Sow[]>(
            '/app-popup-choose-pr-released/get-pr-released-from-order-list',
            orderPreparationId
          )
          .subscribe(response => {
            this.onChange.emit({
              prItemReleasedList: response,
              parentList: response,
              source: this.source,
              orderPreparationId: orderPreparationId[0]
            });
            this.setStateReady();
            this.log.debug('orderPreparationId List:');
            this.log.debug(orderPreparationId);
          });
      } else {
        const contract =
          this.tableResponseContract.selectedRow.getSelectedRecordList()[0];
        this.log.debug('contract:');
        this.log.debug(contract);

        const contractPreparationId = tableResponse.selectedRow
          .getSelectedRecordList()
          .map(rec => rec.contractPreparation.id);
        this.httpClientService
          .post<Sow[]>(
            '/app-popup-choose-pr-released/get-pr-released-from-contract-list',
            contractPreparationId
          )
          .subscribe(response => {
            this.onChange.emit({
              prItemReleasedList: response,
              parentList: response,
              source: this.source,
              contract
            });
            this.setStateReady();
          });
      }
    } else if (
      tableResponse.selectedRow.getSelectedRecordList().length !== 0 &&
      vendorIdList.size > 1
    ) {
      this.snackbarService.showWarning(
        'app-popup-choose-pr-released.validation.onlyOneVendor'
      );
    } else {
      if (this.source === this.global.appConstant.core.SOURCE_ORDER) {
        this.snackbarService.showWarning(
          'app-popup-choose-pr-released.validation.chooseOneVendor'
        );
      } else {
        this.snackbarService.showWarning(
          'app-popup-choose-pr-released.validation.chooseOneObject'
        );
      }
    }
  }

  public onChangeSource(): void {
    if (
      this.source === this.global.appConstant.core.SOURCE_ORDER ||
      this.source === this.global.appConstant.core.SOURCE_DIRECT_PO
    ) {
      this.source = this.formGroup.get('source').value;
      this.buildTableResponse();
      this.tableResponsePrItem.reload();
    } else if (
      this.source === this.global.appConstant.core.SOURCE_ORDER_CONTRACT
    ) {
      this.source = this.formGroup.get('source').value;
      this.buildTableResponse();
      this.tableResponseOrderPreparation.reload();
    } else {
      this.source = this.formGroup.get('source').value;
      this.buildTableResponse();
      this.tableResponseContract.reload();
    }
  }
}
