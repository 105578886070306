/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\action\select-all-table-record-action.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:16:13 am

 *
*
 */
import { TableRow } from '../domain/table-row';
import { TableState } from '../domain/table-state';
import { resolveTableRowList } from '../helper';
export const selectAllTableRowAction = (
  state: TableState,
  payload: { isChecked: boolean }
): void => {
  state.selectedRow.isSelectAll = payload.isChecked;
  if (payload.isChecked) {
    state.selectedRow.customData = state.tableRequest.customData;
    state.selectedRow.keyword = state.tableRequest.globalFilter;
    setCheckedRecordList(state);
  } else {
    state.selectedRow.reset();
    state.model.selectedRow = state.selectedRow;
    state.rowList.forEach(tableRecord => tableRecord.selectedRow.reset());
    state.setStateReady();
  }
};

const setCheckedRecordList = (state: TableState): void => {
  if (state.isServerSide) {
    state.selectedRow.selectedRowList = [];
    state.model.selectedRow = state.selectedRow;
  } else {
    resolveTableRowList(state, true).then((rowList: Array<TableRow>) => {
      state.selectedRow.selectedRowList = rowList;
      state.model.selectedRow = state.selectedRow;
    });
  }
  state.rowList.forEach(row => (row.selectedRow.isSelectAll = true));
  state.setStateReady();
};
