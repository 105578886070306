<app-landing-page-card vectorClassName="pvc pv-envelope">
  <h2 class="email-verification-title fw-700">
    {{ 'registration.emailVerification.title' | translate }}
  </h2>
  <ng-template #footer>
    <h3 class="text-center">
      {{ 'registration.emailVerification.prompt' | translate }}
    </h3>
    <h4 class="text-center mt-3 email-verification-email">
      {{ email }}
    </h4>
  </ng-template>
</app-landing-page-card>
