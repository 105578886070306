import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { OrganizationComponent } from './organization.component';
import { OrganizationEditAddComponent } from './organization-edit-add.component';
import { ColorPickerModule } from 'ngx-color-picker';
const routes = [
    {
        path: '',
        component: OrganizationComponent,
        data: { breadcrumb: '' }
    },
    {
        path: 'add',
        component: OrganizationEditAddComponent,
        data: { breadcrumb: 'organization.breadcrumb.detail'},
        canDeactivate: [ConfirmGuard]
    },
    {
        path: 'edit',
        component: OrganizationEditAddComponent,
        data: { breadcrumb: 'organization.breadcrumb.detail'},
        canDeactivate: [ConfirmGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        NgbModule,
        ColorPickerModule
    ],
    declarations: [
        OrganizationComponent,
        OrganizationEditAddComponent
    ],
    providers: [
    ]
})

export class OrganizationModule {

}
