/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\domain\selected-table-record.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 1:32:50 pm

 *
*
 */
import { ArrayUtils } from '../../../utils';
import { SelectedTableRecordModel } from '../model/selected-table-record-model';
import { TableRow } from './table-row';
export class SelectedTableRow {
  // is checked all merupakan flag ketika checkbox check all di table di checked
  // atau semua record telah dipilih
  isSelectAll: boolean;
  // selectedRowList merupakan penampung untuk semua record yang telah dipilih
  selectedRowList: Array<TableRow> = new Array();
  // unSelectedRowList merupakan penampung untuk record yang tidak dipilih
  // dengan catatan pertama kali di check all pada table kemudian yang sudah ke select di-uncheck
  unSelectedRowList: Array<TableRow> = new Array();
  // custom data digunakan untuk menampung filter untuk selected record
  // yang dapat digunakan di backend untuk filter select all berdasarkan customData
  customData: string;
  // keywords merupakan kata kunci pencarian yang ada pada table kemudian recordnya di checAll
  // ini berfungsi untuk filter pada backend
  keyword: string;

  public setSelectedRowList(tableRowList: Array<TableRow>): void {
    this.selectedRowList = tableRowList;
  }

  public addRowToSelectedRowList(tableRow: TableRow): void {
    this.selectedRowList.push(tableRow);
  }

  public addRowToUnSelectedRowList(tableRow: TableRow): void {
    this.unSelectedRowList.push(tableRow);
  }

  public removeRecordFromSelectedRowList(tableRow: TableRow): void {
    this.selectedRowList = ArrayUtils.removeRecordFromArray(
      this.selectedRowList,
      tableRow,
      'record'
    );
  }

  public removeRecordFromUnSelectedRowList(tableRow: TableRow): void {
    this.unSelectedRowList = ArrayUtils.removeRecordFromArray(
      this.unSelectedRowList,
      tableRow,
      'record'
    );
  }

  public getValue(): SelectedTableRecordModel {
    return new SelectedTableRecordModel(
      this.isSelectAll,
      this.getSelectedRecordList(),
      this.getUnSelectedRecordList(),
      this.customData,
      this.keyword
    );
  }

  public getSelectedRecordList(): Array<any> {
    return Array.from(this.selectedRowList).map(
      (tableRow: TableRow) => tableRow.record
    );
  }

  public getUnSelectedRecordList(): Array<any> {
    return Array.from(this.unSelectedRowList).map(
      (tableRow: TableRow) => tableRow.record
    );
  }

  public reset(): void {
    this.isSelectAll = false;
    this.selectedRowList.splice(0);
    this.unSelectedRowList.splice(0);
    this.customData = null;
    this.keyword = null;
  }
}
