/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\core\components\app-stepper\app-stepper.module.ts
 
 * Created Date: Thursday, October 7th 2021, 11:59:19 am

 *
*
 */

import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppContentSliderModule } from '../app-content-slider/app-content-slider.module';
import { AppStepperItemComponent } from './app-stepper-item.component';
import { AppStepperComponent } from './app-stepper.component';
@NgModule({
  imports: [VendorModule, AppContentSliderModule, PipesModule],
  declarations: [AppStepperComponent, AppStepperItemComponent],
  exports: [AppStepperComponent, AppStepperItemComponent]
})
export class AppStepperModule {}
