/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\directives\table-row.directive.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 3:00:46 pm

 *
*
 */

import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
import { TableRow } from '../domain/table-row';
import { TableState } from '../domain/table-state';

@Directive({
  selector: '[table-row]'
})
export class TableRowDirective implements OnInit, AfterViewInit {
  @Input() state: TableState;
  @Input() row: TableRow;
  @Input() position: number;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.row.element = this.elementRef.nativeElement;
    this.row.position = this.position;
    this.state.event.emit({
      type: 'ON-BEFORE-ROW-CREATED',
      data: this.row
    });
  }

  ngAfterViewInit(): void {
    this.state.event.emit({
      type: 'ON-AFTER-ROW-CREATED',
      data: this.row
    });
  }

  @HostListener('click')
  onClickHostListener(): void {
    this.state.event.emit({
      type: 'ON-CLICK',
      data: this.row.record
    });
  }
}
