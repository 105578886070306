import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { Item } from '../../../bean/item';
import { Uom } from '../../../bean/uom';
import { WorkPlanItem } from '../../../bean/work-plan-item';
import { AppPopupBudgetService } from '../app-popup-budget/app-popup-budget.service';
import { AppPopupItemService } from '../app-popup-item/app-popup-item.service';

@Component({
  templateUrl: './app-popup-work-plan-item.component.html'
})
export class AppPopupWorkPlanItemComponent
  extends BaseComponentComponent
  implements AfterViewChecked
{
  @Input() public workPlanItem: WorkPlanItem;
  @Input() public itemIdList: number[];
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public item: Item = new Item();
  public uom: Uom = new Uom();

  constructor(
    public appPopupItemService: AppPopupItemService,
    public appPopupBudgetService: AppPopupBudgetService,
    public activeModal: NgbActiveModal,
    public cdf: ChangeDetectorRef
  ) {
    super('app-popup-work-plan-item');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.setStateReady();
  }

  ngAfterViewChecked(): void {
    this.cdf.detectChanges();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      item: [null],
      itemName: [null, Validators.compose([Validators.required])],
      unit: [null, Validators.compose([Validators.required])],
      qty: [null, Validators.compose([Validators.required])],
      unitPrice: [null, Validators.compose([Validators.required])],
      convertUnitPrice: [null, Validators.compose([Validators.required])],
      totalPrice: [null, Validators.compose([Validators.required])],
      convertTotalPrice: [null, Validators.compose([Validators.required])],
      specification: [null, Validators.compose([Validators.required])],
      budget: [null],
      coa: [null, Validators.compose([Validators.required])]
    });
  }

  public setFormGroup(): void {
    if (this.workPlanItem != null) {
      this.formGroup.patchValue({
        item: this.workPlanItem.item,
        itemName: this.workPlanItem.item.name,
        unit: this.workPlanItem.item.uom.name,
        qty: this.workPlanItem.quantity,
        unitPrice: this.workPlanItem.price,
        convertUnitPrice: this.global.converterService.convertMoney(
          this.workPlanItem.price
        ),
        totalPrice: this.workPlanItem.totalPrice,
        convertTotalPrice: this.global.converterService.convertMoney(
          this.workPlanItem.totalPrice
        ),
        specification: this.workPlanItem.specification
      });
    }
  }

  public doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup);
    }
  }

  public onChangeQuantity(): void {
    const { unitPrice, qty } = this.formGroup.value;
    if (unitPrice != null || unitPrice !== undefined) {
      const unitPriceNumber =
        this.global.converterService.uConvertMoney(unitPrice);
      const totalPrice = +unitPriceNumber * qty;
      this.formGroup.patchValue({
        totalPrice,
        convertTotalPrice: this.global.converterService.convertMoney(
          totalPrice.toString()
        ),
        convertUnitPrice: unitPrice,
        unitPrice: unitPriceNumber
      });
    }
  }

  public onChangeUnitPrice(): void {
    const { unitPrice, qty } = this.formGroup.value;
    const unitPriceNumber =
      this.global.converterService.uConvertMoney(unitPrice);
    const totalPrice = +unitPriceNumber * qty;

    this.formGroup.patchValue({
      totalPrice,
      convertTotalPrice: this.global.converterService.convertMoney(
        totalPrice.toString()
      ),
      convertUnitPrice: unitPrice,
      unitPrice: unitPriceNumber
    });
  }

  public chooseItem(): void {
    this.appPopupItemService.open(this.itemIdList).subscribe(item => {
      this.formGroup.patchValue({
        item,
        itemName: item.name,
        unit: item.uom.name
      });
    });
  }

  public chooseBudget(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    const totalPrice = this.formGroup.value.totalPrice;
    this.appPopupBudgetService.open(totalPrice).subscribe(budget => {
      this.formGroup.patchValue({
        budget,
        coa: budget.name
      });
    });
  }
}
