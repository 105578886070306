
  <ng-container *ngTemplateOutlet="formLoading ? loadingTemplate : contentTemplate">
  </ng-container>

  <ng-template #loadingTemplate>
    <div class="promise-container d-flex align-items-center justify-content-center" style="min-height: 95vh">
      <div class="promise-loading">
        <div class="loading"></div>
      </div>
    </div>
  </ng-template>

  <ng-template #contentTemplate>

    <div class="portal-home jumbotron">
      <div id="carouselExampleIndicators" class="container carousel" data-ride="carousel">
        <div class="carousel-inner">
          <div *ngFor="let data of homeSlideList; let i = index"
            [ngClass]="i === 0 ? 'carousel-item active' : 'carousel-item'">
            <a><img class="d-block img-fluid w-100" *ngIf="data.imgFile !== null" [src]="
                  global.config.BACKEND_ADDRESS +
                    '/home-slide/file/view/' +
                    data.imgFile.uploadedFileName +
                    '/' +
                    data.imgFile.fileType.code
                    | resolveImgSrc
                    | async
                " alt="First slide" [disabled]="true" />
            </a>
            
          </div>
        </div>
        <div class="carousel-footer">
          <a href="#carouselExampleIndicators" role="button" data-slide="prev">
            <em class="pir pi-chevron-left"></em>
          </a>
          <a href="#carouselExampleIndicators" role="button" data-slide="next">
            <em class="pir pi-chevron-right"></em>
          </a>
        </div>
      </div>
    </div>

    <div class="container">
     
      <div class="card-deck w-100 m-0 justify-content-center p-10">
        <div class="col-md-4 card phf-card m-2" *ngFor="let item of cardItemList" (click)="doNavigate(item.routerUrl)">
          <div class="card-body">
            <div [ngClass]="item.vector" style="fill: black;"></div>
            <div class="phfc-body">
              <h3>{{ item.title | translate }}</h3>
              <p>{{ item.text | translate }}</p>
            </div>
            <div class="phfc-footer">
              <span>{{ 'portal.home.readMore' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
   
    </div>

  </ng-template>
