/*
 * Filename:  WebContent\src\app\core\components\app-table-x\helpers\set-plugin-formcontrol-is-view.helper.ts
 * Path:  WebContent
 * Created Date: Thursday, August 26th 2021, 8:25:22 pm

 *

 */
import { FormControl } from '@angular/forms';
import { AllTablePluginOptions } from '../interfaces/available-table-plugin';
import { TablePluginFormOption } from '../types/table-plugin-options';
export const setPluginFormcontrolIsView = (
  plugin: AllTablePluginOptions,
  formControl: FormControl,
  isView: boolean
): void => {
  const tablePluginFormOption = plugin as TablePluginFormOption;
  formControl.setIsView(
    typeof tablePluginFormOption.isView !== 'undefined'
      ? tablePluginFormOption.isView
      : !!isView
  );
};
