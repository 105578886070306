import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { BaseModuleComponent } from './core/base/angular/base-module.component';
import { BroadcastMessage } from './core/bean/broadcast-message';
import { AlertTopService } from './core/services/alert-top/alert-top.service';
import { AuthenticationService } from './core/services/authentication.service';
import { HttpClientService } from './core/services/http-client.service';
import { AlertTopWebsocketService } from './core/services/websocket/alert-top-websocket.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent extends BaseModuleComponent {
  public settings: Settings;
  public isOffline: boolean;
  public message: string;
  constructor(
    public appSettings: AppSettings,
    translateService: TranslateService,
    public authenticationService: AuthenticationService,
    public alertTopWebsocketService: AlertTopWebsocketService,
    public httpClientService: HttpClientService,
    public alertTopService: AlertTopService
  ) {
    super('app', translateService);
    this.settings = this.appSettings.settings;
  }

  public onInit(): void {
    this.setStateReady();
    this.initializeWebSocketConnection();
    this.restoreRouterParams();
    this.getBroadcastMessage();
  }

  public initializeWebSocketConnection(): void {
    this.alertTopWebsocketService.connect();
  }

  public doCopyErrorMessage(
    customTostCopyElement: HTMLElement,
    contentElement: HTMLElement
  ): void {
    customTostCopyElement.classList.add('show');
    setTimeout(() => customTostCopyElement.classList.remove('show'), 2000);
    const textAreaElement = document.createElement('textarea');
    textAreaElement.style.position = 'absolute';
    textAreaElement.style.opacity = '0';
    textAreaElement.value = contentElement.textContent;
    document.body.appendChild(textAreaElement);
    textAreaElement.select();
    document.execCommand('copy');
    document.body.removeChild(textAreaElement);
  }

  private restoreRouterParams(): void {
    const paramStone = localStorage.getItem('params-stone');
    if (paramStone) {
      const routerParamsObject = JSON.parse(paramStone);
      Object.keys(routerParamsObject).forEach((key: string) => {
        this.global.routerParams.set(key, routerParamsObject[key]);
      });
    }
  }

  public getBroadcastMessage(): void {
    const messageId =
      localStorage.getItem('last-read-broadcast-message') !== null &&
      localStorage.getItem('last-read-broadcast-message') !== 'undefined'
        ? localStorage.getItem('last-read-broadcast-message')
        : 0;
    this.httpClientService
      .get<BroadcastMessage>('/app-info/get-broadcast-message/' + messageId)
      .subscribe((broadcastMessage: BroadcastMessage) => {
        if (broadcastMessage) {
          this.alertTopService.showInfo(broadcastMessage);
        }
      });
  }

  public doCloseBlockUI(): void {
    if (this.global.blockMsg.msg.includes('REQ-ERR-004:')) {
      this.authenticationService.logout();
    }
    this.global.unblockUI();
  }

  @HostListener('window:beforeunload')
  onBeforeUnload(): void {
    const routerParams = {};
    for (let [key, value] of this.global.routerParams) {
      routerParams[key] = value;
    }
    if (Object.keys(routerParams).length > 0) {
      const circularJSON = require('circular-json');
      localStorage.setItem(
        'params-stone',
        circularJSON.stringify(routerParams)
      );
    }
  }

  @HostListener('window:online')
  onWindowOnline(): void {
    this.isOffline = false;
  }
 
  @HostListener('window:offline')
  onWindowOffliine(): void {
    this.isOffline = true;
  }
}
