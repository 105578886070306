/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\default-plugin\index.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 1:43:18 pm

 *
*
 */
import { createTableComponent } from '../../helper';
import { TablePlugin } from '../../interface/table-plugin';
import { TablePluginData } from '../../interface/table-plugin-data';
import { TableDefaultPlugin } from './default-plugin.component';
export const tableDefaultPlugin: TablePlugin = {
  name: 'default',
  before: (tablePluginData: TablePluginData): HTMLElement =>
    createTableDefaultPlugin(tablePluginData),
  onCreate: (tablePluginData: TablePluginData): HTMLElement =>
    createTableDefaultPlugin(tablePluginData)
};
const createTableDefaultPlugin = (tablePluginData: TablePluginData) => {
  return createTableComponent(
    tablePluginData,
    TableDefaultPlugin,
    'default-plugin-content'
  );
};
