

import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import * as accordionAction from './actions/accordion-action';
import { AppAccordionService } from './app-accordion.service';
import { AccordionState } from './domain/accordion-state';
import { AccordionResponseModel } from './model/accordion-response-model';
@Component({
  selector: 'app-accordion',
  templateUrl: './app-accordion.component.html',
  providers: [AppAccordionService]
})
export class AppAccordionComponent extends BaseComponentComponent {
  @Input() isLoading: boolean;
  @Input() theme: 'LIGHT' | 'DARK' | 'TRANSPARENT' = 'LIGHT';
  @Input() model: AccordionResponseModel<any>;
  @Input() isShowButtonExpandCollapse: boolean;
  @Input() isExpandAll: boolean;
  @Input() isShowMultiple: boolean;
  @Input() isView: boolean;
  @Input() customClass: string;
  @Input() customClassHeader: string;
  @Input() customClassBody: string;

  public state: AccordionState;

  constructor(private appAccordionService: AppAccordionService) {
    super('app-accordion');
  }

  onInit(): void {
    this.setInitializationState();
    this.setStateReady();
  }

  private setInitializationState(): void {
    this.state = this.appAccordionService.setState(
      this.isShowMultiple,
      this.isShowButtonExpandCollapse,
      this.isExpandAll,
      this.model,
      this.isView
    );
  }

  public doExpandAll(isExpandAll: boolean): void {
    this.appAccordionService.dispatch(
      new accordionAction.ExpandOrCollapseAllAccordion({ isExpandAll })
    );
  }
}
