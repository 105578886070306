/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\portal\landing-page\landing-page.component.ts
 
 * Created Date: Friday, October 1st 2021, 9:07:13 pm

 *
*
 */

import { Component } from '@angular/core';
@Component({
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {}
