/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\portal\landing-page\reset-password-success.component.ts
 
 * Created Date: Sunday, October 3rd 2021, 1:58:49 pm

 *
*
 */

import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  templateUrl: './reset-password-success.component.html'
})
export class ResetPasswordSuccessComponent {
  constructor(private router: Router) {}
  public doNavigateToDashboard(): void {
    this.router.navigate(['/portal/home']);
  }
}
