export class VMConstant {
  public V_ACTIVITY_STATUS_PROFILE_COMPLETION = 'PROFILE_COMPLETION';
  public V_ACTIVITY_STATUS_VERIFICATION = 'VERIFICATION';
  public V_ACTIVITY_STATUS_DUE_DILIGENCE = 'DUE_DILIGENCE';
  public V_ACTIVITY_STATUS_OTS = 'OTS';
  public V_ACTIVITY_STATUS_INTERVIEW = 'INTERVIEW';
  public V_ACTIVITY_STATUS_APPROVAL_CANDIDATE = 'APPROVAL_CANDIDATE';
  public V_ACTIVITY_STATUS_REJECT_REGISTRATION = 'REJECT_REGISTRATION';
  public V_ACTIVITY_STATUS_APPROVAL_REACTIVATION = 'APPROVAL_REACTIVATION';
  public V_ACTIVITY_STATUS_REVISION_REACTIVATION = 'REVISION_REACTIVATION';
  public V_ACTIVITY_STATUS_VENDOR_ACTIVE = 'VENDOR_ACTIVE';
  public V_ACTIVITY_STATUS_RE_VERIFICATION = 'RE_VERIFICATION';
  public V_ACTIVITY_STATUS_APPROVAL_UPDATE_PROFILE = 'APPROVAL_UPDATE_PROFILE';
  public V_ACTIVITY_STATUS_CERTIFICATE_EXPIRED = 'CERTIFICATE_EXPIRED';
  public V_ACTIVITY_STATUS_VENDOR_INACTIVE = 'VENDOR_INACTIVE';
  public V_ACTIVITY_STATUS_APPROVAL_VENDOR_BLACKLIST =
    'APPROVAL_VENDOR_BLACKLIST';
  public V_ACTIVITY_STATUS_REVISION_VENDOR_BLACKLIST =
    'REVISION_VENDOR_BLACKLIST';
  public V_ACTIVITY_STATUS_VENDOR_BLACKLIST = 'VENDOR_BLACKLIST';
  public V_ACTIVITY_STATUS_APPROVAL_VENDOR_WHITELIST =
    'APPROVAL_VENDOR_WHITELIST';
  public V_ACTIVITY_STATUS_REVISION_VENDOR_WHITELIST =
    'REVISION_VENDOR_WHITELIST';
  public V_ACTIVITY_STATUS_WAITING_FOR_WHITELIST = 'WAITING_FOR_WHITELIST';
  public V_ACTIVITY_STATUS_WAITING_FOR_BLACKLIST = 'WAITING_FOR_BLACKLIST';

  /* t1_certificate_status */
  public CERTIFICATE_STATUS_TEMPORARY = 'TEMPORARY';
  public CERTIFICATE_STATUS_ACTIVE = 'ACTIVE';
  public CERTIFICATE_STATUS_EXPIRED = 'EXPIRED';

  /* t1_module_scoring_criteria */
  public M_SCORING_CRITERIA_REGISTRATION = 'REGISTRATION';
  public M_SCORING_CRITERIA_PROCUREMENT = 'PROCUREMENT';
  public M_SCORING_CRITERIA_FULFILLMENT = 'FULFILLMENT';

  /* t1_vendor_type */
  public VENDOR_TYPE_LOCAL_COMPANY = 'LCL-CMPN';
  public VENDOR_TYPE_LOCAL_INDIVIDU = 'LCL-INDV';
  public VENDOR_TYPE_FOREIGN_COMPANY = 'FRN-CMPN';

  /* t1_vendor_tab */
  public VENDOR_TAB_COMPANY_DATA = 'CD';
  public VENDOR_TAB_SEGMENTATION = 'SGMN';
  public VENDOR_TAB_DOCUMENT = 'DOC';
  public VENDOR_TAB_EXPERIENCE = 'EXP';
  public VENDOR_TAB_EXPERTISE_EQUIPMENT = 'EP&EQ';
  public VENDOR_TAB_GENERAL_INFORMATION = 'GI';
  public VENDOR_TAB_COMPANY_ORGANIZATION = 'CO';
  public VENDOR_TAB_BANK = 'BANK';
  public VENDOR_TAB_FINANCIAL_STATEMENT = 'FS';
  public VENDOR_TAB_PARTNERSHIP = 'PART';
  public VENDOR_TAB_MARKETING = 'MRKT';
  public VENDOR_TAB_REP_AGENT = 'RA';

  /* t1_vendor_status */
  public VENDOR_STATUS_ACTIVE = 'ACTIVE';
  public VENDOR_STATUS_CANDIDATE = 'CANDIDATE';
  public VENDOR_STATUS_INACTIVE = 'INACTIVE';
  public VENDOR_STATUS_BLACKLIST = 'BLACKLIST';

  /* t1_catalog_type */
  public CATALOG_TYPE_VENDOR = 'VENDOR_CATALOG';
  public CATALOG_TYPE_CONTRACT = 'CONTRACT_CATALOG';

  /* t1_procurement_transaction_type */
  public PROC_TRANSACTION_TYPE_ONLINE = 'ONLINE';
  public PROC_TRANSACTION_TYPE_OFFLINE = 'OFFLINE';

  /* t1_catalog_contract_status */
  public CATALOG_CONTRACT_STATUS_NOT_STARTED = 'NOT_STARTED';
  public CATALOG_CONTRACT_STATUS_TERMINATED = 'TERMINATED';
  public CATALOG_CONTRACT_STATUS_ACTIVE = 'ACTIVE';
  public CATALOG_CONTRACT_STATUS_NOT_ACTIVE = 'NOT_ACTIVE';

  /* t1_catalog_status */
  public CATALOG_STATUS_VERIFICATION = 'VERIFICATION';
  public CATALOG_STATUS_REVISION = 'REVISION';
  public CATALOG_STATUS_ACTIVE = 'ACTIVE';
  public CATALOG_STATUS_NEED_COMPLETION = 'NEED_COMPLETION';
  public CATALOG_STATUS_NON_ACTIVE = 'NON_ACTIVE';
  public CATALOG_STATUS_REJECTED = 'REJECTED';
}
