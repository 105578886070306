import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { CompanyIdentity } from 'src/app/core/bean/company-identity';
import { ResourceModel } from 'src/app/core/model/resource.model';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { AppLoadService } from '../../../core/services/app-load.service';
import { Resource } from './../../../core/bean/resource';
@Component({
  templateUrl: './company-identity-edit-add.component.html'
})
export class CompanyIdentityEditAddComponent extends BaseModuleComponent {
  public appLoadService: AppLoadService;

  constructor(
    translateService: TranslateService,
    appLoadService: AppLoadService
  ) {
    super('company-identity', translateService);
    this.appLoadService = appLoadService;
  }

  public onInit(): void {
    this.doSetTodoFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetTodoFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      companyIdentityList: this.formBuilder.array([])
    });
  }

  public get companyIdentityList(): FormArray {
    return this.formGroup.get('companyIdentityList') as FormArray;
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<ResourceModel[]>('/company-identity/add-edit', {})
      .subscribe(companyIdentityList => {
        companyIdentityList.forEach((companyIdentity: CompanyIdentity) => {
          const formGroup = this.formBuilder.group({
            id: companyIdentity.id,
            code: companyIdentity.code,
            name: companyIdentity.name,
            value: companyIdentity.value
          });
          formGroup.get('name').setIsView(true);
          this.companyIdentityList.push(formGroup);
        });
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<Resource>>(
                '/company-identity/update',
                this.companyIdentityList.value
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.appLoadService.getBackendConfig().then(() => {
                    this.router.navigate(['/pages/content']);
                  });
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/content']);
  }
}
