/*
 * Filename:  WebContent\src\app\core\components\app-table-x\pipe\resolve-is-checked-group.pipe.ts
 * Path:  WebContent
 * Created Date: Monday, August 16th 2021, 4:38:15 pm

 *
*
 */

import { Pipe, PipeTransform } from '@angular/core';
import { ResolveInputIsCheckedPipe } from '../../../pipe/resolve-input-is-checked.pipe';
import { CheckedRecord } from '../model/checked-record';
import { TableRecord } from '../model/table-record';
@Pipe({
  name: 'resolveTableRowIsChecked'
})
export class ResolveTableRowIsCheckedPipe implements PipeTransform {
  constructor(private resolveInputIsCheckedPipe: ResolveInputIsCheckedPipe) {}
  transform(
    selectedRecord: CheckedRecord,
    record: TableRecord,
    isCheckedAll?: boolean,
    valueField?: string
  ): boolean {
    if (record.childrens && record.childrens.length > 0) {
      console.log('Info: Come from if');
      return (
        isCheckedAll ||
        Array.from(record.childrens)
          .map(recordChildren =>
            selectedRecord
              .getValue()
              .checkedRecordList.findIndex(
                checkedRecord =>
                  JSON.stringify(recordChildren) ===
                  JSON.stringify(checkedRecord)
              )
          )
          .filter(val => val === -1).length === 0
      );
    } else {
      return this.resolveInputIsCheckedPipe.transform(
        selectedRecord.getValue().checkedRecordList,
        record.record,
        isCheckedAll,
        valueField
      );
    }
  }
}
