/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table\app-portal-table.module.ts
 
 * Created Date: Thursday, September 30th 2021, 10:27:01 am

 *
*
 */

import { NgModule } from '@angular/core';
import { AppButtonModule } from '../../../../core/components/app-button/app-button.module';
import { AppPaginationXModule } from '../../../../core/components/app-pagination-x/app-pagination-x.module';
import { VendorModule } from '../../../../core/vendor/vendor.module';
import { AppPortalTableComponent } from './app-portal-table.component';
@NgModule({
  imports: [VendorModule, AppButtonModule, AppPaginationXModule],
  declarations: [AppPortalTableComponent],
  exports: [AppPortalTableComponent]
})
export class AppPortalTableModule {}
