/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\date-picker-plugin\date-picker-plugin.component.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 9:49:59 pm

 *
*
 */

import { Component } from '@angular/core';
import { ArrayUtils } from '../../../../utils';
import { BaseTableFormPluginComponent } from '../../base/base-table-form-plugin.component';
import { TextFieldPlugin } from '../../interface/available-table-plugin';
@Component({
  templateUrl: './text-field-plugin.component.html'
})
export class TableTextFieldPluginComponent extends BaseTableFormPluginComponent {
  public textFieldPlugin: TextFieldPlugin;
  constructor() {
    super();
  }

  protected onInitBaseTableForm(): void {
    this.setInitializationPluginState();
    this.requestChangesListener();
  }

  private setInitializationPluginState(): void {
    this.textFieldPlugin = this.tablePluginData.plugin as TextFieldPlugin;
  }

  private requestChangesListener(): void {
    this.tablePluginData.column.requestChanges.subscribe(() => {
      this.formControl.patchValue(
        ArrayUtils.resolveFieldData(
          this.updatedRecord,
          this.tablePluginData.column.column.field
        )
      );
    });
  }
}
