/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table\action\first-load-portal-table-action.ts
 
 * Created Date: Thursday, September 30th 2021, 10:55:28 am

 *
*
 */

import { PortalTableState } from '../domain/portal-table-state';
import { resolvePortalTableRecordList } from '../helper/resolve-portal-table-record-list-helper';
export const firstLoadPortalTable = (state: PortalTableState): void => {
  resolvePortalTableRecordList(state).then((rowList: Array<any>) => {
    state.rowList = rowList;
    state.setStateReady();
  });
};
