import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { BaseModuleComponent } from '../core/base/angular/base-module.component';
import { AppPopupService } from '../core/components/app-popup/app-popup.service';
import { AuthenticationService } from './../core/services/authentication.service';
import { AppLoginFormPopupComponent } from './login/app-login-form-popup.component';
import { MapResponseModel } from '../core/components/app-map/model/map-response-model';
import { Subscription } from 'rxjs';

import { Global } from '../core/global/global';
import { ActiveDropDown } from '../core/components/app-drop-down/active-drop-down.service';
import { PopupLoginComponent } from '../themes/promise-portal/components/popup-login/popup-login.component';
import { Lang } from '../core/bean/lang';
import { LogoFileResponse } from '../core/model/logo-file-response';
@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal_css/style.css', './portal_css/responsive.css'],
  encapsulation: ViewEncapsulation.None
})
export class PortalComponent extends BaseModuleComponent {

  public menuList: Array<any> = [];
  public mainLogoFileName: string;
  public mapModel: MapResponseModel = new MapResponseModel(null, true, true);
  public isLoading = true;
  public contactText: string;
  public subscription: Subscription;
  public src: string;


  public settings: Settings;
  public logoHeaderPortal: string;
  public currentYear: number = new Date().getFullYear();
  constructor(

    public global: Global,
    translateService: TranslateService,
    public appSettings: AppSettings,
    public authenticationService: AuthenticationService,
    public appPopupService: AppPopupService,
   
    private activeDropDown: ActiveDropDown
  ) {
    super('portal', translateService);
    this.settings = this.appSettings.settings;
    if (sessionStorage['skin']) {
      this.settings.theme.skin = sessionStorage['skin'];
    }
  }

  public onInit(): void {
    if (this.global.userSession) {
      this.global.routerParams.set('reload', this.authenticationService.URL);
      this.router.navigate(['/reload']);
    }

    this.subscription = this.httpClientService
    .get('/contact/get-contact/' + this.global.currentLang)
    .subscribe(response => {
      const longLat = this.mapModel.convertStringToLatLng(
        response.coordinateLocation.value
      );
      this.mapModel.setLatitudeAndLongitude(
        longLat.latitude,
        longLat.longitude
      );
      this.contactText = response.contact.value;
      this.isLoading = false;
    });

    
    this.setStateReady();
  }

  private doCloseNavbar(): void {
    const customNavbarToggleElement: any = document.getElementById(
      'custom-navbar-toggle'
    );
    customNavbarToggleElement.checked = false;
  }

  public doLogin(): void {
   
    this.doCloseNavbar();
    this.appPopupService.open(AppLoginFormPopupComponent, null, {
      backdrop: true,
      windowClass: 'portal-popup-form',
      size: null
    });
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }


  ngOnInit(): void {
    this.getImageLogo(this.global.appConstant.fileType.IMG_MAIN_LOGO);
    this.setMenuListState();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private setMenuListState(): void {
    const menuList =
      'Home, About, News, Contact, Faq '.split(',');
    this.menuList = menuList.map((name: string, index: number) => {
      return {
        id: index + 1,
        name:
          'portal.menu.' +
          name.trim()
            .split(' ')
            .map((str, index) => (index === 0 ? str.toLowerCase() : str))
            .join(''),
        routerLink: name.toLowerCase().trim()
      };
    });
  }

  public doShowPopupLogin(): void {
    this.appPopupService.open(PopupLoginComponent, null, {
      windowClass: 'promise-portal-popup-login'
    });
  }

  public doChangeCurrentLang(lang: Lang): void {
    this.activeDropDown.close();
    if (lang.code.toLowerCase() !== this.global.currentLang) {
      this.global.changeLang(lang.code.toLowerCase(), 'portal');
    }
  }

  public getImageLogo(fileType: string): void {
    this.httpClientService
      .post<LogoFileResponse>(
        '/app-info/logo', 
        fileType
      )
      .subscribe(logoFileResponse => {
        if(logoFileResponse != null){
          if(logoFileResponse.mainLogoFileName != null){
            this.mainLogoFileName = logoFileResponse.mainLogoFileName;
          }
        }
      },
    );
  }

}
