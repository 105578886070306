/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\helper\resolve-widget-helper.ts
 
 * Created Date: Thursday, November 18th 2021, 1:54:56 pm

 *
*
 */

import { UserDashboardWidget } from '../../../../core/bean/user-dashboard-widget';
import { HttpClientService } from '../../../../core/services/http-client.service';
import { WidgetItem } from '../domain/widget-item';
import { WidgetState } from '../domain/widget-state';
export const resolveWidget = (state: WidgetState): Promise<WidgetItem[]> => {
  return new Promise(resolve => {
    if (state.isServerSide) {
      const httpClientService = state.injector.get(HttpClientService);
      httpClientService.get(state.model.stringUrl).subscribe(
        (userDashboardWidget: UserDashboardWidget[]) =>
          resolve(userDashboardWidget.map(widget => WidgetItem.create(widget))),
        error => {
          console.log(`[ resolveWidget ]: Something failed`);
          console.log(error.message);
          resolve([]);
        }
      );
    } else {
      resolve(
        state.model.getWidgetList().map(widget => WidgetItem.create(widget))
      );
    }
  });
};
