
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { expandCollapseAnimation, rotateAnimation } from '../../animation';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { TextUtils } from '../../utils';
import * as accordionAction from './actions/accordion-action';
import { AppAccordionService } from './app-accordion.service';
import { AccordionItemModel } from './model/accordion-item-model';
@Component({
  selector: 'app-accordion-item',
  templateUrl: './app-accordion-item.component.html',
  animations: [expandCollapseAnimation(), rotateAnimation()]
})
export class AppAccordionItemComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() model: AccordionItemModel<any>;
  @Input() isLoading: boolean;
  @Input() isHidden: boolean;
  @Input() isView: boolean;
  @Input() header: string;
  @Input() customClass: string;
  @Input() customClassHeader: string;
  @Input() customClassBody: string;
  @Input() isExpanded: boolean;
  @Input() isDisabled: boolean;
  @Input() maxLine = this.global.appConstant.core.MAX_LINE_HTML_TEXT;

  @Output() private onClick: EventEmitter<PointerEvent> = new EventEmitter();

  @ContentChild('headerRight') public headerRightTmpl: TemplateRef<any>;
  @ContentChild('headerLeft') public headerLeftTmpl: TemplateRef<any>;
  public actionButtonLeftTmpl: TemplateRef<any>;
  public title: string;

  private generatedId: string = TextUtils.generateRandomString();
  private isUseModel: boolean;
  constructor(public appAccordionService: AppAccordionService) {
    super('app-accordion-item');
  }

  onInit(): void {
    this.setInitializationState();
    this.addAccordionItemToAccordionState();
    this.setTitleTooltip();
    this.setStateReady();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setTitleTooltip();
    if (
      changes.isExpanded &&
      !changes.isExpanded.firstChange &&
      changes.isExpanded.currentValue !== changes.isExpanded.previousValue &&
      !this.isUseModel
    ) {
      if (!this.isDisabled && !this.isView) {
        this.appAccordionService.dispatch(
          new accordionAction.ExpandOrCollapseAccordion({
            accordionItem: this.model
          })
        );
      }
    }
  }

  private setTitleTooltip(): void {
    const div = document.createElement('div');
    div.innerHTML = this.header;
    this.title = div.textContent || div.innerText || '';
  }

  private setInitializationState(): void {
    this.isUseModel = !!this.model;
    if (!this.model) {
      this.model = new AccordionItemModel({
        header: this.header
      });
    }
    this.model.isExpanded = this.isExpanded || false;
    this.model.id = this.generatedId;
  }

  private addAccordionItemToAccordionState(): void {
    this.appAccordionService.dispatch(
      new accordionAction.AddAccordionItemToState({
        accordionItem: this.model
      })
    );
  }

  public doExpandCollapse(event: PointerEvent): void {
    if (!this.isDisabled && !this.isView) {
      this.appAccordionService.dispatch(
        new accordionAction.ExpandOrCollapseAccordion({
          accordionItem: this.model
        })
      );
      this.onClick.emit(event);
    }
  }
}
