/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\core\components\table\helper\sort-table-record-helper.ts
 
 * Created Date: Wednesday, November 10th 2021, 3:09:11 pm

 *
*
 */

import { ArrayUtils } from '../../../utils';
import { TableState } from '../domain/table-state';

export const sortTableRecord = (
  state: TableState,
  recordList: Array<any> = []
): Array<any> => {
  let result = [];
  const sortFieldTemp = state.tableRequest.sortField;
  const sortOrder = state.sortOrder;
  const sortColumn = state.sortByColumn;
  if (sortFieldTemp) {
    result = recordList.sort((recordA, recordB) => {
      let sortField = sortFieldTemp;
      let valueA: string;
      let valueB: string;
      if (sortColumn.callbacks && sortColumn.callbacks.onSort) {
        valueA =
          sortColumn.callbacks.onSort(
            state.recordTemp.recordMap.get(JSON.stringify(recordA)) || recordA
          ) || '';
        valueB =
          sortColumn.callbacks.onSort(
            state.recordTemp.recordMap.get(JSON.stringify(recordB)) || recordB
          ) || '';
      } else {
        const dataMap = sortColumn.dataMap
          ? typeof sortColumn.dataMap === 'string'
            ? JSON.parse(sortColumn.dataMap)
            : sortColumn.dataMap
          : null;

        const tablePluginOptions = Array.isArray(sortColumn.plugins)
          ? sortColumn.plugins
          : [sortColumn.plugins];

        if (sortColumn.plugins) {
          tablePluginOptions.forEach(tablePluginOption => {
            if (tablePluginOption['name'] === 'currency') {
              sortField = sortFieldTemp + '.price';
            }
          });
        }
        valueA = ArrayUtils.resolveFieldData(recordA, sortField, dataMap) || '';
        valueB = ArrayUtils.resolveFieldData(recordB, sortField, dataMap) || '';
      }

      if (valueA && valueB) {
        valueA = typeof valueA === 'string' ? valueA.toLowerCase() : valueA;
        valueB = typeof valueB === 'string' ? valueB.toLowerCase() : valueB;
        return valueA < valueB
          ? sortOrder === 'ASC'
            ? -1
            : 1
          : valueA > valueB
          ? sortOrder === 'ASC'
            ? 1
            : -1
          : 0;
      }
      return 0;
    });

    return result;
  }

  return recordList;
};
