import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SensitiveDataService } from 'src/app/core/services/sensitive-data.service';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Organization } from '../../core/bean/organization';
import { Vendor } from '../../core/bean/vendor';
import { VendorType } from '../../core/bean/vendor-type';
import { AppPopupRegistrationTermConditionService } from '../../core/components/app-popup/app-popup-registration-term-condition/app-popup-registration-term-condition.service';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { RegistrationVendorRequest } from './registration-vendor-request';
import { RegistrationVendorResponse } from './registration-vendor-response';
@Component({
  templateUrl: './registration-confirmation.component.html',
  styleUrls: ['./registration-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationConfirmationComponent extends BaseModuleComponent {
  public phone: any;
  public vendor: Vendor = new Vendor();
  public vendorTypeList: VendorType[] = [];
  public registrationVendorRequest: RegistrationVendorRequest =
    new RegistrationVendorRequest();
  public lastOrganization: Organization;
  public taxNumber: string;
  public isEmpty = false;
  public dataForSave: any[] = [];
  public dissappear = true;
  public appear = false;
  public data: any;
  public setCompanyNameLabelActive: boolean;
  public registrationVendorResponse: RegistrationVendorResponse;
  public isShowPassword = false;
  constructor(
    public readonly translate: TranslateService,
    public sensitiveDataService: SensitiveDataService,
    public appPopupRegistrationTermConditionService: AppPopupRegistrationTermConditionService
  ) {
    super('registration', translate);
  }

  onInit(): void {
    this.buildFormGroup();
    this.setRegistrationVendorRequest();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      isCheckTOC: [true, Validators.isChecked(1)],
      isCheckVendorPolicy: [true, Validators.isChecked(1)],
      captcha: [
        null,
        Validators.compose(
          this.global.config.appInfoResponse.isShowCaptcha
            ? [Validators.required('app.validation.captchaRequired')]
            : []
        )
      ]
    });
  }

  public setRegistrationVendorRequest(): void {
    this.registrationVendorResponse = this.global.routerParams.get(
      'registrationVendorResponse'
    ); /** utk display TOC */
    this.data = this.global.routerParams.get('data');
    this.registrationVendorRequest = this.data;
    this.setCompanyNameLabelActive = this.global.routerParams.get(
      'setCompanyNameLabelActive'
    );
    this.registrationVendorRequest.phoneNumber = this.data.phone.number;
    this.registrationVendorRequest.regionPhone = this.data.phone.country;
    this.phone =
      this.registrationVendorRequest.regionPhone.callingCode +
      this.registrationVendorRequest.phoneNumber;
    this.lastOrganization =
      this.data.organizationList[this.data.organizationList.length - 1];
    this.taxNumber = this.data.taxNumber;
    if (Object.keys(this.registrationVendorRequest).length === 0) {
      this.isEmpty = true;
    }
    this.global.routerParams.set('isFromTOC', true);
  }

  public doSave(): void {
    this.global.modalService
      .saveConfirmation()
      .pipe()
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          const registrationVendorRequest: RegistrationVendorRequest =
            this.data;
          registrationVendorRequest.phoneNumber = this.data.phone.number;
          registrationVendorRequest.regionPhone = this.data.phone.country;
          registrationVendorRequest.organizationList =
            this.data.organizationList;
          registrationVendorRequest.lang = this.global.currentLang;

          registrationVendorRequest.password =
            this.sensitiveDataService.encrypt([
              registrationVendorRequest.password
            ]);
          delete registrationVendorRequest.retypePassword;

          this.httpClientService
            .post<Response<RegistrationVendorRequest>>(
              '/registration/insert',
              registrationVendorRequest
            )
            .subscribe(response => {
              if (response.status !== 'ERROR') {
                this.global.routerParams.set(
                  'vendorEmail',
                  registrationVendorRequest.email
                );
                this.router.navigate([
                  '/portal/registration/email-verification'
                ]);
                this.data = null;
              } else {
                this.showAlertMessage(response);
              }
              this.setStateReady();
            });
        }
      });
  }

  public showAlertMessage(response: Response<RegistrationVendorRequest>): void {
    if (response.status === ResponseStatusModel.OK) {
      const firstTranslate = this.translateService.instant(
        'registration.msg.registrationSuccess1'
      );
      const secondTranslate = this.translateService.instant(
        'registration.msg.registrationSuccess2'
      );
      this.global.alertService.showSuccess(
        firstTranslate + this.registrationVendorRequest.email + secondTranslate,
        null,
        { static: true }
      );
      this.dissappear = false;
      this.appear = true;
    } else {
      this.global.alertService.showError(response.statusText);
    }
  }

  public doPortal(): void {
    if (this.global.config.parameterModel.isInterfacing) {
      window.location.href = this.global.config.BACKEND_ADDRESS.replace(
        '/api',
        ''
      );
    } else {
      this.router.navigate(['/portal']);
    }
  }

  public doBack(): void {
    this.global.routerParams.set('isBackFromRegistConfirm', true);
    this.global.routerParams.set('todo', 'edit-add');
    this.global.routerParams.set('data', this.registrationVendorRequest);
    this.global.routerParams.set(
      'setCompanyNameLabelActive',
      this.setCompanyNameLabelActive
    );
    this.router.navigate(['/portal/registration']);
  }

  public doDisplayTermCondition(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupRegistrationTermConditionService.open(
      this.registrationVendorResponse
    );
  }

  public doDisplayVendorPolicy(): void {
    window.open('/portal/vendor-policy');
  }

  public doTogglePassword(): void {
    this.isShowPassword = !this.isShowPassword;
  }
}
