<ng-container *ngIf="!ISVIEW">
  <div class="app-chosen p-0" [ngClass]="elementWidthClassName">
    <div
      *ngIf="!(type === 'AUTOCOMPLETE' && !isMultiple)"
      class="form-control"
      (click)="onClickInput()"
      [ngClass]="[
        elementHeightClassName,
        isInvalid ? 'is-invalid' : '',
        isValid ? 'is-valid' : '',
        type === 'CHOSEN' || isMultiple
          ? 'form-control-tagging app-chosen-label'
          : ''
      ]"
      [ngStyle]="{ position: 'relative' }"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
      [appDropDown]="dropDownSelectChosen"
      className="drop-down-select"
      [disableOnClick]="
        type === 'CHOSEN' || type === 'AUTOCOMPLETE' ? true : false
      "
    >
      <!--ngClass formControl.disabled ? 'disabled' : ''-->
      <!-- <ng-container *ngIf="type === 'CHOSEN' || isMultiple; then multipleTmpl; else singleTmpl">
          </ng-container> -->
      <ng-container *ngIf="type === 'CHOSEN' || isMultiple">
        <app-tagging
          size="XL"
          [formControl]="formControl"
          [optionView]="this.optionList?.viewPath || 'name'"
          (onChange)="handleAppTaggingChange($event)"
          [showLimitValue]="showLimitValue"
        >
          <li class="d-inline-block" *ngIf="type !== 'CHOSEN'">
            <!-- <ul class="d-inline-block" *ngIf="!formControl.value">
              {{
                placeholder | translate
              }}</ul
            > -->
            <input
              #inputElement
              type="text"
              [size]="
                placeholder && !formControl.value ? placeholder.length + 5 : 1
              "
              [placeholder]="
                placeholder && !formControl.value
                  ? (placeholder | translate)
                  : ''
              "
              (input)="onInputKeyword($event)"
              [attr.id]="generatedId + 'input'"
            />
          </li>
        </app-tagging>
      </ng-container>

      <ng-container *ngIf="type === 'COMBOBOX' || type === 'CHOSEN'">
        <p *ngIf="!formControl.value" class="m-0 placeholder">
          {{ placeholder | translate }}
        </p>

        <ng-container *ngIf="!valueTmpl; then defaultValue; else customValue">
        </ng-container>
        <ng-template #defaultValue>
          {{
            formControl.value
              | resolveFieldData: optionList?.viewPath || 'name'
              | translate
          }}
        </ng-template>
        <ng-template #customValue>
          <ng-container
            *ngTemplateOutlet="
              valueTmpl;
              context: { $implicit: formControl.value }
            "
          ></ng-container>
        </ng-template>
      </ng-container>
    </div>

    <ng-container *ngIf="type === 'AUTOCOMPLETE' && !isMultiple">
      <!-- <div class="form-control">  -->
      <ng-container *ngIf="!isAddManual">
        <input
          #inputElement
          type="text"
          class="form-control"
          [ngClass]="[
            elementHeightClassName,
            isInvalid ? 'is-invalid' : '',
            isValid ? 'is-valid' : ''
          ]"
          (input)="handleInputAutoCompleteSingle($event)"
          [value]="
            formControl.value
              | resolveFieldData: optionList?.viewPath || 'name'
              | translate
          "
          [disabled]="formControl.disabled"
          [appDropDown]="dropDownSelectChosen"
          className="drop-down-select"
          [disableOnClick]="true"
        />
      </ng-container>

      <ng-container *ngIf="isAddManual" [formGroup]="generateFormGroup">
        <!-- is add manual true -->
        <input
          #inputElement
          type="text"
          class="form-control"
          [ngClass]="[
            elementHeightClassName,
            isInvalid ? 'is-invalid' : '',
            isValid ? 'is-valid' : ''
          ]"
          (input)="handleInputAutoCompleteSingle($event)"
          formControlName="name"
          [disabled]="formControl.disabled"
          [appDropDown]="dropDownSelectChosen"
          className="drop-down-select"
          [disableOnClick]="true"
          [value]="
            formControl.value | resolveFieldData: optionList?.viewPath || 'name'
          "
        />
      </ng-container>

      <!--(focus)="handleFocus($event)"
              (focusout)="handleFocusOut()"-->
      <!-- </div> -->
    </ng-container>

    <ng-template #dropDownSelectChosen>
      <!-- tadinya diluar div class="app-chosen p-0 -->
      <div class="app-chosen-dropdown ">
        <!--*ngIf="!formControl.disabled"-->
        <app-select
          [optionList]="optionList"
          [formControl]="formControl"
          [isMultiple]="type === 'CHOSEN' || isMultiple"
          [isShowInputControl]="type === 'CHOSEN'"
          [keywords]="keywords"
          [stringUrl]="stringUrl"
          [params]="params"
          (onChange)="onSelectChange()"
        >
          <ng-container *ngIf="optionTmpl">
            <ng-template #content let-data>
              <ng-container
                *ngTemplateOutlet="optionTmpl; context: { $implicit: data }"
              >
              </ng-container>
            </ng-template>
          </ng-container>

          <ng-template #selectAll *ngIf="type === 'CHOSEN' && selectAllTmpl">
            <ng-container
              *ngTemplateOutlet="selectAllTmpl; context: { $implicit: data }"
            >
            </ng-container>
          </ng-template>

          <ng-template
            #placeholderOption
            *ngIf="type === 'COMBOBOX' || type === 'CHOSEN'"
          >
            {{ placeholder | translate }}
          </ng-template>
          <ng-template #footer>
            <ng-container *ngTemplateOutlet="footerTmpl"> </ng-container>
          </ng-template>
        </app-select>
      </div>
    </ng-template>

    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>

  <small class="text-danger" *ngIf="isInvalid">
    {{ errors?.message }}
  </small>
</ng-container>

<ng-container *ngIf="ISVIEW">
  <div *ngIf="type === 'COMBOBOX'" class="d-inline-block position-relative">
    <ng-container *ngIf="!valueTmpl; then defaultValue; else customValue">
    </ng-container>
    <ng-template #defaultValue>
      {{
        formControl.value || '-'
          | resolveFieldData: optionList.viewPath
          | translate
      }}
    </ng-template>
    <ng-template #customValue>
      <ng-container
        *ngTemplateOutlet="valueTmpl; context: { $implicit: formControl.value }"
      ></ng-container>
    </ng-template>
  </div>

  <div
    *ngIf="type === 'AUTOCOMPLETE' && !isMultiple"
    [ngStyle]="{ position: 'relative', display: 'inline-block' }"
  >
    <span>{{
      (value && optionView ? value[optionView] : value?.name) || value || '-'
    }}</span>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>

  <ng-container *ngIf="type === 'CHOSEN' || isMultiple">
    <!-- ISVIEW TRUE chosen auto multi -->
    <div class="d-inline-block position-relative" [formGroup]="formGroup">
      <app-tagging
        [formControlName]="formControlName"
        [optionView]="this.optionList?.viewPath || 'name'"
      >
      </app-tagging>
      <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [symbolic]="symbolic"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
