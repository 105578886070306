/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\action\change-filter-per-page-action.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:08:29 am

 *
*
 */
import { TableState } from '../domain/table-state';
import { resolveTableRowList } from '../helper/resolve-table-row-list-helper';
export const changeTableFilterPerPageAction = (
  state: TableState,
  payload: { perPage: number }
): void => {
  state.pagination.perPage = payload.perPage;
  state.tableRequest.rows = payload.perPage;
  resolveTableRowList(state).then((rowList: Array<any>) => {
    state.setRowList(rowList);
    state.setStateReady();
  });
};
