import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { Subscription } from 'rxjs';
import { expandCollapseAnimation } from '../../animation';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { OptionListModel } from '../../model/option-list-model';
import { ArrayUtils, TextUtils } from '../../utils';

declare let $: any;

@Component({
  selector: 'app-select',
  templateUrl: './app-select.component.html',
  styleUrls: ['./app-select.component.scss'],
  providers: makeProvider(AppSelectComponent),
  animations: [expandCollapseAnimation()],
  encapsulation: ViewEncapsulation.None
})
export class AppSelectComponent
  extends BaseValueAccessorComponent<Array<any>>
  implements OnChanges
{
  @Input() search: boolean;
  @Input() sorter: boolean;
  @Input() selectAllText: string;
  @Input() isSelectAllDisabled: boolean;
  @Input() isMultiple: boolean;
  @Input() keywords: string;
  @Input() isToggle: boolean;
  @Input() isShowInputControl: boolean;
  @Input() params: Object;
  @Input() maxHeight: string;
  @Input() isShowBorder = false;
  @Input() isShowHover = true;
  @Input() minSelectItem = 2;

  @ContentChild('content') contentTmpl: TemplateRef<any>;
  @ContentChild('contentBottom') contentBottomTmpl: TemplateRef<any>;
  @ContentChild('headerFilter') headerFilterTemplate: TemplateRef<any>;
  @ContentChild('headerFilterGroup')
  headerFilterGroupTemplate: TemplateRef<any>;
  @ContentChild('headerButtons') headerButtonsTmpl: TemplateRef<any>;
  @ContentChild('selectAll') selectAllTemplate: TemplateRef<any>;
  @ContentChild('actionButtons') actionButtonsTmpl: TemplateRef<any>;
  @ContentChild('placeholderOption') placeholderOptionTmpl: TemplateRef<any>;
  @ContentChild('footer') footerTmpl: TemplateRef<any>;

  public generatedId: string = TextUtils.generateRandomString();
  public httpRequest: Subscription;

  public optionListTemp: Array<any>;
  private urlParams: string;

  public isExpandedFilterGroup: boolean;

  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super('app-select', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setInitializationState();
    this.setStateUrlParams();
    this.setRequestFromBackend();
    this.setStateReady();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.keywords &&
      changes.keywords.currentValue !== changes.keywords.previousValue
    ) {
      this.stringUrl
        ? this.setRequestFromBackend()
        : this.onInputSearch(changes.keywords.currentValue);
    }

    if (
      changes &&
      changes.params &&
      changes.params.currentValue !== changes.params.previousValue
    ) {
      this.setStateUrlParams();
    }
  }

  private setInitializationState(): void {
    if (!this.stringUrl) {
      this.setStateOptionListTemp();
      this.optionList.requestValueChanges.subscribe(() => {
        this.setStateOptionListTemp();
      });
    } else {
      this.optionList = new OptionListModel(true);
    }

    this.isSelectAllDisabled = typeof this.isSelectAllDisabled != 'undefined'?this.isSelectAllDisabled:false;

    if(this.isSelectAllDisabled == true){
      this.formControl.patchValue(null);
      this.onChange.emit(this.formControl.value);
    }
  }

  private setStateOptionListTemp(): void {
    this.onInputSearch(this.keywords);
  }

  private setStateUrlParams(): void {
    this.urlParams = this.params
      ? '&' +
        Object.keys(this.params)
          .map(
            key =>
              this.params[key] &&
              `${key}=${JSON.stringify(this.params[key])
                .trim()
                .replace(/"/g, '')}`
          )
          .filter(val => val)
          .join('&')
      : '';
  }

  private setRequestFromBackend(): void {
    if (this.stringUrl) {
      if (this.httpRequest) {
        this.httpRequest.unsubscribe();
      }

      const stringUrl =
        `${this.stringUrl}` +
        '?keyword=' +
        `${this.keywords ? this.keywords.trim() : ''}` +
        this.urlParams;

      this.httpRequest = this.httpClientService
        .get(stringUrl)
        .subscribe(response => {
          this.optionList.setRequestValues(response);
          this.optionListTemp = response;
        });
    }
  }

  public onScrollend(): void {
    // console.log('Info: Come from onScrollEnd');
    // this.formLoading = true;
  }

  public onInputSearch(keywords: string): void {
    if (this.stringUrl) {
      this.keywords = keywords;
      this.setRequestFromBackend();
    } else {
      const optionListRequestValues = Array.from(this.optionList.requestValues);
      this.optionListTemp = ArrayUtils.filterArrayByText(
        optionListRequestValues,
        keywords,
        this.optionList.searchFieldList || (this.optionList.viewPath as string)
      );
    }
  }

  public doClick(event: PointerEvent): void {
    event.stopPropagation();
  }

  public doSort(sort: 'ASC' | 'DESC'): void {
    const optionListRequestValues = Array.from(this.optionList.requestValues);
    this.optionListTemp = ArrayUtils.sortArray(
      optionListRequestValues,
      this.optionList.viewPath as string,
      sort
    );
  }

  public onCheckBoxChange(option: any): void {
    const values = Array.from(this.formControl.value || []);
    const indexOfOption = values.findIndex(
      val => JSON.stringify(val) === JSON.stringify(option)
    );
    indexOfOption !== -1
      ? values.splice(indexOfOption, 1)
      : values.push(option);
    this.formControl.patchValue(values.length === 0 ? null : values);
    this.onChange.emit(this.formControl.value);
  }

  public onRadioChange(option: any): void {
    this.formControl.patchValue(option);
    this.onChange.emit(this.formControl.value);
  }

  public onCheckBoxChangeAll(
    event: Event & { target: HTMLElement & { checked: boolean } }
  ): void {
    if(this.isSelectAllDisabled===true){
      this.isSelectAllDisabled = false;
    }
    const values = Array.from(this.optionList.requestValues);
    this.formControl.patchValue(event.target.checked ? values : null);
    this.onChange.emit(this.formControl.value);
  }

  public doClickPlaceholderOption(): void {
    this.formControl.patchValue(null);
    this.onChange.emit(this.formControl.value);
  }
}
