/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\default-plugin\default-plugin.component.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Friday, September 10th 2021, 6:58:54 pm

 *
*
 */

import { Component } from '@angular/core';
import { ArrayUtils } from '../../../../utils';
import { BaseTablePluginComponent } from '../../base/base-table-plugin.component';
import { DefaultPlugin } from '../../interface/available-table-plugin';
@Component({
  templateUrl: 'default-plugin.component.html'
})
export class TableDefaultPlugin extends BaseTablePluginComponent {
  public defaultPlugin: DefaultPlugin;
  public isHtmlValue: boolean;
  public value: any;
  constructor() {
    super();
  }

  onInit(): void {
    this.setInitializationPluginState();
    this.requestChangesListener();
  }

  private setInitializationPluginState(): void {
    this.defaultPlugin = this.tablePluginData.plugin as DefaultPlugin;
    this.isHtmlValue = !!this.tablePluginData.value?.childNodes;
    this.tablePluginData.column.value = this.tablePluginData.value;
    this.value =
      (this.defaultPlugin &&
        this.defaultPlugin.callbacks &&
        this.defaultPlugin.callbacks.value(this.record)) ||
      this.tablePluginData.column.value;
  }

  private requestChangesListener(): void {
    const dataMap = this.tablePluginData.column.column.dataMap;
    this.tablePluginData.column.requestChanges.subscribe(() => {
      this.value =
        (this.defaultPlugin &&
          this.defaultPlugin.callbacks &&
          this.defaultPlugin.callbacks.value(this.record)) ||
        ArrayUtils.resolveFieldData(
          this.record,
          this.tablePluginData.column.column.field,
          dataMap
            ? typeof dataMap === 'string'
              ? JSON.parse(dataMap)
              : dataMap
            : null
        );
    });
  }
}
