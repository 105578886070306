import { Component, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { WebContent } from '../../core/bean/web-content';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './registration-toc.component.html',
  styleUrls: ['./registration-toc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationTOCComponent extends BaseModuleComponent {
  public termsAndCondition: SafeHtml;
  public isCheckTOC: boolean;
  public isFromVendorPolicy: boolean;
  constructor(translate: TranslateService, public sanitizer: DomSanitizer) {
    super('registration', translate);
  }

  public onInit(): void {
    this.setDataFromRouterParam();
    this.buildFormGroup();
    this.doGetTOC();
  }

  public setDataFromRouterParam(): void {
    this.isCheckTOC = this.global.routerParams.get('isCheckTOC');
    this.isFromVendorPolicy =
      this.global.routerParams.get('isFromVendorPolicy');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      checkTOC: [
        false,
        Validators.compose([
          Validators.required(),
          Validators.isChecked(1, 'registration.validation.termsAndCondition')
        ])
      ]
    });
  }

  public doGetTOC(): void {
    this.httpClientService
      .get<WebContent>('/registration/get-toc/' + this.global.currentLang)
      .subscribe((termsAndCondition: WebContent) => {
        this.termsAndCondition = this.sanitizer.bypassSecurityTrustHtml(
          termsAndCondition.value
        );
        window.scroll({
          top: 0
        });
        this.global.routerParams.set('isFromVendorPolicy', false);
        this.setStateReady();
      });
  }

  public doNext(): void {
    this.global.routerParams.set(
      'isCheckTOC',
      this.formGroup.get('checkTOC').value
    );
    this.global.routerParams.set('isFromTOC', true);
    this.router
      .navigateByUrl('/', {
        skipLocationChange: true
      })
      .then(() => {
        this.router.navigate(['/portal/registration']);
      });
  }

  public doPrevious(): void {
    this.global.routerParams.set('isFromTOC', true);
    this.router.navigate(['/portal/registration/vendor-policy']);
  }
}
