
<div
  class="card widget-card"
  [ngClass]="[
    'widget-card-' + (size | lowercase),
    customClass ? customClass : ''
  ]"
  [@changeHeaderHeight]="isExpanded ? 'default' : 'height'"
  (click)="onClick.emit($event)"
>
  <div
    class="card-header d-flex justify-content-between"
    [ngClass]="[
      'card-header-' + (headerSize | lowercase),
      isExpanded
        ? isShowButtonExpandCollapse
          ? 'border-5'
          : 'border-1'
        : 'border-0',
      customClassHeader ? customClassHeader : ''
    ]"
    *ngIf="header || customHeaderTmpl"
  >
    <div class="ch-left mr-2" *ngIf="headerLeftTmpl">
      <ng-container *ngTemplateOutlet="headerLeftTmpl"></ng-container>
    </div>
    <h4 class="title" *ngIf="!customHeaderTmpl">{{ header | translate }}</h4>
    <ng-container *ngTemplateOutlet="customHeaderTmpl"></ng-container>
    <div class="ch-left ml-2" *ngIf="headerRightTmpl">
      <ng-container *ngTemplateOutlet="headerRightTmpl"></ng-container>
    </div>
    <ng-container *ngIf="isShowButtonExpandCollapse">
      <em
        class="pir pi-chevron-down pi-border pi-rounded cursor-pointer"
        (click)="isExpanded = !isExpanded"
        [@rotate]="isExpanded ? 'rotated' : 'default'"
      ></em>
    </ng-container>
  </div>
  <ng-container *ngTemplateOutlet="headerTmpl"></ng-container>

  <ng-container
    *ngTemplateOutlet="isLoading ? loadingTemplate : defaultTemplate"
  ></ng-container>

  <ng-template #loadingTemplate>
    <div class="d-flex align-items-center h-100">
      <div class="promise-loading">
        <div class="loading"></div>
      </div>
    </div>
  </ng-template>

  <ng-template #defaultTemplate>
    <div
      #cardBody
      class="card-body"
      [ngClass]="customClassBody ? customClassBody : ''"
      [@expandedCollapsed]="
        isShowButtonExpandCollapse
          ? isExpanded
            ? 'expanded'
            : 'collapsed'
          : ''
      "
    >
      <perfect-scrollbar>
        <div class="mr-3" [ngClass]="customClassBody ? customClassBody : ''">
          <ng-content></ng-content>
        </div>
      </perfect-scrollbar>
    </div>
    <div
      class="card-footer"
      *ngIf="footerTmpl"
      [ngClass]="customClassFooter ? customClassFooter : ''"
    >
      <ng-container *ngTemplateOutlet="footerTmpl"></ng-container>
    </div>
  </ng-template>
</div>
