/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\core\components\table\helper\create-table-column-list-helper.ts
 
 * Created Date: Tuesday, October 12th 2021, 7:23:16 pm

 *
*
 */

import { TableColumnModel } from '../model/table-column-model';
export const createTableColumnList = (
  columnList: Array<TableColumnModel>
): Array<TableColumnModel> => {
  const result: Array<TableColumnModel> = new Array();
  columnList.forEach(column => {
    if (column.columnList) {
      result.push(...createTableColumnList(column.columnList));
    } else {
      result.push(column);
    }
  });
  return result;
};
