/*
 * Filename:  WebContent\src\app\core\components\app-table-x\plugins\text-area.plugin.ts
 * Path:  WebContent
 * Created Date: Thursday, August 12th 2021, 10:51:13 am

 *
*
 */
import { AppDynamicComponentService } from '../../../services/app-dynamic-component.service';
import { AppTextAreaComponent } from '../../app-text-area/app-text-area.component';
import { createPluginFormcontrol } from '../helpers';
import { addPluginFormControlToFormGroup } from '../helpers/add-plugin-formcontrol-to-formgroup.helper';
import { setPluginFormcontrolIsView } from '../helpers/set-plugin-formcontrol-is-view.helper';
import { setTableRecordFormValue } from '../helpers/set-table-record-form-value.helper';
import { TextAreaPlugin } from '../interfaces/available-table-plugin';
import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';
export const AppTableTextAreaPlugin: TablePlugin = {
  name: 'text-area',
  before: (data: TablePluginData) => {
    data.element.addEventListener('click', (event: PointerEvent) =>
      event.stopPropagation()
    );
    const textAreaPlugin: TextAreaPlugin = data.column
      .plugins as TextAreaPlugin;
    const appDynamicComponent = data.state.injector.get(
      AppDynamicComponentService
    );
    let config: any = {};
    if (typeof textAreaPlugin !== 'string' && textAreaPlugin) {
      config = textAreaPlugin;
    }
    const formControl = createPluginFormcontrol(data);
    setPluginFormcontrolIsView(textAreaPlugin, formControl, data.state.isView);
    addPluginFormControlToFormGroup(data, formControl);
    appDynamicComponent
      .create(AppTextAreaComponent, data.element, {
        ...config,
        formControl: formControl,
        size: config.size || 'XL'
      })
      .onChange.subscribe(
        setTableRecordFormValue.bind(
          this,
          data.state,
          data.record,
          data.column,
          data.indexI
        )
      );
    data.element.style.maxWidth = '500px';
    data.element.style.width = '500px';
  }
};
