/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\action\widget-dispatch.ts
 
 * Created Date: Thursday, November 18th 2021, 11:06:25 am

 *
*
 */

import { WidgetState } from '../domain/widget-state';
import { addWidgetAction } from './add-widget-action';
import { deleteWidgetAction } from './delete-widget-action';
import { firstLoadWidgetAction } from './first-load-widget-action';
import { restoreWidgetAction } from './restore-widget-action';
import { saveWidgetAction } from './save-widget-action';
import { setWidgetEditableAction } from './set-widget-editable-action';
import * as widgetAction from './widget-action';
export const dispatchWidget = (
  state: WidgetState,
  action: widgetAction.AllWidgetAction
): void => {
  switch (action.type) {
    case widgetAction.FIRST_LOAD_WIDGET:
      firstLoadWidgetAction(state);
      break;
    case widgetAction.SET_WIDGET_EDITABLE:
      setWidgetEditableAction(state);
      break;
    case widgetAction.ADD_WIDGET:
      addWidgetAction(state);
      break;
    case widgetAction.DELETE_WIDGET:
      deleteWidgetAction(state, action.payload);
      break;
    case widgetAction.SAVE_WIDGET:
      saveWidgetAction(state);
      break;
    case widgetAction.RESTORE_WIDGET:
      restoreWidgetAction(state);
      break;
    default:
      console.log(`[ Widget ]: There's no type provided`);
      break;
  }
};
