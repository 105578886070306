<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="officer.title" detail="app.action.{{todo}}"
            [isLoading]="formLoading" [isSaving]="formSaving">
            <form class="form-horizontal" [formGroup]="formGroup">
                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        "officer.form.position" | translate }}</label>
                    <div class="col-sm-9 text-left">
                        <app-dropdown-select
                            formControlName="position"
                            stringUrl="/officer/auto-complete-position-list"
                            (onChange)="autoCompleteValueChange($event)"
                            type="AUTOCOMPLETE"
                            size="MD"
                        >
                        </app-dropdown-select>
                    </div>
                </div>
                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{ "officer.form.user"
                        | translate}}</label>
                    <div class="col-sm-9 text-left">
                        <app-choose formControlName="userName"
                            (onClick)="doChooseUser()"></app-choose>
                    </div>
                </div>
                <div class="button-group button-group-center mt-5">
                    <app-button color="SECONDARY" (onClick)="doCancel()"
                        [disabled]="formSaving">
                        {{ "app.button.back" | translate }}
                    </app-button>
                    <app-button (onClick)="doSave()" [disabled]="formSaving">
                        {{ "app.button.save" | translate }}
                    </app-button>
                </div>
            </form>
        </app-card>
    </div>
</div>