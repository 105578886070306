import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  selector: 'app-widget-performance-overall',
  templateUrl: './app-widget-performance-overall.component.html',
  styleUrls: ['./app-widget-performance-overall.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetPerformanceOverallComponent extends BaseComponentComponent {
  public performanceOverall: number;
  constructor() {
    super('app-widget-performance-overall');
  }

  public onInit(): void {
    this.getAndSetPerformanceOverall();
  }

  public getAndSetPerformanceOverall(): void {
    this.httpClientService
      .get('/widget-performance-overall/index')
      .subscribe((performanceOverall: number) => {
        this.performanceOverall = performanceOverall;
        this.global.widgetLoadingStatus.set(this.moduleCode, true);
        this.setStateReady();
      });
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
