/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\action\first-load-table-action.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 4:07:15 pm

 *
*
 */
import { TableRow } from '../domain/table-row';
import { TableState } from '../domain/table-state';
import { resolveTableRowList } from '../helper';
import { createTableCustomData } from '../helper/create-table-custom-data-helper';
export const firstLoadTableAction = (state: TableState): void => {
  state.tableRequest.customData = createTableCustomData(
    state.model.getCustomData()
  );
  resolveTableRowList(state).then((rowList: Array<TableRow>) => {
    state.setRowList(rowList);
    state.setStateReady();
  });
};
