export class AppTableConstant {
  public FIELD_FORMAT_SHORT_DATE = 0;
  public FIELD_FORMAT_SHORT_DATE_AND_TIME = 1;
  public FIELD_FORMAT_LONG_DATE = 2;
  public FIELD_FORMAT_LONG_DATE_AND_TIME = 3;
  public FIELD_FORMAT_CURRENCY = 4;
  public FIELD_FORMAT_INTEGER = 5;
  public FIELD_FORMAT_HTML = 6;
  public FIELD_FORMAT_IMG = 7;
  public FIELD_FORMAT_DOWNLOADABLE_FILE = 8;
  public FIELD_FORMAT_COLOR_STATUS = 9;
  public FIELD_FORMAT_RATING = 10;
  public FIELD_FORMAT_EDIT_COLS = 11;
  public FIELD_FORMAT_CHECK_BOX = 12;
  public FIELD_FORMAT_INPUT_NUMBER = 13;
  public FIELD_FORMAT_RADIO = 14;
  public FIELD_FORMAT_INTEGER_DECIMAL = 15;

  public FIELD_FORMAT_DOWNLOADABLE_PM_FILE = 50;
}
