/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\components\app-table\app-table.component.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 10:53:36 am

 *
*
 */
import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import { UserPreference } from '../../../../bean/user-preference';
import { ValueType } from '../../../../bean/value-type';
import { Response } from '../../../../model/response-model';
import { ResponseStatusModel } from '../../../../model/response-status-model';
import { AppPopupService } from '../../../app-popup/app-popup.service';
import * as tableAction from '../../action/table-action';
import { BaseTableComponent } from '../../base/base-table.component';
import { TableColumnModel } from '../../model/table-column-model';
import { TableService } from '../../table.service';
import { AppPopupModifyTableComponent } from '../app-popup-modify-table/app-popup-modify-table.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@Component({
  selector: 'app-table-xx',
  templateUrl: './app-table.component.html',
  styleUrls: ['./app-table.component.scss'],
  providers: [
    TableService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppTableComponent extends BaseTableComponent implements OnChanges {
  @Input() public tips: string;
  @Input() public headerAction: string;
  @ViewChild('theadDefault') theadDefaultElement: ElementRef;
  @ViewChild('theadFixed') theadFixedElement: ElementRef;
  @ViewChild('perfectScrollBar') perfectScrollBar: PerfectScrollbarComponent;
  @ContentChild('noDataTemplate') noDataTmpl: TemplateRef<any>;
  // from old table
  public columnListTemp: Array<TableColumnModel>;
  public rowColumnList: Array<Array<TableColumnModel>>;
  public columnFieldList: Array<string> = new Array();
  public userPreference: UserPreference;

  public maxHeight: string;

  constructor(
    tableService: TableService,
    elementRef: ElementRef,
    private appPopupService: AppPopupService
  ) {
    super(tableService, elementRef);
  }

  onInitBaseTable(): void {
    this.copyColumnAndRowState();
    this.setStateEditTable();
    this.showAllData();
    console.log(`[ Info ]: Come from onInitBaseTable`);
    console.log(this.state);
    this.setStateReady();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.isShowPopupModifyTable &&
      !changes.isShowPopupModifyTable.firstChange &&
      changes.isShowPopupModifyTable.previousValue !==
        changes.isShowPopupModifyTable.currentValue
    ) {
      this.doShowPopupModifyTable();
    }
  }

  public doClickInputCheckbox(event: PointerEvent): void {
    event.stopPropagation();
  }

  public showAllData(): void {
    if (
      !this.isShowPagination &&
      !this.isShowScrollbar &&
      !this.state.isServerSide
    ) {
      this.state.pagination.setTotalPage();
      const totalPage = this.state.pagination.totalPage;
      for (let i = 0; i < totalPage - 1; i++) {
        this.tableService.dispatch(
          new tableAction.ChangeTablePage({
            page: this.state.pagination.page + 1
          })
        );
      }
    }
  }

  public onScrollEnd(event: CustomEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (
      !this.isShowPagination &&
      this.isShowScrollbar &&
      this.state.pagination.totalRecords > this.state.rowList.length &&
      !this.state.isLoading
    ) {
      this.tableService.dispatch(
        new tableAction.ChangeTablePage({
          page: this.state.pagination.page + 1
        })
      );
    }
  }

  public copyColumnAndRowState(): void {
    this.columnListTemp = Array.from(this.state.columnList);
    this.rowColumnList = Array.from(this.state.rowColumnList);
  }

  // start edit table section
  public setStateEditTable(): void {
    this.userPreference = new UserPreference();
    if (this.isShowButtonModifyTable) {
      const name =
        'DATA-TABLE-' +
        this.model.moduleCode.toUpperCase() +
        (this.key ? '-' + this.key.toUpperCase() : '');
      this.userPreference.value =
        this.global.userSession.userPreferenceMap[name];
      if (this.userPreference.value) {
        this.columnFieldList = JSON.parse(this.userPreference.value);
        this.setStateModelColumn();
      }
    }
  }

  private setStateModelColumn(): void {
    this.state.rowColumnList = this.rowColumnList
      .map(columnList => {
        return columnList.filter(
          column => this.columnFieldList.indexOf(column.field) !== -1
        );
      })
      .filter(columnList => columnList.length > 0);
    this.state.columnList = this.columnListTemp.filter(
      column => this.columnFieldList.indexOf(column.field) !== -1
    );
  }

  public doShowPopupModifyTable(): void {
    const model = Object.assign({}, this.model);
    model.columnList = this.columnListTemp;
    this.appPopupService
      .open(
        AppPopupModifyTableComponent,
        {
          columnList: this.columnListTemp,
          tableParentResponse: model,
          columnFieldList: this.columnFieldList
        },
        { windowClass: 'app-popup-modify-table' }
      )
      .subscribe((columnFieldList: Array<any>) => {
        this.columnFieldList = columnFieldList;
        this.setStateModelColumn();
        const url = '/config/update-user-preference';
        const userPreference = this.userPreference;
        userPreference.name =
          'DATA-TABLE-' +
          this.model.moduleCode.toUpperCase() +
          (this.key ? '-' + this.key.toUpperCase() : '');
        userPreference.value = JSON.stringify(columnFieldList);
        userPreference.valueType = new ValueType();
        userPreference.valueType.code = 'STRING';
        this.httpClientService
          .post<Response<UserPreference>>(url, userPreference)
          .subscribe(response => {
            if (response.status === ResponseStatusModel.OK) {
              this.global.userSession.userPreferenceMap[userPreference.name] =
                userPreference.value;
              this.userPreference.value = userPreference.value;
            } else {
              this.state.columnList = this.columnListTemp;
            }
          });
        this.isShowPopupModifyTable = false;
      });
  }
}
