import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guard/auth.guard';
import { PagesComponent } from './pages.component';
export const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
     
      {
        path: 'account-setting',
        loadChildren: () =>
          import('./account-setting/account-setting.module').then(
            m => m.AccountSettingModule
          ),
        data: { breadcrumb: 'account-setting.breadcrumb.index' }
      },
   
      {
        path: 'admin-panel',
        loadChildren: () =>
          import('./admin-panel/admin-panel.module').then(
            m => m.AdminPanelModule
          ),
        data: { breadcrumb: 'admin-panel.breadcrumb.index' }
      },
     
    
      
      {
        path: 'approval',
        loadChildren: () =>
          import('./approval/approval.module').then(m => m.ApprovalModule),
        data: { breadcrumb: 'approval.breadcrumb.index' }
      },

      {
        path: 'example',
        loadChildren: () =>
          import('./example/example.module').then(m => m.ExampleModule),
        data: { breadcrumb: 'example.breadcrumb.index' }
      },

      {
        path: 'approval-prcs',
        loadChildren: () =>
          import('./approval-prcs/approval-prcs.module').then(
            m => m.ApprovalPrcsModule
          ),
        data: { breadcrumb: 'worklist-approval.breadcrumb.index' }
      },
      {
        path: 'approval-workflow',
        loadChildren: () =>
          import('./approval-workflow/approval-workflow.module').then(
            m => m.ApprovalWorkflowModule
          ),
        data: { breadcrumb: 'approval-workflow.breadcrumb.index' }
      },
     
      
      {
        path: 'item-category',
        loadChildren: () =>
          import('./item-category/item-category.module').then(
            m => m.ItemCategoryModule
          ),
        data: { breadcrumb: 'item-category.breadcrumb.index' }
      },
      
      {
        path: 'condition',
        loadChildren: () =>
          import('./condition/condition.module').then(m => m.ConditionModule),
        data: { breadcrumb: 'condition.breadcrumb.index' }
      },
      {
        path: 'content',
        loadChildren: () =>
          import('./content/content.module').then(m => m.ContentModule),
        data: { breadcrumb: 'content.breadcrumb.index' }
      },
     
      
      {
        path: 'country-flag',
        loadChildren: () =>
          import('./country-flag/country-flag.module').then(
            m => m.CountryFlagModule
          ),
        data: { breadcrumb: 'country-flag.breadcrumb.index' }
      },
      {
        path: 'currency',
        loadChildren: () =>
          import('./currency/currency.module').then(m => m.CurrencyModule),
        data: { breadcrumb: 'currency.breadcrumb.index' }
      },
      {
        path: 'bank',
        loadChildren: () =>
          import('./bank/bank.module').then(m => m.BankModule),
        data: { breadcrumb: 'bank.breadcrumb.index' }
      },
      {
        path: 'education',
        loadChildren: () =>
          import('./education/education.module').then(m => m.EducationModule),
        data: { breadcrumb: 'education.breadcrumb.index' }
      },
      
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { breadcrumb: 'dashboard.breadcrumb.index' }
      },
      {
        path: 'dependency',
        loadChildren: () =>
          import('./dependency/dependency.module').then(
            m => m.DependencyModule
          ),
        data: { breadcrumb: 'dependency.breadcrumb.index' }
      },
      
      {
        path: 'doc-group',
        loadChildren: () =>
          import('./doc-group/doc-group.module').then(m => m.DocGroupModule),
        data: { breadcrumb: 'document-group.breadcrumb.index' }
      },
     
    
      {
        path: 'document-type',
        loadChildren: () =>
          import('./file-type/file-type.module').then(m => m.FileTypeModule),
        data: { breadcrumb: 'document-type.breadcrumb.index' }
      },
    
      {
        path: 'due-diligence',
        loadChildren:
          'src/app/pages/due-diligence/due-diligence.module#DueDiligenceModule',
        data: { breadcrumb: 'Due Diligence' }
      },
    
 
    
    
      
      {
        path: 'holiday',
        loadChildren: () =>
          import('./holiday/holiday.module').then(m => m.HolidayModule),
        data: { breadcrumb: 'holiday.breadcrumb.index' }
      },
      {
        path: 'industry',
        loadChildren: () =>
          import('./industry/industry.module').then(m => m.IndustryModule),
        data: { breadcrumb: 'industry.breadcrumb.index' }
      },
     
     
      {
        path: 'item',
        loadChildren: () =>
          import('./item/item.module').then(m => m.ItemModule),
        data: { breadcrumb: 'item.breadcrumb.index' }
      },
      {
        path: 'lang',
        loadChildren: () =>
          import('./lang/lang.module').then(m => m.LangModule),
        data: { breadcrumb: 'lang.breadcrumb.index' }
      },
      {
        path: 'login-log',
        loadChildren: () =>
          import('./login-log/login-log.module').then(m => m.LoginLogModule),
        data: { breadcrumb: 'login-log.breadcrumb.index' }
      },
    
      {
        path: 'module',
        loadChildren: () =>
          import('./module/module.module').then(m => m.ModuleModule),
        data: { breadcrumb: 'module.breadcrumb.index' }
      },
      {
        path: 'module-dependency',
        loadChildren: () =>
          import('./module-dependency/module-dependency.module').then(
            m => m.ModuleDependencyModule
          ),
        data: { breadcrumb: 'module-dependency.breadcrumb.index' }
      },
      {
        path: 'period',
        loadChildren: () =>
          import('./period/period.module').then(m => m.PeriodModule),
        data: { breadcrumb: 'period.breadcrumb.index' }
      },
      {
        path: 'qualification',
        loadChildren: () =>
          import('./qualification/qualification.module').then(
            m => m.QualificationModule
          ),
        data: { breadcrumb: 'qualification.breadcrumb.index' }
      },

      {
        path: 'institution',
        loadChildren: () =>
          import('./institution/institution.module').then(
            m => m.InstitutionModule
          ),
        data: { breadcrumb: 'institution.breadcrumb.index' }
      },

      {
        path: 'module-group',
        loadChildren: () =>
          import('.//module-group/module-group.module').then(
            m => m.ModuleGroupModule
          ),
        data: { breadcrumb: 'module-group.breadcrumb.index' }
      },
      {
        path: 'mvc-generator',
        loadChildren: () =>
          import('./mvc-generator/mvc-generator.module').then(
            m => m.MVCGeneratorModule
          ),
        data: { breadcrumb: 'mvc-generator.breadcrumb.index' }
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./news/news.module').then(m => m.NewsModule),
        data: { breadcrumb: 'news.breadcrumb.index' }
      },
      {
        path: 'notification-blast',
        loadChildren: () =>
          import('./notification-blast/notification-blast.module').then(
            m => m.NotificationBlastModule
          ),
        data: { breadcrumb: 'notification-blast.breadcrumb.index' }
      },
      {
        path: 'notification-job',
        loadChildren: () =>
          import('./notification-job/notification-job.module').then(
            m => m.NotificationJobModule
          ),
        data: { breadcrumb: 'notification-log.breadcrumb.index' }
      },
      {
        path: 'notification-loader',
        loadChildren: () =>
          import('./notification-loader/notification-loader.module').then(
            m => m.NotificationLoaderModule
          ),
        data: { breadcrumb: 'notification-loader.breadcrumb.index' }
      },
      {
        path: 'notification-template',
        loadChildren: () =>
          import('./notification-template/notification-template.module').then(
            m => m.NotificationTemplateModule
          ),
        data: { breadcrumb: 'notification-template.breadcrumb.index' }
      },
      {
        path: 'number-generator',
        loadChildren: () =>
          import('./number-generator/number-generator.module').then(
            m => m.NumberGeneratorModule
          ),
        data: { breadcrumb: 'number-generator.breadcrumb.index' }
      },
    
      {
        path: 'officer',
        loadChildren: () =>
          import('./officer/officer.module').then(m => m.OfficerModule),
        data: { breadcrumb: 'officer.breadcrumb.index' }
      },
      
      {
        path: 'organization',
        loadChildren: () =>
          import('./organization/organization.module').then(
            m => m.OrganizationModule
          ),
        data: { breadcrumb: 'organization.breadcrumb.index' }
      },
      {
        path: 'parameter',
        loadChildren: () =>
          import('./parameter/parameter.module').then(m => m.ParameterModule),
        data: { breadcrumb: 'parameter.breadcrumb.index' }
      },
      
      {
        path: 'profession',
        loadChildren: () =>
          import('./profession/profession.module').then(
            m => m.ProfessionModule
          ),
        data: { breadcrumb: 'profession.breadcrumb.index' }
      },
      {
        path: 'position',
        loadChildren: () =>
          import('./position/position.module').then(m => m.PositionModule),
        data: { breadcrumb: 'position.breadcrumb.index' }
      },
     
      {
        path: 'question',
        loadChildren: () =>
          import('./question/question.module').then(m => m.QuestionModule),
        data: { breadcrumb: 'question.breadcrumb.index' }
      },
     
     
      {
        path: 'region',
        loadChildren: () =>
          import('./region/region.module').then(m => m.RegionModule),
        data: { breadcrumb: 'region.breadcrumb.index' }
      },
      {
        path: 'reminder',
        loadChildren: () =>
          import('./reminder/reminder.module').then(m => m.ReminderModule),
        data: { breadcrumb: 'reminder.breadcrumb.index' }
      },
      
      {
        path: 'resource',
        loadChildren: () =>
          import('./resource/resource.module').then(m => m.ResourceModule),
        data: { breadcrumb: 'resource.breadcrumb.index' }
      },
     
   
      {
        path: 'report-recap',
        loadChildren: () =>
          import('./report-recap/report-recap.module').then(
            m => m.ReportRecapModule
          ),
        data: { breadcrumb: 'report-recap.breadcrumb.index' }
      },
      {
        path: 'report-recap-pr',
        loadChildren: () =>
          import('../core/report-recap/pr/report-recap-pr.module').then(
            m => m.ReportRecapPrModule
          ),
        data: { breadcrumb: 'report-recap-pr.breadcrumb.index' }
      },
      {
        path: 'report-recap-order-process',
        loadChildren: () =>
          import(
            '../core/report-recap/order-process/report-recap-order-process.module'
          ).then(m => m.ReportRecapOrderProcessModule),
        data: { breadcrumb: 'report-recap-order-process.breadcrumb.index' }
      },
      {
        path: 'report-recap-procurement',
        loadChildren: () =>
          import(
            '../core/report-recap/procurement/report-recap-procurement.module'
          ).then(m => m.ReportRecapProcurementModule),
        data: { breadcrumb: 'report-recap-procurement.breadcrumb.index' }
      },
      {
        path: 'report-recap-rfp',
        loadChildren: () =>
          import('../core/report-recap/rfp/report-recap-rfp.module').then(
            m => m.ReportRecapRfpModule
          ),
        data: { breadcrumb: 'report-recap-rfp.breadcrumb.index' }
      },
      {
        path: 'report-recap-rfq',
        loadChildren: () =>
          import('../core/report-recap/rfq/report-recap-rfq.module').then(
            m => m.ReportRecapRfqModule
          ),
        data: { breadcrumb: 'report-recap-rfq.breadcrumb.index' }
      },
      {
        path: 'report-recap-budget-used',
        loadChildren: () =>
          import(
            '../core/report-recap/budget-used/report-recap-budget-used.module'
          ).then(m => m.ReportRecapBudgetUsedModule),
        data: { breadcrumb: 'report-recap-budget-used.breadcrumb.index' }
      },
      
     
    
      {
        path: 'role',
        loadChildren: () =>
          import('./role/role.module').then(m => m.RoleModule),
        data: { breadcrumb: 'role.breadcrumb.index' }
      },
      {
        path: 'role-menu',
        loadChildren: () =>
          import('./role-menu/role-menu.module').then(m => m.RoleMenuModule),
        data: { breadcrumb: 'role-menu.breadcrumb.index' }
      },


      {
        path: 'qc',
        loadChildren: () =>
          import('./qc/qc.module').then(m => m.QcModule),
        data: { breadcrumb: 'qc.breadcrumb.index' }
      },

      {
        path: 'qc-rigid',
        loadChildren: () =>
          import('./qc-rigid/qc-rigid.module').then(m => m.QcRigidModule),
        data: { breadcrumb: 'qc.breadcrumb.index' }
      },

      {
        path: 'qc-flexy',
        loadChildren: () =>
          import('./qc-flexy/qc-flexy.module').then(m => m.QcFlexyModule),
        data: { breadcrumb: 'qc.breadcrumb.index' }
      },
       
      {
        path: 'qc-export',
        loadChildren: () =>
          import('./qc-export/qc-export.module').then(m => m.QcExportModule),
        data: { breadcrumb: 'qc.breadcrumb.index' }
      },
      {
        path: 'qc-outgoing',
        loadChildren: () =>
          import('./qc-outgoing/qc-outgoing.module').then(m => m.QcOutgoingModule),
        data: { breadcrumb: 'qc.breadcrumb.index' }
      },
            














      {
        path: 'schedule-stages',
        loadChildren: () =>
          import('./schedule-stages/schedule-stages.module').then(
            m => m.ScheduleStagesModule
          ),
        data: { breadcrumb: 'schedule-stages.breadcrumb.index' }
      },
      {
        path: 'scheduler',
        loadChildren: () =>
          import('./scheduler/scheduler.module').then(m => m.SchedulerModule),
        data: { breadcrumb: 'scheduler.breadcrumb.index' }
      },
      {
        path: 'scheduler-log',
        loadChildren: () =>
          import('./scheduler-log/scheduler-log.module').then(
            m => m.SchedulerLogModule
          ),
        data: { breadcrumb: 'scheduler-log.breadcrumb.index' }
      },
     
      {
        path: 'segmentation',
        loadChildren: () =>
          import('./segmentation/segmentation.module').then(
            m => m.SegmentationModule
          ),
        data: { breadcrumb: 'segmentation.breadcrumb.index' }
      },
    
      {
        path: 'tc',
        loadChildren: () => import('./tc/tc.module').then(m => m.TcModule),
        data: { breadcrumb: 'tc.breadcrumb.index' }
      },
     
      {
        path: 'template-group',
        loadChildren: () =>
          import('./template-group/template-group.module').then(
            m => m.TemplateGroupModule
          ),
        data: { breadcrumb: 'template-group.breadcrumb.index' }
      },
      {
        path: 'translation',
        loadChildren: () =>
          import('./translation/translation.module').then(
            m => m.TranslationModule
          ),
        data: { breadcrumb: 'translation.breadcrumb.index' }
      },
      {
        path: 'threshold',
        loadChildren: () =>
          import('./threshold/threshold.module').then(m => m.ThresholdModule),
        data: { breadcrumb: 'threshold.breadcrumb.index' }
      },
     
     
      {
        path: 'user',
        loadChildren: () =>
          import('./user/user.module').then(m => m.UserModule),
        data: { breadcrumb: 'user.breadcrumb.index' }
      },
      {
        path: 'user-action',
        loadChildren: () =>
          import('./user-action/user-action.module').then(
            m => m.UserActionModule
          ),
        data: { breadcrumb: 'user-action.breadcrumb.index' }
      },
      {
        path: 'user-delegation',
        loadChildren: () =>
          import('./user-delegation/user-delegation.module').then(
            m => m.UserDelegationModule
          ),
        data: { breadcrumb: 'delegation.breadcrumb.index' }
      },
    
      {
        path: 'user-manual',
        loadChildren: () =>
          import('./user-manual/user-manual.module').then(
            m => m.UserManualModule
          ),
        data: { breadcrumb: 'user-manual.breadcrumb.index' }
      },
      {
        path: 'uom',
        loadChildren: () => import('./uom/uom.module').then(m => m.UomModule),
        data: { breadcrumb: 'uom.breadcrumb.index' }
      },
     
      
     
     
     
      {
        path: 'workflow',
        loadChildren: () =>
          import('./workflow/workflow.module').then(m => m.WorkflowModule),
        data: { breadcrumb: 'workflow.breadcrumb.index' }
      },
      {
        path: 'workflow-condition',
        loadChildren: () =>
          import('./workflow-condition/workflow-condition.module').then(
            m => m.WorkflowConditionModule
          ),
        data: { breadcrumb: 'workflow-condition.breadcrumb.index' }
      },
     
      {
        path: 'official-report',
        loadChildren: () =>
          import('./official-report/official-report.module').then(
            m => m.OfficialReportModule
          ),
        data: { breadcrumb: 'official-report.breadcrumb.index' }
      },
      {
        path: 'push-notification',
        loadChildren: () =>
          import('./push-notification/push-notification.module').then(
            m => m.PushNotificationModule
          ),
        data: { breadcrumb: 'push-notification.breadcrumb.index' }
      },
     
      {
        path: 'error',
        // component: ErrorPageComponent,
        loadChildren: () =>
          import('./errors/error-page/error-page.module').then(
            m => m.ErrorPageModule
          ),
        data: { breadcrumb: 'pages.error' }
      },
      { path: '**', redirectTo: 'error' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {}
