
import { AccordionState } from '../domain/accordion-state';
import { AccordionItemModel } from '../model/accordion-item-model';
export const expandOrCollapseAccordionAction = (
  state: AccordionState,
  payload: { accordionItem: AccordionItemModel<any> }
): void => {
  if (state.isShowMultiple || (!state.isShowMultiple && state.isExpandAll)) {
    state.accordionItemMap.get(payload.accordionItem.id).isExpanded =
      !state.accordionItemMap.get(payload.accordionItem.id).isExpanded;
  } else {
    for (let key of state.accordionItemMap.keys()) {
      const accordionItem = state.accordionItemMap.get(key);
      accordionItem.isExpanded =
        key !== payload.accordionItem.id ? false : !accordionItem.isExpanded;
    }
  }

  if (state.model) {
    if (!payload.accordionItem.isLoaded) {
      payload.accordionItem.isLoading = true;
      payload.accordionItem.isExpanded = true;
    }
  }
};
