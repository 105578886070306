/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\action\change-table-page-action.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:08:41 am

 *
*
 */
import { TableState } from '../domain/table-state';
import { resolveTableRowList } from '../helper/resolve-table-row-list-helper';
export const changeTablePageAction = (
  state: TableState,
  payload: { page: number }
) => {
  state.pagination.page = payload.page;
  state.tableRequest.first = (payload.page - 1) * state.pagination.perPage;
  resolveTableRowList(state).then((rowList: Array<any>) => {
    state.isServerSide
      ? setOrAddStateRowList(state, rowList)
      : setTimeout(() => setOrAddStateRowList(state, rowList), 1000);
  });
};

const setOrAddStateRowList = (state: TableState, rowList: Array<any>): void => {
  state.isShowPagination === false
    ? state.addRowList(rowList)
    : state.setRowList(rowList);
  state.setStateReady();
};
