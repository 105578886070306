import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedModule } from '../core/shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { DirectivesModule } from '../core/directives/directives.module';
import { PipesModule } from '../core/pipe/pipe.module';
import { LayoutModule } from '../themes/promise/layout/layout.module';
import { CommonModule } from '@angular/common';
import { BackTopComponent } from '../themes/promise/components/back-top/back-top.component';
@NgModule({
  imports: [
    PerfectScrollbarModule,
    PagesRoutingModule,
    SharedModule,
     DirectivesModule, PipesModule,
    LayoutModule, CommonModule
  ],
  declarations: [PagesComponent, BackTopComponent]
})
export class PagesModule {}
