import { NgModule } from '@angular/core';
import { AppPopupAddBudgetItemModule } from './app-popup-add-budget-item/app-popup-add-budget-item.module';
import { AppPopupAddUserModule } from './app-popup-add-user/app-popup-add-user.module';
import { AppPopupAddressBookModule } from './app-popup-address-book/app-popup-address-book.module';
import { AppPopupAddressModule } from './app-popup-address/app-popup-address.module';
import { AppPopupAllocatedItemDetailModule } from './app-popup-allocated-item-detail/app-popup-allocated-item-detail.module';
import { AppPopupAnalysisMultipleModule } from './app-popup-analysis-multiple/app-popup-analysis-multiple.module';
import { AppPopupAnalysisSingleModule } from './app-popup-analysis-single/app-popup-analysis-single.module';
import { AppPopupAttributeModule } from './app-popup-attribute/app-popup-attribute.module';
import { AppPopupBankModule } from './app-popup-bank/app-popup-bank.module';
import { AppPopupBillingMemoBudgetDetailModule } from './app-popup-billing-memo-budget-detail/app-popup-billing-memo-budget-detail.module';
import { AppPopupBudgetWorkplanInfoModule } from './app-popup-budget-work-plan-info/app-popup-budget-work-plan-info.module';
import { AppPopupBudgetModule } from './app-popup-budget/app-popup-budget.module';
import { AppPopupChooseBillModule } from './app-popup-choose-bill/app-popup-choose-bill.module';
import { AppPopupChooseBudgetXModule } from './app-popup-choose-budget-x/app-popup-choose-budget-x.module';
import { AppPopupChooseBudgetModule } from './app-popup-choose-budget/app-popup-choose-budget.module';
import { AppPopupChoosePrReleasedModule } from './app-popup-choose-pr-released/app-popup-choose-pr-released.module';
import { AppPopupChoosePrModule } from './app-popup-choose-pr/app-popup-choose-pr.module';
import { AppPopupChooseUserModule } from './app-popup-choose-user/app-popup-choose-user.module';
import { AppPopupChooseVendorMultiWinnerModule } from './app-popup-choose-vendor-multi-winner/app-popup-choose-vendor-multi-winner.module';
import { AppPopupChooseVendorModule } from './app-popup-choose-vendor/app-popup-choose-vendor.module';
import { AppPopupCoaModule } from './app-popup-coa-bjb/app-popup-coa.module';
import { AppPopupComparisonModule } from './app-popup-comparison/app-popup-comparison.module';
import { AppPopupContractModule } from './app-popup-contract/app-popup-contract.module';
import { AppPopupDocGroupModule } from './app-popup-doc-group/app-popup-doc-group.module';
import { AppPopupEditTableModule } from './app-popup-edit-table/app-popup-edit-table.module';
import { AppPopupEmailModule } from './app-popup-email/app-popup-email.module';
import { AppPopupFinalPriceDetailModule } from './app-popup-final-price-detail/app-popup-final-price-detail.module';
import { AppPopupFinesModule } from './app-popup-fines/app-popup-fines.module';
import { AppPopupGuaranteeModule } from './app-popup-guarantee/app-popup-guarantee.module';
import { AppPopupImportModule } from './app-popup-import/app-popup-import.module';
import { AppPopupItemModule } from './app-popup-item/app-popup-item.module';
import { AppPopupLoadingBlockModule } from './app-popup-loading-block/app-popup-loading-block.module';
import { AppPopupOfficerModule } from './app-popup-officer/app-popup-officer.module';
import { AppPopupPrItemInfoModule } from './app-popup-pr-item-info/app-popup-pr-item-info.module';
import { AppPopupPreviewImageModule } from './app-popup-preview-image/app-popup-preview-image.module';
import { AppPopupProgressBarDownloadModule } from './app-popup-progress-bar-download/app-popup-progress-bar-download.module';
import { AppPopupRegistrationTermConditionModule } from './app-popup-registration-term-condition/app-popup-registration-term-condition.module';
import { AppPopupResourceModule } from './app-popup-resource/app-popup-resource.module';
import { AppPopupSowModule } from './app-popup-sow/app-popup-sow.module';
import { AppPopupStatistikVendorModule } from './app-popup-statistik-vendor/app-popup-statistik-vendor.module';
import { AppPopupTOCModule } from './app-popup-toc/app-popup-toc.module';
import { AppPopupTreeModule } from './app-popup-tree/app-poup-tree.module';
import { AppPopupUserModule } from './app-popup-user/app-popup-user.module';
import { AppPopupVendorModule } from './app-popup-vendor/app-popup-vendor.module';
import { AppPopupVersionHistoryModule } from './app-popup-version-history/app-popup-version-history.module';
import { AppPopupWorkplanItemInfoModule } from './app-popup-work-plan-item-info/app-popup-work-plan-item-info.module';
import { AppPopupWorkPlanItemModule } from './app-popup-work-plan-item/app-popup-work-plan-item.module';
import { AppPopupWorkPlanModule } from './app-popup-work-plan/app-popup-work-plan.module';
import { AppPopupWorkflowModule } from './app-popup-workflow/app-popup-workflow.module';
import { AppPopupModule } from './app-popup.module';

@NgModule({
  exports: [
    AppPopupModule,
    AppPopupUserModule,
    AppPopupWorkflowModule,
    AppPopupDocGroupModule,
    AppPopupOfficerModule,
    AppPopupImportModule,
    AppPopupItemModule,
    AppPopupAddressBookModule,
    AppPopupRegistrationTermConditionModule,
    AppPopupEditTableModule,
    AppPopupAttributeModule,
    AppPopupTreeModule,
    AppPopupVendorModule,
    AppPopupEmailModule,
    AppPopupStatistikVendorModule,
    AppPopupContractModule,
    AppPopupBudgetWorkplanInfoModule,
    AppPopupWorkPlanModule,
    AppPopupWorkPlanItemModule,
    AppPopupBudgetModule,
    AppPopupBankModule,
    AppPopupWorkplanItemInfoModule,
    AppPopupTOCModule,
    AppPopupComparisonModule,
    AppPopupResourceModule,
    AppPopupCoaModule,
    AppPopupLoadingBlockModule,
    AppPopupPreviewImageModule,
     AppPopupChooseBudgetModule,
    AppPopupBillingMemoBudgetDetailModule,
    AppPopupChooseBudgetXModule,
    AppPopupPrItemInfoModule,
    AppPopupAddressModule,
    AppPopupChooseVendorModule,
    AppPopupChoosePrModule,
    AppPopupChoosePrReleasedModule,
    AppPopupSowModule,
    AppPopupFinesModule,
    AppPopupGuaranteeModule,
    AppPopupProgressBarDownloadModule,
    AppPopupChooseVendorMultiWinnerModule,
    AppPopupAllocatedItemDetailModule,
   
    AppPopupFinalPriceDetailModule,
    AppPopupAddUserModule,
    AppPopupChooseUserModule,
    AppPopupAddBudgetItemModule,
    AppPopupChooseBillModule,
    AppPopupVersionHistoryModule,
    AppPopupAnalysisSingleModule,
    AppPopupAnalysisMultipleModule
  ]
})
export class SharedPopupModule {}
