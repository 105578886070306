/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table\model\portal-table-pagination-model.ts
 
 * Created Date: Thursday, September 30th 2021, 12:30:54 pm

 *
*
 */

import { PaginationModel } from '../../../../../core/components/app-pagination/model/pagination-model';
export class PortaltablePaginationModel extends PaginationModel {}
