<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="module-dependency.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/module-dependency/index"
      >
        <ng-template #headerFilterTable>
          <div class="app-table-filter-item" style="width: 300px">
            <form
              class="form-horizontal"
              [formGroup]="formGroup"
              novalidate
              *ngIf="!formLoading"
            >
              <label class="col-sm-12">{{
                'module-dependency.label.module' | translate
              }}</label>
              <div class="col-sm-12 text-left">
                <app-dropdown-select
                  size="XL"
                  formControlName="moduleGroup"
                  [optionList]="moduleGroupOptionList"
                  (onChange)="doOnChangeModuleGroup()"
                >
                </app-dropdown-select>
              </div>
            </form>
          </div>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}"
          >
            <em class="fas fa-pencil-alt"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
