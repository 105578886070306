/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table\action\search-portal-table-action.ts
 
 * Created Date: Thursday, September 30th 2021, 11:14:44 am

 *
*
 */

import { PortalTableState } from '../domain/portal-table-state';
import { resolvePortalTableRecordList } from '../helper/resolve-portal-table-record-list-helper';

export const searchPortalTable = (
  state: PortalTableState,
  payload: { keyword: string }
): void => {
  state.tableRequest.keyword = payload.keyword;
  resolvePortalTableRecordList(state).then((rowList: Array<any>) => {
    state.rowList = rowList;
    state.setStateReady();
  });
};
