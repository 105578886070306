<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="number-generator.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'number-generator.form.stage' | translate
          }}</label>
          <div class="col-sm-9 text-left">{{ module.name }}</div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'number-generator.form.moduleGroup' | translate
          }}</label>
          <div class="col-sm-9 text-left">{{
            formGroup.controls.moduleGroup.value
          }}</div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'number-generator.form.format' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              type="uppercase"
              formControlName="format"
              maxLength="64"
            >
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'number-generator.form.keyword' | translate
          }}</label>
          <div class="col-sm-7 text-left table-response custom-table">
            <table
              class="table table-striped table-sm table-bordered col-sm-9"
              aria-describedby="Table"
            >
              <thead>
                <tr>
                  <th scope="col">{{
                    'number-generator.form.code' | translate
                  }}</th>
                  <th scope="col">{{
                    'number-generator.form.description' | translate
                  }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>YYYY</td>
                  <td>Tahun Format yyyy</td>
                </tr>
                <tr>
                  <td>YY</td>
                  <td>Tahun Format yy</td>
                </tr>
                <tr>
                  <td>MM</td>
                  <td>Bulan Format mm</td>
                </tr>
                <tr>
                  <td>DD</td>
                  <td>Hari Format dd</td>
                </tr>
                <tr>
                  <td>ORG</td>
                  <td>Nama Organisasi</td>
                </tr>
                <tr>
                  <td>SQMX</td>
                  <td>Increment Number yang akan direset tiap bulan</td>
                </tr>
                <tr>
                  <td>SQYX</td>
                  <td>Increment Number yang akan direset tiap tahun</td>
                </tr>
                <tr>
                  <td>SEQ</td>
                  <td>Increment Number</td>
                </tr>
                <tr>
                  <td>DATE</td>
                  <td>Tanggal Format DDMMYYYY</td>
                </tr>
                <tr>
                  <td>ROMAN</td>
                  <td>Bulan Format Romawi</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
