import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TreeEvent, TreeModel } from '../../core/components/app-tree';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './template-group.component.html'
})
export class TemplateGroupComponent extends BaseModuleComponent {
  public treeModel: TreeModel = new TreeModel(this.moduleCode, 'WRITE');
  constructor(translateService: TranslateService) {
    super('template-group', translateService);
  }

  public onInit(): void {
    this.doBuildTreeFormGroup();
    this.setStateReady();
  }

  public doBuildTreeFormGroup(): void {
    this.treeModel.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      description: [null, Validators.maxLength(512)],
      tempId: [null],
      parentId: [null],
      hasChild: [null]
    });
  }

  public doOnEventChange(treeEvent: TreeEvent): void {
    if (treeEvent.type === 'ON-SHOW-POPUP') {
      const id = this.formGroup.value.id;
      treeEvent.formGroup.get('code').setAsyncValidators(
        Validators.existsAsync('/organization/isAlreadyExist', null, null, {
          id
        })
      );
    } else if (treeEvent.type === 'ON-CLOSE-POPUP') {
      treeEvent.formGroup.get('code').clearAsyncValidators();
    }
  }

  public doCodeChange(code: string): void {
    const id = this.formGroup.value.id;
    let treeProcessItemCodeList = this.treeModel.treeProcess.treeProcessItemList
      .map(
        treeProcessItem =>
          treeProcessItem.type !== 'ADD' && treeProcessItem.origin.code
      )
      .filter(code => code);
    if (treeProcessItemCodeList.indexOf(code) !== -1) {
      this.treeModel.formGroup.get('code').clearAsyncValidators();
    } else {
      treeProcessItemCodeList = this.treeModel.treeProcess.treeProcessItemList
        .map(
          treeProcessItem =>
            treeProcessItem.type === 'ADD' && treeProcessItem.data.code
        )
        .filter(code => code);
      this.treeModel.formGroup.get('code').setAsyncValidators(
        Validators.existsAsync('/template-group/isAlreadyExist', null, null, {
          id
        })
      );
    }
  }
}
