import { Component, ViewEncapsulation } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Module } from '../../core/bean/module';
import { ModuleGroup } from '../../core/bean/module-group';
import { Workflow } from '../../core/bean/workflow';
import { PickListModel } from '../../core/components/app-pick-list/model/pick-list-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { WorkflowAdditional } from './../../core/bean/workflow-additional';
import { WorkflowAdditionalPopupComponent } from './workflow-additional-popup.component';
import { WorkflowEditAddResponse } from './workflow-edit-add-response';
import { WorkflowModuleStaterResponse } from './workflow-module-stater-response';
import { WorkflowRequest } from './workflow-request';
@Component({
  templateUrl: './workflow-edit-add.component.html',
  styleUrls: ['./workflow-edit-add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkflowEditAddComponent extends BaseModuleComponent {
  public workflowEditAddResponse: WorkflowEditAddResponse =
    new WorkflowEditAddResponse();
  public workflowRequest: WorkflowRequest = new WorkflowRequest();
  public workflowId: number;
  public sourceModuleTempList: Module[] = [];
  public workflowAdditionalList: WorkflowAdditional[] = [];
  public workflowAdditionalStageList: Module[] = [];
  public sourceModuleList: Module[] = [];
  public targetModuleList: Module[] = [];
  public sourceModuleListTemp: Module[] = [];
  public targetModuleListTemp: Module[] = [];
  public isDuplicate = false;
  public customModal = false;
  public customModalData: Object = {};
  public errors: any = [];
  public flag = false;

  public moduleGroupOptionList: OptionListModel<ModuleGroup> =
    new OptionListModel();
  public moduleStarterOptionList: OptionListModel<Module> =
    new OptionListModel();
  public tableResponse: TableResponseModel<any>;

  public modulePickListModel: PickListModel<Module>;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('workflow', translateService);
  }

  onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildtableResponse();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public buildtableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'workflowModel.module.name', header: 'table.column.stage' },
      { field: 'afterModule.name', header: 'table.column.afterStage' },
      { field: 'beforeModule.name', header: 'table.column.beforeStage' },
      {
        field: 'isMandatory',
        header: 'table.column.mandatory',
        datamap: JSON.stringify({ false: 'No', true: 'Yes' })
      }
    ]);
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.workflowId = this.global.routerParams.get('workflowId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.minLength(2)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      description: [null],
      moduleGroup: [null, Validators.required()],
      organization: [null],
      module: [null, Validators.required()],
      workflowAdditionalList: this.formBuilder.array([])
    });
  }

  public doSetFormGroup(): void {
    // tslint:disable-next-line: max-line-length
    this.httpClientService
      .post<WorkflowEditAddResponse>(
        '/workflow/add-edit',
        new RouteRequestModel(this.todo, this.workflowId)
      )
      .subscribe(workflowEditAddResponse => {
        this.workflowEditAddResponse = workflowEditAddResponse;
        if (this.workflowEditAddResponse.workflow != null) {
          this.sourceModuleList = this.workflowEditAddResponse.sourceModuleList;
          this.targetModuleList = this.workflowEditAddResponse.targetModuleList;
          // tslint:disable-next-line: max-line-length
          this.modulePickListModel = new PickListModel(
            this.moduleCode,
            this.workflowEditAddResponse.sourceModuleList,
            this.workflowEditAddResponse.targetModuleList
          );
          this.copy(this.sourceModuleList, this.targetModuleList);
          this.workflowAdditionalList =
            this.workflowEditAddResponse.workflowAdditionalList;
          // tslint:disable-next-line: max-line-length
          const indexModuleGroup =
            this.workflowEditAddResponse.moduleGroupList.findIndex(
              mGroup =>
                mGroup.id ===
                this.workflowEditAddResponse.workflow.moduleGroup.id
            );
          const indexModuleStarter =
            this.workflowEditAddResponse.moduleStarterList.findIndex(
              m => m.id === this.workflowEditAddResponse.workflow.module.id
            );
          const { id, code, name, description, organization } =
            this.workflowEditAddResponse.workflow;
          const moduleGroup =
            this.workflowEditAddResponse.moduleGroupList[indexModuleGroup];
          const module =
            this.workflowEditAddResponse.moduleStarterList[indexModuleStarter];
          this.formGroup.patchValue({
            id,
            code,
            name,
            description,
            organization,
            moduleGroup,
            module
          });
        }

        this.moduleGroupOptionList.setRequestValues(
          this.workflowEditAddResponse.moduleGroupList
        );
        this.moduleStarterOptionList.setRequestValues(
          this.workflowEditAddResponse.moduleStarterList
            ? this.workflowEditAddResponse.moduleStarterList
            : []
        );

        if (this.todo === 'edit') {
          this.workflowAdditionalList.forEach(workflowAdditional => {
            const {
              id,
              workflowModel,
              beforeModule,
              afterModule,
              isMandatory
            } = workflowAdditional;
            const { module } = workflowModel;
            this.workflowAdditionalStageList.push(
              workflowAdditional.workflowModel.module
            );
            this.workflowAdditionalListControls.push(
              this.formBuilder.group({
                id,
                workflowModel,
                module,
                beforeModule,
                afterModule,
                isMandatory
              })
            );
          });
        }
        this.doSetTableResponseRecords();
        this.setStateReady();
      });
  }

  public get workflowAdditionalListControls(): FormArray {
    return this.formGroup.controls.workflowAdditionalList as FormArray;
  }

  public doOnChangeModuleGroup(): void {
    this.sourceModuleList = [];
    this.targetModuleList = [];
    this.formGroup.patchValue({ module: null });
    const moduleGroup: ModuleGroup = this.formGroup.get('moduleGroup').value;

    this.formGroup.get('module').reset();
    this.formGroup.get('module').disable();
    if (moduleGroup) {
      this.httpClientService
        .get<WorkflowModuleStaterResponse>(
          '/workflow/module-starter/' + moduleGroup.code
        )
        .subscribe(workflowResponseModuleStarterList => {
          this.formGroup.get('module').enable();
          this.workflowEditAddResponse.moduleStarterList =
            workflowResponseModuleStarterList.moduleStarterList;
          this.moduleStarterOptionList.setRequestValues(
            this.workflowEditAddResponse.moduleStarterList
          );
          this.sourceModuleTempList =
            workflowResponseModuleStarterList.sourceModuleList;
          this.workflowAdditionalListControls.removeAt(0);
        });
    }
  }

  public doOnChangeStarterModule(): void {
    this.sourceModuleList =
      this.sourceModuleTempList && this.sourceModuleTempList.length > 0
        ? this.sourceModuleTempList
        : this.sourceModuleList;
    this.copy(this.sourceModuleList, this.targetModuleList);
  }

  public doDuplicate(): void {
    this.isDuplicate = true;
    this.formGroup.patchValue({
      id: null,
      code: null,
      name: null,
      description: null,
      module: null
    });
    this.formGroup.get('moduleGroup').disable();
  }

  public doAddAdditionalStage(): void {
    if(this.modulePickListModel !== undefined){
      if( this.modulePickListModel.sourceItemList.length !== 0 
      && this.modulePickListModel.targetItemList.length >= 2){
        this.getStageListWorkflowAdditional(this.workflowAdditionalList, null);
        this.appPopupService
        .open(
          WorkflowAdditionalPopupComponent,
          {
            sourceModuleList: this.workflowAdditionalStageList,
            targetModuleList: this.targetModuleList
          },
          { centered: false, size: 'lg' }
        )
        .subscribe(data => {
          const module = data.get('module').value;
          this.getSourceModuleList(module.id);
          this.workflowAdditionalListControls.push(data);
          this.doSetTableResponseRecords();
        });
      }else{
        this.global.modalService.message(
          this.translateService.instant('workflow.additionalStage.warning.note'),
          this.translateService.instant('workflow.additionalStage.warning.header')
        );
      }
    }else{
      this.global.modalService.message(
        this.translateService.instant('workflow.additionalStage.warning.note'),
        this.translateService.instant('workflow.additionalStage.warning.header')
      );
    }  
  }

  public doEditAdditionalStage(
    workflowAdditional: WorkflowAdditional,
    index: number
  ): void {
    this.workflowAdditionalList = this.formGroup.get(
      'workflowAdditionalList'
    ).value;
    this.getStageListWorkflowAdditional(
      this.workflowAdditionalList,
      workflowAdditional
    );
    this.appPopupService
      .open(
        WorkflowAdditionalPopupComponent,
        {
          workflowAdditional,
          sourceModuleList: this.workflowAdditionalStageList,
          targetModuleList: this.targetModuleList
        },
        { centered: false, size: 'lg' }
      )
      .subscribe(data => {
        const module = data.get('module').value;
        this.getSourceModuleList(module.id);
        this.workflowAdditionalListControls.removeAt(index);
        this.workflowAdditionalListControls.insert(index, data);
        this.doSetTableResponseRecords();
      });
  }

  public doDeleteAdditionalStage(
    workflowAdditional: WorkflowAdditional,
    index: number
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.sourceModuleList.push(workflowAdditional.workflowModel.module);
          this.workflowAdditionalListControls.removeAt(index);
          this.doSetTableResponseRecords();
        }
      });
  }

  public doSetTableResponseRecords(): void {
    this.tableResponse.resetRecords();
    this.tableResponse.setRecords(this.workflowAdditionalListControls.value);
    this.tableResponse.reloadClient();
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            // const newWorkflowResponse = new WorkflowResponse();
            const workflow: Workflow = this.global.copyFormAttributeToModel(
              new Workflow(),
              this.formGroup
            );
            this.workflowRequest.workflow = workflow;
            this.workflowRequest.targetModuleList =
              this.modulePickListModel.targetItemList;
            this.workflowAdditionalList = this.formGroup.get(
              'workflowAdditionalList'
            ).value;
            this.workflowRequest.workflowAdditionalList =
              this.workflowAdditionalList;
            const url =
              this.todo === 'edit' && !this.isDuplicate
                ? '/workflow/update'
                : '/workflow/insert';
            this.httpClientService
              .post<Response<Workflow>>(url, this.workflowRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/workflow']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
    this.setStateReady();
  }

  public getStageListWorkflowAdditional(
    workflowAddtionalList: WorkflowAdditional[],
    workflowAdditional?: WorkflowAdditional
  ): void {
    this.workflowAdditionalStageList = [];
    workflowAddtionalList.forEach(workflowAdditionalLoop => {
      if (
        workflowAdditional != null &&
        (workflowAdditionalLoop.id === workflowAdditional.id ||
          workflowAdditionalLoop.workflowModel.workflow.module.id ===
            workflowAdditional.workflowModel.module.id)
      ) {
        this.workflowAdditionalStageList.push(
          workflowAdditionalLoop.workflowModel.module
        );
      }
    });
    this.sourceModuleList.forEach(module => {
      this.workflowAdditionalStageList.push(module);
    });
  }

  public getSourceModuleList(moduleId: number): void {
    this.sourceModuleList.forEach((module, index) => {
      if (module.id === moduleId) {
        this.sourceModuleList.splice(index, 1);
      }
    });
  }

  public doShowDescription(event): void {
    this.appPopupService.open(
      null,
      { title: 'Dependency', table: event },
      { size: 'lg' }
    );
  }

  public doClick(item): void {
    this.customModalData = [];
    this.customModal = true;
    const inputDependency = this.getArrayItemDependency(item.inputDependency);
    const outputDependency = this.getArrayItemDependency(item.outputDependency);
    this.customModalData = { inputDependency, outputDependency };
  }

  public getArrayItemDependency(item): any {
    return item !== '' ? item.split(',').map(i => i.trim()) : null;
  }

  public doDrag(targetItemList): void {
    let listItem: any = [];
    listItem = targetItemList;
    listItem = [this.formGroup.value.module].concat(listItem);
    this.httpClientService
      .post<Response<string>>('/workflow/validate-dependency', listItem)
      .subscribe(response => {
        if (response.status !== ResponseStatusModel.OK) {
          this.errors.status = response.status;
          this.errors.statusText = response.statusText;
          this.errors.isError = true;
          this.restore(this.sourceModuleListTemp, this.targetModuleListTemp);
          this.copy(this.sourceModuleList, this.targetModuleList);
        } else {
          this.copy(this.sourceModuleList, this.targetModuleList);
        }
      });
  }

  public copy(sourceModuleList: Module[], targetModuleList: Module[]): void {
    this.sourceModuleListTemp = [];
    this.targetModuleListTemp = [];

    sourceModuleList.forEach(sourceModule => {
      this.sourceModuleListTemp.push(sourceModule);
    });
    targetModuleList.forEach(targetModule => {
      this.targetModuleListTemp.push(targetModule);
    });

    this.modulePickListModel = new PickListModel(
      this.moduleCode,
      this.sourceModuleList,
      this.targetModuleList
    );
  }

  public restore(sourceModuleListTemp, targetModuleListTemp): void {
    this.sourceModuleList = sourceModuleListTemp;
    this.targetModuleList = targetModuleListTemp;
  }

  public doCloseCustomModal(): void {
    this.customModal = false;
    this.errors = [];
  }

  public disableDrag(): void {
    const source = document.getElementsByClassName('ui-picklist-source')[0];
    if (this.errors.isError && !this.flag) {
      // disabled drop from picklist source
      const div = document.createElement('div');
      div.style.position = 'absolute';
      div.style.top = '0';
      div.style.bottom = '0';
      div.style.right = '0';
      div.style.left = '0';
      div.style.opacity = '0';
      div.style.zIndex = '2';
      div.style.background = 'red';
      div.setAttribute('id', 'disabled-drag');
      div.style.cursor = 'not-allowed';
      div.setAttribute('draggable', 'false');
      div.addEventListener('dblclick', (event: PointerEvent) => {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
      });
      source.appendChild(div);
      this.flag = true;
    }

    if (!this.errors.isError && this.flag) {
      this.flag = false;
      const element = document.getElementById('disabled-drag');
      source.removeChild(element);
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/workflow']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
