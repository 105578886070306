<ng-container *ngIf="ISVIEW; then isViewTmpl; else isNotViewTmpl">
</ng-container>
<ng-template #isNotViewTmpl>
  <div class="app-npwp p-0" [ngClass]="elementWidthClassName">
    <div
      class="form-control npwp-text"
      [ngClass]="[
        elementHeightClassName,
        isInvalid ? 'is-invalid' : '',
        isValid ? 'is-valid' : '',
        disabled ? 'disabled' : ''
      ]"
      #npwpLabelText
      (click)="inputElement.focus()"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    >
      <input
        type="text"
        [formControl]="formControl"
        [value]="npwpInputValue"
        #inputElement
        (input)="handleNpwpInput($event)"
        (change)="handleChange($event)"
        maxlength="20"
        [attr.placeholder]="dataPlaceholder"
      />
    </div>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
    <small class="text-danger" *ngIf="isInvalid">
      {{ errors.message | translate }}
    </small>
  </div>
</ng-template>
<ng-template #isViewTmpl>
  <div class="position-relative d-inline-block">
    <span>{{ npwpInputValue || '-' }}</span>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [symbolic]="symbolic"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
