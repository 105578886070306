/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\img-plugin\img-plugin.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 11:16:59 pm

 *
*
 */
import { NgModule } from '@angular/core';
import { PipesModule } from '../../../../pipe/pipe.module';
import { VendorModule } from '../../../../vendor/vendor.module';
import { TableImgPluginComponent } from './img-plugin.component';
@NgModule({
  imports: [VendorModule, PipesModule],
  declarations: [TableImgPluginComponent],
  exports: [TableImgPluginComponent]
})
export class TableImgPluginModule {}
