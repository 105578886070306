/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\date-picker-plugin\date-picker-plugin.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 9:50:09 pm

 *
*
 */

import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../vendor/vendor.module';
import { AppTextFieldModule } from '../../../app-text-field/app-text-field.module';
import { TableTextFieldPluginComponent } from './text-field-plugin.component';
@NgModule({
  imports: [VendorModule, AppTextFieldModule],
  declarations: [TableTextFieldPluginComponent],
  exports: [TableTextFieldPluginComponent]
})
export class TableTextFieldPluginModule {}
