/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\base\base-table.component.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Friday, September 10th 2021, 4:27:58 pm

 *
*
 */
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {
  expandCollapseAnimation,
  fullViewAnimation,
  rotateAnimation
} from '../../../animation';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { TextUtils } from '../../../utils';
import * as tableAction from '../action/table-action';
import { TableRow } from '../domain/table-row';
import { TableState } from '../domain/table-state';
import { TableEvent } from '../interface/table-event';
import { TableColumnModel } from '../model/table-column-model';
import { TableResponseModel } from '../model/table-response-model';
import { TableService } from '../table.service';
@Component({
  template: '',
  animations: [
    fullViewAnimation({
      padding: '18px',
      overflow: 'auto'
    }),
    expandCollapseAnimation(),
    rotateAnimation()
  ]
})
export abstract class BaseTableComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() header: string;
  @Input() model: TableResponseModel<any>;
  @Input() stringUrl: string;
  @Input() isShowButtonModifyTable: boolean;
  @Input() isShowButtonMaximize: boolean;
  @Input() key: string;
  @Input() isView: boolean;
  @Input() isMultipleSelect: boolean;
  @Input() isShowCheckBox: boolean;
  @Input() isShowSearch = true;
  @Input() isShowPagination = true;
  @Input() isShowButtonExpandCollapse: boolean;
  @Input() customClass: string;
  @Input() customClassHeader: string;
  @Input() customClassBody: string;
  @Input() customClassFooter: string;
  @Input() theme: 'LIGHT' | 'DARK' | 'TRANSPARENT' = 'LIGHT';
  @Input() isFullViewTable: boolean;
  @Input() isShowPopupModifyTable: boolean;
  @Input() isShowScrollbar = true;

  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() onInput: EventEmitter<any> = new EventEmitter();
  @Output() onBeforeRowCreated: EventEmitter<TableRow> = new EventEmitter();
  @Output() onAfterRowCreated: EventEmitter<TableRow> = new EventEmitter();

  @ContentChild('row') rowTemplate: TemplateRef<any>;
  @ContentChild('headerButtons') headerButtonsTmpl: TemplateRef<any>;
  @ContentChild('actionButtons') actionButtonsTemplate: TemplateRef<any>;
  @ContentChild('headerFilter') headerFilterTemplate: TemplateRef<any>;
  @ContentChild('headerFilterGroup')
  headerFilterGroupTemplate: TemplateRef<any>;
  @ContentChild('tableFooter') tableFooterTmpl: TemplateRef<any>;
  @ContentChild('alertWrapper') alertWrapperTmpl: TemplateRef<any>;

  readonly SEARCH_INTERVAL = 500; // 0.5s
  timeOut: any;

  protected abstract onInitBaseTable(): void;

  public state: TableState;
  public generatedId = TextUtils.generateRandomString();
  public isExpandedFilterGroupContent = false;
  constructor(
    protected tableService: TableService,
    public elementRef: ElementRef
  ) {
    super('app-table');
  }

  onInit(): void {
    this.setInitializationState();
    this.tableStateEventListener();
    this.modelReloadEventListener();
    this.modelDeleteEventListener();
    this.onInitBaseTable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.state && this.state.model) {
      if (
        changes.isView &&
        changes.isView.previousValue !== changes.isView.currentValue
      ) {
        if (this.state.model.formArray) {
          this.state.model.formArray.controls.forEach(control => {
            control.setIsView(this.isView);
          });
        }
      }
    }
  }

  private setInitializationState(): void {
    // this.modelTemp = Object.assign({}, this.model);
    // this.setStateEditTable();
    this.state = this.tableService.setState(
      this.model,
      this.stringUrl,
      this.isView,
      this.isMultipleSelect,
      this.isShowPagination
    );
    this.tableService.dispatch(new tableAction.FirstLoadTable());
  }

  private tableStateEventListener(): void {
    this.state.event.subscribe((event: TableEvent) => {
      switch (event.type) {
        case 'ON-CLICK':
          this.onClick.emit(event.data);
          break;
        case 'ON-CHANGE':
          this.onChange.emit(event.data);
          break;
        case 'ON-INPUT':
          this.onInput.emit(event.data);
          break;
        case 'ON-BEFORE-ROW-CREATED':
          this.onBeforeRowCreated.emit(event.data);
          break;
        case 'ON-AFTER-ROW-CREATED':
          this.onAfterRowCreated.emit(event.data);
          break;
        default:
          console.log(`There's no event type`);
          break;
      }
    });
  }

  private modelReloadEventListener(): void {
    this.model.requestReload.subscribe(() => {
      this.tableService.dispatch(new tableAction.ReloadTable());
    });
  }

  private modelDeleteEventListener(): void {
    this.model.requestDelete.subscribe(() => {
      this.tableService.dispatch(new tableAction.DeleteTableRow());
    });
  }

  public doSetFullViewTable(): void {
    this.isFullViewTable = !this.isFullViewTable;
    if (!this.isFullViewTable) {
      document.body.removeAttribute('style');
    } else {
      document.body.setAttribute('style', 'overflow: hidden');
    }
  }

  public onChangeSelectTableRecord(
    isChecked: boolean,
    row: TableRow,
    parent: TableRow
  ): void {
    this.tableService.dispatch(
      new tableAction.SelectTableRow({ row, isChecked, parent })
    );
    this.onChange.emit(this.state.recordTemp.getRecordList());
  }

  public onChangeSelectAllTableRecord(isChecked: boolean): void {
    this.tableService.dispatch(
      new tableAction.SelectAllTableRow({ isChecked })
    );
    this.onChange.emit(this.state.recordTemp.getRecordList());
  }

  public doSort(column: TableColumnModel): void {
    this.tableService.dispatch(new tableAction.SortTable({ column }));
  }

  public doResetInputSearch(
    inputSearch: HTMLElement & { value: string }
  ): void {
    inputSearch.value = '';
    this.tableService.dispatch(new tableAction.SearchTable({ keyword: '' }));
  }

  public onInputSearch(keyword: string): void {
    if (this.stringUrl) {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.tableService.dispatch(new tableAction.SearchTable({ keyword }));
      }, this.SEARCH_INTERVAL);
    } else {
      this.tableService.dispatch(new tableAction.SearchTable({ keyword }));
    }
  }

  public onChangePage(currentPage: number): void {
    this.tableService.dispatch(
      new tableAction.ChangeTablePage({ page: currentPage })
    );
  }

  public onChangeFilterPerPage(perPage: number): void {
    this.tableService.dispatch(
      new tableAction.ChangeTableFilterPerPage({ perPage })
    );
  }

  public doLoadTableRecordChildren(row: TableRow): void {
    this.tableService.dispatch(new tableAction.LoadTableRowChild({ row }));
  }

  public doExpandOrCollapseTableRow(row: TableRow): void {
    this.tableService.dispatch(
      new tableAction.ExpandOrCollapseTableRow({ row })
    );
  }

  public doExpandOrCollapseAllTableRow(action: 'EXPAND' | 'COLLAPSE'): void {
    this.tableService.dispatch(
      new tableAction.ExpandOrCollapseAllTableRow({ action })
    );
  }
}
