/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */
import { NgModule } from '@angular/core';
import { AppAccordionModule } from '../components/app-accordion/app-accordion.module';
import { AppAlertIconModule } from '../components/app-alert-icon/app-alert-icon.module';
import { AppAlertXModule } from '../components/app-alert-x/app-alert-x.module';
import { AppAlertSharedModule } from '../components/app-alert/app-alert-shared.module';
import { AppApprovalLogModule } from '../components/app-approval-log/app-approval-log.module';
import { AppApprovalPathXModule } from '../components/app-approval-path-x';
import { AppApprovalPathModule } from '../components/app-approval-path/app-approval-path.module';
import { AppAutoCompleteModule } from '../components/app-auto-complete/app-auto-complete.module';
import { AppBadgeModule } from '../components/app-badge/app-badge.module';
import { AppButtonActionGroupModule } from '../components/app-button-action-group/app-button-action-group.module';
import { AppButtonModule } from '../components/app-button/app-button.module';
import { AppCaptchaModule } from '../components/app-captcha/app-captcha.module';
import { AppCardModule } from '../components/app-card/app-card.module';
import { AppCarouselModule } from '../components/app-carousel/app-carousel.module';
import { AppChangeHistoryModule } from '../components/app-change-history/app-change-history.module';
import { AppCheckBoxModule } from '../components/app-check-box/app-check-box.module';
import { AppChooseModule } from '../components/app-choose/app-choose.module';
import { AppChosenModule } from '../components/app-chosen/app-chosen.module';
import { AppComboBoxTreeModule } from '../components/app-combo-box-tree/app-combo-box-tree.module';
import { AppComboBoxModule } from '../components/app-combo-box/app-combo-box.module';

import { AppContentSidebarModule } from '../components/app-content-sidebar/app-content-sidebar.module';
import { AppContentSliderModule } from '../components/app-content-slider/app-content-slider.module';
import { AppCountModule } from '../components/app-count/app-count.module';
import { AppCurrencyModule } from '../components/app-currency/app-currency.module';
import { AppDatePickerXModule } from '../components/app-date-picker-x/app-date-picker-x.module';
import { AppDatepickerModule } from '../components/app-date-picker/app-date-picker.module';
import { AppDragAndDropModule } from '../components/app-drag-and-drop/app-drag-and-drop.module';
import { AppDropDownModule } from '../components/app-drop-down/app-drop-down.module';
import { AppDropdownSelectModule } from '../components/app-dropdown-select/app-dropdown-select.module';
import { AppFieldsetModule } from '../components/app-fieldset/app-fieldset.module';
import { AppFlagNewModule } from '../components/app-flag-new/app-flag-new.module';
import { AppFormObjectModule } from '../components/app-form-object/app-form-object.module';

import { AppLabelOptionalModule } from '../components/app-label-optional/app-label-optional.module';
import { AppLayoutModule } from '../components/app-layout/app-layout.module';
import { AppLazyLoadModule } from '../components/app-lazy-load/app-lazy-load.module';
import { AppMapModule } from '../components/app-map/app-map.module';
import { AppModalModule } from '../components/app-modal/app-modal.module';
import { AppApprovalMonitoringModule } from '../components/app-monitoring-approval/app-approval-monitoring.module';
import { AppMonthPickerModule } from '../components/app-month-picker/app-month-picker.module';
import { AppMonthYearPickerModule } from '../components/app-month-year-picker/app-month-year-picker.module';
import { AppNpwpModule } from '../components/app-npwp/app-npwp.module';
import { AppPaginationModule } from '../components/app-pagination/app-pagination.module';
import { AppPhoneModule } from '../components/app-phone/app-phone.module';
import { AppPickListModule } from '../components/app-pick-list/app-pick-list.module';
import { SharedPopupModule } from '../components/app-popup/shared-popup.module';
import { AppRadioModule } from '../components/app-radio/app-radio.module';
import { AppRatingModule } from '../components/app-rating/app-rating.module';
import { AppSelectModule } from '../components/app-select/app-select.module';
import { AppStepperModule } from '../components/app-stepper/app-stepper.module';
import { AppTabModule } from '../components/app-tab';
import { AppTableXModule } from '../components/app-table-x/components/app-table-x/app-table-x.module';
import { AppTableModule } from '../components/app-table/app-table.module';
import { AppTaggingModule } from '../components/app-tagging/app-tagging.module';
import { AppTextAreaModule } from '../components/app-text-area/app-text-area.module';
import { AppTextFieldModule } from '../components/app-text-field/app-text-field.module';
import { AppTextGeneratedModule } from '../components/app-text-generated/app-text-generated.module';
import { AppTextMapModule } from '../components/app-text-map/app-text-map.module';
import { AppTextTaggingModule } from '../components/app-text-tagging/app-text-tagging.module';
import { AppTextTreeModule } from '../components/app-text-tree/app-text-tree.module';
import { AppTimeModule } from '../components/app-time/app-time.module';
import { AppTipsModule } from '../components/app-tips/app-tips.module';
import { AppToastModule } from '../components/app-toast/app-toast.module';
import { AppToggleSwitchModule } from '../components/app-toggle-switch/app-toggle-switch.module';
import { AppTooltipWrapperModule } from '../components/app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppTooltipModule } from '../components/app-tooltip/app-tooltip.module';
import { AppTreeModule } from '../components/app-tree';
import { AppTreeOrgModule } from '../components/app-tree-org';
import { AppTreeXModule } from '../components/app-tree-x';
import { AppWorkPathModule } from '../components/app-work-path/app-work-path.module';
import { AppWorkflowInfoModule } from '../components/app-workflow-info/app-workflow-info.module';
import { AppWorklistInfoModule } from '../components/app-worklist-info/app-worklist-info.module';
import { AppWysiwygModule } from '../components/app-wysiwyg/app-wysiwyg.module';
import { AppCalendarModule } from '../components/calendar/components/app-calendar/app-calendar.module';
import { TableModule } from '../components/table/table.module';
import { UploadModule } from '../components/upload';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipe/pipe.module';
import { ServiceModule } from '../services/service.module';
import { VendorModule } from '../vendor/vendor.module';
import { SharedWidgetsModule } from '../widgets/shared-widgets.module';
import { PromiseThemeVendorModule } from 'src/app/themes/promise/vendor/promise-theme-vendor.module';
import { SidebarModule } from 'src/app/themes/promise/components/sidebar/sidebar.module';
import { FooterModule } from 'src/app/themes/promise/components/footer/footer.module';
import { ThemeBreadcrumbModule } from 'src/app/themes/promise/components/breadcrumb/breadcrumb.module';
import { TipsModule } from 'src/app/themes/promise/components/tips/tips.module';

@NgModule({
  imports: [
    VendorModule,
    DirectivesModule,
    AppFieldsetModule,
    AppDatepickerModule,
    AppTableModule,
    SharedPopupModule,
    AppTreeModule,
    AppTreeOrgModule,
    AppChooseModule,
    AppCardModule,
    AppButtonModule,
    AppAutoCompleteModule,
    AppChosenModule,
    AppTextFieldModule,
    AppComboBoxModule,
    AppTextTreeModule,
    AppTaggingModule,
    AppTextTaggingModule,
    AppTextAreaModule,
    AppToggleSwitchModule,
    AppCheckBoxModule,
    AppWysiwygModule,
    AppTooltipWrapperModule,
    AppRadioModule,
    AppCaptchaModule,
    AppPhoneModule,
    AppRatingModule,
    AppWorkPathModule,
    AppApprovalPathModule,
    AppCurrencyModule,
    AppModalModule,
    AppButtonActionGroupModule,
    AppWorkflowInfoModule,
    AppCarouselModule,
    AppMapModule,
    AppTextMapModule,
    AppTooltipModule,
    AppTabModule,
    AppFormObjectModule,
    AppTimeModule,
    AppMonthPickerModule,
    AppNpwpModule,
    AppFlagNewModule,
    AppDragAndDropModule,
    AppPaginationModule,
    AppTextGeneratedModule,
    AppApprovalPathXModule,
    AppApprovalMonitoringModule,
    AppApprovalLogModule,
    AppChangeHistoryModule,
    AppCountModule,
    AppLabelOptionalModule,

    AppWorklistInfoModule,
    // ESM
  
    SharedWidgetsModule,
    // pipe
    PipesModule,
    ServiceModule,
    AppPickListModule,
    UploadModule,
    AppAlertSharedModule,
    AppToastModule,
    AppMonthYearPickerModule,
    AppLazyLoadModule,
    AppSelectModule,
    AppBadgeModule,
    AppTreeXModule,
    AppComboBoxTreeModule,
    AppDropDownModule,
    AppContentSidebarModule,
    AppTableXModule,
    AppTipsModule,
    AppLayoutModule,

    AppAlertXModule,
    AppAlertIconModule,
    AppDropdownSelectModule,
    AppDatePickerXModule,
    AppContentSliderModule,
    TableModule,
    AppCalendarModule,
    AppStepperModule,
    AppAccordionModule
  ],
  exports: [
    AppCardModule,
    AppFieldsetModule,
    VendorModule,
    AppTableModule,
    SharedPopupModule,
    AppDatepickerModule,
    DirectivesModule,
    AppTreeModule,
    AppTreeOrgModule,
    AppChooseModule,
    AppButtonModule,
    AppAutoCompleteModule,
    AppChosenModule,
    AppTextFieldModule,
    AppComboBoxModule,
    AppTextTreeModule,
    AppTaggingModule,
    AppTextTaggingModule,
    AppTextAreaModule,
    AppToggleSwitchModule,
    AppCheckBoxModule,
    AppWysiwygModule,
    AppTooltipWrapperModule,
    AppRadioModule,
    AppCaptchaModule,
    AppPhoneModule,
    AppRatingModule,
    AppWorkPathModule,
    AppApprovalPathModule,
    AppCurrencyModule,
    AppButtonActionGroupModule,
    AppWorkflowInfoModule,
    AppCarouselModule,
    AppMapModule,
    AppTextMapModule,
    AppTooltipModule,
    AppTabModule,
    AppFormObjectModule,
    AppTimeModule,
    AppMonthPickerModule,
    AppNpwpModule,
    AppFlagNewModule,
    AppDragAndDropModule,
    AppPaginationModule,
    AppTextGeneratedModule,
    AppApprovalPathXModule,
    AppApprovalMonitoringModule,
    AppApprovalLogModule,
    AppChangeHistoryModule,
    AppCountModule,
    AppLabelOptionalModule,

    AppWorklistInfoModule,
   
    SharedWidgetsModule,
    // Pipe
    PipesModule,
    ServiceModule,
    AppPickListModule,
    UploadModule,
    AppAlertSharedModule,
    AppToastModule,
    AppMonthYearPickerModule,
    AppLazyLoadModule,
    AppSelectModule,
    AppBadgeModule,
    AppTreeXModule,
    AppComboBoxTreeModule,
    AppDropDownModule,
    AppContentSidebarModule,
    AppTableXModule,
    AppTipsModule,
    AppLayoutModule,

    AppAlertXModule,
    AppAlertIconModule,
    AppDropdownSelectModule,
    AppDatePickerXModule,
    AppContentSliderModule,
    TableModule,
    AppCalendarModule,
    AppStepperModule,
    AppAccordionModule,

    PromiseThemeVendorModule,
  
    SidebarModule,
   
    FooterModule,
    ThemeBreadcrumbModule,
    TipsModule
  ]
})
export class SharedModule {}
