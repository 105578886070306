/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\action\restore-widget-action.ts
 
 * Created Date: Thursday, November 18th 2021, 12:00:23 pm

 *
*
 */

import { WidgetState } from '../domain/widget-state';
export const restoreWidgetAction = (state: WidgetState): void => {
  state.isShowWidgetEditable = false;
  const widget = [];
  Object.assign(widget, state.widgetListTemp);
  state.widgetList = widget;
  state.setStateReady();
};
