import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Item } from '../../core/bean/item';
import { ItemCategory } from '../../core/bean/item-category';
import { ItemType } from '../../core/bean/item-type';
import { Uom } from '../../core/bean/uom';
import { AppAutocompleteComponent } from '../../core/components/app-auto-complete/app-auto-complete.component';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { OptionListModel } from './../../core/model/option-list-model';
import { ItemResponse } from './item-response';

@Component({
  templateUrl: 'item-edit-add.component.html',
  styleUrls: ['./item-edit-add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ItemEditAddComponent extends BaseModuleComponent {
  @ViewChild(AppAutocompleteComponent)
  public autoComplete: AppAutocompleteComponent;
  public itemId: number;
  public categoryList: ItemCategory[] = [];
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    true
  );
  public autoCompleteValue: any;
  public isValid = false;
  constructor(translateService: TranslateService) {
    super('item', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.itemId = this.global.routerParams.get('itemId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null, Validators.required()],
      name: [null, Validators.required()],
      itemType: [null, Validators.required()],
      uom: [null, Validators.required()],
      category: [null, Validators.required()],
      description: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ItemResponse>(
        '/item/add-edit',
        new RouteRequestModel(this.todo, this.itemId)
      )
      .subscribe((ItemResponse: ItemResponse) => {
        this.itemTypeOptionList.setRequestValues(ItemResponse.itemTypeList);
        this.autoCompleteValue = ItemResponse.item && ItemResponse.item.uom;
        if (ItemResponse.item != null) {
          const indexItemType = ItemResponse.itemTypeList.findIndex(
            (itemType: ItemType) =>
              itemType.id === ItemResponse.item.itemType.id
          );
          const { code, name, description, id, uom } = ItemResponse.item;
          this.formGroup.patchValue({
            code,
            name,
            description,
            id,
            uom: uom.name,
            itemType: ItemResponse.itemTypeList[indexItemType],
            category: ItemResponse.categoryList
          });
        }
        this.setStateReady();
      });
    if (this.todo === 'edit') {
      this.formGroup.get('code').setIsView(true);
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const item: Item = this.global.copyFormAttributeToModel(
              new Item(),
              this.formGroup
            );
            const uom: Uom = new Uom();
            uom.id = this.autoCompleteValue.id;
            item.uom = uom;
            const categoryList: ItemCategory[] =
              this.formGroup.get('category').value;
            item.itemCategory = categoryList[categoryList.length - 1];
            const url = this.todo === 'edit' ? '/item/update' : '/item/insert';
            this.httpClientService
              .post<Response<Item>>(url, item)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/item']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public autoCompleteValueChange(event: any): void {
    this.autoCompleteValue = event;
  }

  public doCancel(): void {
    this.router.navigate(['/pages/item']);
  }
}
