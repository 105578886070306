import { Component } from '@angular/core';
import { BaseWidgetComponent } from '../../base/base-widget.component';
@Component({
  templateUrl: './widget-login-information.component.html'
 
})
export class WidgetLoginInformationComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-login-information';
  public lastLogin: Date;
  public organizationName: string;
  constructor() {
    super(WidgetLoginInformationComponent.moduleCode);
  }

  protected onInit(): void {
    this.httpClientService
      .get('/widget-login-information/index')
      .subscribe((response: { lastLogin: Date; organizationName: string }) => {
        this.lastLogin = new Date(response.lastLogin);
        this.organizationName = response.organizationName;
        this.global.widgetLoadingStatus.set(this.moduleCode, true);
        this.setStateReady();
      });
  }
}
