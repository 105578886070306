import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { BudgetAllocation } from 'src/app/core/bean/budget-allocation';
import { BudgetType } from 'src/app/core/bean/budget-type';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Coa } from '../../../bean/coa';
import { TablePluginData } from '../../table/interface/table-plugin-data';
import { TableResponseModel } from '../../table/model/table-response-model';
import { AppPopupChooseBudgetCustomDataXResponse } from './app-popup-choose-budget-custom-data-x.response';
import { AppPopupChooseBudgetXModel } from './app-popup-choose-budget-x.model';

@Component({
  templateUrl: './app-popup-choose-budget-x.component.html'
})
export class AppPopupChooseBudgetXComponent extends BaseModuleComponent {
  @Input() isMultiple: boolean;
  @Input() budgetAllocations: BudgetAllocation[] = [];
  @Input() public usedBudgetList: Map<number, number> = new Map<
    number,
    number
  >();
  @Input() public prItemBudgetEditList: Map<number, number> = new Map<
    number,
    number
  >();
  @Input() public fromModuleCode: string;
  @Output() onChange: EventEmitter<BudgetAllocation[]> = new EventEmitter();

  public tableResponse: TableResponseModel<BudgetAllocation>;
  public activityPeriodOptionList: OptionListModel<number> =
    new OptionListModel(false);
  public budgetTypeOptionList: OptionListModel<BudgetType> =
    new OptionListModel(false);
  public appPopupChooseBudgetModel: AppPopupChooseBudgetXModel =
    new AppPopupChooseBudgetXModel();
  public errorMapper: Map<number, string> = new Map();
  public budgetAllocationTempList: BudgetAllocation[] = [];
  public isBudgetChecked = false;
  public isShowFilter = false;
  public budgetIdList: number[];

  constructor(translateService: TranslateService) {
    super('app-popup-choose-budget', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.getOptionList();
  }

  public buildFormGroup(): void {
    this.budgetIdList = this.budgetAllocations?.map(budget => budget.id);
    this.formGroup = this.formBuilder.group({
      activityPeriod: [null],
      organization: [null],
      budgetType: [null],
      costCenter: [null],
      coa: [null],
      idList: [this.budgetIdList]
    });
  }

  public buildTableResponse(): void {
    if (
      this.prItemBudgetEditList &&
      Object.keys(this.prItemBudgetEditList).length === 0 &&
      this.prItemBudgetEditList.constructor === Object
    ) {
      this.prItemBudgetEditList = new Map<number, number>();
    }

    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'organization.name',
        header: 'table.column.department'
      },
      {
        field: 'activityPeriod',
        header: 'table.column.period'
      },
      {
        field: 'costCenter.code',
        header: 'table.column.costCenter'
      },
      {
        field: 'coa.code',
        header: 'table.column.coa'
      },
      {
        field: 'budgetType.name',
        header: 'table.column.type'
      },
      this.fromModuleCode?.toUpperCase() ===
      this.global.appConstant.core.MCODE_WORK_PLAN
        ? {
            field: 'availablePlannedBudget',
            header: 'table.column.availablePlanning',
            plugins: [
              {
                name: 'custom-plugin',
                before: (tablePlugin: TablePluginData): number => {
                  if (tablePlugin.value) {
                    let available =
                      this.prItemBudgetEditList &&
                      this.prItemBudgetEditList.get(tablePlugin.row.record.id)
                        ? tablePlugin.value -
                          +(
                            this.usedBudgetList.get(
                              tablePlugin.row.record.id
                            ) || 0
                          ) +
                          +this.prItemBudgetEditList.get(
                            tablePlugin.row.record.id
                          )
                        : this.usedBudgetList.get(tablePlugin.row.record.id)
                        ? tablePlugin.value -
                          this.usedBudgetList.get(tablePlugin.row.record.id)
                        : tablePlugin.value;

                    return available;
                  }
                }
              },
              {
                name: 'default',
                type: 'currency'
              }
            ],
            className: 'text-right'
          }
        : {
            field: 'availableBudget',
            header: 'table.column.availablePlanning',
            plugins: [
              {
                name: 'custom-plugin',
                before: (tablePlugin: TablePluginData): number => {
                  if (tablePlugin.value) {
                    let available =
                      this.prItemBudgetEditList &&
                      this.prItemBudgetEditList.get(tablePlugin.row.record.id)
                        ? tablePlugin.value -
                          +tablePlugin.row.record.tempBookedBudget -
                          +(
                            this.usedBudgetList.get(
                              tablePlugin.row.record.id
                            ) || 0
                          ) +
                          +this.prItemBudgetEditList.get(
                            tablePlugin.row.record.id
                          )
                        : this.usedBudgetList.get(tablePlugin.row.record.id)
                        ? tablePlugin.value -
                          tablePlugin.row.record.tempBookedBudget -
                          this.usedBudgetList.get(tablePlugin.row.record.id)
                        : tablePlugin.value -
                          +tablePlugin.row.record.tempBookedBudget;

                    return available;
                  }
                }
              },
              {
                name: 'default',
                type: 'currency'
              }
            ],
            className: 'text-right'
          }
    ]);

    this.tableResponse.setCustomData(this.formGroup.value);
  }

  public getOptionList(): void {
    this.httpClientService
      .get<AppPopupChooseBudgetCustomDataXResponse>(
        '/app-popup-choose-budget-x/get-option-list'
      )
      .subscribe((response: AppPopupChooseBudgetCustomDataXResponse) => {
        this.activityPeriodOptionList.setRequestValues(
          response.activityPeriodList
        );
        this.budgetTypeOptionList.setRequestValues(response.budgetTypeList);
        this.setBudgetAllocation();
        this.setStateReady();
      });
  }

  public setBudgetAllocation(): void {
    if (this.budgetAllocations && this.budgetAllocations.length !== 0) {
      this.tableResponse.setSelectedRowList(this.budgetAllocations);
    }
  }

  public get budgetAllocationList(): FormArray {
    return this.formGroup.get('budgetAllocationList') as FormArray;
  }

  public setBudgetList(): void {
    this.tableResponse.selectedRow
      .getSelectedRecordList()
      .forEach(checkRecord => {
        if (checkRecord.record) {
          this.budgetAllocationTempList.push(checkRecord.record);
        }
      });
  }

  public doChoose(): void {
    if (this.tableResponse.selectedRow.getSelectedRecordList().length !== 0) {
      this.onChange.emit(
        this.tableResponse.selectedRow.getSelectedRecordList()
      );
    } else {
      this.global.alertService.showError(
        'app-popup-choose-budget.validation.chooseBudget',
        '.popup-choose-budget'
      );
    }
  }

  public doApply(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.formGroup.patchValue({ idList: this.budgetIdList });
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doShowFilter(): void {
    this.isShowFilter = !this.isShowFilter;
  }

  public doOnChange(event): void {
    console.log(event);
  }

  public concatCoa(coa: Coa): string {
    return coa.code + ' - ' + coa.name;
  }
}
