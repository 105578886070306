import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppLazyLoadModule } from '../app-lazy-load/app-lazy-load.module';
import { PipesModule } from './../../pipe/pipe.module';
import { AppTreeOrgComponent } from './app-tree-org.component';
import { AppTreePopupModule } from './popup/app-tree-popup.module';
@NgModule({
  imports: [
    VendorModule,
    AppTreePopupModule,
    AppButtonModule,
    PipesModule,
    AppLazyLoadModule
  ],
  declarations: [AppTreeOrgComponent],
  exports: [AppTreeOrgComponent]
})
export class AppTreeOrgModule {}
