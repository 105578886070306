import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
@Component({
  templateUrl: './registration-email-verification.component.html',
  styleUrls: ['./registration-email-verification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationEmailVerificationComponent extends BaseModuleComponent {
  public email: string;
  constructor(public translateService: TranslateService) {
    super('registration', translateService);
  }

  public onInit(): void {
    this.email = this.global.routerParams.get('vendorEmail');
    this.global.routerParams.clear();
  }
}
