<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="notification-log.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'notification-log.form.tipe' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ formGroup.controls.notificationType.value }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'notification-log.form.receiver' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ formGroup.controls.receiver.value }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'notification-log.form.cc' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ formGroup.controls.cc.value || '-' }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'notification-log.form.subject' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ formGroup.controls.subject.value }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'notification-log.form.content' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div [innerHTML]="notifJobBody"> </div>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'notification-log.form.logDate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              formGroup.controls.logDate.value
                | date : global.config.parameterModel.dateFormatShort
            }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'notification-log.form.status' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ formGroup.controls.status.value }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'notification-log.form.resend' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-button size="SM" (onClick)="doSend()" [disabled]="btnDisabled">
              {{ 'app.button.resend' | translate }}
            </app-button>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button
            mode="BYPASS"
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
