/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\model\table-request-model.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 1:11:23 pm

 *
*
 */

import { PaginationRequestModel } from '../../../model/pagination-request-model';
import { TableColumnModel } from './table-column-model';
export class TableRequestModel extends PaginationRequestModel {
  // cols: Array<TableColumnModel> = new Array(); // diubah jadi columnList
  columnList: Array<TableColumnModel> = new Array(); // diubah jadi columnList
  // custom data digunakan untuk filter table
  // customData: string;
  // page saat ini dan ini diganti dengan page
  // first: number;
  page: number;
  // rows ada total rows per page diganti dengan perPage
  // rows: number;
  perPage: number;
  // sort field adalah field yang digunakan untuk sort column
  sortField: string;
  // sort order digunakan untuk menentukan urutan record.
  sortOrder: number;
  // multiSortMeta: Array<TableSortMeta> = new Array();
  // filters: {
  //   [s: string]: TableFilterMetaData;
  // };
  // global filter adalah kata kunci pencarian diganti dengan keyword
  // globalFilter: string;
  keyword: string;
  constructor(columnList?: Array<TableColumnModel>, perPage = 10) {
    super();
    this.cols = columnList;
    this.columnList = columnList;
    this.first = 0;
    this.page = 0;
    this.sortOrder = 1;
    this.globalFilter = '';
    this.keyword = '';
    this.rows = perPage;
    this.perPage = perPage;
  }

  setSearchText(keyword: string): void {
    this.globalFilter = keyword;
    this.keyword = keyword;
  }

  setFirst(first: number): void {
    this.first = first;
  }
}
