import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Qc } from '../../core/bean/qc';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
@Component({
  templateUrl: './example.component.html'
})
export class ExampleComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Qc>;
  public isLoading: boolean;
  options: { name: string, value: string }[] = [];
  public myOptionList: OptionListModel<any> = new OptionListModel().setValuePath('value');
  constructor(
    translateService: TranslateService
  ) {
    super('example', translateService);

    const animalNames = ['Singa', 'Gajah', 'Harimau', 'Jerapah', 'Buaya'];

    // Menginisialisasi array dengan nama binatang acak
    for (let i = 0; i < animalNames.length; i++) {
      this.options.push({ name: animalNames[i], value: animalNames[i] });
    }
    this.myOptionList.setRequestValues(this.options);

  }

  public onInit(): void {
    this.formGroup = this.formBuilder.group({
      name: [null],
      age: [null],
      dateTo: [null]
    });
   this.setStateReady();
  }

  public onChoose(event: any): void {
  console.log(event);
  }
 
}
