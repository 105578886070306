import { EventEmitter } from '@angular/core';

export class OptionListModel<T> {
  public requestValues: T[] = [];
  public responseValues: T[] = [];
  public isWaitFromServer: boolean;
  public viewPath: string | { fn: (option: any) => string };
  public valuePath: string;
  public options: Map<string, any> = new Map();
  public requestValueChanges: EventEmitter<T[]> = new EventEmitter();
  public responseValueChanges: EventEmitter<T[]> = new EventEmitter();
  public resetValueChanges: EventEmitter<boolean> = new EventEmitter();
  public sortBy: string;
  public stringUrl: string;
  public fileTypeCode: string;
  public searchFieldList: Array<string>;
  constructor(
    isWaitFromServer = false,
    viewPath?: string | { fn: (option: any) => string },
    valuePath?: string,
    sortBy?: string
  ) {
    this.isWaitFromServer = isWaitFromServer;
    this.viewPath = viewPath;
    this.valuePath = valuePath;
    this.sortBy = sortBy;
  }

  public getRequestValues(maxValues?: number): T[] {
    const requestValues = Array.from(this.requestValues);
    return requestValues.splice(
      0,
      maxValues ? maxValues : requestValues.length
    );
  }

  public setRequestValues(values: T[]): this {
    this.requestValues = values;
    this.requestValueChanges.emit(values);
    return this;
  }

  public getResponseValues(): T[] {
    return this.responseValues;
  }

  public setResponseValues(values: T[]): void {
    this.responseValues = values;
    this.responseValueChanges.emit(values);
  }

  public setOption(key: string, value: any): this {
    this.options.set(key, value);
    return this;
  }

  public setViewPath(viewPath: string): this {
    this.viewPath = viewPath;
    return this;
  }

  public setValuePath(valuePath: string): this {
    this.valuePath = valuePath;
    return this;
  }

  public setSortBy(sortBy: string): this {
    this.sortBy = sortBy;
    return this;
  }

  public setIsWaitingFromServer(isWaitingFromServer: boolean): this {
    this.isWaitFromServer = isWaitingFromServer;
    return this;
  }

  public addOption(option: T): void {
    this.requestValues.push(option);
    this.requestValueChanges.emit(this.requestValues);
    this.responseValueChanges.emit(this.requestValues);
  }

  public removeOption(index: number): void {
    this.requestValues.splice(index, 1);
    this.requestValueChanges.emit(this.requestValues);
    this.responseValueChanges.emit(this.requestValues);
  }

  public reset(): void {
    this.requestValues = [];
    this.responseValues = [];
    this.requestValueChanges.emit(this.requestValues);
    this.responseValueChanges.emit(this.responseValues);
    this.resetValueChanges.emit(true);
  }

  public getOptionByValue(value: any, field: string): any {
    if (value) {
      const requestValues = Array.from(this.requestValues);
      return requestValues.filter(
        d => JSON.stringify(field ? d[field] : d) === JSON.stringify(value)
      )[0];
    }
    return null;
  }

  /**
   * menentukan field yg bisa di-search di app-select (Client side only)
   * @param fieldList field yg ingin bisa di-search
   * @returns OptionListModel
   */
  public setSearchFieldList(fieldList: string[]): this {
    this.searchFieldList = fieldList;
    return this;
  }
}
