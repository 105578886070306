<div class="promise-container">
  <div class="promise-container-left">
    <app-card header="portal.menu.faq" [isLoading]="formLoading">
      <ng-template #customLoading>
        <div class="promise-loading">
          <div class="loading"></div>
        </div>
      </ng-template>
      <div class="faq-content">
        <div class="custom-search custom-search-lg mb-4">
          <em class="pir pi-search"></em>
          <input
            type="text"
            class="form-control"
            placeholder="{{
              'portal.faq.placeholder.searchQuestion' | translate
            }}"
            [formControl]="keyword"
          />
          <em class="pir pi-times" (click)="doResetSearch()"></em>
        </div>
        <app-accordion [isShowMultiple]="true">
          <app-accordion-item
            *ngFor="let qa of qaList | search: keyword.value:'question'"
            [header]="qa.question"
            customClassHeader="bg-white"
            customClassBody="bg-light"
          >
            <div
              [innerHTML]="qa.answer"
              [ngStyle]="{
                'white-space': 'normal',
                'max-width': '600px',
                'word-break': 'break-word'
              }"
            >
            </div>
          </app-accordion-item>
        </app-accordion>
        <div
          class="d-flex py-5 align-items-center flex-column"
          *ngIf="
            !formLoading &&
            (qaList | search: keyword.value:'question').length === 0
          "
        >
          <div class="pvc pv-search pv-1x mb-3"></div>
          <p>
            {{ 'app.text.noResultsFor' | translate }} "{{ keyword.value }}"
          </p>
        </div>
      </div>
    </app-card>
  </div>
  <div class="promise-container-right sticky">
    <app-card>
      <h4 class="text-center" style="color: var(--primary-dark)">
        <!-- Can't find the right answer? Ask us! -->
        {{ 'portal.faq.msg.cantFindTheRightAnswerAskUs' | translate }}
      </h4>
      <form class="mt-4" [formGroup]="formGroup">
        <div class="form-group">
          <label for="name">{{ 'portal.faq.form.name' | translate }}</label>
          <app-text-field
            formControlName="userName"
            size="XL"
            maxLength="64"
          ></app-text-field>
        </div>
        <div class="form-group">
          <label for="email">{{ 'portal.faq.form.email' | translate }}</label>
          <app-text-field
            formControlName="email"
            size="XL"
            maxLength="64"
          ></app-text-field>
        </div>
        <div class="form-group">
          <label for="question">{{
            'portal.faq.form.question' | translate
          }}</label>
          <app-text-area
            formControlName="question"
            size="XL"
            maxLength="255"
          ></app-text-area>
          <small class="d-block mt-2">
            <!-- We will reply the answer to your email -->
            {{ 'portal.faq.msg.weWillReplyAnswerToEmail' | translate }}
          </small>
        </div>
        <div class="button-group full-button">
          <app-button (onClick)="doSendQuestion()">{{
            'portal.faq.button.sendQuestion' | translate
          }}</app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
