/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table\model\portal-table-resposne-model.ts
 
 * Created Date: Thursday, September 30th 2021, 10:31:05 am

 *
*
 */

import { EventEmitter } from '@angular/core';
export class PortalTableResponseModel<T> {
  private recordList: Array<T> = new Array();
  private customData: object;
  public requestReload: EventEmitter<void> = new EventEmitter();
  constructor(public moduleCode: string) {}

  public setRecordList(recordList: Array<T>): void {
    this.recordList = recordList;
  }

  public getRecordList(): Array<T> {
    return this.recordList;
  }

  public reload(): void {
    this.requestReload.emit();
  }

  public setCustomData(customData: object): void {
    this.customData = customData;
  }

  public getCustomData(): object {
    return this.customData;
  }
}
