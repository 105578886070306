/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */

import { Menu } from '../bean/menu';
import { Organization } from '../bean/organization';
import { User } from '../bean/user';
import { UserRole } from '../bean/user-role';
import { Vendor } from '../bean/vendor';
import { AuthenticationResponse } from '../response/authentication-response';
import { AngularMenuModel } from './angular-menu-model';
export class UserSessionModel {
  user: User;
  userBean: User;
  vendor: Vendor;
  activeUserRole: UserRole;
  token: any;
  menuMap: Map<string, AngularMenuModel>;
  headerMenuList: Menu[];
  activeMenu: AngularMenuModel;
  userPreferenceMap: Map<string, string>;
  organizationTenant: Organization;
  constructor(authenticationResponse: AuthenticationResponse) {
    this.user = authenticationResponse.user;
    this.userBean = authenticationResponse.userBean;
    this.vendor = authenticationResponse.vendor;
    this.activeUserRole = authenticationResponse.activeUserRole;
    this.token = authenticationResponse.strToken;
    this.menuMap = authenticationResponse.menuMap;
    this.userPreferenceMap = authenticationResponse.userPreferenceMap;
    this.organizationTenant = authenticationResponse.organizationTenant;
  }
}
