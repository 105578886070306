<app-popup [header]="moduleCode + '.popup.addWidget.title'" [isLoading]="formLoading">
  <perfect-scrollbar>
    <app-accordion theme="TRANSPARENT" [isExpandAll]="true">
      <app-accordion-item *ngFor="let model of dashboardAddWidgetModelList" [header]="
          model.widgetGroup.translationKey.module.code.toLowerCase() +
          '.' +
          model.widgetGroup.translationKey.key
        " customClass="border-0 mb-0" customClassHeader="bg-light" customClassBody="border-top-0 mx-0 pb-0 pt-0">
        <ul class="list">
          <li class="list-item" *ngFor="
              let roleDashboardWidget of model.roleDashboardWidgetList;
              let i = index
            " [ngClass]="
              model.roleDashboardWidgetList.length - 1 > i
                ? 'border-bottom'
                : ''
            ">
            <div class="d-flex justify-content-between px-2">
              <em class="align-self-center" [ngClass]="
                  roleDashboardWidget.widget.icon
                    ? roleDashboardWidget.widget.icon.name
                    : 'pvc pv-graphic-board'
                "></em>
              <div class="list-item-content flex-1 mx-3 mt-3">
                <h4 class="d-flex align-items-end justify-content-between mb-2">
                  {{
                  roleDashboardWidget.widget.nameTranslationKey
                  ? (roleDashboardWidget.widget.nameTranslationKey.module.code.toLowerCase() +
                  '.' +
                  roleDashboardWidget.widget.nameTranslationKey.key
                  | translate)
                  : roleDashboardWidget.widget.name
                  }}
                </h4>
                <p class="text-secondary">{{
                  roleDashboardWidget.widget.descTranslationKey
                  ? (roleDashboardWidget.widget.descTranslationKey.module.code.toLowerCase() +
                  '.' +
                  roleDashboardWidget.widget.descTranslationKey.key
                  | translate)
                  : roleDashboardWidget.widget.description
                  ? roleDashboardWidget.widget.description
                  : '-'
                  }}</p>
              </div>
              <div class="button-add mt-2">
                <app-button size="TN" (onClick)="onChange.emit([roleDashboardWidget])">
                  <em class="pir pi-plus"></em>
                </app-button>
              </div>
            </div>
          </li>
        </ul>
      </app-accordion-item>
    </app-accordion>
  </perfect-scrollbar>
</app-popup>