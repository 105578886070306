/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table-filter\app-portal-table-filter.module.ts
 
 * Created Date: Friday, October 1st 2021, 9:26:02 am

 *
*
 */

import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../core/vendor/vendor.module';
import { AppPortalTableFilterComponent } from './app-portal-table-filter.component';
@NgModule({
  imports: [VendorModule],
  declarations: [AppPortalTableFilterComponent],
  exports: [AppPortalTableFilterComponent]
})
export class AppPortaltableFilterModule {}
