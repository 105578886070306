/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\portal\landing-page\landing-page-card.component.ts
 
 * Created Date: Friday, October 1st 2021, 9:22:44 pm

 *
*
 */

import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-landing-page-card',
  templateUrl: './app-landing-page-card.component.html',
  styleUrls: ['./app-landing-page-card.component.scss']
})
export class AppLandingPageCardComponent {
  @Input() vectorClassName: string;
  @Input() header: string;
  @Input() isLoading: boolean;
  @ContentChild('footer') footerTmpl: TemplateRef<any>;
}
