<div class="app-organization-rk-info" *ngIf="!formLoading && !isSyncRunning">
  <app-widget-content-slider>
    <ng-template #contentOuterLeft>
      <div
        class="widget-card"
        [ngClass]="dataObject.attentionOrganizationBudget"
      >
        <div class="widget-content">
          <div class="content-text">
            <h5 class="content-text-title">
              {{ 'app-organization-plan-info.workPlan' | translate }}
            </h5>
            <span class="content-text-value">
              {{ dataObject.totalWorkPlan }}/
              {{ dataObject.totalRealizedWorkPlan }}
            </span>
          </div>
          <div class="content-text mt-2">
            <h5 class="content-text-title">
              {{ 'app-organization-plan-info.absorption' | translate }}
            </h5>
            <span class="content-text-value"
              >{{ dataObject.realizedBudgetPercentage || 0 }}%</span
            >
          </div>
        </div>
      </div>
    </ng-template>
    <app-organization-rk-info-item-card
      *ngFor="
        let organizationPlanInfoModel of dataObject.organizationPlanInfoModelList
      "
      [organizationPlanInfoModel]="organizationPlanInfoModel"
    >
    </app-organization-rk-info-item-card>
    <ng-template #contentOuterRight>
      <div class="btn-show-all">
        <button
          class="btn btn-primary btn-sm"
          (click)="doShowViewAll()"
          (keyup)="onKeyUp($event)"
          [attr.title]="'app-organization-plan-info.showAll' | translate"
        >
          <em class="fas fa-copy fa-2x"></em>
        </button>
      </div>
    </ng-template>
  </app-widget-content-slider>
</div>
