<app-card
  header="registration.title"
  [isLoading]="formLoading"
  [isSaving]="formSaving"
>
  <div
    class="form-group row justify-content-center text-sm-right"
    [ngStyle]="{ 'margin-left': '0.5%', 'margin-right': '0.5%' }"
  >
    <!-- <div></div> -->
    <div
      class="col-sm-12 alert-dark text-left"
      [ngStyle]="{ height: '2%', 'border-radius': '3px', padding: '10px' }"
    >
      <!-- Click here for download registration manual book -->
      {{ 'registration.form.downloadUserManual1' | translate }}
      <a (click)="downloadManualBook()" [ngStyle]="{ color: '#87b4e7' }">
        {{ 'registration.form.downloadUserManual2' | translate }}
        <!-- click here -->
      </a>
    </div>
  </div>
  <form
    class="form-horizontal main-row"
    [formGroup]="formGroup"
    novalidate
    id="registration-form"
  >
    <div class="form-group">
      <label for="vendorType"
        >{{ 'registration.form.vendorType' | translate }}
      </label>
      <app-dropdown-select
        size="XL"
        [optionList]="vendorTypeOptionList"
        formControlName="vendorType"
        (onChange)="changeLabelAndVendorEntity($event)"
        placeholder="Select vendor type"
        type="COMBOBOX"
      ></app-dropdown-select>
    </div>
    <div class="form-group">
      <label for="vendorEntity">
        {{ 'registration.form.vendorEntity' | translate }}
      </label>
      <app-dropdown-select
        size="XL"
        [optionList]="vendorEntityOptionList"
        formControlName="vendorEntity"
        tooltip="{{ vendorEntityTooltip }}"
        placeholder="Select entity vendor"
        type="COMBOBOX"
      ></app-dropdown-select>
      <!-- <app-combo-box
        size="XL"
        [optionList]="vendorEntityOptionList"
        formControlName="vendorEntity"
        tooltip="{{ vendorEntityTooltip }}"
        placeholder="Select entity vendor"
      >
      </app-combo-box> -->
    </div>
    <div *ngIf="setCompanyNameLabelActive">
      <div class="form-group">
        <label for="companyName">
          {{ 'registration.form.companyName' | translate }}
        </label>
        <app-text-field
          size="XL"
          formControlName="companyName"
          maxLength="255"
          type="alphanumeric"
          include="-.\',"
          tooltip="{{ 'registration.tooltip.companyName' | translate }}"
          placeholder="Type your Company Name"
        >
        </app-text-field>
      </div>
    </div>
    <div class="form-group">
      <label [attr.for]="setCompanyNameLabelActive ? 'picName' : 'personName'">
        {{
          (setCompanyNameLabelActive
            ? 'registration.form.picName'
            : 'registration.form.personName'
          ) | translate
        }}
      </label>
      <app-text-field
        size="XL"
        formControlName="name"
        maxLength="255"
        type="alphanumeric"
        include="-.\',"
        placeholder="Type your Full Name"
      >
      </app-text-field>
    </div>
    <div class="form-group">
      <label for="email">
        {{ 'registration.form.email' | translate }}
      </label>
      <app-text-field
        size="XL"
        formControlName="email"
        maxLength="64"
        tooltip="{{ emailTooltip }}"
        placeholder="Type email address"
      >
      </app-text-field>
    </div>
    <div class="form-group">
      <label for="retypeEmail">
        {{ 'registration.form.retypeEmail' | translate }}
      </label>
      <app-text-field
        size="XL"
        formControlName="retypeEmail"
        maxLength="64"
        placeholder="Type confirm email address"
      >
      </app-text-field>
    </div>
    <div class="form-group">
      <label for="password">{{
        'registration.form.password' | translate
      }}</label>
      <app-text-field
        size="XL"
        formControlName="password"
        type="password"
        maxLength="64"
        tooltip="{{ 'registration.tooltip.password' | translate }}"
        placeholder="Type Password"
      >
      </app-text-field>
    </div>
    <div class="form-group">
      <label for="retypePassword">
        {{ 'registration.form.retypePassword' | translate }}
      </label>
      <app-text-field
        size="XL"
        formControlName="retypePassword"
        type="password"
        maxLength="64"
        placeholder="Type Confirm Password"
      >
      </app-text-field>
    </div>

    <div class="form-group">
      <label for="phone">{{ 'registration.phone' | translate }}</label>
      <app-phone
        size="XL"
        formControlName="phone"
        [optionList]="regionOptionList"
        maxLength="32"
        tooltip="{{ 'registration.tooltip.phone' | translate }}"
        placeholder="Type Phone Number"
      >
      </app-phone>
    </div>

    <div class="form-group">
      <label for="registeredOn">{{
        'registration.form.registeredOn' | translate
      }}</label>
      <ng-container
        *ngIf="registrationVendorResponse.defaultOrganization === null"
      >
        <app-text-tree
          size="XL"
          formControlName="organizationList"
          stringUrl="/organization"
          header="Organization"
          [isLazy]="true"
        >
        </app-text-tree>
      </ng-container>
      <ng-container
        *ngIf="registrationVendorResponse.defaultOrganization !== null"
      >
        {{
          registrationVendorResponse.defaultOrganization &&
            registrationVendorResponse.defaultOrganization.name
        }}
      </ng-container>
    </div>

    <div class="form-group">
      <label for="npwp">{{ 'registration.form.taxNumber' | translate }}</label>
      <app-text-field
        *ngIf="
          vendorTypeCode === global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
        "
        formControlName="taxNumber"
        size="XL"
        placeholder="NPWP number"
        maxLength="32"
      ></app-text-field>
      <app-npwp
        *ngIf="
          vendorTypeCode !== global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
        "
        formControlName="taxNumber"
        size="XL"
      >
      </app-npwp>
    </div>

    <div class="button-group full-button">
      <app-button (onClick)="doNext()">
        {{ 'registration.button.register' | translate }}
      </app-button>
    </div>
    <div class="form-group text-center mt-4">
      <p>
        Already have an account?
        <a href="#" class="font-weight-bold" (click)="doLogin($event)"
          >Log in</a
        >
      </p>
    </div>
  </form>
</app-card>
