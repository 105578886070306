/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\service\table-plugin.service.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 1:54:27 pm

 *
*
 */
import { Injectable } from '@angular/core';
import {
  AvailableTableHeaderPlugin,
  AvailableTablePlugin
} from '../interface/available-table-plugin';
import { TableHeaderPlugin, TablePlugin } from '../interface/table-plugin';
import * as plugins from '../plugins';
@Injectable({
  providedIn: 'root'
})
export class TablePluginService {
  private pluginsMap: Map<
    AvailableTablePlugin | AvailableTableHeaderPlugin,
    TablePlugin
  > = new Map();
  constructor() {
    Object.keys(plugins).forEach(key => {
      this.pluginsMap.set(plugins[key].name, plugins[key]);
    });
  }

  public getPlugin(
    name: AvailableTablePlugin | AvailableTableHeaderPlugin
  ): TablePlugin | TableHeaderPlugin {
    return this.pluginsMap.get(name);
  }
}
