<div class="main-row">
  <div class="row">
    <div class="col-lg-12 mb-4">
      <app-card
        header="workflow.title"
        detail="app.action.{{ todo }}"
        [isLoading]="formLoading"
        [isSaving]="formSaving"
      >
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
          <div class="col-sm-12 text-right">
            <app-button (onClick)="doDuplicate()" *ngIf="todo == 'edit'">
              {{ 'workflow.form.duplicate' | translate }}
            </app-button>
          </div>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'workflow.form.code' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                autofocus="true"
                formControlName="code"
                type="code"
                maxLength="64"
              ></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'workflow.form.moduleGroup' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box
                formControlName="moduleGroup"
                [optionList]="moduleGroupOptionList"
                (onChange)="doOnChangeModuleGroup()"
              >
              </app-combo-box>
            </div>
          </div>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'workflow.form.name' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="name"
                maxLength="64"
              ></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'workflow.form.description' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-area formControlName="description"> </app-text-area>
            </div>
          </div>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'workflow.form.moduleStarter' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box
                formControlName="module"
                [optionList]="moduleStarterOptionList"
                (onChange)="doOnChangeStarterModule()"
              >
              </app-combo-box>
            </div>
          </div>
        </form>
      </app-card>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 mb-4">
      <app-card header="workflow.setWorkflow">
        <app-pick-list
          [model]="modulePickListModel"
          searchSourcePlaceholder="workflow.appPickList.modulePlaceHolder"
          searchTargetPlaceholder="workflow.appPickList.modulePlaceHolder"
          sourceTitle="workflow.appPickList.moduleSourceTitle"
          targetTitle="workflow.appPickList.moduleTargetTitle"
        >
          <ng-template #item let-workflow>
            <div class="ui-helper-clearfix position-relative w-100">
              <div
                class="float-left d-block w-100"
                [ngStyle]="{
                  'font-size': '14px',
                  margin: '0 5px 0 0',
                  'padding-right': '25px'
                }"
              >
                {{ workflow.name }}
              </div>
              <span *ngIf="errors[workflow.code]" class="show-error"></span>
              <button
                type="button"
                id="{{ workflow.name }}"
                class="btn btn-sm btn-outline-secondary sm mr-2 position-absolute"
                placement="left"
                (click)="doClick(workflow)"
                [ngStyle]="{
                  right: '-5px',
                  top: '-3px',
                  color: 'inherit'
                }"
                ><em class="fas fa-search"></em
              ></button>
            </div>
          </ng-template>
        </app-pick-list>
      </app-card>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 mb-4">
      <app-card header="workflow.additionalStage">
        <app-table [model]="tableResponse">
          <ng-template #headerButtons>
            <app-button
              (onClick)="doAddAdditionalStage()"
              title="{{ 'app.button.add' | translate }}"
              ><em class="fas fa-plus"></em
              >{{ 'app.button.add' | translate }}</app-button
            >
          </ng-template>
          <ng-template #actionButtons let-rowData let-i="index">
            <app-button
              size="TN"
              (onClick)="doEditAdditionalStage(rowData, i)"
              title="{{ 'app.tooltip.edit' | translate }}"
            >
              <em class="fas fa-pencil-alt"></em>
            </app-button>
            <app-button
              size="TN"
              color="DANGER"
              (onClick)="doDeleteAdditionalStage(rowData, i)"
              title="{{ 'app.tooltip.delete' | translate }}"
            >
              <em class="fas fa-trash"></em>
            </app-button>
          </ng-template>
        </app-table>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </app-card>
    </div>
  </div>

  <div *ngIf="customModal || errors.isError" class="custom-modal">
    <div
      class="fix-modal"
      (click)="doCloseCustomModal()"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    ></div>
    <div class="fm-body">
      <div class="fmb-content" [ngClass]="errors.isError ? 'alert errors' : ''">
        <table class="table" *ngIf="!errors.isError" aria-describedby="Table">
          <thead>
            <tr>
              <th scope="col">Input</th>
              <th scope="col">Output</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <ul
                  *ngIf="customModalData.inputDependency; else noDataTemplate"
                >
                  <li
                    *ngFor="
                      let inputDependency of customModalData.inputDependency
                    "
                  >
                    {{ inputDependency }}
                  </li>
                </ul>
              </td>
              <td>
                <ul
                  *ngIf="customModalData.outputDependency; else noDataTemplate"
                >
                  <li
                    *ngFor="
                      let outputDependency of customModalData.outputDependency
                    "
                  >
                    {{ outputDependency }}</li
                  >
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="errors.isError">
          <div class="alert-icon"
            ><em class="fas fa-exclamation-triangle"></em
          ></div>
          <div class="alert-content">
            <strong>{{ errors.status }} :</strong>
            {{ errors.statusText }}
          </div>
        </div>
        <div> </div>
      </div>
      <ng-template #noDataTemplate>-</ng-template></div
    ></div
  ></div
>
