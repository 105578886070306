export class ESMConstant {
  /* t1_work_plan_status */
  public WORK_PLAN_STATUS_NEW = 'NEW';
  public WORK_PLAN_STATUS_DRAFT = 'DRAFT';
  public WORK_PLAN_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public WORK_PLAN_STATUS_REVISION = 'REVISION';
  public WORK_PLAN_STATUS_WAITING_PR = 'WAITING_PR';
  public WORK_PLAN_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public WORK_PLAN_STATUS_WAITING_SUBMISSION = 'WAITING_SUBMISSION';
  public WORK_PLAN_STATUS_DONE = 'DONE';

  public WORK_PLAN_STATUS_TYPE_ON_PROCESS_WORK_PLAN = 'ON-PROCESS-WORK-PLAN';
  public WORK_PLAN_STATUS_TYPE_ON_PROCESS_PROCUREMENT =
    'ON-PROCESS-PROCUREMENT';
  public WORK_PLAN_STATUS_TYPE_ON_PROCESS_PAYMENT = 'ON-PROCESS-PAYMENT';

  /* t1_procurement_status */
  public PROCUREMENT_STATUS_PURCHASE_REQUEST = 'PURCHASE-REQUEST';
  public PROCUREMENT_STATUS_PROCUREMENT_PROCESS = 'PROCUREMENT-PROCESS';
  public PROCUREMENT_STATUS_PAYMENT_PROCESS = 'PAYMENT-PROCESS';
  public PROCUREMENT_STATUS_CLOSED = 'CLOSED';

  public BUDGET_NONE = 0;
  public BUDGET_SAFE = 1;
  public BUDGET_WARNING = 2;
  public BUDGET_EXPIRED = 3;

  /* t1_work_plan_worklist_status */
  public WORK_PLAN_WORKLIST_STATUS_NEW = 'NEW';
  public WORK_PLAN_WORKLIST_STATUS_DRAFT = 'DRAFT';
  public WORK_PLAN_WORKLIST_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public WORK_PLAN_WORKLIST_STATUS_REVISION = 'REVISION';
}
