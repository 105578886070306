

import { Action } from '../../../interfaces/action';
import { AccordionItemModel } from '../model/accordion-item-model';

export const ADD_ACCORDION_ITEM_TO_STATE = `[ Accordion ]: Add Accordion item to state`;
export const EXPAND_OR_COLLAPSE_ACCORDION = `[ Accordion ]: Expand Or Collapse Accordion`;
export const EXPAND_OR_COLLAPSE_ALL_ACCORDION = `[ Accordion ]: Expand Or Collapse All Accordion`;

export class AddAccordionItemToState
  implements Action<{ accordionItem: AccordionItemModel<any> }>
{
  public readonly type = ADD_ACCORDION_ITEM_TO_STATE;
  constructor(public payload: { accordionItem: AccordionItemModel<any> }) {}
}

export class ExpandOrCollapseAccordion
  implements Action<{ accordionItem: AccordionItemModel<any> }>
{
  public readonly type = EXPAND_OR_COLLAPSE_ACCORDION;
  constructor(public payload: { accordionItem: AccordionItemModel<any> }) {}
}

export class ExpandOrCollapseAllAccordion
  implements Action<{ isExpandAll: boolean }>
{
  public readonly type = EXPAND_OR_COLLAPSE_ALL_ACCORDION;
  constructor(public payload: { isExpandAll: boolean }) {}
}

export type AllAccordionAction =
  | AddAccordionItemToState
  | ExpandOrCollapseAccordion
  | ExpandOrCollapseAllAccordion;
