import { Component, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { NotificationJob } from '../../core/bean/notification-job';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';

@Component({
  templateUrl: './notification-job-detail.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NotificationJobViewComponent extends BaseModuleComponent {
  public notificationJob: NotificationJob = new NotificationJob();
  public notificationJobId: number;
  public btnDisabled = false;
  public notifJobBody: SafeHtml;
  constructor(
    translateService: TranslateService,
    public sanitizer: DomSanitizer
  ) {
    super('notificationJob', translateService);
  }

  onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.notificationJobId = this.global.routerParams.get('notificationJobId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      notificationType: [null],
      receiver: [null],
      cc: [null],
      subject: [null],
      body: [null],
      logDate: [null],
      status: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<NotificationJob>(
        '/notification-job/detail',
        new RouteRequestModel(this.todo, this.notificationJobId)
      )
      .subscribe(notificationJob => {
        if (notificationJob != null) {
          this.notificationJob = notificationJob;
          this.notifJobBody = this.sanitizer.bypassSecurityTrustHtml(
            this.notificationJob.body
          );
          const {
            id,
            receiver,
            cc,
            subject,
            body,
            logDate,
            status,
            notificationType
          } = this.notificationJob;
          this.formGroup.patchValue({
            id,
            receiver,
            cc,
            subject,
            body,
            logDate,
            status,
            notificationType: notificationType.name
          });
        }
        this.setStateReady();
      });
  }

  public doSend(): void {
    this.setStateProcessing();
    this.btnDisabled = true;
    this.httpClientService
      .post<Response<string>>('/notification-job/resend', this.notificationJob)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccess(response.statusText);
        } else {
          this.global.alertService.showError(response.statusText);
        }
        this.setStateReady();
        this.btnDisabled = false;
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/notification-job']);
  }
}
