import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Approval } from '../../core/bean/approval';
import { ApprovalPathModel } from '../../core/components/app-approval-path';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { ArrayUtils } from '../../core/utils';
import { WorkflowModel } from './../../core/bean/workflow-model';

@Component({
  templateUrl: './approval-workflow-edit-add.component.html'
})
export class ApprovalWorkflowEditAddComponent extends BaseModuleComponent {
  public workflowModel: WorkflowModel;
  public approvalList: Approval[] = [];
  public approvalPath: ApprovalPathModel<any> = new ApprovalPathModel(
    this.moduleCode,
    'READ',
    false
  );
  public approvalOptionList: OptionListModel<Approval> = new OptionListModel();
  constructor(translateService: TranslateService) {
    super('approval-workflow', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.workflowModel = this.global.routerParams.get('workflowModel');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      approval: [null]
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<Approval[]>(
        '/approval-workflow/add-edit',
        new RouteRequestModel(this.todo, this.workflowModel.id)
      )
      .subscribe((approvalList: Approval[]) => {
        approvalList = ArrayUtils.sortArray(approvalList, 'name');

        this.approvalList = approvalList != undefined ? approvalList : [];
        if (this.workflowModel.approval != null) {
          const indexApproval = this.approvalList.findIndex(
            approv => approv.id === this.workflowModel.approval.id
          );
          if (indexApproval !== -1) {
            const approval = this.approvalList[indexApproval];
            this.formGroup.patchValue({ approval });
            this.approvalPath
              .setApproverList(approval.approvalModelList)
              .setType(approval.type);
          }
        }
        this.approvalOptionList.setRequestValues(this.approvalList);
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.workflowModel.approval = this.formGroup.get('approval').value;
            this.httpClientService
              .post<Response<WorkflowModel>>(
                '/approval-workflow/update',
                this.workflowModel
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/approval-workflow/detail']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/approval-workflow/detail']);
  }

  public onChangeApproval(approval: Approval): void {
    if (approval !== null) {
      this.approvalPath
        .setApproverList(approval.approvalModelList)
        .setType(approval.type);
    } else {
      this.approvalPath.reset();
    }
  }
}
