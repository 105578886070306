/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\table.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 10:51:52 am

 *
*
 */
import { NgModule } from '@angular/core';
import { AppTableModule } from './components/app-table/app-table.module';
@NgModule({
  imports: [AppTableModule],
  exports: [AppTableModule]
})
export class TableModule {}
