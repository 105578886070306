
import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppAccordionModule } from '../../../core/components/app-accordion/app-accordion.module';
import { AppButtonModule } from '../../../core/components/app-button/app-button.module';
import { AppPopupModule } from '../../../core/components/app-popup/app-popup.module';
import { AppRatingModule } from '../../../core/components/app-rating/app-rating.module';
import { AppSelectModule } from '../../../core/components/app-select/app-select.module';
import { TableModule } from '../../../core/components/table/table.module';
import { VendorModule } from '../../../core/vendor/vendor.module';
import { WidgetCardModule } from './components/widget-card/widget-card.module';
import { WidgetActivityHistoryModule } from './modules/widget-activity-history/widget-activity-history.module';
import { WidgetAdminOprWorklistModule } from './modules/widget-admin-opr-worklist/widget-admin-opr-worklist.module';
import { WidgetLoginInformationModule } from './modules/widget-login-information/widget-login-information.module';
import { WidgetTaskActivityModule } from './modules/widget-task-activity/widget-task-activity.module';
import { WidgetWaitingApprovalModule } from './modules/widget-waiting-approval/widget-waiting-approval.module';
import { PopupAddWidgetComponent } from './popup-add-widget.component';
import { WidgetComponent } from './widget.component';
import { WidgetDirective } from './widget.directive';
import { WidgetService } from './widget.service';
@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppSelectModule,
    AppButtonModule,
    AppAccordionModule,
    AppRatingModule,
    PerfectScrollbarModule,
    TableModule,
    WidgetActivityHistoryModule,
    WidgetAdminOprWorklistModule,
    WidgetLoginInformationModule,
    WidgetTaskActivityModule,
    WidgetWaitingApprovalModule
    
  ],
  declarations: [
    WidgetComponent,
    WidgetDirective,
    PopupAddWidgetComponent
  
  ],
  providers: [WidgetService],
  exports: [WidgetCardModule,  WidgetComponent]
})
export class WidgetModule {}
