/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\hyperlink-plugin\hyperlink-plugin.component.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 11:08:11 pm

 *
*
 */

import { Component, ViewEncapsulation } from '@angular/core';
import { BaseTableFormPluginComponent } from '../../base/base-table-form-plugin.component';
import { HyperlinkPlugin } from '../../interface/available-table-plugin';

@Component({
  templateUrl: './hyperlink-plugin.component.html',
  styleUrls: ['./hyperlink-plugin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableHyperlinkPluginComponent extends BaseTableFormPluginComponent {
  public hyperlinkPlugin: HyperlinkPlugin;
  constructor() {
    super();
  }

  protected onInitBaseTableForm(): void {
    this.setInitializationPluginState();
  }

  private setInitializationPluginState(): void {
    this.hyperlinkPlugin = this.tablePluginData.plugin as HyperlinkPlugin;
    this.tablePluginData.column.value =
      this.hyperlinkPlugin.customText || this.tablePluginData.value;
  }
}
