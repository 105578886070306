import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ResourceModel } from 'src/app/core/model/resource.model';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { FileUploader } from '../../../core/components/upload';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { AppLoadService } from '../../../core/services/app-load.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { Validators } from '../../../core/validators';
import { Resource } from './../../../core/bean/resource';
@Component({
  templateUrl: './logo-edit-add.component.html'
})
export class LogoEditAddComponent extends BaseModuleComponent {
  public appLoadService: AppLoadService;
  public fileUploaderList: FileUploader[] = new Array();
  public isAllUploaded = false;
  constructor(
    translateService: TranslateService,
    appLoadService: AppLoadService,
    public authenticationService: AuthenticationService
  ) {
    super('logo', translateService);
    this.appLoadService = appLoadService;
  }

  public onInit(): void {
    this.doSetTodoFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetTodoFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      resourceList: this.formBuilder.array([])
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<ResourceModel[]>('/logo/add-edit', {})
      .subscribe(resourceDTOList => {
        const control = this.formGroup.controls.resourceList as FormArray;
        resourceDTOList.forEach(
          (resourceModel: ResourceModel, index: number) => {
            const { id, code, name, height, width } = resourceModel.resource;

            if (code === this.global.appConstant.core.RESOURCE_LOGO_BIG) {
              this.fileUploaderList[index] = new FileUploader(
                '/logo/',
                '',
                this.global.appConstant.fileType.IMG_MAIN_LOGO
              );
            } else if (
              code === this.global.appConstant.core.RESOURCE_LOGO_SMALL
            ) {
              this.fileUploaderList[index] = new FileUploader(
                '/logo/',
                '',
                this.global.appConstant.fileType.IMG_SMALL_LOGO
              );
            } else if (
              code === this.global.appConstant.core.RESOURCE_LOGO_REPORT
            ) {
              this.fileUploaderList[index] = new FileUploader(
                '/logo/',
                '',
                this.global.appConstant.fileType.IMG_REPORT_LOGO
              );
            }

            this.fileUploaderList[index].setFile(
              resourceModel.resource.imgFile
            );

            control.push(
              this.formBuilder.group({
                id: [id],
                code: [code],
                name: [name],
                height: [height],
                width: [width],
                file: [
                  this.fileUploaderList[index].fileObjectList,
                  Validators.required()
                ]
              })
            );
          }
        );
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const resourceModelList: Array<ResourceModel> =
              this.getResourceModelList();
            this.httpClientService
              .post<Response<Resource>>('/logo/update', resourceModelList)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.appLoadService.getBackendConfig().then(() => {
                    this.router.navigate(['/pages/content']);

                    this.authenticationService.loadLogo();
                  });
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public getResourceModelList(): Array<ResourceModel> {
    return this.formGroup.value.resourceList.map(
      (resource: Resource, index: number) => {
        const resourceModel: ResourceModel = new ResourceModel();
        resourceModel.resource = resource;
        resourceModel.fileObjectList =
          this.fileUploaderList[index].fileObjectList;
        return resourceModel;
      }
    );
  }

  public doCancel(): void {
    this.router.navigate(['/pages/content']);
  }

  public get formArrayResourceList(): FormArray {
    return this.formGroup.get('resourceList') as FormArray;
  }
}
