/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\count-plugin\count-plugin.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 3:49:10 pm

 *
*
 */

import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../vendor/vendor.module';
import { AppCountModule } from '../../../app-count/app-count.module';
import { TableCountPluginComponent } from './count-plugin.component';
@NgModule({
  imports: [VendorModule, AppCountModule],
  declarations: [TableCountPluginComponent],
  exports: [TableCountPluginComponent]
})
export class TableCountPluginModule {}
