/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\directives\table-directives.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 5:03:28 pm

 *
*
 */

import { NgModule } from '@angular/core';
import { TableRowDirective } from './table-row.directive';
import { TableTdDirective } from './table-td.directive';
import { TableThDirective } from './table-th.directive';
@NgModule({
  declarations: [TableTdDirective, TableRowDirective, TableThDirective],
  exports: [TableTdDirective, TableRowDirective, TableThDirective]
})
export class TableDirectiveModule {}
