import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { FileType } from '../../core/bean/file-type';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './file-type.component.html'
})
export class FileTypeComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public tableResponse: TableResponseModel<FileType>;
  constructor(translateService: TranslateService) {
    super('document-type', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: '0.code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      {
        field: '1',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      },
      {
        field: '0.fileExtension',
        header: 'table.column.fileExtension',
        customClass: 'text-left'
      },
      {
        field: '2',
        header: 'table.column.maxSize',
        customClass: 'text-center',
        fn: this.addMb
      }
    ]);
  }

  public addMb(record): string {
    return record.toString() + 'Mb';
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/document-type/add']);
  }

  public doEdit(rowData: any): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('fileTypeId', rowData[0].id);
    this.router.navigate(['/pages/document-type/edit']);
  }

  public doImport(): void {
    this.log.debug('Feature not Available yet');
  }

  public doDelete(rowData: any): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.deleteTranslationKey(rowData[0]);
        }
      });
  }

  public deleteTranslationKey(fileType: FileType): void {
    this.httpClientService
      .post<Response<FileType>>('/document-type/delete', fileType)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccessDelete();
          this.table.reload();
        } else {
          this.global.alertService.showError(response.statusText);
        }
      });
  }
}
