import { BaseDTO } from './../base/base-dto';
export class AuthenticationDTO extends BaseDTO {
  auth: string;
  captcha: string;
  token: string;
  sessionId: string;
  roleCode: string;
  isDontAskMeAgain: boolean;
  langCode: string;
}
