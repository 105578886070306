
<div class="app-accordion" [ngClass]="'app-accordion-' + (theme | lowercase)">
  <ng-container
    *ngTemplateOutlet="isLoading ? loadingTemplate : defaultTemplate"
  ></ng-container>

  <ng-template #loadingTemplate>
    <div class="d-flex align-item-center justify-content-center py-4">
      <div class="promise-loading">
        <div class="loading"></div>
      </div>
    </div>
  </ng-template>

  <ng-template #defaultTemplate>
    <div
      class="button-group button-group-right button-group-icon-text mb-3 pr-3"
      *ngIf="isShowButtonExpandCollapse"
    >
      <app-button color="SECONDARY" (onClick)="doExpandAll(false)">
        <em class="fas fa-compress-alt"></em
        >{{ 'app.button.collapse' | translate }}
      </app-button>
      <app-button (onClick)="doExpandAll(true)">
        <em class="fas fa-expand-alt"></em>{{ 'app.button.expand' | translate }}
      </app-button>
    </div>
    <ng-container
      *ngIf="model; then modelTemplate; else defaultTemplate"
    ></ng-container>

    <ng-template #modelTemplate>
      <app-accordion-item
        *ngFor="let accordionItem of model.accordionItemList; let i = index"
        [model]="accordionItem"
        [header]="accordionItem.header"
        [isLoading]="accordionItem.isLoading && !accordionItem.isLoaded"
        [isExpanded]="accordionItem.isExpanded"
        [customClass]="customClass"
        [customClassHeader]="customClassHeader"
        [customClassBody]="customClassBody"
      >
        <div
          class="accordion-item-content"
          [model]="accordionItem"
          *ngIf="accordionItem.isExpanded || accordionItem.isLoaded"
          [selector]="accordionItem.selector"
        >
        </div>
      </app-accordion-item>
    </ng-template>

    <ng-template #defaultTemplate>
      <ng-content select="app-accordion-item[header]"></ng-content>
      <ng-content></ng-content>
    </ng-template>
  </ng-template>
</div>
