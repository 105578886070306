import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupCoaComponent } from './app-popup-coa.component';
import { AppPopupCoaService } from './app-popup-coa.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppTableModule, AppButtonModule],
  declarations: [AppPopupCoaComponent],
  entryComponents: [AppPopupCoaComponent],
  providers: [AppPopupCoaService],
  exports: [AppPopupCoaComponent]
})
export class AppPopupCoaModule {}
