/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\icon-plugin\incon-plugin.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Wednesday, September 15th 2021, 11:57:07 am

 *
*
 */
import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../vendor/vendor.module';
import { TableIconPluginComponent } from './icon-plugin.component';
@NgModule({
  imports: [VendorModule],
  declarations: [TableIconPluginComponent],
  exports: [TableIconPluginComponent]
})
export class TableIconPluginModule {}
