import {
  Component,
  ElementRef,
  Optional,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
@Component({
  selector: 'app-npwp',
  templateUrl: './app-npwp.component.html',
  styleUrls: ['./app-npwp.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: makeProvider(AppNpwpComponent)
})
export class AppNpwpComponent extends BaseValueAccessorComponent<object> {
  @ViewChild('npwpLabelText') npwpLabelText: ElementRef<object>;
  dataPlaceholder = '00.000.000.0-000.000';
  npwpInputValue = '';
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public renderer2: Renderer2
  ) {
    super('app-npwp', controlContainer, elementRef);
  }

  public onInitBaseValueAccessor(): void {
    this.setNPWPInputValue();
    this.setDataPlaceholder();
  }

  setNPWPInputValue(): void {
    if (this.formControl.value) {
      this.npwpInputValue = this.numberToNpwp(
        this.npwpToNumber(this.formControl.value)
      );
    }
  }

  setDataPlaceholder(): void {
    if (this.disabled) {
      this.dataPlaceholder = '';
    } else {
      const newDataPlaceholder = this.dataPlaceholder.slice(
        +this.npwpInputValue.length,
        this.dataPlaceholder.length
      );
      this.dataPlaceholder = this.npwpInputValue + newDataPlaceholder;
    }
  }

  handleNpwpInput(event: any): void {
    const inputValueLength = event.target.value.length;
    const lastInputIsNumber = !isNaN(Number(event.data));
    if (event.inputType === 'insertText') {
      const nextInputIsNumber = Number(this.dataPlaceholder[inputValueLength]);
      if (lastInputIsNumber) {
        if (
          inputValueLength < this.dataPlaceholder.length &&
          isNaN(nextInputIsNumber)
        ) {
          event.target.value =
            event.target.value + this.dataPlaceholder[inputValueLength];
        }
        this.setNpwpInputValue(event);
      } else {
        this.setNpwpInputValue(event);
      }
    } else if (event.inputType === 'deleteContentBackward') {
      this.setNpwpInputValue(event);
    } else if (event.inputType === 'deleteContentForward') {
      this.setNpwpInputValue(event);
    } else if (event.inputType === 'insertFromPaste') {
      this.setNpwpInputValue(event);
      this.formControl.patchValue(event.target.value);
    }
    const newDataPlaceholder = this.dataPlaceholder.slice(
      event.target.value.length,
      this.dataPlaceholder.length
    );
    this.renderer2.setAttribute(
      this.npwpLabelText.nativeElement,
      'data-placeholder',
      event.target.value + newDataPlaceholder
    );
  }

  setNpwpInputValue(event: any): void {
    if (event.target.value) {
      event.target.value = this.numberToNpwp(
        this.npwpToNumber(event.target.value)
      );
    }
  }

  npwpToNumber(npwp: string): string {
    return npwp
      .split('')
      .filter(n => !isNaN(+n))
      .join('');
  }

  numberToNpwp(num: string): string {
    let arrayNpwp = num.slice(0, 15).replace(/\./g, '').split('');
    for (let i = 0; i < this.dataPlaceholder.length; i++) {
      if (i < arrayNpwp.length) {
        const isNumber = !isNaN(Number(this.dataPlaceholder[i]));
        if (!isNumber) {
          arrayNpwp = [
            ...arrayNpwp.slice(0, i),
            this.dataPlaceholder[i],
            ...arrayNpwp.slice(i)
          ];
        }
      }
    }
    return arrayNpwp.join().replace(/,/g, '');
  }

  handleChange(event: any): void {
    this.onChange.emit(event);
  }

  public onKeyUp(event: KeyboardEvent): void {
    this.log.debug(event);
  }

  public onKeyDown(event: KeyboardEvent): void {
    this.log.debug(event);
  }
}
