import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Organization } from 'src/app/core/bean/organization';
import { FileObject, FileUploader } from 'src/app/core/components/upload';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Response } from '../../core/model/response-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { AuthenticationService } from '../../core/services/authentication.service';
import { OrganizationRequest } from './organization-request';
import { OrganizationResponse } from './organization-response';
@Component({
  templateUrl: './organization-edit-add.component.html'
})
export class OrganizationEditAddComponent extends BaseModuleComponent {
  public organizationId: number;
  public parentIdAdd: number;
  public hasChild = false;
  public isEdit = false;

  public togglePrimaryColor = false;
  public toggleColorOnPrimaryColor = false;
  public togglePrimaryDarkColor = false;
  public toggleColorOnPrimaryDarkColor = false;
  public toggleTableHeaderColor = false;
  public toggleColorOnTableHeaderColor = false;
  public toggleTableBodyColor = false;
  public toggleColorOnTableBodyColor = false;
  public toggleTextColor = false;

  public mainLogoUploader = new FileUploader(
    '/organization/',
    '',
    this.global.appConstant.fileType.IMG_MAIN_LOGO,
    true,
    1
  );
  public smallLogoUploader = new FileUploader(
    '/organization/',
    '',
    this.global.appConstant.fileType.IMG_SMALL_LOGO,
    true,
    1
  );
  public reportLogoUploader = new FileUploader(
    '/organization/',
    '',
    this.global.appConstant.fileType.IMG_REPORT_LOGO,
    true,
    1
  );
  public organizationRequestSave: OrganizationRequest;
  public organizationResponseTemp: OrganizationResponse;
  public zoomPercentage: number;

  primaryColor: string;
  colorOnPrimaryColor: string;
  primaryDarkColor: string;
  colorOnPrimaryDarkColor: string;
  tableHeaderColor: string;
  colorOnTableHeaderColor: string;
  tableBodyColor: string;
  colorOnTableBodyColor: string;
  textColor: string;

  constructor(
    translateService: TranslateService,
    public authenticationService: AuthenticationService
  ) {
    super('organization', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setInitializationState();
    this.doBuildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.organizationId = this.global.routerParams.get('organizationId');
    this.parentIdAdd = this.global.routerParams.get('parentIdAdd');
    if (this.todo === 'edit') {
      this.isEdit = true;
    }
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.minLength(4),
          Validators.maxLength(32)
        ])
      ],
      name: [null, Validators.compose([Validators.required()])],
      hierarchyLevel: [null],
      letterNumberCode: [null],
      mainLogoFile: [null],
      smallLogoFile: [null],
      reportLogoFile: [null],
      tempId: [null],
      parentId: [null],
      isHasChild: [null],
      primaryColor: [null],
      colorOnPrimaryColor: [null],
      primaryDarkColor: [null],
      colorOnPrimaryDarkColor: [null],
      tableHeaderColor: [null],
      colorOnTableHeaderColor: [null],
      tableBodyColor: [null],
      colorOnTableBodyColor: [null]
      // textColor: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<OrganizationResponse>(
        '/organization/add-edit',
        new RouteRequestModel(this.todo, this.organizationId)
      )
      .subscribe(organizationResponse => {
        this.organizationResponseTemp = organizationResponse;
        if (organizationResponse.organization != null) {
          if (organizationResponse.organization.isHasChild != null) {
            this.hasChild = organizationResponse.organization.isHasChild;
          }
          if (organizationResponse.organization.mainLogoFile != null) {
            this.mainLogoUploader.setFile(
              organizationResponse.organization.mainLogoFile
            );
          }
          if (organizationResponse.organization.smallLogoFile != null) {
            this.smallLogoUploader.setFile(
              organizationResponse.organization.smallLogoFile
            );
          }
          if (organizationResponse.organization.reportLogoFile != null) {
            this.reportLogoUploader.setFile(
              organizationResponse.organization.reportLogoFile
            );
          }
          this.formGroup.patchValue({
            id: organizationResponse.organization.id,
            code: organizationResponse.organization.code,
            name: organizationResponse.organization.name,
            parentId: organizationResponse.organization.parentId,
            level: organizationResponse.organization.level,
            hierarchyLevel: organizationResponse.organization.hierarchyLevel,
            letterNumberCode:
              organizationResponse.organization.letterNumberCode,
            mainLogoFile: this.mainLogoUploader.fileObjectList,
            smallLogoFile: this.smallLogoUploader.fileObjectList,
            reportLogoFile: this.reportLogoUploader.fileObjectList,
            hasChild: organizationResponse.organization.hasChild
          });
        }
        this.setColor(false);
        this.setStateReady();
      });
  }

  public doResetColor(): void {
    this.setColor(true);
  }

  public setColor(isReset: boolean): void {
    if (isReset) {
      this.primaryColor = this.organizationResponseTemp.defaultPrimaryColor;
      this.colorOnPrimaryColor =
        this.organizationResponseTemp.defaultColorOnPrimaryColor;
      this.primaryDarkColor =
        this.organizationResponseTemp.defaultPrimaryDarkColor;
      this.colorOnPrimaryDarkColor =
        this.organizationResponseTemp.defaultColorOnPrimaryDarkColor;
      this.tableHeaderColor =
        this.organizationResponseTemp.defaultTableHeaderColor;
      this.colorOnTableHeaderColor =
        this.organizationResponseTemp.defaultColorOnTableHeaderColor;
      this.tableBodyColor = this.organizationResponseTemp.defaultTableBodyColor;
      this.colorOnTableBodyColor =
        this.organizationResponseTemp.defaultColorOnTableBodyColor;
      this.textColor = this.organizationResponseTemp.defaultTextColor;
    } else {
      this.primaryColor = this.organizationResponseTemp.primaryColor;
      this.colorOnPrimaryColor =
        this.organizationResponseTemp.colorOnPrimaryColor;
      this.primaryDarkColor = this.organizationResponseTemp.primaryDarkColor;
      this.colorOnPrimaryDarkColor =
        this.organizationResponseTemp.colorOnPrimaryDarkColor;
      this.tableHeaderColor = this.organizationResponseTemp.tableHeaderColor;
      this.colorOnTableHeaderColor =
        this.organizationResponseTemp.colorOnTableHeaderColor;
      this.tableBodyColor = this.organizationResponseTemp.tableBodyColor;
      this.colorOnTableBodyColor =
        this.organizationResponseTemp.colorOnTableBodyColor;
      this.textColor = this.organizationResponseTemp.textColor;
    }

    this.formGroup.patchValue({
      primaryColor: this.primaryColor,
      colorOnPrimaryColor: this.colorOnPrimaryColor,
      primaryDarkColor: this.primaryDarkColor,
      colorOnPrimaryDarkColor: this.colorOnPrimaryDarkColor,
      tableHeaderColor: this.tableHeaderColor,
      colorOnTableHeaderColor: this.colorOnTableHeaderColor,
      tableBodyColor: this.tableBodyColor,
      colorOnTableBodyColor: this.colorOnTableBodyColor,
      textColor: this.textColor
    });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .newSaveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const organizationRequest: OrganizationRequest =
              this.prepareOrganizationRequestResponse();
            const url =
              this.todo === 'edit'
                ? '/organization/update'
                : '/organization/insert';
            this.httpClientService
              .post<Response<OrganizationRequest>>(url, organizationRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService.saveSuccessWithoutButton();
                  this.router.navigate(['/pages/organization/']);
                  this.authenticationService.loadLogo();
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doDelete(): void {
    this.global.modalService
      .newDeleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<number>('/organization/delete', this.organizationId)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.modalService.confirmation(
                  'organization.confirmation.deleteSuccess.prompt',
                  'organization.confirmation.deleteSuccess.title',
                  null,
                  null,
                  'pvc pv-trash',
                  false,
                  {
                    autoCloseInMs: 1000
                  }
                );
                this.router.navigate(['/pages/organization/']);
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/organization/']);
  }

  public prepareOrganizationRequestResponse(): OrganizationRequest {
    const organizationRequest: OrganizationRequest = new OrganizationRequest();
    const organization: Organization = new Organization();
    if (this.todo === 'edit') {
      organization.id = this.organizationId;
    }
    organization.code = this.formGroup.value.code;
    organization.name = this.formGroup.value.name;
    organization.parentId = this.parentIdAdd;
    organizationRequest.organization = organization;

    const fileObjectList: FileObject[] = [];
    const mainLogoFileObjectList = this.formGroup.value.mainLogoFile;
    const smallLogoFileObjectList = this.formGroup.value.smallLogoFile;
    const reportLogoFileObjectList = this.formGroup.value.reportLogoFile;
    if (mainLogoFileObjectList != null) {
      fileObjectList.push(mainLogoFileObjectList[0]);
    }
    if (smallLogoFileObjectList != null) {
      fileObjectList.push(smallLogoFileObjectList[0]);
    }
    if (reportLogoFileObjectList != null) {
      fileObjectList.push(reportLogoFileObjectList[0]);
    }
    organizationRequest.fileObjectList = fileObjectList;

    organizationRequest.primaryColor = this.formGroup.value.primaryColor;
    organizationRequest.colorOnPrimaryColor =
      this.formGroup.value.colorOnPrimaryColor;
    organizationRequest.primaryDarkColor =
      this.formGroup.value.primaryDarkColor;
    organizationRequest.colorOnPrimaryDarkColor =
      this.formGroup.value.colorOnPrimaryDarkColor;
    organizationRequest.tableHeaderColor =
      this.formGroup.value.tableHeaderColor;
    organizationRequest.colorOnTableHeaderColor =
      this.formGroup.value.colorOnTableHeaderColor;
    organizationRequest.tableBodyColor = this.formGroup.value.tableBodyColor;
    organizationRequest.colorOnTableBodyColor =
      this.formGroup.value.colorOnTableBodyColor;
    // organizationRequest.textColor = this.formGroup.value.textColor;

    return organizationRequest;
  }

  public updatePrimaryColor(event: any): void {
    this.formGroup.get('primaryColor').setValue(event);
  }

  public updateColorOnPrimary(event: any): void {
    this.formGroup.get('colorOnPrimaryColor').setValue(event);
  }

  public updatePrimaryDarkColor(event: any): void {
    this.formGroup.get('primaryDarkColor').setValue(event);
  }

  public updateColorOnPrimaryDark(event: any): void {
    this.formGroup.get('colorOnPrimaryDarkColor').setValue(event);
  }

  public updateTableHeaderColor(event: any): void {
    this.formGroup.get('tableHeaderColor').setValue(event);
  }

  public updateOnTableHeaderColor(event: any): void {
    this.formGroup.get('colorOnTableHeaderColor').setValue(event);
  }

  public updateTableBodyColor(event: any): void {
    this.formGroup.get('tableBodyColor').setValue(event);
  }

  public updateColorOnTableBody(event: any): void {
    this.formGroup.get('colorOnTableBodyColor').setValue(event);
  }

  // public updateTextColor(event: any) {
  //   this.formGroup.get("textColor").setValue(event);
  // }

  public doZoom(command: 'in' | 'out'): void {
    if (
      (this.zoomPercentage > 0 && command === 'out') ||
      (this.zoomPercentage < 200 && command === 'in')
    ) {
      this.zoomPercentage =
        command === 'in' ? this.zoomPercentage + 25 : this.zoomPercentage - 25;
      // const scale = this.zoomPercentage / 100;
      // this.treeElement.style.transform = `scale(${scale})`;
    }
  }

  public doReset(): void {
    this.zoomPercentage = 100;
    // this.treeElement.style.transform = 'scale(1)';
  }

  private setInitializationState(): void {
    this.zoomPercentage = 100;
  }
}
