import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { MVCGeneratorComponent } from './mvc-generator.component';
export const routes = [
  {
    path: '',
    component: MVCGeneratorComponent,
    data: { breadcrumb: '' },
    canDeactivate: [ConfirmGuard]
   }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [MVCGeneratorComponent],
  providers: []
})
export class MVCGeneratorModule {}
