/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\domain\table-record-temp.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 2:33:21 pm

 *
*
 */
export class TableRecordTemp {
  recordMap: Map<string, any> = new Map();
  public getRecordList(): Array<any> {
    const recordList = new Array();
    this.recordMap.forEach(value => recordList.push(value));
    return recordList;
  }
}
