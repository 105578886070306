/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\badge-plugin\badge-plugin.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 2:47:01 pm

 *
*
 */

import { NgModule } from '@angular/core';
import { PipesModule } from '../../../../pipe/pipe.module';
import { VendorModule } from '../../../../vendor/vendor.module';
import { AppBadgeModule } from '../../../app-badge/app-badge.module';
import { TableBadgePluginComponent } from './badge-plugin.component';
@NgModule({
  imports: [VendorModule, AppBadgeModule, PipesModule],
  declarations: [TableBadgePluginComponent],
  exports: [TableBadgePluginComponent]
})
export class TableBadgePluginModule {}
