
import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardComponent } from './widget-card.component';
@NgModule({
  imports: [VendorModule, PerfectScrollbarModule],
  declarations: [WidgetCardComponent],
  exports: [WidgetCardComponent]
})
export class WidgetCardModule { }
