/*
 * Filename:  WebContent\src\app\core\components\app-table-x\action\get-record-childrens-action.ts
 * Path:  WebContent
 * Created Date: Tuesday, August 10th 2021, 2:41:40 pm

 *
*
 */
import { resolveTableRecordChildrens } from '../helpers/resolve-table-record-childrens-helper';
import { TableRecord } from '../model/table-record';
import { TableState } from '../model/table-state';
export const loadTableRecordChildrens = (
  state: TableState,
  payload: { record: TableRecord }
): void => {
  state.setStateReady();
  payload.record.setStateLoading();
  payload.record.tableRequest.first += 1;
  resolveTableRecordChildrens(state, payload.record).then(
    (value: { recordList: TableRecord[]; totalRecord: number }) => {
      payload.record.childrens.push(...value.recordList);
      payload.record.isShowButtonLoadMore =
        payload.record.childrens.length < value.totalRecord;
      payload.record.setStateReady();
    }
  );
};
