import { BaseEntity } from "../base/base-entity";
import { FinesMaster } from "../bean/fines-master";

export class FinesEntity extends BaseEntity {
    finesMaster: FinesMaster;
    objectName: string;
    objectId: number;
    amount: number;
    percentageValue: number;
    description: string;
}