/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\modules\widget-admin-opr-worklist\widget-admin-opr-worklist.component.ts
 
 * Created Date: Wednesday, November 24th 2021, 3:03:23 pm

 *
*
 */

import { Component } from '@angular/core';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetAdminOprWorklistResponseDTO } from './widget-admin-opr-worklist-response-dto';
@Component({
  templateUrl: './widget-admin-opr-worklist.component.html'
})
export class WidgetAdminOprWorklistComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-admin-opr-worklist';
  public adminOperationWorklistList: Array<WidgetAdminOprWorklistResponseDTO>;
  constructor() {
    super(WidgetAdminOprWorklistComponent.moduleCode);
  }

  protected onInit(): void {
    this.httpClientService
      .get('/widget-admin-opr-worklist/index')
      .subscribe((response: Array<WidgetAdminOprWorklistResponseDTO>) => {
        this.adminOperationWorklistList = response;
        this.setStateReady();
      });
  }

  public doNavigateToDetails(url: string, code: string): void {
    this.global.routerParams.set('vendorActivityStatusCodeFromWidget', code);
    this.router.navigate([url]);
  }
}
