<ol class="breadcrumb float-sm-right" >
  <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs; let i = index"  [ngClass]="{ 'active': i==(breadcrumbs.length-1)}" >
    <a *ngIf="i!=(breadcrumbs.length-1)" [routerLink]="[breadcrumb.url]">
      {{ breadcrumb.name | translate }}
    </a>
    <span *ngIf="i==(breadcrumbs.length-1)" >
      {{ breadcrumb.name | translate }}
    </span>
  </li>
</ol>
