import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import * as L from 'leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { AppMapService } from './app-map.service';
import { MapResponseModel } from './model/map-response-model';
@Component({
  selector: 'app-map',
  templateUrl: './app-map.component.html',
  styleUrls: ['./app-map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppMapComponent
  extends BaseComponentComponent
  implements AfterViewInit
{
  @Input() model: MapResponseModel;
  @Output() onChange: EventEmitter<MapResponseModel> =
    new EventEmitter<MapResponseModel>();
  map: any;
  provider = new OpenStreetMapProvider();
  searchControl: GeoSearchControl;
  currentMarker: any;
  mapMarkerIcon = L.icon({
    iconUrl:
      'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
    iconRetinaUrl:
      'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
    shadowUrl:
      'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
    shadowRetinaUrl:
      'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
    iconSize: [32, 32],
    shadowSize: [0, 0]
  });

  constructor(public appMapService: AppMapService) {
    super('app-map');
  }

  public onInit(): void {
    this.setStateReady();
  }

  ngAfterViewInit(): void {
    if (!this.model.isServerSide) {
      this.reload();
    } else {
      this.model.valueChanges.subscribe(() => {
        this.reload();
      });
    }
  }

  reload(): void {
    setTimeout(() => {
      this.setMap();
      this.setSearchControl();
      this.setTileLayer();
      if (!this.model.isViewOnly) {
        this.handleEvent();
      }
    });
  }

  setMap(): void {
    const zoom = this.model.isViewOnly ? 15 : this.model.zoom;
    this.map = L.map('app-map', {
      center: this.model.getCurrentPosition(),
      zoom: +zoom,
      minZoom: 0,
      maxZoom: 20,
      zoomAnimation: true
    });
    this.map.panTo({
      lat: this.model.getCurrentPosition()[0],
      lng: this.model.getCurrentPosition()[1]
    });
    L.control.scale().addTo(this.map);
    this.map.flyTo(this.model.getCurrentPosition(), +zoom, { animate: true });
    this.addMarker();
  }

  setTileLayer(): void {
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
  }

  setSearchControl(): void {
    if (!this.model.isViewOnly) {
      this.searchControl = new GeoSearchControl({
        provider: this.provider,
        showMarker: true,
        style: 'bar',
        marker: {
          icon: new L.Icon.Default(),
          draggable: false
        },
        autoComplete: true,
        autoCompleteDelay: 250,
        autoClose: true,
        searchLabel: this.global.translateService.instant(
          'app-map.placeholder.enterAddress'
        )
      }).addTo(this.map);
    }
  }

  handleEvent(): void {
    this.map.on('click', (event: any) => {
      this.model.originalResource = event;
      this.model.setLatitudeAndLongitude(event.latlng.lat, event.latlng.lng);
      this.addMarker();
    });

    this.map.on('geosearch/showlocation', (event: any) => {
      this.model.originalResource = event;
      this.model.setLatitudeAndLongitude(event.location.y, event.location.x);
      this.addMarker();
    });

    this.currentMarker.on('dragend', (event: any) => {
      this.model.originalResource = event;
      this.model.setLatitudeAndLongitude(
        event.target._latlng.lat,
        event.target._latlng.lng
      );
      this.addMarker();
    });
  }

  addMarker(): void {
    this.removeCurrentMarker();
    this.currentMarker = new L.Marker(this.model.getRequestValue() as any, {
      draggable: true
    })
      .addTo(this.map)
      .bindPopup(
        `<span style="color:#a0a0a0">${this.model.latitude}, ${this.model.longitude}</span>`
      )
      .openPopup();
    this.currentMarker.setIcon(this.mapMarkerIcon);
    this.map.addLayer(this.currentMarker);
  }

  removeCurrentMarker(): void {
    if (this.currentMarker) {
      this.map.removeLayer(this.currentMarker);
    }
  }

  doSave(): void {
    this.onChange.emit(this.model);
  }
}
