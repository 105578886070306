<div class="app-widget-performance-overall">
  <app-widget-card-x
    header="{{ 'app-widget-performance-overall.title' | translate }}"
    customClassBody="mt-0"
    customClassHeader="border-bottom-0 pb-1"
    size="SM"
    [isLoading]="formLoading"
  >
    <div *ngIf="performanceOverall === null">
      <p>{{
        'app-widget-performance-overall.sorryYourRoleCannotUseThisWidget'
          | translate
      }}</p>
    </div>
    <div class="mt-2" *ngIf="performanceOverall !== null">
      <app-rating
        size="XL"
        [value]="performanceOverall"
        [isViewOnly]="true"
      ></app-rating>
    </div>
  </app-widget-card-x>
</div>
