/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\action\add-widget-action.ts
 
 * Created Date: Thursday, November 18th 2021, 11:33:14 am

 *
*
 */
import { UserDashboardWidget } from '../../../../core/bean/user-dashboard-widget';
import { AppPopupService } from '../../../../core/components/app-popup/app-popup.service';
import { WidgetItem } from '../domain/widget-item';
import { WidgetState } from '../domain/widget-state';
import { PopupAddWidgetComponent } from '../popup-add-widget.component';
export const addWidgetAction = (state: WidgetState): void => {
  const appPopupService = state.injector.get(AppPopupService);
  appPopupService
    .open(PopupAddWidgetComponent, null, {
      windowClass: 'app-popup-widget-add-content',
      size: 'SM'
    })
    .subscribe((widgetList: UserDashboardWidget[]) => {
      state.widgetList.push(
        ...widgetList.map(widget => WidgetItem.create(widget, true))
      );
    });
};
