import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppLazyLoadModule } from '../app-lazy-load/app-lazy-load.module';
import { AppTreeComponent } from './app-tree.component';
import { AppTreePopupModule } from './popup/app-tree-popup.module';
@NgModule({
  imports: [
    VendorModule,
    AppTreePopupModule,
    AppButtonModule,
    AppLazyLoadModule
  ],
  declarations: [AppTreeComponent],
  exports: [AppTreeComponent]
})
export class AppTreeModule {}
