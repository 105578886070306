import { Global } from '../../../global/global';
import { ServiceLocator } from '../../../services/service-locator';
import { Uploader } from '../model/uploader';
import { UploaderFile } from '../model/uploader-file';
import { UploaderFileError } from '../model/uploader-file-error';

export function validateUploaderFile(uploaderFile: UploaderFile): UploaderFile {
  const uploader: Uploader = uploaderFile.parent;
  const maxSize = uploader.options && uploader.options.maxSize;
  const allowedExtension =
    uploader.options && uploader.options.allowedExtension;
  if (!uploaderFile.error && allowedExtension) {
    uploaderFile.error = validateExtension(
      uploaderFile.file.extension,
      allowedExtension
    );
  }

  if (!uploaderFile.error && maxSize) {
    uploaderFile.error = validateMaxSize(uploaderFile.file.size, maxSize);
  }

  if (!uploaderFile.error) {
    uploaderFile.error = validateUnicode(uploaderFile.file.fileName);
  }

  if (!uploaderFile.error) {
    uploaderFile.error = validateMaxLengthUploadFileName(
      uploaderFile.file.fileName,
      uploaderFile.parent.options.maxFileNameLength
    );
  }

  if (uploaderFile.error) {
    uploaderFile.setStatus('INVALID');
  }

  return uploaderFile;
}

export function validateMaxUploadFile(
  uploader: Uploader,
  fileList: FileList
): Uploader {
  if (
    uploader.uploaderFileList.length + fileList.length >
    uploader.options.maxFile
  ) {
    uploader.setError({
      type: 'MAX_FILE_ERROR',
      message: 'app.acceptable.maxFile'
    });
  }
  return uploader;
}

function validateExtension(
  fileExtension: string,
  allowedExtension: string
): UploaderFileError {
  if (
    !allowedExtension.toLowerCase().includes(fileExtension.toLocaleLowerCase())
  ) {
    return { type: 'EXTENSION_ERROR', message: 'app.acceptable.extension' };
  }
}

function validateMaxLengthUploadFileName(
  fileName: string,
  maxFileNameLength: number
): UploaderFileError {
  if (fileName.length > maxFileNameLength) {
    return {
      type: 'MAX_LENGTH_FILE_NAME',
      message: 'app.acceptable.maxLengthFileName'
    };
  }
}

function validateMaxSize(fileSize: number, maxSize: number): UploaderFileError {
  if (fileSize > maxSize) {
    return { type: 'MAX_SIZE_ERROR', message: 'app.acceptable.size' };
  }
}

function validateUnicode(fileName: string): UploaderFileError {
  const listName = fileName.split('.');
  let valid = true;
  if (listName.length > 2) {
    valid = false;
  } else {
    const name = listName[0];
    const global: Global = ServiceLocator.injector.get(Global);
    const regex = new RegExp(
      global.config.parameterModel.uploadValidationUnicode
    );
    const isValidPassword = regex.test(name);
    if (isValidPassword) {
      valid = false;
    }
  }
  if (!valid) {
    return { type: 'UNICODE_ERROR', message: 'app.acceptable.unicode' };
  }
}
