/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\action\dispatch-table-action.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:13:43 am

 *
*
 */
import { TableState } from '../domain/table-state';
import { changeTableFilterPerPageAction } from './change-table-filter-per-page-action';
import { changeTablePageAction } from './change-table-page-action';
import { deleteTableRowAction } from './delete-table-row-action';
import { expandOrCollapseAllTableRow } from './expand-or-collapse-all-table-row-action';
import { expandOrCollapseTableRow } from './expand-or-collapse-table-row-action';
import { firstLoadTableAction } from './first-load-table-action';
import { loadTableRowChildAction } from './load-table-row-child-action';
import { reloadTableAction } from './reload-table-action';
import { searchTableAction } from './search-table-action';
import { selectAllTableRowAction } from './select-all-table-row-action';
import { selectTableRowAction } from './select-table-row-action';
import { setTableHeaderStickyAction } from './set-table-header-sticky-action';
import { sortTableAction } from './sort-table-action';
import * as tableAction from './table-action';
export function dispatchTable(
  state: TableState,
  action: tableAction.AllTableAction
): void {
  switch (action.type) {
    case tableAction.FIRST_LOAD_TABLE:
      firstLoadTableAction(state);
      break;
    case tableAction.SEARCH_TABLE:
      searchTableAction(state, action.payload);
      break;
    case tableAction.SORT_TABLE:
      sortTableAction(state, action.payload);
      break;
    case tableAction.CHANGE_TABLE_PAGE:
      changeTablePageAction(state, action.payload);
      break;
    case tableAction.SET_STICKY_TABLE_HEADER:
      setTableHeaderStickyAction(state, action.payload);
      break;
    case tableAction.DELETE_TABLE_ROW:
      deleteTableRowAction(state);
      break;
    case tableAction.SELECT_TABLE_ROW:
      selectTableRowAction(state, action.payload);
      break;
    case tableAction.SELECT_ALL_TABLE_ROW:
      selectAllTableRowAction(state, action.payload);
      break;
    case tableAction.CHANGE_TABLE_FILTER_PER_PAGE:
      changeTableFilterPerPageAction(state, action.payload);
      break;
    case tableAction.EXPAND_OR_COLLAPSE_TABLE_ROW:
      expandOrCollapseTableRow(state, action.payload);
      break;
    case tableAction.EXPAND_OR_COLLAPSE_ALL_TABLE_ROW:
      expandOrCollapseAllTableRow(state, action.payload);
      break;
    case tableAction.RELOAD_TABLE:
      reloadTableAction(state);
      break;
    case tableAction.LOAD_TABLE_RECORD_CHILD:
      loadTableRowChildAction(state, action.payload);
      break;
    default:
      console.log(`Info: There's no action type`);
      break;
  }
}
