/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\action\expand-or-collapse-all-table-row-action.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:15:11 am

 *
*
 */
import { TableRow } from '../domain/table-row';
import { TableState } from '../domain/table-state';
import { resolveTableRowChildrenList } from '../helper/resolve-table-row-children-list-helper';
export const expandOrCollapseAllTableRow = (
  state: TableState,
  payload: { action: 'EXPAND' | 'COLLAPSE' }
): void => {
  state.setStateReady();
  state.rowList.forEach((tableRow: TableRow) => {
    tableRow.childrenList.length === 0 && tableRow.setStateLoading();
    if (
      (tableRow.isExpanded && payload.action === 'COLLAPSE') ||
      (!tableRow.isExpanded && payload.action === 'EXPAND')
    ) {
      tableRow.isExpanded = !tableRow.isExpanded;
    }
    tableRow.isExpanded &&
      tableRow.childrenList.length === 0 &&
      resolveTableRowChildrenList(state, tableRow).then(
        (value: { rowList: TableRow[]; totalRecord: number }) => {
          tableRow.childrenList = value.rowList;
          tableRow.isShowButtonLoadMore =
            tableRow.childrenList.length < value.totalRecord;
          tableRow.setStateReady();
        }
      );
  });
};
