import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { divExpandedCollapsedAnimation, rotateAnimation } from '../../../../core/animation';
import { MenuService } from '../../services/menu.service';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from '../../services/menu-item.model';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [rotateAnimation(), divExpandedCollapsedAnimation],
  providers: [MenuService]
})
export class SidebarComponent extends BaseModuleComponent {
  @ViewChild('sidebar') sidebar: ElementRef;


  public keyword: string = '';
  public menuItemsMaster: Array<any>;
  public menuItems: Array<any>;
  public menuItemsForSearch: MenuItem[];
  public filteredMenu: MenuItem[];
  constructor(
    public menuService: MenuService, translateService: TranslateService
  ) {
    super('sidebar-left', translateService);
  }
  
  onInit(): void {
    this.formGroup = this.formBuilder.group({ search: [''] });
    this.formGroup.patchValue({ search: '' });
    this.menuItemsMaster = this.menuService.getMenu();
    this.menuItems = this.menuItemsMaster;

    // console.log('menu items = ' + this.global.utils.JSONStringfy(this.menuItemsMaster));


    this.menuItemsForSearch = this.menuService.collectMenuItemsForSearch(this.global, this.menuItems);

    this.addDashboardToLastActiveRouterList();
    this.formGroup.get('search').valueChanges.subscribe(newValue => {


      this.keyword = newValue.trim();
      if (this.keyword.length > 0) {

        this.filteredMenu = this.filterObjectsByTitle(this.menuItemsForSearch, this.keyword);
        //console.log(this.global.utils.JSONStringfy(this.filteredMenu));
        this.menuItems = this.filteredMenu;

      }
      else {
        this.menuItems = this.menuItemsMaster;
      }

    });
    this.setStateReady();
  }

  private addDashboardToLastActiveRouterList(): void {
    this.global.lastActiveRouterList.push(
      this.menuService.activeMenu?.routerLink || '/pages/dashboard'
    );
  }



  public doClickMenuItem(event: PointerEvent, menu: any): void {
    event.preventDefault();
    event.stopPropagation();
    if (menu.routerLink) {
      this.menuService.setActiveMenuToUnactive(this.menuItems);
      this.global.isModuleChange = true;
      this.global.activeModuleCode = (menu as any).moduleCode;
      this.doLoadMenu(menu);
      this.menuService.setExpandedMenu(
        menu.parentId === 0 ? this.menuItems : menu.parent.childrens
      );
      this.menuService.setActiveMenu(menu);
    } else {
      let isExpanded = menu.isExpanded;
      this.menuService.setExpandedMenu(
        menu.parentId === 0 ? this.menuItems : menu.parent.childrens
      );
      menu.isExpanded = !isExpanded;
    }


  }

  public doLoadMenu(menu: any): void {
    if (this.global.config.parameterModel.isRecordMode) {
      this.httpClientService
        .post('/dashboard/menu-info', menu.code)
        .subscribe(() => { });
    }
    this.global.lastActiveRouterList.push(menu.routerLink);
    this.router.navigate([menu.routerLink]);
  }

  // untuk scrolling
  public onMouseMove(event: MouseEvent) {
    if (this.sidebar == null) return;
    const sidebarElement: HTMLElement = this.sidebar.nativeElement;
    const mouseY = event.clientY - sidebarElement.getBoundingClientRect().top;
    sidebarElement.scrollTop = (mouseY / sidebarElement.clientHeight) * (sidebarElement.scrollHeight);
  }


  public filterObjectsByTitle(objects: MenuItem[], keyword: string): MenuItem[] {
    const m: MenuItem[] = objects.filter(obj => obj.title.toLowerCase().includes(keyword.toLowerCase()));
    return m;
  }


  public resetSearchInput() {
    this.formGroup.get('search').setValue('');

  }

  onKeyDown(event: KeyboardEvent) {

    if (event.key === 'Enter') {
      event.preventDefault();
      if ((this.keyword.trim().length > 0) && (this.filteredMenu.length > 0)) {
        this.doLoadMenu(this.filteredMenu[0]);
      }
    }
  }



}
