<div class="app-lazy-load">
  <div class="app-lazy-load-content-wrapper">
    <ng-content></ng-content>
  </div>
  <div *ngIf="scrollMoreTmpl && !isLoading">
    <ng-container *ngTemplateOutlet="scrollMoreTmpl"></ng-container>
  </div>

  <div class="lazy-loading-wrapper" *ngIf="isLoading">
    <div class="lazy-loading-content text-center">
      <div class="lazy-loading"
        ><div></div><div></div><div></div><div></div
      ></div>
      <span class="lazy-loading-text d-block mt-2" *ngIf="loadingText">
        {{ loadingText }}
      </span>
    </div>
  </div>
</div>
