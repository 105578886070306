import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TreeModel } from '../../app-tree';
import { AppPopupService } from '../app-popup.service';
import { AppPopupTreeComponent } from './app-popup-tree.component';

@Injectable()
export class AppPopupTreeOrganizationService {
  constructor(public appPopupService: AppPopupService) {}
  public open(organizationId: number, tenantId?: number): Observable<any> {
    const treeModel: TreeModel = new TreeModel(
      'organization',
      'READ',
      organizationId,
      false,
      tenantId
    );
    return this.appPopupService.open(AppPopupTreeComponent, {
      model: treeModel,
      isLazy: true
    });
  }
}
