/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\portal\reset-password\reset-password.component.scss
 
 * Created Date: Saturday, October 2nd 2021, 12:13:08 pm

 *
*
 */
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { User } from '../../core/bean/user';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';

declare let AesUtil: any;

@Component({
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent extends BaseModuleComponent {
  public token: string;
  constructor(
    translate: TranslateService,
    private activeRoute: ActivatedRoute
  ) {
    super('reset-password', translate);
  }

  onInit(): void {
    this.setInitializationState();
    this.buildFormGroup();
    this.checkValidateToken();
  }

  public checkValidateToken(): void {
    this.httpClientService
      .post<User>('/reset-password/confirmation', this.token)
      .subscribe(user => {
        if (!user) {
          this.global.routerParams.clear();
          this.global.routerParams.set('canActivate', true);
          this.router.navigate(['/portal/landing-page/expired-link']);
        }
        this.setStateReady();
      });
  }

  private setInitializationState(): void {
    this.activeRoute.paramMap.subscribe(data => {
      this.token = data.get('token');
    });
  }

  private buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      password: [
        null,
        Validators.compose([Validators.required(), Validators.password()])
      ],
      confirmPassword: [
        null,
        Validators.compose([Validators.required(), Validators.matchPassword()])
      ]
    });
  }

  public doSavePassword(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService.saveConfirmation().subscribe(result => {
        if (result) {
          this.setStateProcessing();
          const { password } = this.formGroup.value;
          const aesUtil = new AesUtil();
          const currentDate = new Date(),
            seconds = currentDate.getSeconds(),
            minutes = currentDate.getMinutes(),
            hours = currentDate.getHours();
          const separator = this.global.appConstant.core.LOGIN_SEPARATOR_KEY;
          const pass = aesUtil.encrypt(
            this.global.appConstant.core.LOGIN_SALT_KEY,
            this.global.appConstant.core.LOGIN_IV_KEY,
            this.global.appConstant.core.LOGIN_PASSPHRASE_KEY,
            seconds +
              separator +
              this.token +
              separator +
              minutes +
              separator +
              password +
              separator +
              hours
          );
          this.httpClientService
            .post<Response<string>>('/reset-password/update', pass)
            .subscribe((response: Response<string>) => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.routerParams.clear();
                this.global.routerParams.set('canActivate', true);
                this.router.navigate([
                  '/portal/landing-page/reset-password/success'
                ]);
              } else {
                // this.global.alertService.showError('reset-password-confirmation.msg.error.checkPassword');
                console.log('Info: Error Reset Password');
              }
            });
        }
      });
    }
  }
}
