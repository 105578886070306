import { Pipe, PipeTransform } from '@angular/core';
import { FieldFormatEnum } from '../model/field-format.enum';
import { TableFieldModel } from '../model/table-field-model';

@Pipe({ name: 'resolveColumnClassName' })
export class ResolveColumnClassNamePipe implements PipeTransform {
  transform(column: TableFieldModel): string {
    let className = '';
    const format = column.format;
    if (
      format === FieldFormatEnum.ShortDate ||
      format === FieldFormatEnum.ShortDateAndTime ||
      format === FieldFormatEnum.LongDate ||
      format === FieldFormatEnum.LongDateAndTime ||
      format === FieldFormatEnum.Currency
    ) {
      className +=
        format === FieldFormatEnum.Currency ? 'text-right' : 'text-left';
    } else if (format === FieldFormatEnum.Integer || format === FieldFormatEnum.IntegerDecimal) {
      className += 'text-right';
    } else {
      className += 'text-left';
    }

    return className + ' ' + (column.customClass || '');
  }
}
