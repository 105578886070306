import { HttpProgressEvent } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { File as FileBean } from '../../../bean/file';
import { UploadFileStatusEvent } from '../events/upload-file-status-event';
import { UploadFileModel } from '../interface';
import { CroppedPoints } from '../interface/cropped-points';
import { UploadFileChange } from '../interface/upload-file-change';
import { UploadStatus } from '../types/upload-status';
import { Uploader } from './uploader';
import { UploaderFileError } from './uploader-file-error';
export class UploaderFile {
  id: string;
  file: FileBean;
  origin: File;
  isNew: boolean;
  parent: Uploader;
  status: UploadStatus;
  uploaderProgress: number;
  error: UploaderFileError;
  src: string | ArrayBuffer;
  subscription: Subscription;
  uploadFileChange: UploadFileChange;
  croppedPoints: CroppedPoints;
  statusChanges: EventEmitter<UploadFileStatusEvent> = new EventEmitter();
  uploaderProgressChanges: EventEmitter<number> = new EventEmitter();
  constructor() {}
  setUploaderProgress(response: HttpProgressEvent | number): void {
    this.uploaderProgress =
      typeof response === 'number'
        ? response
        : Math.round((response.loaded / response.total) * 100);
    if (this.uploaderProgress === 100) {
      this.uploaderProgress = 99;
    }
    this.uploaderProgressChanges.emit(this.uploaderProgress);
  }

  setStatus(status: UploadStatus): void {
    this.status = status;
  }

  setFileWithResponse(uploadFileModel: UploadFileModel): void {
    this.file.fileName = uploadFileModel.fileName;
    this.file.size = uploadFileModel.fileSize;
    this.file.uploadedFileName = uploadFileModel.uploadedFileName;
  }

  setError(error: UploaderFileError): void {
    this.error = error;
    this.parent.error = this.error;
  }

  setFileChange(uploadFileChange: UploadFileChange): void {
    if (uploadFileChange.uploaderFile.file.id) {
      this.uploadFileChange = uploadFileChange;
    }
  }

  get formData(): FormData {
    if (this.status !== 'UPLOADED') {
      const formData: FormData = new FormData();
      formData.append(
        (this.parent.options && this.parent.options.formDataName) || 'file',
        this.origin,
        this.file.fileName
      );

      if (this.croppedPoints) {
        const strCroppedPoints = Object.values(this.croppedPoints).join(',');
        formData.append(
          'croppedPoints',
          new Blob([strCroppedPoints], { type: 'text/plain' })
        );
      }

      if (this.parent.options.fileType) {
        formData.append(
          'fileTypeCode',
          new Blob([this.parent.options.fileType.code], { type: 'text/plain' })
        );
      } else {
        const fileTypeCode = this.parent.fileTypeCode.split(',');
        formData.append(
          'fileTypeCode',
          new Blob(
            [
              JSON.stringify(fileTypeCode)
                .replace(/\"/g, "'")
                .replace('[', '')
                .replace(']', '')
            ],
            { type: 'text/plain' }
          )
        );
      }
      return formData;
    }
    return null;
  }
}
