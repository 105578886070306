

import { AccordionState } from '../domain/accordion-state';
import * as accordionAction from './accordion-action';
import { addAccordionItemToState } from './add-accordion-item-to-state';
import { expandOrCollapseAccordionAction } from './expand-or-collapse-accordion-action';
import { expandOrCollapseAllAccordionAction } from './expand-or-collapse-all-accordion-action';
export const dispatchAccordion = (
  state: AccordionState,
  action: accordionAction.AllAccordionAction
): void => {
  switch (action.type) {
    case accordionAction.ADD_ACCORDION_ITEM_TO_STATE:
      addAccordionItemToState(state, action.payload);
      break;
    case accordionAction.EXPAND_OR_COLLAPSE_ACCORDION:
      expandOrCollapseAccordionAction(state, action.payload);
      break;
    case accordionAction.EXPAND_OR_COLLAPSE_ALL_ACCORDION:
      expandOrCollapseAllAccordionAction(state, action.payload);
      break;
    default:
      console.log(`There's no accordion type`);
      break;
  }
};
