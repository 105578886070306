/*
 * Filename:  WebContent\src\app\core\components\app-table-x\helpers\add-plugin-formcontrol-to-formgroup.helper.ts
 * Path:  WebContent
 * Created Date: Monday, August 30th 2021, 11:53:00 am

 *

 */
import { FormControl, FormGroup } from '@angular/forms';
import { TablePluginData } from '../interfaces/table-plugin-data';
export const addPluginFormControlToFormGroup = (
  data: TablePluginData,
  formControl: FormControl
): void => {
  const row = data.indexI;
  const formArray = data.state.model.formArray;
  if (!formArray.controls[row - 1]) {
    const formGroup = new FormGroup({
      [data.column.field]: formControl
    });
    formArray.push(formGroup);
  } else {
    (formArray.controls[row - 1] as FormGroup).addControl(
      data.column.field,
      formControl
    );
  }
};
