/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\model\table-response-model.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:41:23 am

 *
*
 */

import { EventEmitter } from '@angular/core';
import { FormArray } from '@angular/forms';
import { CurrentRowChange } from '../domain/current-row-change';
import { SelectedTableRow } from '../domain/selected-table-row';
import { TableRow } from '../domain/table-row';
import { TableColumnModel } from './table-column-model';
import { TableOptionsModel } from './table-options';

export class TableResponseModel<T> {
  // record yang disisipkan dari level module
  private recordList: Array<T>;
  // custom data digunakan untuk keperluan filter table
  private customData: object;
  // updated record list merupakan record-record yang telah diubah pada table
  private updatedRecordList: Array<T>;
  // request reload digunakan untuk memberitahukan komponen untuk me-reload table
  public requestReload: EventEmitter<void> = new EventEmitter();
  // request delete digunakan untuk meminta table menghapus record yang telah dipilih
  public requestDelete: EventEmitter<void> = new EventEmitter();
  // current row changes adalah row terakhir dalam table yang diubah
  public currentRowChange: CurrentRowChange;
  // form array digunakan untuk menyimpan sementara record-record yang telah diubah
  public formArray: FormArray = new FormArray([]);
  // selected row
  public selectedRow: SelectedTableRow = new SelectedTableRow();

  constructor(
    public moduleCode: string,
    public columnList: Array<TableColumnModel>,
    public options?: TableOptionsModel
  ) {}

  public setRecordList(recordList: Array<T>): void {
    this.recordList = recordList;
  }

  public getRecordList(): Array<T> {
    return this.recordList || [];
  }

  public setCustomData(customData: object): void {
    this.customData = customData;
  }

  public getCustomData(): object {
    return this.customData;
  }

  public setUpdatedRecordList(recordList: Array<T>): void {
    this.updatedRecordList = recordList;
  }

  public getUpdatedRecordList(): Array<T> {
    return this.updatedRecordList;
  }

  public reload(): void {
    this.requestReload.emit();
  }

  public deleteSelectedRecord(): void {
    this.requestDelete.emit();
  }

  public setSelectedRowList(recordList: Array<T>): void {
    this.selectedRow.setSelectedRowList(
      Array.from(recordList).map(record => new TableRow(record))
    );
  }
}
