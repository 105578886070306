import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BaseModuleComponent } from '../../base/angular/base-module.component';
import { AppPopupService } from '../app-popup/app-popup.service';
import { AppOrganizationRkInfoPopupComponent } from './app-organization-rk-info-popup.component';
import { OrganizationPlanInfoRequest } from './request/organization-plan-info-request';
@Component({
  selector: 'app-organization-rk-info',
  templateUrl: './app-organization-rk-info.component.html',
  styleUrls: ['./app-organization-rk-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppOrganizationRkInfoComponent extends BaseModuleComponent {
  @Input() stringUrl = '/organization-plan-info/index';
  @Output() dataChange: EventEmitter<any> = new EventEmitter();

  dataObject: any;
  isShowAll = false;
  param = { id: 0, todo: null };
  max = 7;

  public itemList = [];
  public isSyncRunning = false;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('', translateService);
  }

  onInit(): void {
    // this.reload();
    this.isSynchronizeRunning();
  }

  public isSynchronizeRunning(): void {
    this.httpClientService
      .get<Boolean>(
        '/budget-allocation/is-budget-synchronize-running/onlyBudget'
      )
      .subscribe((isSynchronizeRunning: boolean) => {
        this.isSyncRunning = isSynchronizeRunning;
        if (!this.isSyncRunning) {
          this.reload();
        }
      });
  }

  reload(): void {
    this.extractDataFromServer();
  }

  reloadClient(): void {
    // ... add some logic here for reload clientSide
  }

  extractDataFromServer(): void {
    this.dataFromServer.subscribe(data => {
      this.setDataToTemplateData(data);
      this.setStateReady();
    });
  }

  setDataToTemplateData(data): void {
    this.dataObject = data;
    this.setStateReady();
  }

  doShowViewAll(): void {
    const organizationPlanInfoModelList =
      this.dataObject.organizationPlanInfoModelList;
    this.appPopupService
      .open(
        AppOrganizationRkInfoPopupComponent,
        { organizationPlanInfoModelList },
        { size: 'lg', windowClass: 'app-organizastion-rk-info-popup' }
      )
      .subscribe(organization => {
        this.dataChange.emit(organization);
      });
  }

  doCloseViewAll(): void {
    this.isShowAll = false;
  }

  get dataFromServer(): Observable<OrganizationPlanInfoRequest> {
    return this.httpClientService.get<OrganizationPlanInfoRequest>(
      this.stringUrl
    );
  }

  setMaxString(maxString, value): any {
    if (value.length >= maxString) {
      return value.slice(0, 17) + '...';
    }
    return value;
  }

  onKeyUp(event: KeyboardEvent): void {
    this.log.debug(event);
  }
}
