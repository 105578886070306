<app-layout [isLoading]="formLoading" [isEnableBackButton]="true">
  <div class="row">
    <div class="col-sm-4">
      <app-card [isLoading]="formLoading" header="Filter Report">
        <form
          class="form-horizontal"
          [formGroup]="formGroup"
          novalidate
          *ngIf="!formLoading"
        >
          <h4 class="separator">General</h4>
          <div class="form-group row text-sm-left">
            <label class="col-sm-4 control-label">
              {{
                'report-recap-budget-used.form.filter.activityPeriod'
                  | translate
              }}
            </label>
            <div class="col-sm-8">
              <app-dropdown-select
                size="XL"
                [optionList]="periodProgramList"
                formControlName="activityPeriodList"
                showLimitValue="3"
                type="CHOSEN"
                placeholder="{{
                  'report-recap-budget-used.form.filter.placeholder.activityPeriod'
                    | translate
                }}"
              >
              </app-dropdown-select>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-4 control-label">
              {{
                'report-recap-budget-used.form.filter.organization' | translate
              }}</label
            >
            <div class="col-sm-8 text-left">
              <app-combo-box-tree
                size="XL"
                formControlName="organizationList"
                stringUrl="/organization"
                header="Organization"
                [isLazy]="true"
              >
              </app-combo-box-tree>
            </div>
          </div>
          <h4 class="separator">{{
            'report-recap-budget-used.form.filter.title.typeOfBudgetUsed'
              | translate
          }}</h4>
          <div class="form-group row text-sm-left">
            <label class="col-sm-4 control-label">{{
              'report-recap-budget-used.form.filter.costCenterCode' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-text-field
                size="XL"
                formControlName="costCenterCode"
                placeholder="{{
                  'report-recap-budget-used.form.filter.placeholder.costCenterCode'
                    | translate
                }}"
              >
              </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-4 control-label">{{
              'report-recap-budget-used.form.filter.coaCode' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-text-field
                size="XL"
                formControlName="coaCode"
                placeholder="{{
                  'report-recap-budget-used.form.filter.placeholder.coaCode'
                    | translate
                }}"
              >
              </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-4 control-label">{{
              'report-recap-budget-used.form.filter.requestor' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-text-field
                size="XL"
                formControlName="requestor"
                placeholder="{{
                  'report-recap-budget-used.form.filter.placeholder.requestor'
                    | translate
                }}"
              >
              </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-4 control-label">{{
              'report-recap-budget-used.form.filter.prNumber' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-text-field
                size="XL"
                formControlName="prNumber"
                placeholder="{{
                  'report-recap-budget-used.form.filter.placeholder.prNumber'
                    | translate
                }}"
              >
              </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-4 control-label">{{
              'report-recap-budget-used.form.filter.procurementNumber'
                | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-text-field
                size="XL"
                formControlName="procurementNumber"
                placeholder="{{
                  'report-recap-budget-used.form.filter.placeholder.procurementNumber'
                    | translate
                }}"
              >
              </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-4 control-label">{{
              'report-recap-budget-used.form.filter.minimumAmount' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-currency
                size="XL"
                placeholder="{{
                  'report-recap-budget-used.form.placeholder.minimumAmount'
                    | translate
                }}"
                [optionList]="currencyOptionList"
                formControlName="minimumAmount"
              ></app-currency>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-4 control-label">{{
              'report-recap-budget-used.form.filter.maximumAmount' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-currency
                size="XL"
                placeholder="{{
                  'report-recap-budget-used.form.placeholder.maximumAmount'
                    | translate
                }}"
                [optionList]="currencyOptionList"
                formControlName="maximumAmount"
              ></app-currency>
            </div>
          </div>
          <div class="button-group half-button">
            <app-button
              mode="BYPASS"
              color="LIGHT"
              [outline]="true"
              (onClick)="doReset()"
            >
              <em class="fas fa-sync-alt"></em>
              {{ 'app.button.reset' | translate }}
            </app-button>
            <app-button
              mode="BYPASS"
              color="PRIMARY"
              (onClick)="doGenerate()"
              [disabled]="formSaving"
            >
              {{ 'app.button.generate' | translate }}
            </app-button>
          </div>
        </form>
      </app-card>
    </div>
    <div class="col-sm-8">
      <app-card [isLoading]="formLoading" [isSaving]="formSaving">
        <app-table-xx
          header="report-recap-budget-used.detail.result.title"
          [model]="tableResponse"
          stringUrl="/report-recap-budget-used/index"
          [isShowPopupModifyTable]="isShowPopupModifyTable"
          [isFullViewTable]="isFullViewTable"
        >
          <ng-template #headerButtons>
            <app-button
              [outline]="true"
              [borderLess]="true"
              (onClick)="doCustomSetting()"
              *ngIf="reportBuilderId"
            >
              <em
                class="pir pi-cog mr-1"
                style="top: auto; left: auto; font-size: 16px; font-weight: 500"
              ></em>
              <span class="text-underline">
                {{ 'app.button.customSetting' | translate }}
              </span>
            </app-button>
            <app-button
              [outline]="true"
              [borderLess]="true"
              (onClick)="isShowPopupModifyTable = !isShowPopupModifyTable"
            >
              <em class="pir pi-cog mr-1"> </em>
              {{ 'app-table.button.modifyTable' | translate }}
            </app-button>
            <app-button
              [outline]="true"
              [borderLess]="true"
              (onClick)="isFullViewTable = !isFullViewTable"
            >
              <em
                class="fas mr-1"
                [ngClass]="
                  isFullViewTable ? 'pir pi-compress-alt' : 'pir pi-expand-alt'
                "
              ></em>
              {{
                (isFullViewTable
                  ? 'app-table.button.minimize'
                  : 'app-table.button.maximize'
                ) | translate
              }}
            </app-button>
            <app-button
              (onClick)="doExport()"
              [outline]="true"
              [borderLess]="true"
            >
              <em class="pir pi-file-download mr-1"></em>
              <span class="text-underline">{{
                'report-recap-budget-used.detail.downloadReport' | translate
              }}</span>
            </app-button>
          </ng-template>
        </app-table-xx>
      </app-card>
    </div>
  </div>
</app-layout>
