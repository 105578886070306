/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\portal\landing-page\email-verification.component.ts
 
 * Created Date: Friday, October 1st 2021, 9:51:36 pm

 *
*
 */

import { Component, OnInit } from '@angular/core';
@Component({
  templateUrl: './email-verification.component.html'
})
export class EmailVerificationComponent implements OnInit {
  public TWO_MINUTES = 1000 * 120;
  public resendEmailTime: number;
  public isDisabled = true;
  private timeout: NodeJS.Timeout;
  ngOnInit(): void {
    this.setResendEmailTimeState();
  }

  public setResendEmailTimeState(): void {
    this.resendEmailTime = this.TWO_MINUTES;
    this.timeout = setInterval(() => {
      this.resendEmailTime = this.resendEmailTime - 1000;
      if (this.resendEmailTime === 0) {
        this.isDisabled = false;
        this.resendEmailTime = this.TWO_MINUTES;
        clearTimeout(this.timeout);
      }
    }, 1000);
  }

  public doSendEmailVerification(): void {
    this.isDisabled = true;
    if (this.resendEmailTime === this.TWO_MINUTES) {
      this.setResendEmailTimeState();
      // do some stuff
    }
  }
}
