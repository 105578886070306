<ng-container *ngTemplateOutlet="isOffline ? offlineTemplate : onlineTemplate"></ng-container>

<ng-template #offlineTemplate>
  <div class="offline-bg">
    <div class="offline-content">
      <h2 class="lpt-2">Connection Lost</h2>
      <h5 class="fw-400">Please check your internet connection</h5>
    </div>
  </div>
</ng-template>

<ng-template #onlineTemplate>
  <div class="block-ui-wrapper" *ngIf="global.blockMsg.active">
    <div class="block-ui-backdrop"></div>
    <div class="card border-0 box-shadow">
      <div class="block-message" *ngIf="this.global.blockMsg.type === 'error'">
        <div class="card card-danger bg-danger">
          <div class="card-header text-white">
            <em class="fas fa-exclamation-circle"></em> Error!
          </div>
          <div class="card-body bg-white">
            <em class="far fa-clone copy-error-text" title="Copy Error Text"
              (click)="doCopyErrorMessage(customTostCopyElement, contentElement)"></em>
            <p class="text-secondary border border-1 p-2" innerHTML={{this.global.blockMsg.msg}} #contentElement></p>
            <div class="custom-tost-copy" #customTostCopyElement>
              <div class="custom-tost-copy-content">
                <em class="far fa-clipboard mr-1"></em>
                Copied to clipboard!
              </div>
            </div>
          </div>
          <div class="card-footer bg-white  d-flex justify-content-end">
            <button type="button" class="btn btn-block col-4 btn-secondary mb-2" (click)="doCloseBlockUI()">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="wrapper">
    <router-outlet></router-outlet>
  </div>