/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\popup-login\popup-login.module.ts
 
 * Created Date: Wednesday, September 29th 2021, 9:03:11 am

 *
*
 */

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PipesModule } from 'src/app/core/pipe/pipe.module';
import { AppButtonModule } from '../../../../core/components/app-button/app-button.module';
import { AppCaptchaModule } from '../../../../core/components/app-captcha/app-captcha.module';
import { AppCheckBoxModule } from '../../../../core/components/app-check-box/app-check-box.module';
import { AppPopupModule } from '../../../../core/components/app-popup/app-popup.module';
import { AppTextFieldModule } from '../../../../core/components/app-text-field/app-text-field.module';
import { VendorModule } from '../../../../core/vendor/vendor.module';
import { PopupLoginComponent } from './popup-login.component';
@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppTextFieldModule,
    AppButtonModule,
    AppCheckBoxModule,
    AppCheckBoxModule,
    AppCaptchaModule,
    RouterModule,
    PipesModule
  ],
  declarations: [PopupLoginComponent],
  exports: [PopupLoginComponent]
})
export class PopupLoginModule {}
