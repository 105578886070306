/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\base\base-widget.component.ts
 
 * Created Date: Thursday, November 18th 2021, 3:17:47 pm

 *
*
 */

import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Global } from '../../../../core/global/global';
import { HttpClientService } from '../../../../core/services/http-client.service';
import { ServiceLocator } from '../../../../core/services/service-locator';
import { WidgetItem } from '../domain/widget-item';
@Component({
  template: ''
})
export abstract class BaseWidgetComponent implements OnInit {
  @Input() model: WidgetItem;
  public global: Global;
  public httpClientService: HttpClientService;
  public router: Router;
  public formGroup: FormGroup = new FormGroup({});
  protected formBuilder: FormBuilder = new FormBuilder();
  protected abstract onInit(): void;
  constructor(@Inject(String) public moduleCode: string) {
    this.global = ServiceLocator.injector.get(Global);
    this.httpClientService = ServiceLocator.injector.get(HttpClientService);
    this.router = ServiceLocator.injector.get(Router);
  }
  ngOnInit(): void {
    this.setStateLoading();
    this.onInit();
  }

  protected setStateLoading(): void {
    this.model.setStateLoading();
  }

  protected setStateReady(): void {
    this.model.setStateReady();
  }
}
