import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { CaptchaModel } from '../../core/components/app-captcha/model/captcha-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Validators } from '../../core/validators';
@Component({
  selector: 'app-login-form',
  templateUrl: './app-login-form.component.html',
  styleUrls: ['./app-login-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppLoginFormComponent extends BaseComponentComponent {
  public captchaModel: CaptchaModel = new CaptchaModel();
  @Output() onClosePopupLoginForm: EventEmitter<boolean> = new EventEmitter();
  constructor(
    public authenticationService: AuthenticationService,
    public activatedRoute: ActivatedRoute
  ) {
    super('app-login-form');
  }

  public onInit(): void {
    this.global.userSession ? this.reload() : this.buildFormGroup();
    this.setStateReady();
  }

  public reload(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('reload', '/pages/dashboard');
    this.router.navigate(['/reload/']);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      email: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      password: [null, Validators.compose([Validators.required()])],
      captcha: [
        null,
        Validators.compose(
          this.global.config.appInfoResponse.isShowCaptcha
            ? [Validators.required('app.validation.captchaRequired')]
            : []
        )
      ],
      isKeepMeSignIn: [null]
    });
  }

  public setStateFormLoginProcessing(): void {
    this.formGroup.disable();
    this.isEnabled = false;
    this.formLoading = false;
    this.formSaving = true;
  }

  public setStateFormLoginReady(): void {
    this.formGroup.enable();
    this.isEnabled = true;
    this.formLoading = false;
    this.formSaving = false;
  }

  public doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.setStateFormLoginProcessing();
      const email = this.formGroup.get('email').value;
      const password = this.formGroup.get('password').value;
      this.authenticationService.getAuth(email, password, null, null).subscribe(
        response => {
          const authenticationResponse = response.body;
          if (response.status === ResponseStatusModel.OK) {
            this.authenticationService.logingIn(authenticationResponse);
            this.onClosePopupLoginForm.emit(true);
          } else {
            if (response.statusText === 'LOGIN_NO_DEFAULT_ROLE') {
              this.global.alertService.showError(
                'No default Role!',
                '.error-section',
                { totalAlert: 1 }
              );
              this.global.routerParams.clear();
              this.global.routerParams.set('email', email);
              this.global.routerParams.set('password', password);
              this.global.routerParams.set(
                'validUserRoleList',
                authenticationResponse.user.validUserRoleList
              );
              setTimeout(() => {
                this.onClosePopupLoginForm.emit(true);
                this.router.navigate(['/portal/select-role']);
              }, 1000);
            } else if (response.statusText === 'LOGIN_NO_ROLE_FOUND') {
              this.global.alertService.showError(
                this.global.translateService.instant(
                  'portal.popupLogin.msg.error.noRoleFound'
                ),
                '.error-section',
                { totalAlert: 1 }
              );
              this.formGroup.get('captcha').reset();
            } else {
              this.global.alertService.showError(
                response.statusText,
                '.error-section',
                { totalAlert: 1 }
              );
              this.formGroup.get('captcha').reset();
            }
          }
          this.setStateFormLoginReady();
        },
        () => {
          this.formGroup.get('captcha').reset();
          this.setStateFormLoginReady();
        }
      );
    }
  }

  public doResetPassword(): void {
    this.onClosePopupLoginForm.emit(true);
    this.router.navigate(['/portal/reset-password']);
  }

  public doSignUp(): void {
    this.onClosePopupLoginForm.emit(true);
    this.router.navigate(['/portal/registration/vendor-policy']);
  }

  public onEnterSubmit(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      this.doSubmit();
    }
  }
}
