/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\components\app-table\app-table.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 10:53:48 am

 *
*
 */
import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { VendorModule } from '../../../../vendor/vendor.module';
import { AppButtonModule } from '../../../app-button/app-button.module';
import { AppLazyLoadModule } from '../../../app-lazy-load/app-lazy-load.module';
import { AppPaginationXModule } from '../../../app-pagination-x/app-pagination-x.module';
import { TableDirectiveModule } from '../../directives/table-directive.module';
import { TablePipeModule } from '../../pipe/table-pipe.module';
import { TablePluginsModule } from '../../plugins/table-plugins.module';
import { AppPopupModifyTableModule } from '../app-popup-modify-table/app-popup-modify-table.module';
import { AppTableComponent } from './app-table.component';
@NgModule({
  imports: [
    VendorModule,
    TableDirectiveModule,
    TablePipeModule,
    PerfectScrollbarModule,
    AppButtonModule,
    TablePluginsModule,
    AppPaginationXModule,
    AppPopupModifyTableModule,
    AppLazyLoadModule
  ],
  declarations: [AppTableComponent],
  exports: [AppTableComponent]
})
export class AppTableModule {}
