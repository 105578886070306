/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\directives\table-column.directive.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 3:00:35 pm

 *
*
 */
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2
} from '@angular/core';
import { AppDynamicComponentService } from '../../../services/app-dynamic-component.service';
import { ArrayUtils } from '../../../utils';
import { TableColumn } from '../domain/table-column';
import { TableRow } from '../domain/table-row';
import { TableState } from '../domain/table-state';
import {
  AllTablePluginOptions,
  AvailableTablePlugin
} from '../interface/available-table-plugin';
import { TablePlugin } from '../interface/table-plugin';
import { TablePluginData } from '../interface/table-plugin-data';
import { TableColumnComponentModel } from '../model/table-column-component-model';
import { TableColumnModel } from '../model/table-column-model';
import { TablePluginService } from '../service/table-plugin.service';
@Directive({
  selector: '[table-td]'
})
export class TableTdDirective implements OnInit, AfterViewInit {
  @Input() state: TableState;
  @Input() row: TableRow;
  @Input() column: TableColumnModel;
  @Input() indexI: number;
  @Input() indexJ: number;
  private divTdContentElement: HTMLElement;
  private htmlElementList: Array<HTMLElement> = [];
  private value: any;
  private tableColumn: TableColumn;
  constructor(
    private tablePluginService: TablePluginService,
    private renderer2: Renderer2,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.setInitializationState();
    this.createTDContentElement();
    this.createPluginComponent();
    this.callPluginBefore();
    this.callPluginOnCreate();
    this.callPluginAfter();
  }

  ngAfterViewInit(): void {
    this.row.columnList.push(this.tableColumn);
    if (this.column.callbacks && this.column.callbacks.onAfterCreate) {
      this.column.callbacks.onAfterCreate(
        this.tableColumn,
        this.row.record,
        this.indexI,
        this.indexJ
      );
    }
  }

  private setInitializationState(): void {
    this.value =
      (this.column.field &&
        ArrayUtils.resolveFieldData(
          this.row.record,
          this.column.field,
          typeof this.column.dataMap === 'string'
            ? this.column.dataMap
            : this.column.dataMap
            ? JSON.stringify(this.column.dataMap)
            : null
        )) ||
      null;
    this.tableColumn = new TableColumn();
    this.tableColumn.column = this.column;
    this.tableColumn.element = this.elementRef.nativeElement;
    if (this.column.callbacks && this.column.callbacks.onBeforeCreate) {
      this.column.callbacks.onBeforeCreate(
        this.tableColumn,
        this.row.record,
        this.indexI,
        this.indexJ
      );
    }
  }

  private createTDContentElement(): void {
    this.divTdContentElement = this.renderer2.createElement('div');
    this.renderer2.addClass(this.divTdContentElement, 'td-content');
    (this.column.className || '').split(' ').forEach((className: string) => {
      if (className && className.trim() !== '') {
        this.renderer2.addClass(this.divTdContentElement, className);
      }
    });
    this.renderer2.appendChild(
      this.elementRef.nativeElement,
      this.divTdContentElement
    );
  }

  private createPluginComponent(): void {
    if (this.column.component) {
      let componentInstance: object;
      let component: object;
      if (
        this.column.component &&
        this.column.component.hasOwnProperty('target')
      ) {
        const columnComponent = this.column
          .component as TableColumnComponentModel;
        component = columnComponent.target;
        componentInstance = columnComponent.callbacks.instance(
          this.row.record,
          this.row,
          this.tableColumn,
          this.state
        );
      } else {
        component = this.column.component;
        componentInstance = {
          tablePluginData: this.tablePluginData
        };
      }
      this.state.injector
        .get(AppDynamicComponentService)
        .create(component, this.divTdContentElement, componentInstance);
    }
  }

  private callPluginBefore(): void {
    this.callPlugin('BEFORE');
  }

  private callPluginOnCreate(): void {
    if (!this.column.component) {
      let htmlElement: Array<HTMLElement> = [];
      if (
        (this.value && this.htmlElementList.length === 0) ||
        (!this.value && this.htmlElementList.length === 0)
      ) {
        htmlElement[0] = (
          this.tablePluginService.getPlugin('default') as TablePlugin
        ).onCreate(this.tablePluginData) as HTMLElement;
      }
      [...this.htmlElementList, ...htmlElement].forEach(
        (htmlElement: HTMLElement) =>
          htmlElement && this.divTdContentElement.appendChild(htmlElement)
      );
    }
  }

  private callPluginAfter(): void {
    this.callPlugin('AFTER');
  }

  private get tablePluginData(): TablePluginData {
    return {
      indexI: this.indexI,
      indexJ: this.indexJ,
      row: this.row,
      element: this.divTdContentElement,
      state: this.state,
      value: this.value,
      column: this.tableColumn
    };
  }

  private callPlugin(command: 'BEFORE' | 'AFTER'): void {
    if (this.column.plugins && !this.column.component) {
      const tablePluginOptionList = Array.isArray(this.column.plugins)
        ? this.column.plugins
        : [this.column.plugins];
      tablePluginOptionList.forEach(
        (tablePluginOption: AllTablePluginOptions) => {
          let tablePlugin: TablePlugin;
          const tablePluginData = this.tablePluginData;
          if (
            typeof tablePluginOption === 'object' &&
            ((command === 'BEFORE' &&
              tablePluginOption.hasOwnProperty('before')) ||
              (command === 'AFTER' &&
                tablePluginOption.hasOwnProperty('after')))
          ) {
            tablePlugin = tablePluginOption as TablePlugin;
          } else {
            const plugin = tablePluginOption as AllTablePluginOptions;
            let tablePluginName: string =
              typeof plugin === 'string' ? plugin : plugin.name;
            tablePlugin = this.tablePluginService.getPlugin(
              tablePluginName as AvailableTablePlugin
            ) as TablePlugin;
            tablePluginData.plugin = plugin;
          }

          if (command === 'BEFORE' && tablePlugin && tablePlugin.before) {
            const result = tablePlugin.before(tablePluginData) as HTMLElement;
            if (result && result.childNodes) {
              this.htmlElementList.push(result);
            } else {
              this.value = result;
            }
          } else if (command === 'AFTER' && tablePlugin && tablePlugin.after) {
            tablePlugin.after(tablePluginData);
          }
        }
      );
    }
  }
}
