import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ApprovalModelPrcs } from '../../core/bean/approval-model-prcs';
import { ApprovalModelPrcsView } from '../../core/bean/approval-model-prcs-view';
import { ApprovalPrcs } from '../../core/bean/approval-prcs';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { ModuleGroup } from './../../core/bean/module-group';
@Component({
  templateUrl: './approval-prcs.component.html',
  styles: [
    '.width-company-name { min-width : 250px; white-space: normal ! important }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ApprovalPrcsComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public requestDateFrom: Date;
  public requestDateTo: Date;
  public moduleGroupList: ModuleGroup[];
  public tableResponse: TableResponseModel<ApprovalModelPrcsView>;
  public currentPosition: string;
  public isWaitingApproval = false;
  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('worklist-approval', translateService);
  }

  onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.doGetAndSetModuleGroupList();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      requestDateFrom: [null, Validators.required],
      requestDateTo: [null, Validators.required],
      organizationId: [null],
      organizationName: [null],
      moduleGroup: [null],
      isWaitingApproval: [
        this.isWaitingApproval ? this.isWaitingApproval : null
      ]
    });
  }

  public doSetDataFromRouterParams(): void {
    this.isWaitingApproval = this.global.routerParams.get('isWaitingApproval')
      ? this.global.routerParams.get('isWaitingApproval')
      : this.isWaitingApproval;
    this.global.routerParams.clear();
  }

  public doGetAndSetModuleGroupList(): void {
    this.httpClientService
      .post<ApprovalPrcs[]>('/approval-prcs/module-group-list', {})
      .subscribe(moduleGroupList => {
        this.moduleGroupList = moduleGroupList;
      });
  }

  public doClick(moduleGroupCode: string): void {
    if (moduleGroupCode) {
      const moduleGroup = new ModuleGroup();
      moduleGroup.code = moduleGroupCode;

      this.formGroup.patchValue({
        moduleGroup
      });
    } else {
      this.formGroup.patchValue({
        moduleGroup: null
      });
    }

    this.currentPosition = moduleGroupCode;
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'approvalModelPrcs.approvalPrcs.moduleGroup.name',
        header: 'table.column.moduleGroup'
      },
      {
        field: 'workflowPrcsName',
        header: 'table.column.name',
        customClass: 'width-company-name'
      },
      {
        field: 'approvalModelPrcs.approvalPrcs.organization.name',
        header: 'table.column.organization'
      },
      {
        field: 'approvalModelPrcs.approvalPrcs.requestedDate',
        header: 'table.column.requestedDate',
        format: FieldFormatEnum.ShortDateAndTime
      },
      {
        field: 'approvalModelPrcs.startDate',
        header: 'table.column.recommendationDate',
        format: FieldFormatEnum.ShortDateAndTime
      }
    ]);
    this.tableResponse.setCustomData(this.formGroup.value);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/approval-prcs/add']);
  }

  public doApply(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }

  public doChooseOrganization(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(organization => {
        const { name: organizationName, id: organizationId } =
          organization.pop();
        this.formGroup.patchValue({ organizationName, organizationId });
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
      });
  }

  public doOnChangeModuleGroup(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doEdit(approvalModelPrcs: ApprovalModelPrcs): void {
    this.global.routerParams.clear();
    // const approverPathResponseModel: AppApproverModel = new AppApproverModel(approvalModelPrcs.approvalPrcs.objectId, true);
    this.global.routerParams.set('approvalModelPrcsId', approvalModelPrcs.id);
    this.global.routerParams.set(
      'objectId',
      approvalModelPrcs.approvalPrcs.objectId
    );
    this.global.routerParams.set(
      'objectName',
      approvalModelPrcs.approvalPrcs.objectName
    );
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('isGuest', true);
    // tslint:disable-next-line: max-line-length
    this.global.routerParams.set(
      'vendorStatus',
      approvalModelPrcs.approvalPrcs.code === 'VENDOR_UPDATE_DATA_NON_TDR' ||
        approvalModelPrcs.approvalPrcs.code === 'UPDATE_DATA_TDR' ||
        approvalModelPrcs.approvalPrcs.code === 'CERTIFICATE_EXPIRED'
        ? 'ACTIVE'
        : null
    ); // untuk keperluan informasi hover new
    this.global.routerParams.set('activityStatus', 'APPROVAL'); // masih hardcode
    this.global.routerParams.set('urlBackOutside', '/pages/approval-prcs');
    this.global.routerParams.set('isApproval', true);
    this.global.routerParams.set('modeApproval', 'edit');
    this.global.routerParams.set(
      'isApprovalViewOnly',
      approvalModelPrcs.isViewOnly
    );
    this.router.navigate([approvalModelPrcs.approvalPath]);
  }
}
