/*
 * Filename:  WebContent\src\app\core\components\app-table-x\action\get-record-childrens-action.ts
 * Path:  WebContent
 * Created Date: Tuesday, August 10th 2021, 2:41:40 pm

 *
*
 */
import { TableRow } from '../domain/table-row';
import { TableState } from '../domain/table-state';
import { resolveTableRowChildrenList } from '../helper/resolve-table-row-children-list-helper';
export const loadTableRowChildAction = (
  state: TableState,
  payload: { row: TableRow }
): void => {
  state.setStateReady();
  payload.row.setStateLoading();
  payload.row.tableRequest.first += payload.row.childrenList.length;
  resolveTableRowChildrenList(state, payload.row).then(
    (value: { rowList: TableRow[]; totalRecord: number }) => {
      payload.row.childrenList.push(...value.rowList);
      payload.row.isShowButtonLoadMore =
        payload.row.childrenList.length < value.totalRecord;
      payload.row.setStateReady();
    }
  );
};
