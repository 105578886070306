/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\core\components\table\helper\create-per-page-filter-list-helper.ts
 
 * Created Date: Thursday, November 4th 2021, 11:16:19 pm

 *
*
 */
export const createPerPageFilterList = (perPage: number): Array<number> => {
  const filterList: Array<number> = new Array();
  const first = Math.ceil(perPage / 2);
  filterList.push(first <= 0 ? 1 : first);
  filterList.push(perPage);
  filterList.push(perPage * 2 + filterList[0]);
  filterList.push(filterList[filterList.length - 1] * 2);
  filterList.push(filterList[filterList.length - 1] * 2);
  return filterList;
};
