/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\core\components\table\directives\table-th.directive.ts
 
 * Created Date: Wednesday, October 13th 2021, 10:13:07 am

 *
*
 */

import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { TableState } from '../domain/table-state';
import {
  AllTableHeaderPluginOptions,
  AvailableTableHeaderPlugin
} from '../interface/available-table-plugin';
import { TableHeaderPlugin } from '../interface/table-plugin';
import { TableHeaderPluginData } from '../interface/table-plugin-data';
import { TableColumnModel } from '../model/table-column-model';
import { TablePluginService } from '../service/table-plugin.service';
@Directive({
  selector: '[table-th]'
})
export class TableThDirective implements OnInit {
  @Input() state: TableState;
  @Input() column: TableColumnModel;
  @Input() indexI: number;
  @Input() indexJ: number;

  private divThContentElement: HTMLElement;
  private htmlElementList: Array<HTMLElement> = new Array();
  constructor(
    private elementRef: ElementRef,
    private tablePluginService: TablePluginService
  ) {}
  ngOnInit(): void {
    this.createThContentElement();
    this.callPluginBefore();
    this.callPluginOnCreate();
    this.calllPluginAfter();
  }

  private createThContentElement(): void {
    this.divThContentElement = document.createElement('div');
    this.divThContentElement.classList.add('th-content');
    if (this.column.columnList) {
      this.divThContentElement.classList.add('justify-content-center');
    }
    this.elementRef.nativeElement.appendChild(this.divThContentElement);
  }

  private callPluginBefore(): void {
    this.callPlugin('BEFORE');
  }

  private callPluginOnCreate(): void {
    let htmlElement: Array<HTMLElement> = [];
    if (this.htmlElementList.length === 0) {
      const defaultPlugin = this.tablePluginService.getPlugin(
        'default-header'
      ) as TableHeaderPlugin;
      htmlElement[0] = defaultPlugin.onCreate(
        this.tableHeaderPluginData
      ) as HTMLElement;
    }
    [...this.htmlElementList, ...htmlElement].forEach(
      (htmlElement: HTMLElement) =>
        htmlElement && this.divThContentElement.appendChild(htmlElement)
    );
  }

  private calllPluginAfter(): void {
    this.callPlugin('AFTER');
  }

  private callPlugin(command: 'BEFORE' | 'AFTER'): void {
    if (
      this.column.header &&
      typeof this.column.header !== 'string' &&
      this.column.header.plugins
    ) {
      const tableHeaderPluginOptionList = Array.isArray(
        this.column.header.plugins
      )
        ? this.column.header.plugins
        : [this.column.header.plugins];
      tableHeaderPluginOptionList.forEach(
        (tablePluginOption: AllTableHeaderPluginOptions) => {
          let tableHeaderPlugin: TableHeaderPlugin;
          const tableHeaderPluginData = this.tableHeaderPluginData;
          if (
            typeof tablePluginOption === 'object' &&
            ((command === 'BEFORE' &&
              tablePluginOption.hasOwnProperty('before')) ||
              (command === 'AFTER' &&
                tablePluginOption.hasOwnProperty('after')))
          ) {
            tableHeaderPlugin = tablePluginOption as TableHeaderPlugin;
          } else {
            const plugin = tablePluginOption as AllTableHeaderPluginOptions;
            let tablePluginName: string =
              typeof plugin === 'string' ? plugin : plugin.name;
            tableHeaderPlugin = this.tablePluginService.getPlugin(
              tablePluginName as AvailableTableHeaderPlugin
            ) as TableHeaderPlugin;
            tableHeaderPluginData.plugin = plugin;
          }

          if (
            command === 'BEFORE' &&
            tableHeaderPlugin &&
            tableHeaderPlugin.before
          ) {
            const result = tableHeaderPlugin.before(
              tableHeaderPluginData
            ) as HTMLElement;
            if (result && result.childNodes) {
              this.htmlElementList.push(result);
            }
          } else if (
            command === 'AFTER' &&
            tableHeaderPlugin &&
            tableHeaderPlugin.after
          ) {
            tableHeaderPlugin.after(tableHeaderPluginData);
          }
        }
      );
    }
  }

  private get tableHeaderPluginData(): TableHeaderPluginData {
    return {
      column: this.column,
      element: this.divThContentElement,
      indexI: this.indexI,
      indexJ: this.indexJ,
      state: this.state
    };
  }
}
