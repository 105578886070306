<div class="user-profile-card">
  <div
    class="user-profile-card-image"
    [ngClass]="[model.totalUploaded > 0 ? 'is-image' : '']"
  >
    <ng-container
      *ngFor="let uploaderFile of model.uploaderFileList; let i = index"
    >
      <div
        class="loading-wrapper"
        *ngIf="
        model.uploaderFileList.length > 0 &&
        model.uploaderFileList[0].status === 'ONPROGRESS'
      "
      > <!---->
        <em class="fas fa-circle-notch fa-pulse fa-4x fa-fw"></em>
      </div>
      <img
        [attr.src]="
          uploaderFile.isNew || uploaderFile.isChange
            ? uploaderFile.src
            : (uploaderFile | resolveUploadSrc | async)
        "
        class="img-thumbnail"
        alt="User profile picture"
        width="200"
        height="200"
      />
    </ng-container>
    <p
      class="choose-image"
      (click)="uploadFileInput.click()"
      [attr.title]="
        'File extension:' +
        model.options.allowedExtension +
        ' &#013;Max size: ' +
        model.options.maxSize / 1000000 +
        'MB'
      "
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    >
      <span class="opacity"></span>
      <em class="fas fa-camera"></em>
    </p>
  </div>
  <input
    type="file"
    (change)="onChangeUpload($event)"
    hidden
    #uploadFileInput
    aria-label="choose-image"
  />
</div>
