<div class="dashboard-widget" [ngClass]="state.isShowWidgetEditable ? 'dashboard-widget-editable' : ''">
  <div class="row justify-content-end mr-2 mb-2">
    <div class="button-group button-group-right">
      <app-button *ngIf="state.isShowWidgetEditable" size="TN" class="mr-1" color="DANGER" [outline]="true"  (onClick)="doRestoreWidget()">
        {{ 'app.button.cancel' | translate }}
      </app-button>

      <app-button *ngIf="state.isShowWidgetEditable" size="TN" class="mr-1" color="PRIMARY" [outline]="true"  (onClick)="doSaveWidget()">
        {{ 'app.button.saveChange' | translate }}
      </app-button>

      <app-button *ngIf="!state.isShowWidgetEditable" size="TN" [outline]="true" color="DEFAULT" (onClick)="doShowWidgetEditTable()">
        <em class="fas fa-cog" *ngIf="!state.isShowWidgetEditable || state.isSaving"></em>
      </app-button>
    </div>
  </div>

    <div class="widget-list" *ngIf="!isLoading">
      <div class="widget-item px-2" style="height: fit-content; float: left"
        *ngFor="let widgetItem of state.widgetList; let i = index">
        <div [@bordered]="state.isShowWidgetEditable ? 'bordered' : 'default'" class="widget-item-content"
          [model]="widgetItem">
        </div>
        <div class="widget-item-editable" *ngIf="state.isShowWidgetEditable">
          <div class="button-group button-group-right pt-2 pr-2">
            <app-button color="DANGER" (onClick)="doDeleteWidget(i)">
              <em class="pir pi-trash-alt mr-2"></em>
              {{ 'app.button.delete' | translate }}
            </app-button>
          </div>
          <div class="drag-text">
            <em class="pir pi-expand-arrows-alt pi-3x mr-2"></em>
            <span>Drag to move widget position</span>
          </div>
        </div>
      </div>
    </div>
 


  <div class="overlay-button" *ngIf="state.isShowWidgetEditable">
    <app-button (onClick)="doAddWidget()">
      <em class="fas fa-plus-square"></em>
      {{ 'app.button.add' | translate }}
    </app-button>
  </div>

</div>