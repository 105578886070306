/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\base\base-table-plugin.component.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Friday, September 10th 2021, 7:00:31 pm

 *
*
 */

import { Component, Input, OnInit } from '@angular/core';
import { TableState } from '../domain/table-state';
import { reSetTableRecord } from '../helper';
import { TablePluginData } from '../interface/table-plugin-data';
import { TablePluginDefaultOption } from '../interface/table-plugin-options';
@Component({
  template: ''
})
export abstract class BaseTablePluginComponent implements OnInit {
  @Input() tablePluginData: TablePluginData;
  @Input() state: TableState;
  protected abstract onInit(): void;
  public updatedRecord: any;
  constructor() {}
  ngOnInit(): void {
    this.listenerColumnValueChanges();
    this.onInit();
  }

  public doClick(): void {
    const plugin = this.tablePluginData.plugin as TablePluginDefaultOption;
    if (plugin.onClick) {
      plugin.onClick(this.tablePluginData.row.record);
    }
  }

  protected setUpdatedRecordValue(value: any, customValue?: any): void {
    let record = this.record;
    record = reSetTableRecord(
      record,
      this.tablePluginData.column.column.field,
      value
    );
    if (
      customValue &&
      this.tablePluginData.plugin &&
      (this.tablePluginData.plugin as any).field
    ) {
      record = reSetTableRecord(
        record,
        (this.tablePluginData.plugin as any).field,
        customValue
      );
    }
    this.tablePluginData.state.recordTemp.recordMap.set(
      JSON.stringify(this.tablePluginData.row.record),
      record
    );
    this.tablePluginData.state.model.setUpdatedRecordList(
      this.tablePluginData.state.recordTemp.getRecordList()
    );
    this.tablePluginData.state.model.currentRowChange = {
      column: this.tablePluginData.column,
      row: this.tablePluginData.row,
      state: this.tablePluginData.state
    };
    this.updatedRecord = this.state.recordTemp.recordMap.get(
      JSON.stringify(this.tablePluginData.row.record)
    );
    this.tablePluginData.row.updatedRecord = this.updatedRecord;
  }

  public listenerColumnValueChanges(): void {
    this.tablePluginData.column.onChange &&
      this.tablePluginData.column.onChange.subscribe(
        ({ value, customValue }) => {
          this.setUpdatedRecordValue(value, customValue);
        }
      );
  }

  public get record(): object {
    return (
      this.tablePluginData.row.updatedRecord || this.tablePluginData.row.record
    );
  }
}
