import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseWidgetComponent } from '../../base/base-widget.component';

@Component({
  templateUrl: './widget-waiting-approval.component.html'
})
export class WidgetWaitingApprovalComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-waiting-approval';
  public total: number;

  constructor(public translateService: TranslateService) {
    super(WidgetWaitingApprovalComponent.moduleCode);
  }

  public onInit(): void {
    this.getTotalWaitingApprovalInformation();
  }

  public getTotalWaitingApprovalInformation(): void {
    this.httpClientService
      .get<number>('/widget-waiting-approval/index')
      .subscribe(response => {
        if (response) {
          this.total = response;
        }
        this.setStateReady();
      });
  }

  public doNavigateToWorklistApproval(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('isWaitingApproval', true);
    this.router.navigate(['/pages/approval-prcs']);
  }
}
