/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table\app-portal-table.service.ts
 
 * Created Date: Thursday, September 30th 2021, 10:39:40 am

 *
*
 */

import { dispatchPortalTable } from './action/dispatch-portal-table-action';
import * as portableTableAction from './action/portal-table-action';
import { PortalTableState } from './domain/portal-table-state';
import { PortalTableResponseModel } from './model/portal-table-response-model';
export class AppPortalTableService {
  state: PortalTableState;
  constructor() {}
  public setState(
    model: PortalTableResponseModel<any>,
    stringUrl: string
  ): PortalTableState {
    this.state = PortalTableState.create(model, stringUrl);
    return this.state;
  }

  public dispatch(action: portableTableAction.AllPortalTableAction): void {
    this.state.setStateLoading();
    dispatchPortalTable(this.state, action);
  }
}
