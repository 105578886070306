/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\portal\landing-page\landing-page.module.ts
 
 * Created Date: Friday, October 1st 2021, 9:07:22 pm

 *
*
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppButtonModule } from '../../core/components/app-button/app-button.module';
import { AppCardModule } from '../../core/components/app-card/app-card.module';
import { AppTextFieldModule } from '../../core/components/app-text-field/app-text-field.module';
import { VendorModule } from '../../core/vendor/vendor.module';
import { AppLandingPageCardModule } from '../../themes/promise-portal/components/app-landing-page-card/app-landing-page-card.module';
import { ConfirmationComponent } from './confirmation.component';
import { EmailVerificationComponent } from './email-verification.component';
import { ExpiredLinkComponent } from './expired-link.component';
import { LandingPageComponent } from './landing-page.component';
import { LandingPageGuard } from './landing-page.guard';
import { RegistrationVerificationComponent } from './registration-verification.component';
import { RegistrationVerificationResolver } from './registration-verification.resolver';
import { ResetPasswordSuccessComponent } from './reset-password-success.component';
import { ResetPasswordComponent } from './reset-password.component';
import { ResetPasswordResolver } from './reset-password.resolver';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    children: [
      { path: '', redirectTo: '/portal/home', pathMatch: 'full' },
      {
        path: 'reset-password/success',
        component: ResetPasswordSuccessComponent,
        canActivate: [LandingPageGuard]
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordComponent,
        resolve: {
          data: ResetPasswordResolver
        }
      },
      {
        path: 'registration-verification/:token',
        component: RegistrationVerificationComponent,
        resolve: {
          data: RegistrationVerificationResolver
        }
      },
      {
        path: 'confirmation',
        component: ConfirmationComponent,
        data: { breadcrumb: 'confirmation' }
      },
      {
        path: 'email-verification',
        component: EmailVerificationComponent,
        canActivate: [LandingPageGuard]
      },
      {
        path: 'expired-link',
        component: ExpiredLinkComponent,
        canActivate: [LandingPageGuard]
      },
      {
        path: '**',
        redirectTo: '/not-found'
      }
    ]
  }
];
@NgModule({
  imports: [
    VendorModule,
    RouterModule.forChild(routes),
    AppCardModule,
    AppButtonModule,
    AppLandingPageCardModule,
    AppTextFieldModule
  ],
  declarations: [
    LandingPageComponent,
    EmailVerificationComponent,
    RegistrationVerificationComponent,
    ResetPasswordComponent,
    ExpiredLinkComponent,
    ResetPasswordSuccessComponent,
    ConfirmationComponent
  ],
  providers: [
    RegistrationVerificationResolver,
    ResetPasswordResolver,
    LandingPageGuard
  ]
})
export class LandingPageModule {}
