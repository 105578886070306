/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationDTO } from '../dto/authentication.dto';
import { Global } from '../global/global';
import { LogoFileResponse } from '../model/logo-file-response';

import { Response } from '../model/response-model';
import { ResponseStatusModel } from '../model/response-status-model';
import { UserSessionModel } from '../model/user-session-model';
import { AuthenticationResponse } from '../response/authentication-response';
import { HttpClientService } from './http-client.service';
import { Log } from './logger';
import { SensitiveDataService } from './sensitive-data.service';
import { ServiceLocator } from './service-locator';
import { UserNotificationWebsocketService } from './websocket/user-notification-websocket.service';
@Injectable()
export class AuthenticationService {
  public authenticationDTO: AuthenticationDTO;
  public log: Log = new Log(this);
  public URL: string;
  constructor(
    public global: Global,
    public http: HttpClient,
    public router: Router,
    public sensitiveDataService: SensitiveDataService,
    private httpClientService: HttpClientService
  ) {}
  public getAuth(
    email: string,
    password: string,
    roleCode: string,
    isDontAskMeAgain: boolean
  ): Observable<Response<AuthenticationResponse>> {
    const authenticationDTO = new AuthenticationDTO();

    authenticationDTO.auth = this.sensitiveDataService.encrypt([
      email,
      password
    ]);
    authenticationDTO.roleCode = roleCode;
    authenticationDTO.isDontAskMeAgain = isDontAskMeAgain;
    authenticationDTO.langCode = this.global.getCurrentLang();
    return this.http.post<Response<AuthenticationResponse>>(
      '/login/getauth',
      authenticationDTO
    );
  }

  public logingIn(authenticationResponse: AuthenticationResponse): void {
    if (authenticationResponse.user.lang !== null) {
      localStorage.removeItem('lang');
      localStorage.setItem(
        'lang',
        authenticationResponse.user.lang.code.toLowerCase()
      );
    }
    localStorage.setItem(
      this.global.appConstant.core.TOKEN_KEY,
      authenticationResponse.strToken
    );
    localStorage.setItem(
      this.global.appConstant.core.COOKIES_APP_ACTIVE_ROLE_CODE,
      authenticationResponse.activeUserRole.role.code
    );
    this.global.userSession = new UserSessionModel(authenticationResponse);
    this.loadLogo();
    this.URL !== null && this.URL !== '/' && !this.URL.includes('portal')
      ? this.router.navigate([this.URL])
      : this.router.navigate(['/pages/dashboard']);
    this.URL = null;
  }

  public relogin(): void {
    this.URL = window.location.pathname;
    const token = localStorage.getItem(this.global.appConstant.core.TOKEN_KEY);
    const tokenFromCookie = this.global.config.appInfoResponse.token;
    const roleCode = localStorage.getItem(
      this.global.appConstant.core.COOKIES_APP_ACTIVE_ROLE_CODE
    );
    const authenticationDTO = new AuthenticationDTO();
    authenticationDTO.token = token;
    authenticationDTO.roleCode = roleCode;
    authenticationDTO.langCode = this.global.getCurrentLang();
    if (
      (tokenFromCookie === null || tokenFromCookie === '') &&
      token === null
    ) {
      const navigateTo = this.URL.includes('pages')
        ? '/portal/login'
        : this.URL;
      this.loadLogo();
   
      this.router.navigate([navigateTo]);
    } else {
      this.global.blockUI('relogin');
      this.http
        .post<Response<AuthenticationResponse>>(
          '/login/getauthbytoken',
          authenticationDTO
        )
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            const authenticationResponse = response.body;
            if (authenticationResponse.user !== null) {
              this.log.debug('[ Relogin OK! ]');
              if (
                authenticationResponse.user.lang !== null &&
                localStorage.getItem('lang') === null
              ) {
                localStorage.removeItem('lang');
                localStorage.setItem(
                  'lang',
                  authenticationResponse.user.lang.code.toLowerCase()
                );
              }
              this.global.userSession = new UserSessionModel(
                authenticationResponse
              );
              // this.global.routerParams.clear();
              this.global.routerParams.set('reload', this.URL);
              this.loadLogo();
            
              this.global.unblockUI();
              this.router.navigate(['/reload/']);
            }
          } else {
            this.loadLogo();
          
            this.global.unblockUI();
            this.log.debug('[ Relogin FAILED! ]');
            if (this.global.config.parameterModel.isInterfacing) {
              window.location.href = this.global.config.BACKEND_ADDRESS.replace(
                '/api',
                ''
              );
            }
          }
        });
    }
  }

  public logout(token?: string): void {
    this.URL = null;
    const authenticationDTO = new AuthenticationDTO();
    authenticationDTO.token = token ? token : this.global.userSession.token;
    this.global.httpClientService.unsubscribe();
    this.http
      .post<Response<AuthenticationResponse>>(
        '/login/logout',
        authenticationDTO
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.loadLogo();
        
          if (authenticationDTO.token === this.global.userSession.token) {
            this.disconnectAllWebsocket();
            localStorage.removeItem(this.global.appConstant.core.TOKEN_KEY);
            localStorage.removeItem(
              this.global.appConstant.core.COOKIES_APP_ACTIVE_ROLE_CODE
            );
            this.global.userSession = null;
            this.global.unblockUI();
            if (this.global.config.parameterModel.isInterfacing) {
              window.location.href = this.global.config.BACKEND_ADDRESS.replace(
                '/api',
                ''
              );
            } else {
              this.router.navigate(['/portal/']);
            }
          }
        }
      });
  }

  // Method disconnect websocket, private
  private disconnectAllWebsocket(): void {
    const userNotificationWebsocketService = ServiceLocator.injector.get(
      UserNotificationWebsocketService
    );


    userNotificationWebsocketService.disconnect();

  }

  public getImageLogo(fileType: string): void {
    this.httpClientService
      .post<LogoFileResponse>('/app-info/logo', fileType)
      .subscribe(logoFileResponse => {
        if (logoFileResponse !== null) {
          if (logoFileResponse.mainLogoFileName !== null) {
            const mainLogoFileName: string = logoFileResponse.mainLogoFileName;
            this.global.mainLogoFileName = mainLogoFileName;
          }
          if (logoFileResponse.smallLogoFileName !== null) {
            const smallLogoFileName: string =
              logoFileResponse.smallLogoFileName;
            this.global.smallLogoFileName = smallLogoFileName;

            
          }
        }
      });
  }

  public loadLogo(): void {
    this.getImageLogo(this.global.appConstant.fileType.IMG_MAIN_LOGO);
    this.getImageLogo(this.global.appConstant.fileType.IMG_SMALL_LOGO);
  }

 
}
