import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { WebContent } from '../../core/bean/web-content';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './registration-vendor-policy.component.html',
  styleUrls: ['./registration-vendor-policy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationVendorPolicyComponent extends BaseModuleComponent {
  public vendorPolicy: string;
  public isCheckVendorPolicy: boolean;
  public isFromTOC: boolean;
  constructor(public translate: TranslateService) {
    super('registration', translate);
  }

  public onInit(): void {
    this.setDataFromRouterParam();
    this.buildFormGroup();
    this.doGetVendorPolicy();
  }

  public setDataFromRouterParam(): void {
    this.isCheckVendorPolicy = this.global.routerParams.get(
      'isCheckVendorPolicy'
    );
    this.isFromTOC = this.global.routerParams.get('isFromTOC');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      checkVendorPolicy: [
        false,
        Validators.compose([
          Validators.required(),
          Validators.isChecked(1, 'registration.validation.vendorPolicy')
        ])
      ]
    });
  }

  public doGetVendorPolicy(): void {
    this.httpClientService
      .get<WebContent>(
        '/vendor-policy/get-vendor-policy/' + this.global.currentLang
      )
      .subscribe(vendorPolicy => {
        this.vendorPolicy = vendorPolicy.value;
        this.formGroup.patchValue({
          checkVendorPolicy: this.isFromTOC ? this.isCheckVendorPolicy : false
        });
        this.global.routerParams.set('isFromTOC', false);
        window.scroll({
          top: 0
        });
        this.setStateReady();
      });
  }

  public doNext(): void {
    this.global.routerParams.set(
      'isCheckVendorPolicy',
      this.formGroup.get('checkVendorPolicy').value
    );
    this.global.routerParams.set('isFromVendorPolicy', true);
    this.router.navigate(['/portal/registration/toc']);
  }
}
