<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="qc.export.title" [isLoading]="formLoading" [isSaving]="formSaving">
      <app-table [model]="tableResponse" [isServerSide]="true" stringUrl="/qc/index/3">
        <ng-template #headerButtons>
          <div class="col-4 col-md-2">
            <button type="button" class="btn  btn-primary btn-block" (click)="doAdd()"><em
                class="fas fa-plus mr-1"></em>{{ 'app.button.add' |
              translate }}</button>
          </div>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <div class="d-flex justify-content-center" >
            <app-button *ngIf="!rowData.submitDate" size="TN" class="mr-1"   (onClick)="doEdit(rowData)"
              title="{{ 'app.tooltip.edit' | translate }}">
              <em class="fa fa-pencil-alt"></em>
            </app-button>

            <app-button *ngIf="rowData.submitDate"  size="TN" color="SUCCESS" class="mr-1"  (onClick)="doEdit(rowData)"
              title="{{ 'app.tooltip.view' | translate }}">
              <em class="fa fa-search"></em>
            </app-button>

            <app-button *ngIf="!rowData.submitDate" size="TN" color="DANGER" (onClick)="doDelete(rowData)"
              title="{{ 'app.tooltip.delete' | translate }}">
              <em class="fa fa-trash"></em>
            </app-button>
          </div>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>