<div class="app-date-picker p-0" [ngClass]="elementWidthClassName">
  <ng-container *ngIf="ISVIEW; then isViewTemplate; else defaultTemplate">
  </ng-container>
  <ng-template #isViewTemplate>
    <ng-container *ngIf="formControl.value">
      <span class="date-text" maxLine [targetSelector]="generatedId">
        {{
          (range
            ? formControl.value.from
            : (state.currentDateValue && state.currentDateValue.date) ||
              formControl.value
          )
            | date
              : global.appConstant.core.FORMAT_SHORT_DATE +
                  (time && !range ? '; HH:mm' : '')
        }}
        <ng-container
          *ngIf="range && formControl.value && formControl.value.to"
        >
          -
          {{
            formControl.value.to
              | date: global.appConstant.core.FORMAT_SHORT_DATE
          }}
        </ng-container>
      </span>
    </ng-container>
    <ng-container
      *ngIf="
        (!range && !formControl.value) ||
        (range &&
          formControl.value &&
          !formControl.value.from &&
          !formControl.value.to)
      "
      >-</ng-container
    >
  </ng-template>
  <ng-template #defaultTemplate>
    <div
      class="form-control d-flex justify-content-between"
      [attr.id]="generatedId"
      [ngClass]="[isValid ? 'is-valid' : '', isInvalid ? 'is-invalid' : '']"
    >
      <span
        class="date-text"
        *ngIf="
          (!state.model.range && formControl.value) ||
          (time && state.currentDateValue) ||
          (state.model.range && formControl.value && formControl.value.from)
        "
        maxLine
        [appDropDown]="dropDownCalendar"
        [targetSelector]="generatedId"
        (onClick)="doChangeView('DATE')"
        position="BOTTOM-RIGHT"
      >
        <ng-container
          *ngIf="
            state.currentDateValue &&
            state.currentDateValue.date &&
            !formControl.value
          "
        >
          {{
            state.currentDateValue.date
              | date: global.appConstant.core.FORMAT_SHORT_DATE
          }}
        </ng-container>
        <ng-container
          *ngIf="
            (state.currentDateValue && formControl.value) ||
            (!state.currentDateValue && formControl.value)
          "
        >
          {{
            (state.model.range
              ? formControl.value.from
              : (state.currentDateValue && state.currentDateValue.date) ||
                formControl.value
            ) | date: global.appConstant.core.FORMAT_SHORT_DATE
          }}
        </ng-container>

        <ng-container
          *ngIf="state.model.range && formControl.value && formControl.value.to"
        >
          -
          {{
            formControl.value.to
              | date: global.appConstant.core.FORMAT_SHORT_DATE
          }}
        </ng-container>
      </span>
      <span
        class="date-text placeholder mr-2"
        *ngIf="
          (!state.model.range &&
            !formControl.value &&
            ((time && !state.currentDateValue) || !time)) ||
          (state.model.range && !formControl.value)
        "
        maxLine
        [appDropDown]="dropDownCalendar"
        [targetSelector]="generatedId"
        (onClick)="doChangeView('DATE')"
        position="BOTTOM-RIGHT"
      >
        {{ 'dd/mm/yyyy ' }}
        <!-- {{ global.appConstant.core.FORMAT_SHORT_DATE }} -->
        <ng-container *ngIf="state.model.range">
          {{ '- dd/mm/yyyy ' }}
          <!-- - {{ global.appConstant.core.FORMAT_SHORT_DATE }} -->
        </ng-container>
      </span>

      <!-- <span class="time-text" *ngIf="!range && time && !state.currentTime">
      HH:mm {{ state.currentTime && state.currentTime.timeString }}
    </span> -->
      <div class="time-text" *ngIf="!range && time && isTimeLoaded">
        <span
          class="hours"
          contenteditable="true"
          (input)="onInputTime($event, 'HOURS')"
          (focusin)="onFocusInTime($event, 'HOURS')"
          (focusout)="onFocusOutTime($event, 'HOURS')"
          (keydown)="onKeydownTime($event)"
        >
          {{
            (state.currentTime && state.currentTime.hoursString) ||
              (formControl.value | date: 'HH') ||
              'hh'
          }}
        </span>
        :
        <span
          class="minutes"
          contenteditable="true"
          (input)="onInputTime($event, 'MINUTES')"
          (focusin)="onFocusInTime($event, 'MINUTES')"
          (focusout)="onFocusOutTime($event, 'MINUTES')"
          (keydown)="onKeydownTime($event)"
        >
          {{
            (state.currentTime && state.currentTime.minutesString) ||
              (formControl.value | date: 'mm') ||
              'mm'
          }}
        </span>
      </div>
      <div class="app-date-picker-button">
        <em class="pir pi-times" (click)="doReset()"></em>
        <em
          class="pir pi-calendar cursor-pointer ml-2"
          [appDropDown]="dropDownCalendar"
          [targetSelector]="generatedId"
          (onClick)="doChangeView('DATE')"
          position="BOTTOM-RIGHT"
        ></em>
        <em
          class="pir pi-clock cursor-pointer ml-2"
          *ngIf="!state.model.range && time"
          [appDropDown]="dropDownTime"
          [targetSelector]="generatedId"
          position="BOTTOM-RIGHT"
        ></em>
      </div>
    </div>
    <small class="text-danger" *ngIf="isInvalid">
      {{ errors.message }}
    </small>
  </ng-template>
</div>

<ng-template #dropDownTime>
  <div class="drop-down-time-wrapper ps ps--active-y" perfectScrollbar>
    <ul class="time-list">
      <li
        class="time"
        *ngFor="let datePickerTime of state.timeList; let i = index"
        [attr.aria-label]="datePickerTime.timeString"
        (click)="doSetDatePickerTime(datePickerTime)"
        [ngClass]="
          datePickerTime
            | resolveDisabledTime
              : formControl.value ||
                  (state.currentDateValue && state.currentDateValue.date)
              : state.minDate && state.minDate.date
              : state.maxDate && state.maxDate.date
        "
      >
        {{ datePickerTime.timeString }}
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #dropDownCalendar>
  <div class="calendar-wrapper">
    <div class="calendar-header">
      <span class="month-year">
        {{
          'app.monthNameLong.' +
            global.appConstant.core.MONTH_NAME_LIST[state.currentDate.month]
            | translate
        }}
        {{ state.currentDate.year }}
      </span>
      <span class="day-count">
        <ng-container *ngIf="!state.model.range">
          {{
            'app.dayNameLong.' +
              global.appConstant.core.DAY_NAME_LIST[state.currentDate.day]
              | translate
          }}, {{ state.currentDate.dateNumber }}
        </ng-container>
        <ng-container
          *ngIf="state.model.range && state.currentDateRange.totalSelectedDate"
        >
          {{ state.currentDateRange.totalSelectedDate }} Days
        </ng-container>

        <ng-container
          *ngIf="state.model.range && !state.currentDateRange.totalSelectedDate"
          >-</ng-container
        >
      </span>
    </div>
    <div class="calendar-body">
      <ng-container *ngIf="state.view === 'DATE'">
        <ng-container *ngTemplateOutlet="calendarBodyNavigation"></ng-container>
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          state.view === 'DATE'
            ? calendar
            : state.view === 'MONTH'
            ? calendarMonth
            : calendarYear
        "
      ></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #calendarBodyNavigation>
  <div class="calendar-navigation">
    <div class="month-navigation">
      <span class="button-controls">
        <em
          class="fas fa-chevron-left"
          (click)="doSetDatePickerMonth(state.currentDate.month - 1)"
        ></em>
      </span>
      <span class="month-text cursor-pointer" (click)="doChangeView('MONTH')">
        {{
          'app.monthNameLong.' + state.monthList[state.currentDate.month]
            | translate
        }}
      </span>
      <span class="button-controls">
        <em
          class="fas fa-chevron-right"
          (click)="doSetDatePickerMonth(state.currentDate.month + 1)"
        ></em>
      </span>
    </div>
    <div class="year-navigation">
      <span class="button-controls">
        <em
          class="fas fa-chevron-left"
          (click)="doSetDatePickerYear(state.currentDate.year - 1)"
        ></em>
      </span>
      <span class="year-text cursor-pointer" (click)="doChangeView('YEAR')">
        {{ state.currentDate.year }}
      </span>
      <span class="button-controls">
        <em
          class="fas fa-chevron-right"
          (click)="doSetDatePickerYear(state.currentDate.year + 1)"
        ></em>
      </span>
    </div>
  </div>
</ng-template>

<ng-template #calendar>
  <div class="promise-calendar">
    <ul class="days-name">
      <li
        class="day-name"
        *ngFor="
          let dayName of global.appConstant.core.DAY_NAME_LIST;
          let i = index
        "
        [ngClass]="i === 0 ? 'text-danger' : ''"
        >{{ 'app.dayNameShort.' + dayName | translate }}</li
      >
    </ul>
    <ul class="date-list">
      <li
        class="date"
        *ngFor="let datePickerDate of state.dateList; let i = index"
        [ngClass]="[
          datePickerDate.dateString
            | resolveDatePickerClassName
              : datePickerDate.monthPosition
              : datePickerDate.isCurrentDate
              : i
              : (state.currentDateValue && state.currentDateValue.date) ||
                  formControl.value
              : range &&
                  state.currentDateRange.start &&
                  state.currentDateRange.start.dateString
              : range &&
                  state.currentDateRange.on &&
                  state.currentDateRange.on.dateString
              : range &&
                  state.currentDateRange.end &&
                  state.currentDateRange.end.dateString
              : range,
          state.minDate && state.minDate.dateTime > datePickerDate.dateTime
            ? 'disabled'
            : '',
          state.maxDate && datePickerDate.dateTime > state.maxDate.dateTime
            ? 'disabled'
            : ''
        ]"
        (mouseenter)="onMouseEnter(datePickerDate)"
      >
        <span
          class="text date"
          (click)="doClickDate(datePickerDate)"
          [attr.aria-label]="datePickerDate.date | date: 'EEEE, MMMM dd yyyy'"
        >
          {{ datePickerDate.dateNumber }}
        </span>
      </li>
    </ul>
  </div>
</ng-template>

<!--
          !isRange && state.maxDate.dateTime > date.dateTime ? 'disabled' : ''
 -->

<ng-template #calendarMonth>
  <div class="calendar-month-wrapper">
    <ul class="calendar-month-list">
      <li
        class="month-item"
        *ngFor="
          let monthName of global.appConstant.core.MONTH_NAME_LIST;
          let i = index
        "
      >
        <span
          class="month-text"
          [ngClass]="i === 0 ? 'active' : ''"
          (click)="doSetDatePickerMonth(i)"
        >
          {{ 'app.monthNameShort.' + monthName | translate }}
        </span>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #calendarYear>
  <div class="calendar-year-wrapper">
    <span class="controls">
      <em
        class="fas fa-chevron-left"
        (click)="doSetDatePickerYearList('BACK')"
      ></em>
    </span>
    <ul class="calendar-year-list">
      <li class="year-item" *ngFor="let year of state.yearList; let i = index">
        <span
          class="text"
          [ngClass]="year === state.currentDate.year ? 'active' : ''"
          (click)="doSetDatePickerYear(year)"
        >
          {{ year }}
        </span>
      </li>
    </ul>
    <span class="controls">
      <em
        class="fas fa-chevron-right"
        (click)="doSetDatePickerYearList('NEXT')"
      ></em>
    </span>
  </div>
</ng-template>
