<div class="row">
  <div class="col-lg-12 mb-4">
    <app-card header="login-log.title" [isLoading]="formLoading">
      <div id="accordion">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                aria-controls="collapseOne"><i class="fa fa-search mr-2"></i>Search</button>
            </h5>
          </div>

          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body" style="padding:2rem">

              <form class="form-horizontal" [formGroup]="formGroup" novalidate>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <label class="control-label">{{ 'login-log.date.login' |
                          translate }}</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5 text-left">
                        <div class="form-group">
                          <label class="mr-3" for="from">{{ 'login-log.date.from' |
                            translate }}</label>
                          <div class="col-md-12 p-0">
                            <app-date-picker size="XL" formControlName="loginDateFrom" maxDate="loginDateTo">
                            </app-date-picker>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5 text-left">
                        <div class="form-group">
                          <label class="mr-3" for="inputLastName">{{ 'login-log.date.to'
                            | translate }}</label>
                          <div class="col-md-12 p-0">
                            <app-date-picker size="XL" formControlName="loginDateTo" minDate="loginDateFrom">
                            </app-date-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <label class="control-label">{{ 'login-log.date.logout' |
                          translate }}</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 text-left">
                        <div class="form-group">
                          <label class="mr-3" for="inputFirstName">{{
                            'login-log.date.from' | translate }}</label>
                          <div class="col-md-12 p-0">
                            <app-date-picker size="XL" formControlName="logoutDateFrom" maxDate="logoutDateTo">
                            </app-date-picker>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 text-left">
                        <div class="form-group">
                          <label class="mr-3" for="inputWebsite">{{ 'login-log.date.to'
                            | translate }}</label>
                          <div class="col-md-12 p-0">
                            <app-date-picker size="XL" formControlName="logoutDateTo" minDate="logoutDateFrom">
                            </app-date-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-end mt-5">

                  <div class="col-md-2">
                    <button (click)="doExport()" class="btn btn-primary btn-block  mr-2 mb-2"> <i class="mr-2 fa"
                        [ngClass]="!isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'"></i> Print</button>
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-block btn-outline-secondary mr-2 mb-2"><em class="mr-2 fas fa-sync-alt"></em>Reset</button>
                   </div>
                   <div class="col-md-2">
                        <button (click)="doApply()" class="btn btn-block btn-primary mb-2"><i class="fa fa-search mr-2"></i>Cari</button>
                  </div>
                </div>
                
                
              </form>
            </div>
          </div>
        </div>
      </div>


      <app-table [model]="tableResponse" [isServerSide]="true" stringUrl="/login-log/index">

        <ng-template #headerFilterTable>
          <div class="app-table-filter-item" [formGroup]="formGroup">
            <label for="organizationName">Organisasi</label>
            <app-choose size="XL" formControlName="organizationName" (onClick)="onChooseOrganization($event)">
            </app-choose>
          </div>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button mode="BYPASS" color="DANGER"
            *ngIf="rowData.statusText === 'Active' && rowData.token !== global.userSession?.token" size="TN"
            class="mr-2" title="{{ 'login-log.tooltip.forceLogout' | translate }}" (onClick)="doForceLogout(rowData)">
            <em class="fas fa-sign-out-alt"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>



  </div>
</div>