/*
 * Filename:  WebContent\src\app\core\components\app-table-x\plugins\wyiwyg.plugn.ts
 * Path:  WebContent
 * Created Date: Thursday, August 12th 2021, 10:51:26 am
 *
 *
 */
import { AppDynamicComponentService } from '../../../services/app-dynamic-component.service';
import { AppDatePickerXComponent } from '../../app-date-picker-x/app-date-picker-x.component';
import { createPluginFormcontrol } from '../helpers';
import { reSetTableRecord } from '../helpers/re-set-table-record.helper';
import { DatePickerPlugin } from '../interfaces/available-table-plugin';
import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';
export const AppTableDatePickerPlugin: TablePlugin = {
  name: 'date-picker',
  before: (data: TablePluginData) => {
    const datePickerPlugin: DatePickerPlugin = data.column
      .plugins as DatePickerPlugin;
    const appDynamicComponent = data.state.injector.get(
      AppDynamicComponentService
    );
    let config: any = {};
    if (typeof datePickerPlugin !== 'string' && datePickerPlugin) {
      config = datePickerPlugin;
    }
    const formControl = createPluginFormcontrol(data);
    formControl.setIsView(!!data.state.isView);
    appDynamicComponent
      .create(AppDatePickerXComponent, data.element, {
        ...config,
        formControl: formControl,
        size: config.size || 'XL'
      })
      .onChange.subscribe((value: any) => {
        formControl.markAsTouched();
        const record =
          data.state.store.recordMap.get(JSON.stringify(data.record.record)) ||
          data.record.record;

        data.state.store.recordMap.set(
          JSON.stringify(data.record.record),
          reSetTableRecord(record, data.column.field, value)
        );
        data.state.model.updatedRecordList = data.state.store.getRecords();
        data.state.event.emit({
          type: 'CHANGE-RECORD',
          data: {
            record: data.state.store.recordMap.get(
              JSON.stringify(data.record.record)
            )
          }
        });
      });
    data.element.style.width = 'max-content';
  }
};
