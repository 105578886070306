
import { Component } from '@angular/core';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetTaskActivityResponseDTO } from './widget-task-activity-response-dto';
@Component({
  templateUrl: './widget-task-activity.component.html'
})
export class WidgetTaskActivityComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-task-activity';

  public widgetTaskActivityResponse: WidgetTaskActivityResponseDTO;
  public taskActivityList = [];
  private TASK_CODE_PROC_ANNOUNCEMENT = 'PENGUMUMAN_PENGADAAN';
  private TASK_CODE_PROC_INVITATION = 'UNDANGAN_PENGADAAN';
  private TASK_CODE_CONTRACT = 'KONTRAK';
  private TASK_CODE_ACTIVE_PROC = 'PENGADAAN_AKTIF';
  private TASK_CODE_JOIN_PROC = 'MENGIKUTI_PENGADAAN';
  private TASK_CODE_PROC_WINS = 'MENANG_PENGADAAN';
  private TASK_CODE_REMAINING_PAYMENT = 'SISA_PEMBAYARAN';

  constructor() {
    super(WidgetTaskActivityComponent.moduleCode);
  }

  protected onInit(): void {
    this.httpClientService
      .get('/widget-task-activity/index')
      .subscribe(
        (widgetTaskActivityResponse: WidgetTaskActivityResponseDTO) => {
          this.taskActivityList = [
            {
              id: 1,
              code: this.TASK_CODE_PROC_ANNOUNCEMENT,
              title: this.moduleCode + '.procurementAnnouncement',
              content:
                widgetTaskActivityResponse.numberOfProcurementAnnouncement
                  ? widgetTaskActivityResponse.numberOfProcurementAnnouncement
                  : widgetTaskActivityResponse.numberOfProcurementAnnouncement ===
                    0
                    ? widgetTaskActivityResponse.numberOfProcurementAnnouncement
                    : '-'
            },
            {
              id: 2,
              code: this.TASK_CODE_PROC_INVITATION,
              title: this.moduleCode + '.procurementInvitation',
              content: widgetTaskActivityResponse.numberOfProcurementInvitation
                ? widgetTaskActivityResponse.numberOfProcurementInvitation
                : widgetTaskActivityResponse.numberOfProcurementInvitation === 0
                  ? widgetTaskActivityResponse.numberOfProcurementInvitation
                  : '-'
            },
            {
              id: 3,
              code: this.TASK_CODE_CONTRACT,
              title: this.moduleCode + '.contract',
              content:
                widgetTaskActivityResponse.numberOfContract ||
                  widgetTaskActivityResponse.numberOfContract === 0
                  ? widgetTaskActivityResponse.numberOfContract
                  : '-'
            },
            {
              id: 4,
              code: this.TASK_CODE_ACTIVE_PROC,
              title: this.moduleCode + '.activeProcurement',
              content:
                widgetTaskActivityResponse.numberOfActiveProcurement ||
                  widgetTaskActivityResponse.numberOfActiveProcurement === 0
                  ? widgetTaskActivityResponse.numberOfActiveProcurement
                  : '-'
            },
            {
              id: 5,
              code: this.TASK_CODE_JOIN_PROC,
              title: this.moduleCode + '.totalJoinProcurement',
              content:
                widgetTaskActivityResponse.totalJoinProcurement ||
                  widgetTaskActivityResponse.totalJoinProcurement === 0
                  ? widgetTaskActivityResponse.totalJoinProcurement
                  : '-'
            },
            {
              id: 6,
              code: this.TASK_CODE_PROC_WINS,
              title: this.moduleCode + '.totalProcurementWins',
              content:
                widgetTaskActivityResponse.totalProcurementWins ||
                  widgetTaskActivityResponse.totalProcurementWins === 0
                  ? widgetTaskActivityResponse.totalProcurementWins
                  : '-'
            },
            {
              id: 7,
              code: this.TASK_CODE_REMAINING_PAYMENT,
              title: this.moduleCode + '.remainingPayment',
              content: widgetTaskActivityResponse.remainingPayment
                ? this.global.converterService.convertMoney(
                  widgetTaskActivityResponse.remainingPayment
                ) + ' IDR'
                : widgetTaskActivityResponse.remainingPayment === 0
                  ? '0 IDR'
                  : '-'
            }
          ];
          this.global.widgetLoadingStatus.set(this.moduleCode, true);
          this.setStateReady();
        }
      );
  }

  public doNavigateToMoreDetails(task): void {
    if (task.code === this.TASK_CODE_REMAINING_PAYMENT) {
      this.router.navigate(['/pages/vendor-payment-request']);
    } else if (
      task.code === this.TASK_CODE_PROC_ANNOUNCEMENT &&
      task.content !== '-'
    ) {
      window.location.href =
        this.global.config.parameterModel.pmAddressUrl +
        '/pm/#/landingpage/pengumumanpengadaan';
    } else if (
      task.code === this.TASK_CODE_PROC_INVITATION &&
      task.content !== '-'
    ) {
      window.location.href =
        this.global.config.parameterModel.pmAddressUrl +
        '/pm/#/landingpage/undanganvendor';
    } else {
      this.global.alertService.showInfo(
        this.global.translateService.instant(
          this.moduleCode + '.validation.procurement'
        ) +
        ' ' +
        this.global.config.parameterModel.companyName
      );
    }
  }
}
