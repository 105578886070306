/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table\action\dispatch-portal-table-action.ts
 
 * Created Date: Thursday, September 30th 2021, 10:45:18 am

 *
*
 */

import { PortalTableState } from '../domain/portal-table-state';
import { changePortalTablePage } from './change-portal-table-page-action';
import { changePortalTablePerPage } from './change-portal-table-per-page-action';
import { firstLoadPortalTable } from './first-load-portal-table-action';
import * as portalTableAction from './portal-table-action';
import { reloadPortalTable } from './reload-portal-table-action';
import { searchPortalTable } from './search-portal-table-action';

export const dispatchPortalTable = (
  state: PortalTableState,
  action: portalTableAction.AllPortalTableAction
): void => {
  switch (action.type) {
    case portalTableAction.FIRST_LOAD_PORTAL_TABLE:
      firstLoadPortalTable(state);
      break;
    case portalTableAction.SEARCH_PORTAL_TABLE:
      searchPortalTable(state, action.payload);
      break;
    case portalTableAction.CHANGE_PORTAL_TABLE_PAGE:
      changePortalTablePage(state, action.payload);
      break;
    case portalTableAction.CHANGE_PORTAL_TABLE_PER_PAGE:
      changePortalTablePerPage(state, action.payload);
      break;
    case portalTableAction.RELOAD_PORTAL_TABLE:
      reloadPortalTable(state);
      break;
    default:
      console.log(`There's no type for 'portableTableAction'`);
      break;
  }
};
