import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { Module } from '../bean/module';
import { PrStatus } from '../bean/pr-status';
import { ProcurementType } from '../bean/procurement-type';
import { PurchaseType } from '../bean/purchase-type';
import { WorkPlan } from '../bean/work-plan';

export class PrEntity extends BaseWorkflowObjectEntity {
  prStatus: PrStatus = new PrStatus();
  procurementType: ProcurementType = new ProcurementType();
  workPlan: WorkPlan = new WorkPlan();
  requiredDate: Date = new Date();
  postDate: Date = new Date();
  description: string;
  amount: number;
  goodsAmount: number;
  serviceAmount: number;
  isItemContract: boolean;
  purchaseType: PurchaseType = new PurchaseType();
  isProcessed: boolean;
  fromModule: Module;
}
