/*
 * Filename:  WebContent\src\app\core\components\app-table-x\pipe\resolve-is-checked-group.pipe.ts
 * Path:  WebContent
 * Created Date: Monday, August 16th 2021, 4:38:15 pm

 *
*
 */

import { Pipe, PipeTransform } from '@angular/core';
import { ResolveInputIsCheckedPipe } from '../../../pipe/resolve-input-is-checked.pipe';
import { SelectedTableRow } from '../domain/selected-table-row';
import { TableRow } from '../domain/table-row';
@Pipe({
  name: 'resolveTableRowIsSelected'
})
export class ResolveTableRowIsSelectedPipe implements PipeTransform {
  constructor(private resolveInputIsCheckedPipe: ResolveInputIsCheckedPipe) {}
  transform(
    selectedTableRow: SelectedTableRow,
    tableRow: TableRow,
    isCheckedAll?: boolean,
    valueField?: string
  ): boolean {
    if (tableRow.childrenList && tableRow.childrenList.length > 0) {
      return (
        isCheckedAll ||
        Array.from(tableRow.childrenList)
          .map(recordChildren =>
            selectedTableRow
              .getValue()
              .selectedRecordList.findIndex(
                checkedRecord =>
                  JSON.stringify(recordChildren.record) ===
                  JSON.stringify(checkedRecord)
              )
          )
          .filter(val => val === -1).length === 0
      );
    } else {
      if (
        isCheckedAll &&
        selectedTableRow
          .getUnSelectedRecordList()
          .findIndex(
            r => JSON.stringify(r) === JSON.stringify(tableRow.record)
          ) !== -1
      ) {
        return false;
      }
      return this.resolveInputIsCheckedPipe.transform(
        selectedTableRow.getValue().selectedRecordList,
        tableRow.record,
        isCheckedAll,
        valueField
      );
    }
  }
}
