
export class AccordionService {
  public availableAccordion: Map<string, object> = new Map();
  public register(widgetSelector: string, widget: object): void {
    this.availableAccordion.set(widgetSelector, widget);
  }

  public getAccordion(selector: string): object {
    return this.availableAccordion.get(selector);
  }
}
