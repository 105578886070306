import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Guarantee } from 'src/app/core/bean/guarantee';
import { AppPopupService } from '../app-popup.service';
import { AppPopupGuaranteeComponent } from './app-popup-guarantee.component';

@Injectable()
export class AppPopupGuaranteeService {
  constructor(public appPopupService: AppPopupService) {}
  public openPo(
    todo: string,
    amountSource?: number,
    guaranteeList?: Guarantee[],
    guaranteeEdit?: Guarantee
  ): Observable<any> {
    return this.appPopupService.open(
        AppPopupGuaranteeComponent,
      {
        todo,
        amountSource,
        guaranteeEdit,
        guaranteeList
      },
      { size: 'lg' }
    );
  }
}
