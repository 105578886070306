import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../../bean/user';
import { AppPopupService } from '../app-popup.service';
import { AppPopupAddUserComponent } from './app-popup-add-user.component';

@Injectable()
export class AppPopupAddUserService {
  constructor(public appPopupService: AppPopupService) {}

  public open(
    userList?: User[],
    userTempList?: User[],
    assigneeRoleIdList?: number[],
    isSelectAllDisabled?: boolean
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupAddUserComponent,
      { userList, userTempList, assigneeRoleIdList, isSelectAllDisabled },
      { size: 'lg' }
    );
  }
}
