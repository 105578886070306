export class Utils {

    public circularReplacer() {
        const seen = new WeakSet();
        return (_key, value) => {
          if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
              return;
            }
            seen.add(value);
          }
          return value;
        };
      }
    

      public JSONStringfy( obj:any):string {

        return JSON.stringify(obj, this.circularReplacer());
      }
    
}


