import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DirectivesModule } from '../../../../core/directives/directives.module';
import { SidebarComponent } from './sidebar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/core/pipe/pipe.module';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    PerfectScrollbarModule,
    DirectivesModule,
    ReactiveFormsModule,
    PipesModule
  ],
  declarations: [SidebarComponent],
  exports: [SidebarComponent]
})
export class SidebarModule {
  public static forRoot(): ModuleWithProviders<SidebarModule> {
    return {
      ngModule: SidebarModule
     
    };
  }


}
