<div class="app-tree">
  <div class="content-wrapper">
    <div class="tree-control left" [attr.id]="'control-left' + generatedId">
      <em
        class="fas fa-chevron-left"
        (click)="doScrollToLeft()"
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
      ></em>
    </div>
    <div class="app-tree-body row" [attr.id]="generatedId" #appTreeBody>
      <div
        class="app-tree-item col-12 col-sm-6 col-lg-4"
        *ngFor="let tree of this.model.treeList; index as i"
      >
        <header class="item-header">
          <input
            type="text"
            class="form-control"
            [attr.placeholder]="'app-tree.search' | translate"
            (input)="doSearch($event.target.value, i)"
            aria-label="{{ 'search-' + generatedId + '-' + i }}"
          />
          <em
            class="fas fa-search"
            [ngStyle]="{ right: model.mode === 'READ' ? '1.4rem' : '3.5rem' }"
          ></em>
          <button
            class="btn btn-primary"
            [attr.title]="'app.button.add' | translate"
            (click)="doAdd(i)"
            [disabled]="model.disableRootButton && i === 0"
            *ngIf="model.mode === 'WRITE'"
          >
            <em class="fas fa-plus"></em>
          </button>
        </header>
        <div class="form-loader" *ngIf="tree.isLoading"></div>

        <ul class="item-content my-2" *ngIf="!tree.isLoading">
          <app-lazy-load
            [isLoading]="tree.isLoading"
            (onScrollEnd)="onScrollEnd(i, $event)"
            target=".item-content"
            maxHeight="400px"
          >
            <li
              class="content-child"
              *ngFor="let treeItem of tree.treeItemList; index as j"
              (click)="doClickTreeItem(treeItem, i)"
              [ngClass]="treeItem.selected ? 'selected' : ''"
              [attr.title]="treeItem.code + '\n' + treeItem.name"
              (keyup)="onKeyUp($event)"
              (keydown)="onKeyDown($event)"
            >
              <div class="content-child-left">
                <div
                  class="custom-control custom-checkbox"
                  (click)="doChecked($event, treeItem)"
                  *ngIf="model.mode === 'READ' && model.isMultiple"
                  (keyup)="onKeyUp($event)"
                  (keydown)="onKeyDown($event)"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="{{ 'tree-check-box' + treeItem.id }}"
                    [value]="treeItem.isChecked"
                    [checked]="treeItem.isChecked"
                    [ngClass]="
                      model.onlyLastChild && treeItem.hasChild
                        ? 'checkbox-secondary'
                        : 'checkbox-primary cursor-pointer'
                    "
                    [disabled]="model.onlyLastChild && treeItem.hasChild"
                  />
                  <label
                    class="custom-control-label"
                    for="{{ 'tree-check-box' + treeItem.id }}"
                  ></label>
                </div>
                <span class="app-tree-item-content-text">
                  <ng-container *ngIf="customTextTmpl">
                    <ng-container
                      *ngTemplateOutlet="
                        customTextTmpl;
                        context: { $implicit: treeItem }
                      "
                    >
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!customTextTmpl">
                    {{ treeItem.name }}
                  </ng-container>
                </span>
              </div>
              <div class="content-child-right">
                <div
                  class="button-group button-group-right"
                  *ngIf="model.mode === 'WRITE'"
                >
                  <app-button
                    size="TN"
                    title="{{ 'app.tooltip.edit' | translate }}"
                    (onClick)="doEdit($event, treeItem, i, j)"
                  >
                    <em class="fas fa-pencil-alt"></em>
                  </app-button>
                  <app-button
                    size="TN"
                    color="DANGER"
                    title="{{ 'app.tooltip.delete' | translate }}"
                    [hidden]="
                      (model.disableRootButton && i === 0) ||
                      model.disableAllButton
                        ? 'hidden'
                        : ''
                    "
                    (onClick)="
                      doDelete($event, treeItem, i, j); $event.stopPropagation()
                    "
                    *ngIf="!treeItem.hasChild"
                  >
                    <em class="fas fa-trash"></em>
                  </app-button>
                </div>
                <em
                  class="fas fa-caret-right"
                  [ngClass]="
                    !treeItem.hasChild || i >= model.limitLevel
                      ? 'transparent'
                      : ''
                  "
                ></em>
              </div>
            </li>
            <li class="text-center my-2" *ngIf="tree.isNoData">
              {{ 'app.info.noData' | translate }}
            </li>
          </app-lazy-load>
        </ul>
      </div>
    </div>
    <div class="tree-control right" [attr.id]="'control-right' + generatedId">
      <em
        class="fas fa-chevron-right"
        (click)="doScrollToRight()"
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
      ></em>
    </div>
  </div>
  <div
    class="app-tree-footer"
    [hidden]="model.treeProcess.treeProcessItemList.length === 0"
    *ngIf="!footerButtonTmpl || model.mode === 'READ'"
  >
    <div class="button-group button-group-right mt-3">
      <app-button color="SECONDARY" (onClick)="doCancel()">
        {{ 'app.button.cancel' | translate }}
      </app-button>
      <app-button
        (onClick)="doSave()"
        [disabled]="
          model.mode === 'READ'
            ? model.onlyLastChild &&
              model.value.length > 0 &&
              model.value[model.value.length - 1].hasChild
            : false
        "
      >
        {{
          (model.mode === 'WRITE' ? 'app.button.save' : 'app.button.choose')
            | translate
        }}
      </app-button>
    </div>
  </div>
  <div
    class="app-tree-footer"
    *ngIf="footerButtonTmpl && model.treeProcess.treeProcessItemList.length > 0"
  >
    <ng-container
      *ngTemplateOutlet="
        footerButtonTmpl;
        context: { $implicit: model.treeProcess }
      "
    >
    </ng-container>
  </div>
</div>
