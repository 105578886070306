 <div *ngIf="global.userSession.activeUserRole.role.code=='QC'" class="row">
  <div class="col-12 pl-3 pr-3 mb-0">
    <div class="callout callout-info">
      <h5>Selamat datang {{
        global.userSession.user.name }} </h5>
      <p>Jangan lupa cek tugas dan jadwal kamu</p>
    </div>
  </div>
</div>


<app-metabase *ngIf="global.userSession.activeUserRole.role.code=='ADMIN'"></app-metabase>


<div class="row">
  <div class="col-12">
    <dashboard-widget [model]="widgetModel" [isLoading]="formLoading"></dashboard-widget>
  </div>
</div>