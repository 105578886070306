/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\core\pipe\search.pipe.ts
 
 * Created Date: Wednesday, November 3rd 2021, 12:00:17 am

 *
*
 */
import { Pipe, PipeTransform } from '@angular/core';
import { ArrayUtils } from '../utils';
@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(
    array: Array<any> = [],
    keyword: string = '',
    filterBy: string = 'name'
  ): Array<any> {
    if (keyword) {
      return ArrayUtils.filterArrayByText(Array.from(array), keyword, filterBy);
    }
    return array;
  }
}
