import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'coloringByKeyword' })
export class ColoringByKeywordPipe implements PipeTransform {
  transform(value: string, term: string): string {
    if (!term || !value) return value;
    const regEx = new RegExp(term, 'gi');
    return value.replace(regEx, match => `<strong>${match}</strong>`);
  }
}

