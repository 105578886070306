import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Officer } from '../../core/bean/officer';
import { AppPopupUserService } from '../../core/components/app-popup/app-popup-user/app-popup-user.service';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { Organization } from './../../core/bean/organization';
import { OfficerResponse } from './officer-response';

@Component({
  templateUrl: './officer-edit-add.component.html'
})
export class OfficerEditAddComponent extends BaseModuleComponent {
  public officerResponse: OfficerResponse = new OfficerResponse();
  public officerId: number;
  public organization: Organization = new Organization();
  public autoCompleteValue: any;

  constructor(
    translateService: TranslateService,
    public appPopupUserService: AppPopupUserService
  ) {
    super('officer', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.organization = this.global.routerParams.get('organization');
    this.officerId = this.global.routerParams.get('officerId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      position: [null, Validators.required()],
      user: [null],
      userName: [null, Validators.required()],
      organization: [null]
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<OfficerResponse>(
        '/officer/add-edit',
        new RouteRequestModel(this.todo, this.officerId)
      )
      .subscribe(officerResponse => {
        this.officerResponse = officerResponse;
        if (this.officerResponse.officer != null) {
          const { id, user, organization, position } =
            this.officerResponse.officer;
          const { name: userName } = user;
          this.formGroup.patchValue({
            id,
            position,
            user,
            userName,
            organization
          });
        }
        this.setStateReady();
      });
  }

  public autoCompleteValueChange(event: any): void {
    this.autoCompleteValue = event;
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      const msgConfirm =
        this.todo === 'add'
          ? 'app.confirmation.save.msg'
          : this.translateService.instant(
              'officer.confirmation.save.msgPrefix'
            ) +
            ' ' +
            this.officerResponse.officer.user.name +
            ' ' +
            this.translateService.instant(
              'officer.confirmation.save.msgSuffix'
            );
      this.global.modalService
        .confirmation(
          msgConfirm,
          'app.confirmation.save.title',
          'app.button.cancel',
          'app.button.yes',
          null,
          false
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const officer = this.global.copyFormAttributeToModel(
              new Officer(),
              this.formGroup
            );
            if (this.todo === 'add') {
              officer.organization = this.organization;
            }
            const url =
              this.todo === 'edit' ? '/officer/update' : '/officer/insert';
            this.httpClientService
              .post<Response<Officer>>(url, officer)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.routerParams.set(
                    'organization',
                    this.organization
                  );
                  this.router.navigate(['/pages/officer/detail']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.global.routerParams.set('organization', this.organization);
    this.router.navigate(['/pages/officer/detail']);
  }

  public doChooseUser(): void {
    const userIdList: number[] = [];
    if (this.officerResponse.officerList.length !== 0) {
      this.officerResponse.officerList.forEach(officer => {
        if (officer.user.id !== this.officerResponse.officer.user.id) {
          userIdList.push(officer.user.id);
        }
      });
    }
    const roleCodeExcludeList: string[] = [];
    roleCodeExcludeList.push('VENDOR');
    this.appPopupUserService
      .open(userIdList, roleCodeExcludeList, null, null, null, true)
      .subscribe(user => {
        this.formGroup.patchValue({ user, userName: user.name });
        userIdList.forEach((userId, index) => {
          if (userId !== user.id) {
            userIdList.splice(index, 1);
          }
        });
      });
  }
}
