import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Fines } from 'src/app/core/bean/fines';
import { FinesMaster } from 'src/app/core/bean/fines-master';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './app-popup-fines.component.html'
})
export class AppPopupFinesComponent extends BaseComponentComponent {
  @Input() public todo: string;
  @Input() public amountSource: number;
  @Input() public finesEdit: Fines = new Fines();
  @Input() public finesList: Fines[] = [];
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public finesMasterOptionList: OptionListModel<FinesMaster> =
    new OptionListModel(true);
  public finesMasterList: FinesMaster[] = [];
  public amount: number;

  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    super('app-popup-fines');
  }

  onInit(): void {
    this.setOptionListModel();
    this.buildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setOptionListModel(): void {
    this.httpClientService
      .get<FinesMaster[]>('/app-popup-fines/get-option-list')
      .subscribe(response => {
        if (response != null) {
          this.finesMasterList = response;
          this.finesMasterOptionList.setRequestValues(this.finesMasterList);
          this.setStateReady();
          this.setFinesMasterList();
        }
      });
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      finesMaster: [null, Validators.compose([Validators.required()])],
      percentageValue: [
        null,
        Validators.compose([Validators.required(), Validators.max(100)])
      ],
      description: [null],
      amount: [null]
    });
  }

  public setFormGroup(): void {
    if (this.todo !== 'add') {
      this.formGroup.patchValue({
        id: this.finesEdit.id,
        finesMaster: this.finesEdit.finesMaster,
        percentageValue: this.finesEdit.percentageValue,
        description: this.finesEdit.description,
        amount: this.finesEdit.amount
      });
      this.amount = this.finesEdit.amount;
    }
  }

  public setFinesMasterList(): void {
    if (this.todo === 'add') {
      const finesMasterList = this.finesMasterList.filter(
        (finesMaster: FinesMaster) =>
          this.finesList.every(fines => fines.finesMaster.id !== finesMaster.id)
      );
      this.finesMasterOptionList.setRequestValues(finesMasterList);
    } else {
      const finesMasterList = this.finesMasterList.filter(
        (finesMaster: FinesMaster) =>
          this.finesList.every(
            (fines: Fines) =>
              fines.finesMaster.id !== finesMaster.id ||
              fines.finesMaster.id === this.finesEdit.finesMaster.id
          )
      );
      this.finesMasterOptionList.setRequestValues(finesMasterList);
    }
  }

  public onInputPercentage(): void {
    const percentage = this.formGroup.get('percentageValue').value;
    const amount = +this.amountSource * (+percentage / 100);
    this.formGroup.get('amount').patchValue(amount);
    this.amount = amount;
  }

  public onChangeFines(): void {
    const finesMaster: FinesMaster = this.formGroup.get('finesMaster').value;
    if (finesMaster) {
      const percentage = finesMaster.percentageValue;
      this.formGroup.get('percentageValue').patchValue(percentage);
      const amount = +this.amountSource * (+percentage / 100);
      this.formGroup.get('amount').patchValue(amount);
      this.amount = amount;
    }
  }

  public doSave(): void {
    this.formGroup.markAllAsTouched();
    this.formGroup.markAsDirty();
    this.validate();
    if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup.value);
    }
  }
}
