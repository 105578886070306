import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { QcFormModel } from './model/qc-form-model';
import { NameChildModel } from './model/name-child-model';

import { FormArray } from '@angular/forms';
import { Validators } from 'src/app/core/validators';
import { NameValueModel } from './model/name-value-model';



@Component({
  templateUrl: './qc-edit-add.component.html',
  styleUrls: ['./qc-edit-add.component.scss'],
})
export class QcEditAddComponent extends BaseModuleComponent {

  public time: Date = new Date();
  public code: string;
  public backUrl: string;
  public qcId: number;
  public qcTypeId: number;

  public shiftOptionList: OptionListModel<any> = new OptionListModel().setValuePath('value');
  public productOptionList: OptionListModel<any> = new OptionListModel().setValuePath('value');
  public productTypeOptionList: OptionListModel<any> = new OptionListModel().setValuePath('value');
  public volumeOptionList: OptionListModel<any> = new OptionListModel().setValuePath('value');
  public machineNumberOptionList: OptionListModel<any> = new OptionListModel().setValuePath('value');
  public carTypeOptionList: OptionListModel<any> = new OptionListModel().setValuePath('value');
  public supplierListOptionList: OptionListModel<any>[] = new Array<OptionListModel<any>>();


  public defectList = this.formBuilder.array([]);
  public carConditionList = this.formBuilder.array([]);
  public supplierList = this.formBuilder.array([]);
  public visibilityConfig: Record<string, boolean> = {};

  constructor(translateService: TranslateService) {
    super('qc', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doInit();
    this.doBuildFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.qcId = this.global.routerParams.get('qcId');
    this.qcTypeId = this.global.routerParams.get('qcTypeId');
    this.backUrl = this.global.routerParams.get('backUrl');
    //window.alert(this.qcTypeId);

  }

  public doInit(): void {
    this.formGroup = this.formBuilder.group({
      code: [null],
      id: [null],
      processDate: [null],

      batch: [null],
      carNumber: [null],
      expedition: [null],
      destination: [null],

      sampleCount: [null],
      sampleOkCount: [null],
      sampleDefectCount: [null],
      stuffingOperator: [null],
      notes: [null],

      isDt1: [null],
      isDt2: [null],
      isDt3: [null],
      tqCount1: [null],
      tqCount2: [null],
      tqCount3: [null],


      carType: [null],
      machineNumber: [null],
      shift: [null],
      product: [null],
      productType: [null],
      volume: [null],

      defectList: this.defectList,
      carConditionList: this.carConditionList,

      supplierList: this.supplierList

    });

  }

  public doBuildFormGroup(): void {
    this.httpClientService
      .post<QcFormModel>('/qc/add-edit/' + this.qcTypeId, new RouteRequestModel(this.todo, this.qcId))
      .subscribe(qcFormModel => {

        const keys = qcFormModel.visibilityConfig.split(',').map(key => key.trim());
        keys.forEach(key => {
          this.visibilityConfig[key] = true;
        });
        Object.keys(this.formGroup.controls).forEach(field => {
          console.log('field control :' + (field))
        }
        );

        this.shiftOptionList.setRequestValues(qcFormModel.shiftOptionList);
        this.productOptionList.setRequestValues(qcFormModel.productOptionList);
        this.productTypeOptionList.setRequestValues(qcFormModel.productTypeOptionList);
        this.volumeOptionList.setRequestValues(qcFormModel.volumeOptionList);
        this.machineNumberOptionList.setRequestValues(qcFormModel.machineNumberOptionList);
        this.carTypeOptionList.setRequestValues(qcFormModel.carTypeOptionList);

        qcFormModel.supplierListOptionList.forEach((optionlist: any) => {
          const supplierOptionList: OptionListModel<any> = new OptionListModel().setValuePath('value');
          supplierOptionList.setRequestValues(optionlist);
          this.supplierListOptionList.push(supplierOptionList);
        })


        // this.partOptionList.setRequestValues(qcFormModel.supplierList);
        const model = qcFormModel;

        this.code = model.code;

        this.formGroup.patchValue({ code: model.code });
        this.formGroup.patchValue({ id: model.id });
        this.formGroup.patchValue({ processDate: model.processDate == null ? this.currentDate : model.processDate });

        this.formGroup.patchValue({ notes: model.notes });

        this.formGroup.patchValue({ batch: model.batch });
        this.formGroup.patchValue({ carNumber: model.carNumber });
        this.formGroup.patchValue({ expedition: model.expedition });
        this.formGroup.patchValue({ destination: model.destination });


        this.formGroup.patchValue({ isDt1: model.isDt1 });
        this.formGroup.patchValue({ isDt2: model.isDt2 });
        this.formGroup.patchValue({ isDt3: model.isDt3 });

        this.formGroup.patchValue({ tqCount1: model.tqCount1 });
        this.formGroup.patchValue({ tqCount2: model.tqCount2 });
        this.formGroup.patchValue({ tqCount3: model.tqCount3 });

        this.formGroup.patchValue({ sampleCount: model.sampleCount });
        this.formGroup.patchValue({ sampleOkCount: model.sampleOkCount });
        this.formGroup.patchValue({ sampleDefectCount: model.sampleDefectCount });
        this.formGroup.patchValue({ stuffingOperator: model.stuffingOperator });

        this.formGroup.patchValue({ carType: model.carType });
        this.formGroup.patchValue({ machineNumber: model.machineNumber });
        this.formGroup.patchValue({ shift: model.shift });
        this.formGroup.patchValue({ product: model.product });
        this.formGroup.patchValue({ productType: model.productType });
        this.formGroup.patchValue({ volume: model.volume });

        this.buildFormArray(qcFormModel.defectList, this.defectList);
        this.buildFormArray(qcFormModel.carConditionList, this.carConditionList);

        this.buildPartFormArray(qcFormModel.supplierList);

        this.isViewOnly = (model.submitDate != null && this.global.userSession.activeUserRole.role.code != 'ADMIN');
        this.setStateReady();

      });
    if (this.todo === 'edit') {
      //this.formGroup.get('code').setIsView(true);
    }
  }


  buildFormArray(dataList: NameChildModel<any>[], formArray: FormArray) {
    dataList.forEach((nameChildModel: NameChildModel<any>) => {
      const categoryGroup = this.formBuilder.group({
        name: [nameChildModel.name],
        child: this.formBuilder.array([])
      });

      const childArray = categoryGroup.get('child') as FormArray;
      nameChildModel.child.forEach(child => {
        childArray.push(this.formBuilder.group({
          name: [child.name],
          value: [child.value]
        }));
      });
      formArray.push(categoryGroup);
    });
  }




  public buildPartFormArray(supplierList: NameValueModel<string>[]): void {
    supplierList.forEach((supplier: NameValueModel<string>) => {

      this.supplierList.push(
        this.formBuilder.group({
          name: [supplier.name],
          value: [supplier.value], // Ini akan menjadi formControl untuk "value"

        })
      )
    })
  }



  public addValidator(key: string, validator: any): void {

    if (this.visibilityConfig[key]) {
      this.formGroup.get(key).setValidators(validator);
      this.formGroup.get(key).updateValueAndValidity();
    }
  }


  public setAllValidator(): void {
    this.addValidator('processDate', Validators.required());
    this.addValidator('batch', Validators.required());
    this.addValidator('carNumber', Validators.required());
    this.addValidator('expedition', Validators.required());
    this.addValidator('destination', Validators.required());
    this.addValidator('stuffingOperator', Validators.required());
    this.addValidator('sampleCount', Validators.required());
    this.addValidator('sampleOkCount', Validators.required());
    this.addValidator('sampleDefectCount', Validators.required());
    // this.addValidator('isDt1', Validators.required());
    // this.addValidator('isDt2', Validators.required());
    // this.addValidator('isDt3', Validators.required());
    this.addValidator('tqCount1', Validators.required());
    this.addValidator('tqCount2', Validators.required());
    this.addValidator('tqCount3', Validators.required());
    this.addValidator('carType', Validators.required());
    this.addValidator('machineNumber', Validators.required());
    this.addValidator('shift', Validators.required());
    this.addValidator('product', Validators.required());
    this.addValidator('productType', Validators.required());
    this.addValidator('volume', Validators.required());

  }

  public clearAllValidator(): void {
    //this.formGroup.get('processDate').updateValueAndValidity();
    this.formGroup.get('processDate').clearValidators();
    this.formGroup.get('batch').clearValidators();
    this.formGroup.get('carNumber').clearValidators();
    this.formGroup.get('expedition').clearValidators();
    this.formGroup.get('destination').clearValidators();
    this.formGroup.get('stuffingOperator').clearValidators();
    this.formGroup.get('sampleCount').clearValidators();
    this.formGroup.get('sampleOkCount').clearValidators();
    this.formGroup.get('sampleDefectCount').clearValidators();
    // this.formGroup.get('isDt1').clearValidators();
    // this.formGroup.get('isDt2').clearValidators();
    // this.formGroup.get('isDt3').clearValidators();
    this.formGroup.get('tqCount1').clearValidators();
    this.formGroup.get('tqCount2').clearValidators();
    this.formGroup.get('tqCount3').clearValidators();
    this.formGroup.get('carType').clearValidators();
    this.formGroup.get('machineNumber').clearValidators();
    this.formGroup.get('shift').clearValidators();
    this.formGroup.get('product').clearValidators();
    this.formGroup.get('productType').clearValidators();
    this.formGroup.get('volume').clearValidators();



  }


  public doSave(isSubmit: boolean = false): void {

    if (isSubmit) {
      console.log('submit');
      this.setAllValidator()
      this.validate();
      if (this.formGroup.valid) {
        this.global.modalService
          .saveConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.doProcess(true);
            }
          })
      }
    }
    else {
      console.log('savedraft');
      this.clearAllValidator();
      this.doProcess(false);

    }
  }


  public normalize(key: string) {
    if (this.formGroup.get(key).value == null) {
      console.log('is null');
      return;
    }

    if (this.formGroup.get(key).value !=  null && this.formGroup.get(key).value.value==null) {
      console.log('is aman');
      return;
    }
    else {
      console.log('is object');
      console.log(JSON.stringify(this.formGroup.get(key).value));
      this.formGroup.get(key).setValue(this.formGroup.get(key).value.value);
      
     
    }

  }

  public doProcess(isSubmit: boolean): void {
    this.setStateProcessing();
    console.log('xxx' + this.formGroup.get('shift').value);

    this.normalize('shift');
    this.normalize('product');
    this.normalize('productType');
    this.normalize('volume');
    this.normalize('machineNumber');
    this.normalize('carType');

    const jsonString: string = JSON.stringify(this.formGroup.value, this.global.utils.circularReplacer());
    
    const model: QcFormModel = JSON.parse(jsonString);
    
    model.isSubmit = isSubmit;

    // console.log('Form value (JSON):', jsonString);

    this.httpClientService
      .post<Response<QcFormModel>>('/qc/insertupdate', model)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.formGroup.isSubmitted = true;
          this.global.alertService.showSuccessSavingOnNextRoute();
          this.router.navigate([this.backUrl]);
        } else {
          this.global.alertService.showError(response.statusText);
          this.setStateReady();
        }
      });

  }


  public doCancel(): void {
    this.router.navigate([this.backUrl]);
  }

 

}






