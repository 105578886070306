/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */

import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AppModalConfirmDialogComponent } from '../components/app-modal/app-modal-confirm-dialog/app-modal-confirm-dialog.component';
import { AppModalInputDialogComponent } from '../components/app-modal/app-modal-input-dialog/app-modal-input-dialog.component';
import { AppModalMessageDialogComponent } from '../components/app-modal/app-modal-message-dialog/app-modal-message-dialog.component';
import { AppPopupService } from '../components/app-popup/app-popup.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public modalOptions: NgbModalOptions & { autoCloseInMs?: number } = {
    backdrop: 'static',
    size: 'md',
    centered: true
  };
  constructor(
    public appPopupService: AppPopupService,
    public readonly translateService: TranslateService
  ) {}

  saveConfirmation(): Observable<boolean> {
    const prompt = this.translateService.instant('app.confirmation.save.msg');
    const title = this.translateService.instant('app.confirmation.save.title');
    const buttonLeft = 'app.button.cancel';
    const buttonRight = 'app.button.yes';
    return this.appPopupService.open(
      AppModalConfirmDialogComponent,
      { prompt, title, buttonLeft, buttonRight },
      this.modalOptions
    );
  }

  deleteConfirmation(): Observable<boolean> {
    const prompt = this.translateService.instant('app.confirmation.delete.msg');
    const title = this.translateService.instant(
      'app.confirmation.delete.title'
    );
    const buttonLeft = 'app.button.cancel';
    const buttonRight = 'app.button.yes';
    return this.appPopupService.open(
      AppModalConfirmDialogComponent,
      { prompt, title, buttonLeft, buttonRight },
      this.modalOptions
    );
  }

  confirm(
    prompt = 'app.confirmation.really',
    title = 'app.confirmation.confirm',
    buttonLeft = 'app.button.cancel',
    buttonRight = 'app.button.yes'
  ): Observable<boolean> {
    return this.appPopupService.open(
      AppModalConfirmDialogComponent,
      { prompt, title, buttonLeft, buttonRight },
      this.modalOptions
    );
  }

  nextConfirm(
    prompt = 'app.confirmation.really',
    title = 'app.confirmation.confirm',
    buttonLeft: 'app.button.no',
    buttonRight: 'app.button.yes'
  ): Observable<boolean> {
    return this.appPopupService.open(
      AppModalConfirmDialogComponent,
      {
        prompt,
        title,
        buttonLeft,
        buttonRight
      },
      this.modalOptions
    );
  }

  input(
    message: string,
    initialValue: string,
    title = 'app.confirmation.input'
  ): Observable<string> {
    return this.appPopupService.open(
      AppModalInputDialogComponent,
      { message, initialValue, title },
      this.modalOptions
    );
  }

  message(
    message: string,
    title = 'app.confirmation.message'
  ): Observable<boolean> {
    return this.appPopupService.open(
      AppModalMessageDialogComponent,
      { message, title },
      this.modalOptions
    );
  }

  // new confirmation function
  confirmation(
    prompt,
    title,
    buttonLeft,
    buttonRight,
    icon,
    isShowCloseButton,
    options: NgbModalOptions & { autoCloseInMs?: number } = {},
    isSingleButton?
  ): Observable<boolean> {
    const modalOptions = Object.assign({ ...this.modalOptions }, options);
    return this.appPopupService.open(
      AppModalConfirmDialogComponent,
      {
        prompt,
        title,
        buttonLeft,
        buttonRight,
        icon,
        isShowCloseButton,
        isSingleButton
      },
      modalOptions
    );
  }

  submitConfirmation(): Observable<boolean> {
    const prompt = this.translateService.instant('app.confirmation.submit.msg');
    const title = this.translateService.instant(
      'app.confirmation.submit.title'
    );
    const buttonLeft = 'app.button.cancel';
    const buttonRight = 'app.button.yes';
    return this.appPopupService.open(
      AppModalConfirmDialogComponent,
      { prompt, title, buttonLeft, buttonRight },
      this.modalOptions
    );
  }

  newSubmitConfirmation(): Observable<boolean> {
    return this.confirmation(
      'app.confirmation.newSubmitConfirmation.prompt',
      'app.confirmation.newSubmitConfirmation.title',
      'app.button.cancel',
      'app.button.yesSubmit',
      null,
      false
    );
  }

  leaveConfirmation(): Observable<boolean> {
    return this.confirmation(
      'app.confirmation.leaveConfirmation.prompt',
      'app.confirmation.leaveConfirmation.title',
      'app.button.stayOnThisPage',
      'app.button.leaveOnThisPage',
      null,
      false
    );
  }

  newDeleteConfirmation(title?: string, prompt?: string): Observable<boolean> {
    return this.confirmation(
      title || 'app.confirmation.deleteConfirmation.prompt',
      prompt || 'app.confirmation.deleteConfirmation.title',
      'app.button.cancel',
      'app.button.delete',
      null,
      false
    );
  }

  deleteItemConfirmation(): Observable<boolean> {
    return this.confirmation(
      'app.confirmation.deleteItemConfirmation.prompt',
      'app.confirmation.deleteItemConfirmation.title',
      'app.button.no',
      'app.button.yes',
      null,
      false
    );
  }

  newSaveConfirmation(title?: string, prompt?: string): Observable<boolean> {
    return this.confirmation(
      title || 'app.confirmation.saveConfirmation.prompt',
      prompt || 'app.confirmation.saveConfirmation.title',
      'app.button.cancel',
      'app.button.save',
      null,
      false
    );
  }

  saveSuccess(): Observable<boolean> {
    return this.confirmation(
      'app.confirmation.saveSuccess.prompt',
      'app.confirmation.saveSuccess.title',
      'app.button.stayOnThisPage',
      'app.button.backToList',
      'pvc pv-check-circle',
      false
    );
  }

  saveSuccessWithoutButton(): Observable<boolean> {
    return this.confirmation(
      'app.confirmation.saveSuccess.prompt',
      'app.confirmation.saveSuccess.title',
      null,
      null,
      'pvc pv-check-circle',
      false,
      {
        autoCloseInMs: 1000
      }
    );
  }

  deleteSuccessWithoutButton(): Observable<boolean> {
    return this.confirmation(
      'organization.confirmation.deleteSuccess.prompt',
      'organization.confirmation.deleteSuccess.title',
      null,
      null,
      'pvc pv-trash',
      false
    );
  }

  submitSuccess(): Observable<boolean> {
    return this.confirmation(
      'app.confirmation.submitConfirmation.prompt',
      'app.confirmation.submitConfirmation.title',
      'app.button.stayOnThisPage',
      'app.button.backToList',
      'pvc pv-check-circle',
      false
    );
  }

  submitSuccessCreateNew(): Observable<boolean> {
    return this.confirmation(
      'app.confirmation.submitConfirmation.prompt',
      'app.confirmation.submitConfirmation.title',
      'app.button.backToList',
      'app.button.createNew',
      'pvc pv-check-circle',
      true
    );
  }

  scheduleMismatch(): void {
    const title = 'app.confirmation.scheduleMismatch.title';
    const prompt = 'app.confirmation.scheduleMismatch.prompt';
    const icon = 'pvc pv-calendar-alt';
    const isAutoClose = true;
    this.appPopupService.open(
      AppModalConfirmDialogComponent,
      { prompt, title, icon, isAutoClose },
      this.modalOptions
    );
  }

  scheduleMismatchConfirmation(): Observable<boolean> {
    return this.confirmation(
      'app.confirmation.scheduleMismatchConfirmation.prompt',
      'app.confirmation.scheduleMismatchConfirmation.title',
      'app.button.no',
      'app.button.reschedule',
      'pvc pv-calendar-alt',
      false
    );
  }

  submitProcurementFailed(minParticipant: number): Observable<boolean> {
    return this.confirmation(
      this.translateService.instant(
        'app.confirmation.submitProcFailedConfirmation.prompt.minimum'
      ) +
        ' ' +
        minParticipant +
        ' ' +
        this.translateService.instant(
          'app.confirmation.submitProcFailedConfirmation.prompt.text'
        ),
      'app.confirmation.submitProcFailedConfirmation.title',
      'app.button.cancel',
      'app.button.submitAnyway',
      'pvc pv-exclamation-triangle',
      true
    );
  }

  downloadSuccess(): void {
    const title = 'app.confirmation.reportDownloaded.title';
    const prompt = '';
    const icon = 'pvc pv-check-circle';
    const isAutoClose = true;
    this.appPopupService.open(
      AppModalConfirmDialogComponent,
      { prompt, title, icon, isAutoClose },
      { size: 'md' }
    );
  }

  initiationAddToItemRequestModal(): Observable<boolean> {
    return this.confirmation(
      'app.desc.item-success',
      'app.main.item-added',
      'app.button.stay-on-catalog',
      'app.button.back-to-details',
      'pvc pv-shopping-cart-circle-check',
      false
    );
  }

  public close(): void {
    this.appPopupService.close();
  }
}
