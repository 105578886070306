import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { ExampleComponent } from './example.component';
const routes: Routes = [
  { path: '', component: ExampleComponent, data: { breadcrumb: '' } }
]
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ExampleComponent]
})
export class ExampleModule {}
