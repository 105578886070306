import { BaseEntity } from "../base/base-entity";
import { GuaranteeBondType } from "../bean/guarantee-bond-type";

export class GuaranteeEntity extends BaseEntity {

    guaranteeBondType: GuaranteeBondType;
    guarantorName: string;
    objectName: string;
    objectId: number;
    amount: number;
    percentageValue: number;
    submissionDate: Date;
    startDate: Date;
    endDate: Date;
    description: string;
}