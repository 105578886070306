
import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { LogoFileResponse } from 'src/app/core/model/logo-file-response';
import { BaseComponentComponent } from '../../../../core/base/angular/base-component.component';
import { ResponseStatusModel } from '../../../../core/model/response-status-model';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { Validators } from '../../../../core/validators';

@Component({
  templateUrl: './popup-login.component.html',
  styleUrls: ['./popup-login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopupLoginComponent extends BaseComponentComponent {
  @Output() onClose: EventEmitter<void> = new EventEmitter();
  mainLogoFileName: string;
  constructor(private authenticationService: AuthenticationService) {
    super('popup-login');
  }

  onInit(): void {
    this.getImageLogo(this.global.appConstant.fileType.IMG_MAIN_LOGO);
    this.buildFormGroup();
  }

  private buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      email: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      password: [null, Validators.required()],
      captch: [
        null,
        this.global.config.appInfoResponse.isShowCaptcha
          ? [Validators.required('app.validation.captchaRequired')]
          : []
      ],
      isKeepMeSignIn: [null]
    });
  }

  public setStateFormLoginProcessing(): void {
    this.formGroup.disable();
    this.formLoading = false;
    this.formSaving = true;
  }

  public setStateFormLoginReady(): void {
    this.formGroup.enable();
    this.formLoading = false;
    this.formSaving = false;
  }

  public doLogin(): void {
    this.validate();
    if (this.formGroup.valid) {
      const { email, password } = this.formGroup.value;
      this.setStateFormLoginProcessing();
      this.authenticationService.getAuth(email, password, null, null).subscribe(
        response => {
          const authenticationResponse = response.body;
          if (response.status === ResponseStatusModel.OK) {
            this.authenticationService.logingIn(authenticationResponse);
            this.onClose.emit();
          } else {
            this.global.alertService.showError(
              response.statusText,
              '#form-login',
              { totalAlert: 1 }
            );
          }
          this.setStateFormLoginReady();
        },
        () => {
          this.formGroup.get('captcha').reset();
          this.setStateFormLoginReady();
        }
      );
    }
  }

  public doForgotPassword(): void {
    this.onClose.emit();
    this.router.navigate(['/portal/forgot-password']);
  }

  public doRegister(): void {
    this.onClose.emit();
    this.router.navigate(['/portal/registration/vendor-policy']);
  }

  public getImageLogo(fileType: string): void {
    this.httpClientService
      .post<LogoFileResponse>('/app-info/logo', fileType)
      .subscribe(logoFileResponse => {
        if (logoFileResponse != null) {
          if (logoFileResponse.mainLogoFileName != null) {
            this.mainLogoFileName = logoFileResponse.mainLogoFileName;
          }
        }
      });
  }
}
