import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { File } from '../../core/bean/file';
import { ModuleType } from '../../core/bean/module-type';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { Module } from './../../core/bean/module';
import { ModuleRequest } from './module-request';
import { ModuleResponse } from './module-response';

@Component({
  templateUrl: './module-edit-add.component.html'
})
export class ModuleEditAddComponent extends BaseModuleComponent {
  public module: Module = new Module();
  public moduleResponse: ModuleResponse = new ModuleResponse();
  public moduleRequest: ModuleRequest = new ModuleRequest();
  public moduleId: number;
  public isAllUploaded = false;
  public fileUploader: FileUploader = new FileUploader(
    '/module/',
    '',
    'IMPORT_TEMPLATE_MODULE'
  );
  public isImport = false;
  public moduleType: ModuleType = new ModuleType();
  public statusList: OptionListModel<any> = new OptionListModel();
  public hierarchyOptionList: OptionListModel<any> = new OptionListModel();

  constructor(translateService: TranslateService) {
    super('module', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doSetStatusList();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.moduleId = this.global.routerParams.get('moduleId');
  }

  public doSetStatusList(): void {
    const moduleStatus = [
      { name: 'app.status.active', value: true },
      { name: 'app.status.inActive', value: false }
    ];
    const hierarchyList = [
      {
        name: 'module.hierarchy.exclusive',
        value: this.global.appConstant.core.HIERARCHY_EXCLUSIVE
      },
      {
        name: 'module.hierarchy.inclusive',
        value: this.global.appConstant.core.HIERARCHY_INCLUSIVE
      },
      {
        name: 'module.hierarchy.noHierarchy',
        value: this.global.appConstant.core.NO_HIERARCHY
      }
    ];
    this.statusList.setRequestValues(moduleStatus);
    // this.delegationOptionList.setRequestValues(delegationList);
    this.hierarchyOptionList.setRequestValues(hierarchyList);
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      moduleGroupCode: [null],
      code: [null],
      name: [null],
      isActive: [null, Validators.required()],
      description: [null],
      templateFile: [null, Validators.required()],
      file: [new File()],
      isStage: [null],
      isStarter: [null],
      isNumberGenerator: [null],
      moduleType: [null],
      isSchedule: [null],
      objectName: [null],
      serviceName: [null],
      isImport: [null],
      path: [null],
      isStartDate: [null],
      isEndDate: [null],
      isLocation: [null],
      isApprove: [null],
      isReject: [null],
      isHold: [null],
      isRevision: [null],
      isVendorAvailable: [null],
      isVendorResponse: [null],
      approvalPath: [null],
      hierarchy: [null]
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<ModuleResponse>(
        '/module/add-edit',
        new RouteRequestModel(this.todo, this.moduleId)
      )
      .subscribe(moduleResponse => {
        this.moduleResponse = moduleResponse;
        if (this.moduleResponse != null) {
          this.isImport = this.moduleResponse.module.isImport;
          this.moduleType = this.moduleResponse.module.moduleType;
          this.fileUploader.setFile(this.moduleResponse.module.file);
          this.formGroup.patchValue(this.moduleResponse.module);
          const status = this.statusList
            .getRequestValues()
            .filter(
              valueOption =>
                valueOption.value === moduleResponse.module.isActive
            )[0];
          this.formGroup.patchValue({
            isActive: status,
            hierarchy: moduleResponse.module.hierarchy
          });

          if (this.isImport === false) {
            this.formGroup.get('templateFile').setValidators(null);
          } else {
            this.formGroup.patchValue({
              templateFile: this.fileUploader
            });
          }
        }
        this.setStateReady();
        this.formGroup.get('isStage').disable();
        this.formGroup.get('isStarter').disable();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.module = this.global.copyFormAttributeToModel(
              this.module,
              this.formGroup
            );
            this.module.isActive = this.formGroup.get('isActive').value.value;
            this.moduleRequest.fileObjectList =
              this.fileUploader.fileObjectList;
            this.moduleRequest.module = this.module;
            this.moduleRequest.module.moduleType = this.moduleType;
            this.httpClientService
              .post<Response<Module>>('/module/update', this.moduleRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/module/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/module/']);
  }

  public doShowImport(): void {
    this.isImport = !this.isImport;
    this.formGroup.get('file').disable();
  }
}
