/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\portal\landing-page\landing-page.guard.ts
 
 * Created Date: Sunday, October 3rd 2021, 2:10:13 pm

 *
*
 */

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Global } from '../../core/global/global';
@Injectable()
export class LandingPageGuard implements CanActivate {
  constructor(private global: Global, private router: Router) {}
  canActivate(): boolean {
    if (this.global.routerParams.get('canActivate')) {
      return true;
    } else {
      this.router.navigate(['/portal']);
    }
  }
}
