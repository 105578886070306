/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table\action\change-portal-table-page-action.ts
 
 * Created Date: Thursday, September 30th 2021, 11:17:11 am

 *
*
 */

import { PortalTableState } from '../domain/portal-table-state';
import { resolvePortalTableRecordList } from '../helper/resolve-portal-table-record-list-helper';
export const changePortalTablePage = (
  state: PortalTableState,
  payload: { page: number }
): void => {
  state.pagination.setCurrentPage(payload.page);
  resolvePortalTableRecordList(state).then((rowList: Array<any>) => {
    state.rowList = rowList;
    state.setStateReady();
  });
};
