

import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppAccordionItemComponent } from './app-accordion-item.component';
import { AppAccordionComponent } from './app-accordion.component';
import { AppAccordionDirective } from './app-accordion.directive';
@NgModule({
  imports: [VendorModule, AppButtonModule, DirectivesModule],
  declarations: [
    AppAccordionComponent,
    AppAccordionItemComponent,
    AppAccordionDirective
  ],
  exports: [
    AppAccordionComponent,
    AppAccordionItemComponent,
    AppAccordionDirective
  ]
})
export class AppAccordionModule {}
