

import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const fullViewAnimation = (
  customStyle?: object,
  animateTime = '500ms'
): AnimationTriggerMetadata => {
  return trigger('fullView', [
    state('default', style({})),
    state(
      'fullScreen',
      style({
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1049,
        ...customStyle
      })
    ),
    transition('default<=>fullScreen', animate(animateTime))
  ]);
};
