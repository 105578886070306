<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="account-setting.title" [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate AUTOCOMPLETE="OFF">
        <div class="form-group row text-sm-right pb-4">
          <label class="col-sm-3 control-label"></label>
          <div class="col-sm-9 text-left">
            <div [ngStyle]="{ width: '210px' }">
              <app-user-profile-card *ngIf="!isPhotoChanged" formControlName="imgFile"
                (onChange)="doDisableButton($event)">
              </app-user-profile-card>
              <div class="text-center" [ngStyle]="{ margin: '10vh 0' }" *ngIf="isPhotoChanged">
                <img src="assets/img/common/loader-small.gif" alt="Loading" />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'account-setting.userName' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field autofocus="true" formControlName="name" type="alphanumeric" include="-.\'," maxLength="255">
            </app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label *ngIf="!isPasswordBlock" class="col-sm-3 control-label">{{
            'account-setting.password' | translate
            }}</label>
          <div [ngClass]="isPasswordBlock ? 'offset-sm-3' : ''" class="col-sm-9 text-left btn-change-password">
            <app-button size="SM" (onClick)="doChangePassword()">
              {{
              (isPasswordBlock
              ? 'app.button.cancel'
              : 'account-setting.button.changePassword'
              ) | translate
              }}
            </app-button>
          </div>
        </div>

        <div id="password-block" [hidden]="!isPasswordBlock">
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'account-setting.form.oldPassword' | translate
              }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="oldPassword" type="password" maxLength="32"></app-text-field>
            </div>
            <div class="col-sm-2"></div>
          </div>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'account-setting.form.newPassword' | translate
              }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="newPassword" type="password" maxLength="32"></app-text-field>
            </div>
            <div class="col-sm-1"></div>
          </div>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'account-setting.form.newPasswordConfirm' | translate
              }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="confirmNewPassword" type="password" maxLength="32"></app-text-field>
            </div>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'account-setting.form.email' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            {{ formGroup.controls.email.value }}
            <p class="m-0" *ngIf="accountSettingRequest.newEmail">{{ accountSettingRequest.newEmail }} -
              <em class="text-warning">Waiting for confirmation</em>
            </p>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'account-setting.form.changeEmail' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-button size="SM" (onClick)="doChangeEmail()">{{ 'account-setting.button.changeEmail' | translate }}
            </app-button>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'account-setting.form.status' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <div *ngIf="formGroup.controls.isActive.value">{{
              'account-setting.active' | translate
              }}</div>
            <div *ngIf="!formGroup.controls.isActive.value">{{
              'account-setting.inActive' | translate
              }}</div>
          </div>
        </div>

        <div class="form-group row text-sm-right required"
          *ngIf="accountSettingResponse?.user?.validUserRoleList.length > 1">
          <label class="col-sm-3 control-label">{{
            'account-setting.role' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box formControlName="userRole" [optionList]="roleOptionList"></app-combo-box>
          </div>
        </div>

        <!-- Request BJB view only untuk default role -->
        <div class="form-group row text-sm-right required"
          *ngIf="accountSettingResponse?.user?.validUserRoleList.length === 1">
          <label class="col-sm-3 control-label">{{
            'account-setting.role' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <div>{{ global.userSession?.activeUserRole.role.name }}</div>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'account-setting.lang' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box formControlName="lang" [optionList]="languageOptionList"></app-combo-box>
          </div>
        </div>

       
        


        <br><br>
        <div class="row justify-content-center  mb-4">
          <div class="col-12 col-sm-2 mb-2">
            <button type="submit" class="btn btn-outline-secondary btn-block" (click)="doCancel()">{{ 'app.button.back'
              | translate}}</button>
          </div>
       
          <div  class="col-12 col-sm-2 mb-2">
            <button type="submit" class="btn  btn-primary btn-block" (click)="doSave()"> {{ 'app.button.submit' | translate}}</button>
          </div>
        </div>
      </form>
    </app-card>

    <app-card *ngIf="global.userSession && global.userSession?.activeUserRole.role.type != 3"
      header="user-role-log.title" [isLoading]="formLoading" [isSaving]="formSaving">
      <div class="row">
        <div class="col-lg-12 mb-4">
          <app-table [tableResponse]="tableResponse" [isServerSide]="true" stringUrl="/account-setting/user-role-log">
          </app-table>
        </div>
      </div>
    </app-card>
  </div>
</div>