<div class="portal-vendor-policy">
  <div class="promise-container promise-container-lg">
    <app-card [isLoading]="formLoading">
      <form [formGroup]="formGroup" class="form-horizontal">
        <app-alert-x color="WARNING">{{
          'registration.alert.msg.warning.vendorPolicy' | translate
        }}</app-alert-x>
        <h2
          class="text-center fw-700"
          [ngStyle]="{
            color: 'var(--primary-dark)',
            margin: '30px 0px 40px 0px'
          }"
          >{{ 'portal.menu.vendorPolicy' | translate }}</h2
        >
        <ng-template #customLoading>
          <div class="promise-loading">
            <div class="loading"></div>
          </div>
        </ng-template>
        <div class="vendor-policy-content">
          <div [innerHtml]="vendorPolicy"></div>
        </div>

        <div class="form-group row text-sm-right mt-5">
          <div class="col-sm-12 text-left">
            <app-check-box formControlName="checkVendorPolicy">
              {{ 'registration.form.agreeWithVendorPolicy' | translate }}
            </app-check-box>
          </div>
        </div>
      </form>
    </app-card>
    <div class="button-group button-group-center">
      <app-button
        (onClick)="doNext()"
        minWidth="180px"
        [disabled]="!formGroup.valid"
      >
        {{ 'app.button.next' | translate }}
      </app-button>
    </div>
  </div>
</div>
