/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\app-calendar\components\app-calendar\app-calendar.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 16th 2021, 12:06:51 pm

 *
*
 */
import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DirectivesModule } from '../../../../directives/directives.module';
import { PipesModule } from '../../../../pipe/pipe.module';
import { VendorModule } from '../../../../vendor/vendor.module';
import { AppButtonModule } from '../../../app-button/app-button.module';
import { AppDatePickerXModule } from '../../../app-date-picker-x/app-date-picker-x.module';
import { AppDropDownModule } from '../../../app-drop-down/app-drop-down.module';
import { AppTooltipWrapperModule } from '../../../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppTooltipModule } from '../../../app-tooltip/app-tooltip.module';
import { PopupEditCalendarModule } from '../popup-edit-calendar/popup-edit-calendar.module';
import { AppCalendarComponent } from './app-calendar.component';
@NgModule({
  imports: [
    VendorModule,
    AppButtonModule,
    PerfectScrollbarModule,
    AppDropDownModule,
    AppDatePickerXModule,
    PipesModule,
    DirectivesModule,
    PopupEditCalendarModule,
    AppTooltipModule,
    AppTooltipWrapperModule
  ],
  declarations: [AppCalendarComponent],
  exports: [AppCalendarComponent]
})
export class AppCalendarModule {}
