<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="qc.flexy.title" [isLoading]="formLoading" [isSaving]="formSaving">

      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'budget-allocation.form.coa' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-chosen formControlName="age" [optionList]="myOptionList"></app-chosen>
            <br>
            <app-combo-box [optionList]="myOptionList" formControlName="name"></app-combo-box>
            <br>
            <app-dropdown-select formControlName="name" placeholder="- select -" [optionList]="myOptionList">
            </app-dropdown-select>
            <br>
            <app-select formControlName="name" placeholder="- select -" [optionList]="myOptionList">
            </app-select>
            <br>
            <app-choose size="XL" formControlName="name"
            (onClick)="onChoose($event)"></app-choose>
            <br>
            
            
          </div>
        </div>

      </form>



    </app-card>
  </div>
</div>