<app-popup [header]="news.title">
  <div
    class="news-image"
    backgroundImage="/news/file/view/{{ news.imgFile?.uploadedFileName }}/{{
      global.appConstant.fileType.IMG_NEWS
    }}"
  >
  </div>
  <div class="news-content text-break">
    <article [innerHtml]="news.body"></article>
    <div class="news-meta-info">
      <span class="posted-on">
        {{ 'portal.news.postedOn' | translate }}
        {{ news.publishDate | date: 'dd MMMM yyyy' }}
      </span>
      <span class="view-count ml-4 mr-2">
        <em class="pir pi-eye"></em>
      </span>
      <span class="fw-500">
        {{ news.viewCount }} {{ 'portal.news.views' | translate }}
      </span>
    </div>
  </div>
</app-popup>
