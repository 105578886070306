/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\portal\landing-page\reset-password.resolver.ts
 
 * Created Date: Sunday, October 3rd 2021, 1:05:18 pm

 *
*
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { User } from '../../core/bean/user';
import { HttpClientService } from '../../core/services/http-client.service';
@Injectable()
export class ResetPasswordResolver
  implements Resolve<{ user: User; token: string }>
{
  constructor(
    private httpClientService: HttpClientService // private router: Router
  ) {}
  resolve(
    route: ActivatedRouteSnapshot
  ): Promise<{ user: User; token: string }> {
    const token = route.params.token;
    return new Promise(resolve => {
      this.httpClientService
        .post<User>('/reset-password/confirmation', token)
        .subscribe(user => {
          user ? resolve({ user, token }) : resolve({ user, token });
          // : this.router.navigate(['/portal/landing-page/expired-link']);
        });
    });
  }
}
