/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\core\components\table\plugins\default-header-plugin\index.ts
 
 * Created Date: Wednesday, October 13th 2021, 10:55:31 am

 *
*
 */
import { AppDynamicComponentService } from '../../../../services/app-dynamic-component.service';
import { TableHeaderPlugin } from '../../interface/table-plugin';
import { TableHeaderPluginData } from '../../interface/table-plugin-data';
import { TableDefaultHeaderPluginComponent } from './default-header-plugin.component';
export const tableHeaderDefaultPlugin: TableHeaderPlugin = {
  name: 'default-header',
  before: (tableHeaderPluginData: TableHeaderPluginData): HTMLElement =>
    createTableHeaderDefaultPlugin(tableHeaderPluginData),
  onCreate: (tableHeaderPluginData: TableHeaderPluginData): HTMLElement =>
    createTableHeaderDefaultPlugin(tableHeaderPluginData)
};
const createTableHeaderDefaultPlugin = (
  tableHeaderPluginData: TableHeaderPluginData
) => {
  if (
    tableHeaderPluginData.column.header ||
    (tableHeaderPluginData.column.header &&
      typeof tableHeaderPluginData.column.header !== 'string' &&
      tableHeaderPluginData.column.header.key)
  ) {
    const divElement = document.createElement('div');
    divElement.classList.add('default-header-content');
    tableHeaderPluginData.state.injector
      .get(AppDynamicComponentService)
      .create(TableDefaultHeaderPluginComponent, divElement, {
        tableHeaderPluginData
      });
    return divElement;
  }
};
