/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\portal\landing-page\registration-success.component.ts
 
 * Created Date: Saturday, October 2nd 2021, 11:33:06 am

 *
*
 */

import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  templateUrl: './registration-verification.component.html'
})
export class RegistrationVerificationComponent {
  constructor(private router: Router) {}
  public doNavigateToDashboard(): void {
    this.router.navigate(['/portal/home']);
  }
}
