/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\action\sort-table-action.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:16:34 am

 *
*
 */
import { AppConstant } from '../../../constant/app-constant';
import { TableRow } from '../domain/table-row';
import { TableState } from '../domain/table-state';
import { resolveTableRowList } from '../helper';
import { TableColumnModel } from '../model/table-column-model';
export const sortTableAction = (
  state: TableState,
  payload: { column: TableColumnModel }
) => {
  if (payload.column.isSortable !== false && !payload.column.columnList) {
    const { tableRequest } = state;
    const appConstant = new AppConstant();
    if (
      payload.column.field === tableRequest.sortField &&
      state.sortOrder === 'DESC'
    ) {
      tableRequest.sortField = null;
      state.sortOrder = '';
      state.sortByColumn = null;
    } else {
      state.sortOrder =
        state.sortOrder === 'ASC'
          ? state.tableRequest.sortField === payload.column.field
            ? 'DESC'
            : 'ASC'
          : 'ASC';
      tableRequest.sortField = payload.column.field;
      state.tableRequest.sortOrder =
        state.sortOrder === 'DESC'
          ? appConstant.core.SORT_ORDER_DESC
          : appConstant.core.SORT_ORDER_ASC;
      state.sortByColumn = payload.column;
    }
    resolveTableRowList(state).then((rowList: Array<TableRow>) => {
      state.setRowList(rowList);
      state.setStateReady();
    });
  } else {
    state.setStateReady();
  }
};
