import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PortalComponent } from './portal.component';
const routes: Routes = [
  {
    path: '',
    component: PortalComponent,
    children: [
      { path: '', redirectTo: '/portal/home', pathMatch: 'full' },
      {
        path: 'news',
        loadChildren: () => import('./news/news.module').then(m => m.NewsModule)
      },
      {
        path: 'vendor-policy',
        loadChildren: () =>
          import('./vendor-policy/vendor-policy.module').then(
            m => m.VendorPolicyModule
          )
      },
      {
        path: 'faq',
        loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('./contact/contact.module').then(m => m.ContactModule)
      },
     
      {
        path: 'about',
        loadChildren: () =>
          import('./about/about.module').then(m => m.AboutModule)
      },
      {
        path: 'registration',
        loadChildren: () =>
          import('./registration/registration.module').then(
            m => m.RegistrationModule
          )
      },
      {
        path: 'reset-email/:token',
        loadChildren: () =>
          import('./reset-email/reset-email.module').then(
            m => m.ResetEmailModule
          )
      },
      {
        path: 'select-role',
        loadChildren: () =>
          import('./select-role/select-role.module').then(
            m => m.SelectRoleModule
          )
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('./forgot-password/forgot-password.module').then(
            m => m.ForgotPasswordModule
          )
      },
     
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'landing-page',
        loadChildren: () =>
          import('./landing-page/landing-page.module').then(
            m => m.LandingPageModule
          )
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortalRoutingModule {}
