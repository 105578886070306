import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Faq } from '../../core/bean/faq';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './faq.component.html'
})
export class FaqComponent extends BaseModuleComponent {
  public faq: string;
  public qaList: Array<any>;
  public keyword: FormControl = new FormControl(null);
  constructor(
    translate: TranslateService,
    public snackbarService: SnackbarService
  ) {
    super('faq', translate);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFaqState();
  }

  private buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      userName: [null, Validators.required()],
      email: [
        null,
        Validators.compose([Validators.required(), Validators.email()])
      ],
      question: [null, Validators.required()]
    });
  }

  public doResetSearch(): void {
    this.keyword.reset();
  }

  private setFaqState(): void {
    this.httpClientService
      .get<Faq[]>('/faq/get-faq-list/' + this.global.getCurrentLang())
      .subscribe(faqList => {
        this.qaList = faqList;
        this.setStateReady();
      });
  }

  public doSendQuestion(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post('/faq/send-question', this.formGroup.value)
              .subscribe(response => {
                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  this.snackbarService.showWarning(
                    'portal.faq.alert.msg.questionSend'
                  );
                  this.formGroup.reset();
                }
              });
          }
        });
    }
  }
}
