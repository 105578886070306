/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\img-plugin\img-plugin.component.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 11:15:57 pm

 *
*
 */

import { Component } from '@angular/core';
import { Global } from 'src/app/core/global/global';
import { ArrayUtils } from 'src/app/core/utils';
import { BaseTablePluginComponent } from '../../base/base-table-plugin.component';
import { ImgPlugin } from '../../interface/available-table-plugin';
@Component({
  templateUrl: './img-plugin.component.html'
})
export class TableImgPluginComponent extends BaseTablePluginComponent {
  public imgPlugin: ImgPlugin;
  public fieldClassName: string;
  constructor(public global: Global) {
    super();
  }

  protected onInit(): void {
    this.doSetInitializationState();
  }

  private doSetInitializationState(): void {
    this.imgPlugin = this.tablePluginData.plugin as ImgPlugin;
    this.fieldClassName =
      this.imgPlugin.fieldClassName &&
      ArrayUtils.resolveFieldData(
        this.tablePluginData.row.record,
        this.imgPlugin.fieldClassName
      );
  }

  public onError(event: Event & { target: HTMLElement }): void {
    event.target.style.display = 'none';
  }
}
