/*
 * Filename:  WebContent\src\app\core\components\app-table-x\action\show-or-hide-record-children-action.ts
 * Path:  WebContent
 * Created Date: Tuesday, August 10th 2021, 11:49:08 am

 *
*
 */
import { resolveTableRecordChildrens } from '../helpers/resolve-table-record-childrens-helper';
import { TableRecord } from '../model/table-record';
import { TableState } from '../model/table-state';
export const expandOrCollapseTableRow = (
  state: TableState,
  payload: { record: TableRecord }
): void => {
  state.setStateReady();
  payload.record.childrens.length === 0 && payload.record.setStateLoading();
  payload.record.isExpanded = !payload.record.isExpanded;
  payload.record.isExpanded &&
    payload.record.childrens.length === 0 &&
    resolveTableRecordChildrens(state, payload.record).then(
      (value: { recordList: TableRecord[]; totalRecord: number }) => {
        payload.record.childrens = value.recordList;
        payload.record.isShowButtonLoadMore =
          payload.record.childrens.length < value.totalRecord;
        payload.record.setStateReady();
      }
    );
};
