import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CustomValidatorError } from '../../core/custom-validator-error';
export class EmailWithPropertyValidator {
  public static emailWithProperty(
    property: string,
    message?: string
  ): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors => {
      if (abstractControl.value) {
        const regex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValidEmail = regex.test(
          String(abstractControl.value[property]).toLowerCase()
        );
        if (!isValidEmail) {
          return CustomValidatorError.create('email', message);
        }
      }
      return null;
    };
  }
}
