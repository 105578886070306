import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { RendererService } from '../../services/renderer';

@Component({
  selector: 'app-button',
  templateUrl: './app-button.component.html',
  styleUrls: ['./app-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppButtonComponent extends BaseComponentComponent {
  @Input() public mode: string;
  @Input() public disabled: boolean;
  @Input() public size: 'TN' | 'SM' | 'MD' | 'LG';
  @Input() public color: string;
  @Input() public icon: string;
  @Input() public rounded: boolean;
  @Input() public borderLess: boolean;
  @Input() public type: string;
  @Input() public tooltip: string;
  @Input() public outline: boolean;
  @Input() public minWidth: string;
  @Output() onClick: EventEmitter<PointerEvent | KeyboardEvent> =
    new EventEmitter();

  readonly BTN_COLOR: object = {
    DEFAULT: 'btn-default',
    PRIMARY: 'btn-primary',
    INFO: 'btn-info',
    SECONDARY: 'btn-secondary',
    SUCCESS: 'btn-success',
    WARNING: 'btn-warning',
    DANGER: 'btn-danger',
    LIGHT: 'btn-light'
  };

  readonly BUTTON_SIZE: object = {
    TN: 'btn-sm btn-tn',
    SM: 'btn-sm',
    MD: 'btn-md',
    LG: 'btn-lg'
  };

  public className: string;
  public isMenuWrite: boolean;
  public isWrite: boolean;
  public buttonElement: RendererService;

  constructor(public elementRef: ElementRef) {
    super('app-button');
    this.elementRef.nativeElement.tabindex = 0;
  }

  onInit(): void {
    this.setInitializationState();
    this.setButtonIsWrite();
    this.setIsWriteByMode();
  }

  private setInitializationState(): void {
    this.className = this.className || 'btn';
    this.mode = this.mode || 'NORMAL';
    this.size = this.size || 'MD';
    this.color = this.color || 'PRIMARY';
    this.rounded = this.rounded || false;
    this.type = this.type || 'button';
    this.isMenuWrite = true;
    this.isWrite = true;
  }

  public setButtonIsWrite(): void {
    if (this.global.userSession) {
      if (this.global.activeModuleCode != null) {
        const moduleCode = this.global.activeModuleCode.toUpperCase();
        const menu = this.global.userSession.menuMap[moduleCode];
        if (menu) {
          this.isMenuWrite = menu.write;
        } else {
          this.isMenuWrite = false;
        }
      } else {
        this.isMenuWrite = true;
      }
    }
  }

  public setIsWriteByMode(): void {
    this.isWrite = this.getIsWrite(this.isMenuWrite)[this.mode.toString()];
  }

  public getIsWrite(isWrite: boolean): object {
    return {
      NORMAL: isWrite,
      BYPASS: true,
      REVERSE: !isWrite
    };
  }

  public doClick(event: PointerEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.onClick.emit(event);
  }

  public handleMouseDown(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
    (event.key === 'Enter' || event.key === ' ') && this.onClick.emit(event);
  }
}
