import {
  Component,
  ElementRef,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { Validators } from '../../validators';
@Component({
  selector: 'app-phone',
  templateUrl: './app-phone.component.html',
  styleUrls: ['./app-phone.component.scss'],
  providers: makeProvider(AppPhoneComponent),
  encapsulation: ViewEncapsulation.None
})
export class AppPhoneComponent extends BaseValueAccessorComponent<object> {
  generateFormGroup: FormGroup = new FormGroup({});
  isWaitingOptionsFromServer = false;
  isFirstLoad = true;
  numberValue: string;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super('app-phone', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.ISVIEW ? this.setViewValue() : this.setAppPhone();
    this.maxLength = 13;
  }

  setAppPhone(): void {
    this.setAppPhoneValue();
    this.formControl.valueChanges.subscribe(() => {
      this.setAppPhoneValue();
    });
  }

  setViewValue(): void {
    let phoneNumber = this.formControl.value && this.formControl.value.number;
    let code =
      this.formControl.value &&
      this.formControl.value.country &&
      this.formControl.value.country.callingCode;
    this.numberValue = (code && phoneNumber && code + phoneNumber) || '-';
    this.formControl.valueChanges.subscribe(value => {
      if (typeof value === 'object' && value) {
        phoneNumber = value.number;
        code = value.country && value.country.callingCode;
        this.numberValue = (code && phoneNumber && code + phoneNumber) || '-';
      }
    });
  }

  setAppPhoneValue(): void {
    this.optionList.stringUrl = '/resource/';
    this.optionList.fileTypeCode = this.global.appConstant.fileType.IMG_RESOURCE;
    this.optionList
      .setOption('isShowIcon', true)
      .setOption('isShowIcon', true)
      .setOption('iconPath', 'resource.imgFile.uploadedFileName');
    this.optionList.setViewPath('callingCode');
    this.optionList.responseValueChanges.subscribe(() => {
      const country = this.generateFormGroup.get('country').value;
      const phoneNumber = this.generateFormGroup.get('number').value;
      if (country && phoneNumber) {
        this.formControl.patchValue({ number: phoneNumber, country });
      }
    });
    this.buildFormGroup();
    this.setFormGroup();
  }

  buildFormGroup(): void {
    if (Object.keys(this.generateFormGroup.controls).length === 0) {
      const requiredValidator =
        this.formControl.errors && this.formControl.errors.required
          ? [Validators.required('')]
          : [];
      this.generateFormGroup = this.formBuilder.group({
        country: [null, Validators.compose([...requiredValidator])],
        number: [
          null,
          Validators.compose([
            ...requiredValidator,
            Validators.maxLength(this.maxLength)
          ])
        ]
      });
    }
  }

  setFormGroup(): void {
    let optionValue: any;
    let phoneNumber: number;
    if (this.formControl.value) {
      const { number: pNumber, country } = this.formControl.value;
      phoneNumber = pNumber;
      optionValue = country || null;
    }
    this.generateFormGroup.get('country').patchValue(optionValue);

    if (!this.generateFormGroup.get('number').value) {
      this.generateFormGroup.get('number').patchValue(phoneNumber);
    }
    if (this.disabled) {
      if (this.formControl.value) {
        this.generateFormGroup.patchValue(this.formControl.value);
      }
      this.generateFormGroup.disable();
    }
    if (this.ISVIEW) {
      this.generateFormGroup.setIsView(true);
    }
  }

  handleChange(): void {
    const country = this.generateFormGroup.get('country').value;
    const phoneNumber = this.generateFormGroup.get('number').value;
    if (country && phoneNumber) {
      this.formControl.patchValue({ country, number: phoneNumber });
      this.onChange.emit(this.formControl.value);
    }
    if (phoneNumber === '') {
      this.formControl.patchValue(null);
      this.generateFormGroup.get('number').patchValue(null);
      this.generateFormGroup.get('country').patchValue(country);
    }

    if (country === null) {
      this.formControl.patchValue(null);
    }
  }
}
