import { ExistsWithPropertyValidator } from './modules/sync/exists-with-property.validator';
import { ProcurementNumberValidator } from './modules/sync/procurement-number.validators';
import { Validators as AngularValidators } from '@angular/forms';
import { ExistsAsyncValidator } from './modules/async/exists-async.validator';
import { ActualLengthValidator } from './modules/sync/actual-length.validator';
import { EmailWithPropertyValidator } from './modules/sync/email-with-property.validator';
import { EmailValidator } from './modules/sync/email.validator';
import { ExistsValidator } from './modules/sync/exists.validator';
import { IsCheckedValidator } from './modules/sync/is-checked.validator';
import { MatchEmailValidator } from './modules/sync/match-email.validator';
import { MatchPasswordValidator } from './modules/sync/match-password.validator';
import { MatchValidator } from './modules/sync/match.validator';
import { MaxLengthValidator } from './modules/sync/max-length.validator';
import { MaxValidator } from './modules/sync/max.validator';
import { MinLengthValidator } from './modules/sync/min-length.validator';
import { MinValidator } from './modules/sync/min.validator';
import { NpwpValidator } from './modules/sync/npwp.validator';
import { PasswordValidator } from './modules/sync/password.validator';
import { RequiredValidator } from './modules/sync/required.validator';
import { UrlValidator } from './modules/sync/url.validator';
export const Validators = {
  // angular validator section
  compose: AngularValidators.compose,
  composeAsync: AngularValidators.composeAsync,
  pattern: AngularValidators.pattern,
  // async validator section
  existsAsync: ExistsAsyncValidator.existsAsync,
  // sync validator section
  actualLength: ActualLengthValidator.actualLength,
  email: EmailValidator.email,
  emailWithProperty: EmailWithPropertyValidator.emailWithProperty,
  existsWithProperty: ExistsWithPropertyValidator.existsWithProperty,
  exists: ExistsValidator.exists,
  isChecked: IsCheckedValidator.isChecked,
  matchEmail: MatchEmailValidator.matchEmail,
  matchPassword: MatchPasswordValidator.matchPassword,
  match: MatchValidator.match,
  maxLength: MaxLengthValidator.maxLength,
  max: MaxValidator.max,
  minLength: MinLengthValidator.minLength,
  min: MinValidator.min,
  npwp: NpwpValidator.npwp,
  password: PasswordValidator.password,
  required: RequiredValidator.required,
  url: UrlValidator.url,
  procurementNumber: ProcurementNumberValidator.procurementNumber
};
