/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\hyperlink-plugin\index.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 11:08:20 pm

 *
*
 */

import { createTableComponent } from '../../helper';
import { HyperlinkPlugin } from '../../interface/available-table-plugin';
import { TablePlugin } from '../../interface/table-plugin';
import { TablePluginData } from '../../interface/table-plugin-data';
import { TableHyperlinkPluginComponent } from './hyperlink-plugin.component';

export const tableHyperlinkPlugin: TablePlugin = {
  name: 'hyperlink',
  before: (tablePluginData: TablePluginData): HTMLElement => {
    const hyperlinkPlugin = tablePluginData.plugin as HyperlinkPlugin;
    if (tablePluginData.value) {
      tablePluginData.element.addEventListener('click', (event: PointerEvent) =>
        event.stopPropagation()
      );
      const divHyperlinkPluginContent = createTableComponent(
        tablePluginData,
        TableHyperlinkPluginComponent,
        'hyperlink-plugin-content'
      );
      hyperlinkPlugin.className &&
        divHyperlinkPluginContent.classList.add(
          ...hyperlinkPlugin.className
            .split(' ')
            .filter(className => className && className.trim() !== '')
        );
      return divHyperlinkPluginContent;
    }
  }
};
