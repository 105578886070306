<app-popup
  header="app-popup-choose-pr-released.title"
  [isLoading]="formLoading"
>
  <div class="col popup-choose-pr" *ngIf="!formLoading">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <div
        class="form-group row text-sm-right"
        [ngStyle]="{
          height: '64px',
          left: '32px',
          top: '87px',
          'margin-left': 'auto',
          'margin-right': 'auto',
          'align-content': 'center',
          background: 'var(--gray-100)',
          'border-radius': '5px'
        }"
      >
        <div class="col-sm-12" [ngStyle]="{ 'text-align': 'left' }"
          ><app-radio
            position="HORIZONTAL"
            formControlName="source"
            [optionList]="selectSourceOptionList"
            (onChange)="onChangeSource()"
          >
          </app-radio
        ></div>
      </div>
      <ng-container
        *ngIf="
          source === global.appConstant.core.SOURCE_ORDER ||
          source === global.appConstant.core.SOURCE_DIRECT_PO
        "
      >
        <div *ngIf="!formLoading">
          <app-table-xx
            [model]="tableResponsePrItem"
            stringUrl="/app-popup-choose-pr-released/index"
            [isShowCheckBox]="true"
            [isShowButtonExpandCollapse]="true"
          >
          </app-table-xx>
        </div>
      </ng-container>
      <ng-container
        *ngIf="source === global.appConstant.core.SOURCE_ORDER_CONTRACT"
      >
        <div *ngIf="!formLoading">
          <app-table-xx
            [model]="tableResponseOrderPreparation"
            stringUrl="/app-popup-choose-pr-released/index-popup-order-contract"
            [isShowCheckBox]="true"
            [isMultipleSelect]="false"
          >
          </app-table-xx>
        </div>
      </ng-container>
      <ng-container *ngIf="source === global.appConstant.core.SOURCE_CONTRACT">
        <div *ngIf="!formLoading">
          <app-table-xx
            [model]="tableResponseContract"
            stringUrl="/app-popup-choose-pr-released/index-popup-contract"
            [isShowCheckBox]="true"
            [isMultipleSelect]="false"
          >
          </app-table-xx>
        </div>
      </ng-container>
    </form>
  </div>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button
        class="mr-1"
        color="SECONDARY"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button (onClick)="doChoose()" [disabled]="formLoading">{{
        'app.button.add' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
