import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Approval } from './../../core/bean/approval';
import { ModuleGroup } from './../../core/bean/module-group';
@Component({
  templateUrl: './approval.component.html'
})
export class ApprovalComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Approval>;
  // public moduleGroupList: ModuleGroup[];
  public moduleGroupList: OptionListModel<ModuleGroup> = new OptionListModel(
    true
  );
  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('approval', translateService);
  }

  onInit(): void {
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.doSetModuleListGroup();
    this.setStateReady();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      organizationName: [null],
      organizationId: [null],
      moduleGroup: [null]
    });
  }

  public doSetModuleListGroup(): void {
    this.httpClientService
      .get<ModuleGroup[]>('/approval/module-group-list', {})
      .subscribe(moduleGroupList => {
        this.moduleGroupList.setRequestValues(moduleGroupList);
      });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      {
        field: 'name',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      },
      {
        field: 'type',
        header: 'table.column.type',
        datamap: '{"1" : "Serial" , "2" : "Paralel" }',
        customClass: 'text-center'
      },
      {
        field: 'moduleGroup.name',
        header: 'table.column.moduleGroup',
        customClass: 'text-center'
      },
      {
        field: 'description',
        header: 'table.column.description',
        customClass: 'white-space-normal'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/approval/add']);
  }

  public doEdit(approval: Approval): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('approvalId', approval.id);
    this.router.navigate(['/pages/approval/edit']);
  }

  public doDelete(approval: Approval): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<Approval>>('/approval/delete', approval)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  public onChooseOrganization(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupOrganizationService
      .open(
        this.global.userSession.user.organization.id,
        this.global.userSession.organizationTenant.id
      )
      .subscribe(organization => {
        const { name: organizationName, id: organizationId } =
          organization.pop();
        this.formGroup.patchValue({ organizationName, organizationId });
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
      });
  }

  public doOnChangeModuleGroup(): void {
    // event.srcElement.blur();
    // event.preventDefault();
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
