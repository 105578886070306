<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="item-category.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-tree
        [model]="treeModel"
        [isLazy]="true"
        (onEventChange)="doOnEventChange($event)"
      >
        <ng-template #treePopupContent let-treePopupFormGroup>
          <form
            class="form-horizontal"
            [formGroup]="treePopupFormGroup"
            novalidate
          >
            <div
              class="form-group row text-sm-right"
              [ngClass]="
                !treeModel.formGroup.controls.code.isView ? 'required' : ''
              "
            >
              <label class="col-sm-3 control-label">{{
                'item-category.form.code' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  autofocus="true"
                  size="LG"
                  formControlName="code"
                  type="code"
                  maxLength="32"
                  tooltip="item-category.tooltip.code"
                ></app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'item-category.form.name' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  size="LG"
                  formControlName="name"
                  maxLength="32"
                ></app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'item-category.form.description' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-area
                  size="LG"
                  formControlName="description"
                  maxLength="512"
                ></app-text-area>
              </div>
            </div>
          </form>
        </ng-template>
      </app-tree>
    </app-card>
  </div>
</div>
