/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table\domain\portal-table-state.ts
 
 * Created Date: Thursday, September 30th 2021, 10:33:45 am

 *
*
 */

import { Injector } from '@angular/core';
import { ServiceLocator } from '../../../../../core/services/service-locator';
import { PortaltablePaginationModel } from '../model/portal-table-pagination-model';
import { PortalTableRequestModel } from '../model/portal-table-request-model';
import { PortalTableResponseModel } from '../model/portal-table-response-model';
export class PortalTableState {
  model: PortalTableResponseModel<any>;
  rowList: Array<any> = new Array();
  stringUrl: string;
  isServerSide: boolean;
  injector: Injector;
  tableRequest: PortalTableRequestModel;
  isLoading: boolean;
  pagination: PortaltablePaginationModel;
  public setStateLoading(): void {
    this.isLoading = true;
  }

  public setStateReady(): void {
    this.isLoading = false;
  }

  public static create(
    model: PortalTableResponseModel<any>,
    stringUrl: string
  ): PortalTableState {
    const state = new PortalTableState();
    state.model = model;
    state.stringUrl = stringUrl;
    state.isServerSide = !!stringUrl;
    state.injector = ServiceLocator.injector;
    state.tableRequest = new PortalTableRequestModel();
    state.pagination = new PortaltablePaginationModel();
    return state;
  }
}
