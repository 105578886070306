/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\modules\widget-admin-opr-worklist\widget-admin-opr-worklist.module.ts
 
 * Created Date: Wednesday, November 24th 2021, 3:03:29 pm

 *
*
 */

import { NgModule } from '@angular/core';
import { AppContentSliderModule } from '../../../../../core/components/app-content-slider/app-content-slider.module';
import { DirectivesModule } from '../../../../../core/directives/directives.module';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetAdminOprWorklistComponent } from './widget-admin-opr-worklist.component';
@NgModule({
  imports: [
    VendorModule,
    AppContentSliderModule,
    WidgetCardModule,
    DirectivesModule
  ],
  declarations: [WidgetAdminOprWorklistComponent],
  exports: [WidgetAdminOprWorklistComponent]
})
export class WidgetAdminOprWorklistModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetAdminOprWorklistComponent.moduleCode,
      WidgetAdminOprWorklistComponent,
      'w-100'
    );
  }
}
