import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ModuleGroup } from '../../core/bean/module-group';
import { ApprovalPathModel } from '../../core/components/app-approval-path';
import { AppPopupOfficerService } from '../../core/components/app-popup/app-popup-officer/app-popup-officer.service';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { Approval } from './../../core/bean/approval';
import { ApprovalResponse } from './approval-response';

@Component({
  templateUrl: './approval-edit-add.component.html'
})
export class ApprovalEditAddComponent extends BaseModuleComponent {
  public approvalId: number;
  public approvalResponse: ApprovalResponse = new ApprovalResponse();
  public approvalPath: ApprovalPathModel<any> = new ApprovalPathModel(
    this.moduleCode,
    'WRITE'
  );
  public moduleGroupOptionList: OptionListModel<ModuleGroup> =
    new OptionListModel();
  public approvalOptionList: OptionListModel<any> = new OptionListModel(
    false,
    null,
    'value'
  );
  public readonly APPROVAL_TYPE_SERIAL = 1;
  public readonly APPROVAL_TYPE_PARALEL = 2;
  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService,
    public appPopupOfficerService: AppPopupOfficerService
  ) {
    super('approval', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.doSetApprovalOptionList();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.approvalId = this.global.routerParams.get('approvalId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      moduleGroup: [null, Validators.required()],
      organization: [null, Validators.required()],
      organizationName: [null],
      description: [null],
      type: [null, Validators.required()]
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<ApprovalResponse>(
        '/approval/add-edit',
        new RouteRequestModel(this.todo, this.approvalId)
      )
      .subscribe((approvalResponse: ApprovalResponse) => {
        this.approvalResponse = approvalResponse;
        this.moduleGroupOptionList.setRequestValues(
          approvalResponse.moduleGroupList
        );
        if (this.approvalResponse.approval != null) {
          const { id, code, name, description, type } =
            this.approvalResponse.approval;
          const organization = this.approvalResponse.organizationList;
          const { name: organizationName } = organization[0];
          const moduleGroup = this.approvalResponse.approval.moduleGroup;
          this.formGroup.patchValue({
            id,
            code,
            name,
            moduleGroup,
            organization,
            organizationName,
            description,
            type
          });
          this.approvalPath
            .setApproverList(this.approvalResponse.approval.approvalModelList)
            .setType(this.formGroup.get('type').value);
        }
        this.setStateReady();
      });
  }

  public doSetApprovalOptionList(): void {
    this.approvalOptionList.setRequestValues([
      { value: this.APPROVAL_TYPE_SERIAL, name: 'approval.form.serial' },
      { value: this.APPROVAL_TYPE_PARALEL, name: 'approval.form.paralel' }
    ]);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const approval: Approval = this.global.copyFormAttributeToModel(
              new Approval(),
              this.formGroup
            );
            const lastIndexOfOrganizationList: number =
              this.formGroup.get('organization').value.length - 1;
            approval.organization =
              this.formGroup.get('organization').value[
                lastIndexOfOrganizationList
              ];
            approval.approvalModelList = this.approvalPath.value;
            const url =
              this.todo === 'edit' ? '/approval/update' : '/approval/insert';
            this.httpClientService
              .post<Response<Approval>>(url, approval)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/approval']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/approval']);
  }

  public onChangeApprovalType(approvalType: number): void {
    this.approvalPath.setType(approvalType);
  }

  public doChooseOfficer(): void {
    this.doOpenPopup('ADD');
  }

  public doOnEdit(currentIndex: number): void {
    this.doOpenPopup('EDIT', currentIndex);
  }

  public doOpenPopup(type: 'ADD' | 'EDIT', index?: number): void {
    this.appPopupOfficerService
      .open(this.approvalPath.value, null, true)
      .subscribe(officer => {
        const { organization, position } = officer;
        if (type === 'ADD') {
          this.approvalPath.addApproverToApproverList({
            organization,
            position
          });
        } else {
          this.approvalPath.changeApproverInApproverList(
            { organization, position },
            index
          );
        }
      });
  }
}
