<div class="app-table v1">
  <div class="d-flex justify-content-end mb-4">
    <app-button mode="BYPASS" (onClick)="doShowPopupEditTable()" *ngIf="isShowEditTable">{{ 'app-table.button.editTable'
      | translate }}</app-button>
    <ng-container *ngTemplateOutlet="headerButtonsTmpl"></ng-container>
  </div>
  <div class="app-table-filter" *ngIf="!isHidePagination">
    <div class="app-table-filter-left">
      <div class="app-table-filter-item">
        <div class="input-group d-flex align-items-center">
          {{ 'app-table.show' | translate }} : &nbsp;&nbsp;
          <select class="form-control custom-select" (change)="onChangeFilter($event.target.value)" aria-label="Filter">
            <option *ngFor="let option of model.selectFilterOption" [value]="option"
              [selected]="option === model.pagination.perPage">
              {{ option }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="app-table-filter-right">
      <ng-container *ngTemplateOutlet="headerFilterTableTmpl"></ng-container>
      <div class="app-table-filter-item">
        <div class="input-group app-table-search">
          <div class="input-group-prepend">
            <span class="input-group-text"><em class="fas fa-search"></em></span>
          </div>
          <input type="text" name="search" class="form-control" placeholder="{{ 'app-table.search' | translate }}"
            (input)="onInputSearch($event.target.value)" aria-label="search" />
        </div>
      </div>
    </div>
  </div>
  <perfect-scrollbar class="custom-table">
    <div [ngClass]="
        (model.isLoading && !model.pagination.totalRecords) ||
        model.isWaitingFromServer
          ? 'form-loader'
          : 'form-idle'
      "></div>
    <div class="loading" *ngIf="model.isLoading && this.model.pagination.totalRecords">
      {{ 'app-table.processing' | translate }}
    </div>
    <table class="table table-bordered table-striped dataTable dtr-inline" aria-describedby="App Table">
      <ng-container *ngTemplateOutlet="theadTmpl; context: { $implicit: model }">
      </ng-container>
      <thead *ngIf="!theadTmpl">
        <tr>
          <th class="tableNo" colspan="1" scope="col">
            {{ 'app-table.column.no' | translate }}
          </th>
          <th *ngFor="let column of model.columns; let i = index"
            (click)="column.sortable !== false && doSort(column.field)" [ngClass]="[
              column.sortable !== false ? 'sort' : '',
              model.request.sortField === column.field &&
              model.sortOrder === 'ASC'
                ? 'sort-asc'
                : '',
              model.request.sortField === column.field &&
              model.sortOrder === 'DESC'
                ? 'sort-desc'
                : '',
              column | resolveColumnClassName
            ]" [hidden]="column.isChecked === false" colspan="99" scope="col" (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)">
            {{ model.moduleCode + '.' + column.header | translate }}
          </th>
          <th *ngIf="statusTmpl" class="text-center tableAction" scope="col">
            {{ 'app-table.column.status' | translate }}
          </th>
          <th class="tableAction" *ngIf="actionButtonsTmpl && actionHeaderTmpl" scope="col">
            <ng-container *ngTemplateOutlet="actionHeaderTmpl"></ng-container>
          </th>
          <th class="tableAction" *ngIf="actionButtonsTmpl && !actionHeaderTmpl" colspan="1" scope="col">
            {{ 'app-table.column.action' | translate }}
          </th>
        </tr>
      </thead>
      <ng-container *ngTemplateOutlet="tbodyTmpl; context: { $implicit: model }">
      </ng-container>
      <tbody *ngIf="
          (!stringUrl && !tbodyTmpl && !model.isLoading) ||
          (stringUrl && !tbodyTmpl)
        ">
        <tr *ngIf="model.page.totalRecords === 0">
          <td colspan="9999999" class="bg-white text-center">
            {{ 'app.info.noData' | translate }}
          </td>
        </tr>
        <ng-container *ngIf="!rowTmpl">
          <tr *ngFor="let record of model.page.records; let i = index">
            <td class="text-center" colspan="1">
              {{ i + 1 + model.request.first }}
            </td>
            <td *ngFor="let column of model.columns" [hidden]="column.isChecked === false" colspan="99" #tdElement>
              {{ record | resolveColumnValue: column:model:tdElement }}
            </td>
            <td *ngIf="statusTmpl" class="text-center tableAction">
              <ng-container *ngTemplateOutlet="
                  statusTmpl;
                  context: { $implicit: record, index: i + model.request.first }
                "></ng-container>
            </td>
            <td *ngIf="actionButtonsTmpl" class="tableAction" colspan="1">
              <div class="button-group button-group-center">
                <ng-container *ngTemplateOutlet="
                    actionButtonsTmpl;
                    context: {
                      $implicit: record,
                      index: i + model.request.first
                    }
                  "></ng-container>
              </div>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngTemplateOutlet="rowTmpl; context: { $implicit: model }">
        </ng-container>
      </tbody>
    </table>
  </perfect-scrollbar>
  <ng-container *ngTemplateOutlet="tableFooterTmpl"></ng-container>
  <div class="container" *ngIf="!isHidePagination &&  this.model.pagination.totalRecords > 0">
      <app-pagination [model]="model.pagination" (onChange)="onChangePage($event)"></app-pagination>
  </div>
</div>