
<div
  class="card"
  [ngClass]="customClass ? customClass : ''"
  [hidden]="isHidden"
>
  <div
    class="card-header d-flex justify-content-between align-items-center"
    (click)="doExpandCollapse($event)"
    [ngClass]="[
      isDisabled ? '' : 'cursor-pointer',
      customClassHeader ? customClassHeader : ''
    ]"
  >
    <div class="ch-left d-flex align-items-center">
      <div class="chl-action-button mr-3" *ngIf="headerLeftTmpl">
        <ng-container *ngTemplateOutlet="headerLeftTmpl"></ng-container>
      </div>
      <h4
        class="title"
        [maxLine]="maxLine"
        title="{{ title | translate }}"
        innerHTML="{{ header | translate }}"
        >{{ header | translate }}</h4
      >
    </div>
    <div class="ch-right d-flex align-items-center">
      <div
        class="chr-action-button mr-2"
        *ngIf="headerRightTmpl && !isDisabled"
      >
        <ng-container *ngTemplateOutlet="headerRightTmpl"></ng-container>
      </div>
      <em
        class="pir pi-chevron-down pi-2x"
        [@rotate]="model.isExpanded ? 'rotated' : 'default'"
      ></em>
    </div>
  </div>
  <div
    class="promise-collapse"
    [@expandedCollapsed]="model.isExpanded ? 'expanded' : 'collapsed'"
  >
    <div class="promise-loading py-4" *ngIf="isLoading">
      <div class="loading"></div>
    </div>
    <div
      class="card-body"
      [ngClass]="customClassBody ? customClassBody : ''"
      *ngIf="!isLoading && !isDisabled && !isHidden"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
