/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\domain\table-row.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:21:48 am

 *
*
 */
import { EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TableRequestModel } from '../../app-table/model/table-request-model';
import { SelectedTableRow } from './selected-table-row';
import { TableColumn } from './table-column';
export class TableRow {
  // isLoading
  isLoading: boolean;
  // ketika total child lebih banyak dari pada child yang tampil
  isShowButtonLoadMore: boolean;
  // posisi dari row dalam sebuah record
  position: number;
  // record dari module atau dari backend
  record: any;
  // updated record adalah record terbaru atau yang sudah diupdate
  updatedRecord: any;
  // untuk keperluan editable dan setiap column akan disimpan disini
  formGroup: FormGroup = new FormGroup({});
  // isExpanded digunakan untuk menghandle row expanded
  isExpanded: boolean;
  // selected record
  selectedRow: SelectedTableRow = new SelectedTableRow();
  // table request digunakan untuk mendapatkan children list
  tableRequest: TableRequestModel = new TableRequestModel();
  element: HTMLElement;
  columnList: Array<TableColumn> = new Array();
  childrenList: Array<TableRow> = new Array();
  requestChanges: EventEmitter<void> = new EventEmitter();
  totalChildrenList = 0;
  constructor(record: any) {
    this.record = record;
  }

  public setStateLoading(): void {
    this.isLoading = true;
  }
  public setStateReady(): void {
    this.isLoading = false;
  }
}
