import { AppTableConstant } from '../app-table-constant';

const appTableConstant = new AppTableConstant();

export enum FieldFormatEnum {
  ShortDate = appTableConstant.FIELD_FORMAT_SHORT_DATE,
  ShortDateAndTime = appTableConstant.FIELD_FORMAT_SHORT_DATE_AND_TIME,
  LongDate = appTableConstant.FIELD_FORMAT_LONG_DATE,
  LongDateAndTime = appTableConstant.FIELD_FORMAT_LONG_DATE_AND_TIME,
  Currency = appTableConstant.FIELD_FORMAT_CURRENCY,
  Integer = appTableConstant.FIELD_FORMAT_INTEGER,
  Html = appTableConstant.FIELD_FORMAT_HTML,
  Img = appTableConstant.FIELD_FORMAT_IMG,
  DownloadableFile = appTableConstant.FIELD_FORMAT_DOWNLOADABLE_FILE,
  ColorStatus = appTableConstant.FIELD_FORMAT_COLOR_STATUS,
  Rating = appTableConstant.FIELD_FORMAT_RATING,
  EditCols = appTableConstant.FIELD_FORMAT_EDIT_COLS,
  CheckBox = appTableConstant.FIELD_FORMAT_CHECK_BOX,
  InputNumber = appTableConstant.FIELD_FORMAT_INPUT_NUMBER,
  Radio = appTableConstant.FIELD_FORMAT_RADIO,
  DownloadablePMFile = appTableConstant.FIELD_FORMAT_DOWNLOADABLE_PM_FILE,
  IntegerDecimal = appTableConstant.FIELD_FORMAT_INTEGER_DECIMAL
}
