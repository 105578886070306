<dashboard-widget-card
  header="Login Info"
  [isLoading]="model.isLoading"
  customClassHeader="border-bottom-0 pb-0"
  customClassBody="mb-2"
  size="SM"
>
  <div class="table-responsive">
    <table class="table table-striped" aria-describedby="Table">
      <tbody>
        <tr>
          <th scope="col" class="border">
            {{ moduleCode + '.role' | translate }}
          </th>
          <td class="border">
            {{ global.userSession?.activeUserRole.role.name }}
          </td>
        </tr>
        <tr>
          <th scope="col" class="border">
            {{ moduleCode + '.organization' | translate }}
          </th>
          <td class="border">{{ organizationName || '-' }}</td>
        </tr>
        <tr>
          <th scope="col" class="border">
            {{ moduleCode + '.lastLogin' | translate }}
          </th>
          <td class="border">
            {{
              lastLogin | date: global.config.parameterModel.datetimeFormatShort
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</dashboard-widget-card>
