/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\action\delete-table-record-action.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:13:34 am

 *
*
 */
import { TableState } from '../domain/table-state';
export const deleteTableRowAction = (state: TableState): void => {
  state.selectedRow.selectedRowList.forEach(record => {
    const indexOfRecord = state.model
      .getRecordList()
      .findIndex(r => JSON.stringify(r) === JSON.stringify(record.record));
    if (indexOfRecord !== -1) {
      state.recordTemp.recordMap.delete(
        JSON.stringify(state.model.getRecordList()[indexOfRecord])
      );
      state.model.getRecordList().slice(indexOfRecord, 1);
    }
  });
  state.model.setUpdatedRecordList(state.recordTemp.getRecordList());
  state.selectedRow.reset();
};
