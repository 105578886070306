import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CustomValidatorError } from '../../core/custom-validator-error';
export class ExistsWithPropertyValidator {
  public static existsWithProperty(
    list: Array<any> = [],
    property: string,
    message?: string,
    field?: string
  ): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors => {
      if (abstractControl.value) {
        if (field) {
          if (
            list.findIndex(
              item => item[field] === abstractControl.value[property]
            ) !== -1
          ) {
            return CustomValidatorError.create('exists', message);
          }
        } else {
          if (list.indexOf(abstractControl.value[property]) !== -1) {
            return CustomValidatorError.create('exists', message);
          }
        }
      }
    };
  }
}
