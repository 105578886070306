/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\action\set-table-header-sticky-action.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 4:45:05 pm

 *
*
 */
import { ElementRef } from '@angular/core';
import { TableState } from '../domain/table-state';
export const setTableHeaderStickyAction = (
  state: TableState,
  payload: {
    headerFixedElementRef: ElementRef;
    headerTempElementRef: ElementRef;
  }
): void => {
  if (state.isShowPagination === false) {
    const trTempList: HTMLCollection =
      payload.headerTempElementRef.nativeElement.children;
    const trFixedList: HTMLCollection =
      payload.headerFixedElementRef.nativeElement.children.item(0).children;
    for (let i = 0; i < trTempList.length; i++) {
      const trHTMLCollection = trTempList.item(i).children;
      for (let j = 0; j < trHTMLCollection.length; j++) {
        (trFixedList.item(j) as HTMLElement).style.width =
          trHTMLCollection.item(j).scrollWidth + 'px';
      }
    }
  }
};
