/*
 * Filename:  WebContent\src\app\core\components\app-table-x\plugins\date.plugin.ts
 * Path:  WebContent
 * Created Date: Thursday, August 26th 2021, 3:59:24 pm
 */
import { DatePipe } from '@angular/common';
import { Global } from '../../../global/global';
import { DatePlugin } from '../interfaces/available-table-plugin';
import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';
export const AppTableDatePlugin: TablePlugin = {
  name: 'date',
  before: (data: TablePluginData): string => {
    const datePlugin = data.plugin as DatePlugin;
    const datePipe = data.state.injector.get(DatePipe);
    let dateFormat: string;
    if ((!datePlugin.format && !datePlugin.customFormat) || datePlugin.format) {
      const global = data.state.injector.get(Global);
      const dateFormatMap: {
        [key: string]: string;
      } = {
        'short-date': global.config.parameterModel.dateFormatShort,
        'short-date-and-time': global.config.parameterModel.datetimeFormatShort,
        'long-date': global.config.parameterModel.dateFormatLong,
        'long-date-and-time': global.config.parameterModel.datetimeFormatLong
      };
      dateFormat = dateFormatMap[datePlugin.format || 'short-date'];
    } else {
      dateFormat = datePlugin.customFormat;
    }
    return datePipe.transform(data.value, dateFormat) || '-';
  }
};
