<app-popup>
  <em
    type="button"
    class="close outline-none"
    aria-label="Close"
    (click)="doClosePopup($event)"
    *ngIf="isShowCloseButton"
  >
    <span aria-hidden="true" class="pir pi-times"></span>
  </em>
  <br *ngIf="!isShowCloseButton" />
  <div class="text-center">
    <br *ngIf="icon" />
    <div
      *ngIf="icon"
      [ngClass]="icon"
      style="width: 100px; height: 100px; margin: 0 auto"
    ></div>
    <br *ngIf="icon" />
    <h3 *ngIf="title" class="title" [ngClass]="icon ? '' : 'text-primary'">{{
      title | translate
    }}</h3
    ><br />
    <div
      *ngIf="prompt"
      class="description"
      innerHTML="{{ prompt | translate }}"
    ></div>
  </div>

  <ng-template *ngIf="buttonLeft || buttonRight" #modalFooter let-activeModal>
    <div class="row justify-content-center mt-5">
      <app-button
        *ngIf="!isSingleButton || buttonLeft"
        color="SECONDARY"
        [outline]="true"
        minWidth="130px"
        (onClick)="activeModal.close(false)"
      >
        {{ buttonLeft || 'app.button.cancel' | translate }}
      </app-button>
      <app-button
        *ngIf="!isSingleButton || buttonRight"
        (onClick)="activeModal.close(true)"
        minWidth="130px"
      >
        {{ buttonRight || 'app.button.ok' | translate }}
      </app-button>
    </div>
  </ng-template>
</app-popup>
