<div class="promise-container promise-container-sm show-bg">
  <app-card>
    <div class="reset-password">
      <div class="rp-header">
        <div class="pvc pv-check-circle"></div>
        <h2 class="text-primary">{{
          'portal.forgotPassword.title' | translate
        }}</h2>
        <!--Forgot Your Password-->
        <p>{{ 'portal.forgotPassword.prompt' | translate }}</p>
        <!--Please enter the email address associated with your account-->
      </div>
      <div class="rp-body">
        <form [formGroup]="formGroup">
          <app-text-field
            size="XL"
            formControlName="email"
            placeholder="{{
              'portal.forgotPassword.placeholder.emailAddress' | translate
            }}"
          ></app-text-field>
        </form>
      </div>
      <div class="rp-footer">
        <div class="button-group half-button">
          <app-button
            color="SECONDARY"
            [outline]="true"
            class="app-button"
            (onClick)="doCancel()"
          >
            {{ 'app.button.cancel' | translate }}
          </app-button>
          <app-button class="app-button" (onClick)="doSave()">{{
            'app.button.submit' | translate
          }}</app-button>
        </div>
      </div>
      <small class="rpf-desc">
        {{ 'portal.forgotPassword.footer' | translate }}
        <!-- For internal users, use your email or pc user id and password. To reset the password, please contact the IT help desk at extension 5101 - 119 -->
      </small>
    </div>
  </app-card>
</div>
