/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table\model\portal-table-request-model.ts
 
 * Created Date: Thursday, September 30th 2021, 11:03:50 am

 *
*
 */
export class PortalTableRequestModel {
  perPage: number;
  page: number;
  keyword: string;
  customData: string;
  constructor(perPage = 10) {
    this.perPage = perPage;
    this.page = 1;
  }
}
