<app-layout
  [isLoading]="formLoading"
  backTo="/pages/official-report"
  header="official-report.breadcrumb.index"
>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        *ngIf="!formLoading"
        novalidate
      >
        <app-card
          header="tc.detail.general.title"
          tips="tc.detail.general.tips"
          class="separator"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">
              {{ 'official-report.form.code' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-field
                autofocus="true"
                formControlName="code"
                maxLength="32"
              ></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">
              {{ 'official-report.form.name' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-field
                autofocus="true"
                formControlName="name"
                maxLength="32"
              ></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">
              {{ 'official-report.form.modulename' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-field
                autofocus="true"
                formControlName="modulename"
                maxLength="32"
              ></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">
              {{ 'official-report.form.description' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-area
                formControlName="description"
                maxLength="512"
                size="LG"
              ></app-text-area>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">
              {{ 'official-report.form.template' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              <app-upload-x
                formControlName="fileList"
                [fileUploader]="fileUploader"
              ></app-upload-x>
            </div>
          </div>
          <div>
            <h4 class="separator">{{
              'official-report.title.availabletoken' | translate
            }}</h4>
          </div>
          <app-table-xx
            [model]="tableResponseOfficialReportToken"
            *ngIf="!formLoading"
            [isShowPagination]="false"
            [isShowSearch]="false"
          >
          </app-table-xx>
        </app-card>
      </form>
      <div class="button-group button-group-center mt-5">
        <app-button (onClick)="doSave()">
          {{ 'app.button.save' | translate }}
        </app-button>
      </div>
      <ng-template #contentSidebarRight>
        <app-tips></app-tips>
      </ng-template>
    </div>
  </div>
</app-layout>
