<app-popup
  header="app-popup-sow.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="todo === 'view'"
>
  <div class="main-row-popup-sow">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-sow.form.stages' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-dropdown-select
            size="LG"
            [optionList]="stageOfWorkOptionList"
            formControlName="stageOfWork"
            (onChange)="onChangeStage()"
          ></app-dropdown-select>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-sow.form.period' | translate
        }}</label>
        <div class="col-sm-9 text-left"
          >{{ currendDate }}
          <app-date-picker-x
            size="LG"
            formControlName="date"
            [range]="true"
            minDate="currentDate"
            maxDate="endDateValidate"
          >
          </app-date-picker-x>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-sow.form.payment' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-dropdown-select
            size="LG"
            [optionList]="sowPaymentTypeOptionList"
            formControlName="sowPaymentType"
            (onChange)="onChangePaymentType()"
          ></app-dropdown-select>
        </div>
      </div>
      <div class="form-group row text-sm-left" *ngIf="!isBlanketOrder">
        <label class="col-sm-3">{{
          'app-popup-sow.form.billingMethod' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-dropdown-select
            size="LG"
            [optionList]="billingMethodOptionList"
            formControlName="billingMethod"
            (onChange)="doOnChangeBillingMethod()"
          ></app-dropdown-select>
        </div>
      </div>

      <app-accordion
        formArrayName="sowPaymentTermList"
        [isLoading]="formLoading"
        *ngIf="!isBlanketOrder"
      >
        <app-accordion-item
          header="{{ 'app-popup-sow.accordion.paymentTerm' | translate }} {{
            i + 1
          }}"
          *ngFor="
            let payment of formGroup.controls.sowPaymentTermList.controls;
            let i = index
          "
          [formGroupName]="i"
          [isExpanded]="i === sowPaymentTermList.length - 1"
        >
          <ng-template #headerRight *ngIf="!formGroup.isView">
            <em
              *ngIf="
                sowPaymentTermList.length > 1 &&
                !isView &&
                (!payment.value.deliveryStatus ||
                  payment.value.deliveryStatus?.code ===
                    global.appConstant.pm.DELIVERY_STATUS_NOT_STARTED)
              "
              class="pir pi-trash-alt float-right"
              [ngStyle]="{ 'padding-right': '10px' }"
              (click)="doDeletePaymentTerm(i)"
              (keyup)="onKeyUp($event)"
              (keydown)="onKeyDown($event)"
            ></em>
          </ng-template>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3"
              >{{ 'app-popup-sow.form.isAssessment' | translate }}
              <app-tooltip
                position="BOTTOM"
                style="position: absolute; margin-left: 1%; margin-top: 0.3%"
              >
                <em
                  class="pir pi-info-circle float-right"
                  style="color: 'var(--primary)'"
                ></em>
                <ng-template #tooltipContent>
                  <div style="width: 150px; padding: 5px">
                    {{ 'app-popup-sow.tooltip.isAssessment' | translate }}
                  </div>
                </ng-template>
              </app-tooltip>
            </label>
            <div
              class="col-sm-9 text-left"
              *ngIf="!formGroup.isView && !payment.get('isAssessment')?.isView"
            >
              <app-toggle-switch
                formControlName="isAssessment"
              ></app-toggle-switch>
            </div>
            <div
              class="col-sm-9 text-left"
              *ngIf="formGroup.isView || payment.get('isAssessment')?.isView"
            >
              {{
                payment.value.isAssessment === true
                  ? translateService.instant('app-popup-sow.isAssessment.yes')
                  : translateService.instant('app-popup-sow.isAssessment.no')
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'app-popup-sow.form.termin' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                size="LG"
                [optionList]="paymentTermOptionList"
                formControlName="paymentTerm"
                (onChange)="onChangePaymentTerm(i)"
              ></app-dropdown-select>
            </div>
          </div>
          <!-- <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
           		'app-popup-sow.form.billingMethod' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                size="LG"
                [optionList]="billingMethodOptionList"
                formControlName="billingMethod"
                (onChange)="doOnChangeBillingMethod(payment)"
              ></app-dropdown-select>
            </div>
          </div> -->
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'app-popup-sow.form.percentage' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                size="MD"
                type="decimal"
                formControlName="percentage"
                maxLength="5"
                groupText="%"
                (onInput)="onInputPercentage(payment, i)"
              >
              </app-text-field>
              <label *ngIf="payment.get('percentage').isView">%</label>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'app-popup-sow.form.price' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                *ngIf="!payment.get('amount').isView"
                size="MD"
                formControlName="amount"
                [optionList]="currencyOptionList"
                (onChange)="onInputAmount(payment, i)"
              >
              </app-currency>
              <div *ngIf="payment.get('amount').isView"
                >{{ payment.value.amount?.price | resolveNumberToCurrency }}
                <strong>{{ currencyList[0]?.code }}</strong>
              </div>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'app-popup-sow.form.remainingPercentage' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ getRemainingPercentage(payment) | resolveNumberToDecimal }} %
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'app-popup-sow.form.remainingBalance' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ payment.value.remainingAmount | resolveNumberToCurrency }}
              <strong>{{ currencyList[0]?.code }}</strong>
            </div>
          </div>
          <div
            class="form-group row text-sm-left"
            *ngIf="payment.value.deliveryStatus && formGroup.isView"
          >
            <label class="col-sm-3">{{
              'app-popup-sow.form.paymentStatus' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-badge
                class="cml-2"
                [color]="getIsPaid(payment.value) ? 'SUCCESS' : 'SECONDARY'"
              >
                {{
                  (getIsPaid(payment.value)
                    ? 'app-popup-sow.paymentStatus.paid'
                    : 'app-popup-sow.paymentStatus.noPaid'
                  ) | translate
                }}
              </app-badge>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'app-popup-sow.form.description' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-area
                size="LG"
                formControlName="description"
                maxLength="512"
              ></app-text-area>
            </div>
          </div>
          <h3 class="separator" style="margin-top: 2rem">{{
            'app-popup-sow.separator.billingDetails' | translate
          }}</h3>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'app-popup-sow.form.billingTerm' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                size="LG"
                formControlName="billingTermList"
                [optionList]="billingTermOptionList"
                (onChange)="doOnChangeBillingTermList(i, $event)"
              ></app-chosen>
            </div>
          </div>

          <!-- <app-table-xx
            *ngIf="!formLoading"
            header="app-popup-sow.separator.goodsItem"
            [model]="tableResponseList[i][0]"
            (onAfterRowCreated)="onAfterRowCreated($event)"
          >
          </app-table-xx>
          <app-table-xx
            *ngIf="!formLoading"
            header="app-popup-sow.separator.serviceItems"
            [model]="tableResponseList[i][1]"
            (onAfterRowCreated)="onAfterRowCreated($event)"
          >
          </app-table-xx> -->

          <h3 class="separator" style="margin-top: 2rem">{{
            'app-popup-sow.separator.goodsItem' | translate
          }}</h3>
          <div
            class="table-responsive custom-table float-none"
            [ngStyle]="{ margin: '0 auto' }"
          >
            <table
              class="table table-striped box-shadow"
              aria-describedby="Table"
            >
              <thead>
                <tr class="text-center">
                  <th scope="col" class="text-left">
                    {{ 'app-popup-sow.table.column.item' | translate }}
                  </th>
                  <th scope="col" class="text-left">
                    {{ 'app-popup-sow.table.column.itemCode' | translate }}
                  </th>
                  <th scope="col" class="text-left">
                    {{ 'app-popup-sow.table.column.uom' | translate }}
                  </th>
                  <th
                    scope="col"
                    [ngClass]="!formGroup.isView ? 'text-center' : 'text-right'"
                  >
                    {{ 'app-popup-sow.table.column.quantity' | translate }}
                  </th>
                  <th scope="col" class="text-right" *ngIf="!formGroup.isView">
                    {{
                      'app-popup-sow.table.column.remainingQuantity' | translate
                    }}
                  </th>
                </tr>
              </thead>
              <tbody formArrayName="sowPaymentTermGoodsItemList">
                <tr
                  *ngIf="
                    payment.controls.sowPaymentTermGoodsItemList.controls
                      .length == 0
                  "
                >
                  <td colspan="99" class="bg-white text-center">
                    <div style="padding: 3% 0">
                      <div
                        class="pvc pv-file-list"
                        style="height: 88.28px; width: 76.17px; margin: 0 auto"
                      ></div>
                      <div
                        style="
                          text-align: center;
                          padding: 50px 0;
                          color: var(--gray-500);
                          font-weight: 500;
                          flex-direction: column;
                        "
                      >
                        <span>
                          {{ 'app-table.noDataAvailable' | translate }}
                        </span>
                        <br />
                        <span>
                          {{ 'app-table.letsTryToAddList' | translate }}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr
                  *ngFor="
                    let paymentGoodsItem of payment.controls
                      .sowPaymentTermGoodsItemList.controls;
                    let j = index
                  "
                  [formGroupName]="j"
                >
                  <td>
                    {{ paymentGoodsItem.value.prItemReleased.prItem.item.name }}
                  </td>
                  <td>
                    {{ paymentGoodsItem.value.prItemReleased.prItem.item.code }}
                  </td>
                  <td class="text-left">
                    {{
                      paymentGoodsItem.value.prItemReleased.prItem.item.uom.name
                    }}
                  </td>
                  <td
                    [ngClass]="
                      !formGroup.isView ? 'text-center row' : 'text-right'
                    "
                  >
                    <app-count
                      size="LG"
                      type="decimal"
                      [min]="0"
                      [max]="
                        paymentGoodsItem.value.remainingQuantity +
                        paymentGoodsItem.value.quantity
                      "
                      formControlName="quantity"
                      (input)="
                        onInputGoodsQuantity(paymentGoodsItem, j, i, payment)
                      "
                      (onChange)="
                        onInputGoodsQuantity(paymentGoodsItem, j, i, payment)
                      "
                      [ngStyle]="{ 'margin-left': 'auto' }"
                    ></app-count>
                  </td>
                  <td class="text-right" *ngIf="!formGroup.isView">
                    {{
                      paymentGoodsItem.value.remainingQuantity !== null
                        ? (paymentGoodsItem.value.remainingQuantity
                          | resolveNumberToDecimal)
                        : (paymentGoodsItem.value.prItemReleased.quantity
                          | resolveNumberToDecimal)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3 class="separator" style="margin-top: 2rem">{{
            'app-popup-sow.separator.serviceItems' | translate
          }}</h3>
          <div
            class="table-responsive custom-table float-none"
            [ngStyle]="{ margin: '0 auto' }"
          >
            <table
              class="table table-striped box-shadow"
              aria-describedby="Table"
            >
              <thead>
                <tr class="text-center">
                  <th scope="col" class="text-left">
                    {{ 'app-popup-sow.table.column.item' | translate }}
                  </th>
                  <th scope="col" class="text-left">
                    {{ 'app-popup-sow.table.column.itemCode' | translate }}
                  </th>
                  <th scope="col" class="text-left">
                    {{ 'app-popup-sow.table.column.uom' | translate }}
                  </th>
                  <th
                    scope="col"
                    [ngClass]="!formGroup.isView ? 'text-center' : 'text-right'"
                  >
                    {{ 'app-popup-sow.table.column.deliverible' | translate }}
                  </th>
                  <th scope="col" class="text-right" *ngIf="!formGroup.isView">
                    {{
                      'app-popup-sow.table.column.remainingDeliverible'
                        | translate
                    }}(%)
                  </th>
                </tr>
              </thead>
              <tbody formArrayName="sowPaymentTermServiceItemList">
                <tr
                  *ngIf="
                    payment.controls.sowPaymentTermServiceItemList.controls
                      .length == 0
                  "
                >
                  <td colspan="99" class="bg-white text-center">
                    <div style="padding: 3% 0">
                      <div
                        class="pvc pv-file-list"
                        style="height: 88.28px; width: 76.17px; margin: 0 auto"
                      ></div>
                      <div
                        style="
                          text-align: center;
                          padding: 50px 0;
                          color: var(--gray-500);
                          font-weight: 500;
                          flex-direction: column;
                        "
                      >
                        <span>
                          {{ 'app-table.noDataAvailable' | translate }}
                        </span>
                        <br />

                        <span>
                          {{ 'app-table.letsTryToAddList' | translate }}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr
                  *ngFor="
                    let paymentServiceItem of payment.controls
                      .sowPaymentTermServiceItemList.controls;
                    let j = index
                  "
                  [formGroupName]="j"
                >
                  <td>
                    {{
                      paymentServiceItem.value.prItemReleased.prItem.item.name
                    }}
                  </td>
                  <td>
                    {{
                      paymentServiceItem.value.prItemReleased.prItem.item.code
                    }}
                  </td>
                  <td class="text-left">
                    {{
                      paymentServiceItem.value.prItemReleased.prItem.item.uom
                        .name
                    }}
                  </td>
                  <td
                    [ngClass]="
                      !formGroup.isView ? 'text-center row' : 'text-right'
                    "
                  >
                    <app-count
                      size="LG"
                      type="decimal"
                      [min]="0"
                      [max]="
                        +paymentServiceItem.value.remainingQuantity +
                        +paymentServiceItem.value.quantity
                      "
                      formControlName="quantity"
                      (input)="
                        onInputQuantityService(
                          paymentServiceItem,
                          j,
                          i,
                          payment
                        )
                      "
                      (onChange)="
                        onInputQuantityService(
                          paymentServiceItem,
                          j,
                          i,
                          payment
                        )
                      "
                      [ngStyle]="{ 'margin-left': 'auto' }"
                    ></app-count>
                  </td>
                  <td class="text-right" *ngIf="!formGroup.isView">
                    {{
                      paymentServiceItem.value.remainingQuantity !== null
                        ? (paymentServiceItem.value.remainingQuantity
                          | resolveNumberToDecimal)
                        : 100.0
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-accordion-item>
      </app-accordion>

      <div class="form-group row text-sm-right" *ngIf="!formGroup.isView">
        <div
          class="col-12 mt-2 text-center button-add-more"
          *ngIf="!isBlanketOrder"
        >
          <app-button
            size="XL"
            [disabled]="remainingAmount === 0 || isDisableAddMore"
            (onClick)="addSowPaymentTerm()"
            ><em class="pir pi-plus-circle"></em>
            {{ 'app-popup-sow.button.addMore' | translate }}</app-button
          >
        </div>
      </div>

      <ng-template *ngIf="!formGroup.isView" #modalFooter let-activeModal>
        <div class="button-group button-group-center">
          <app-button
            color="SECONDARY"
            [outline]="true"
            (onClick)="activeModal.close(true)"
            >{{ 'app.button.cancel' | translate }}</app-button
          >
          <app-button
            color="PRIMARY"
            (onClick)="isBlanketOrder ? doSaveBlanketOrder() : doSave()"
            >{{ 'app.button.add' | translate }}</app-button
          >
        </div>
      </ng-template>
    </form>
  </div>
</app-popup>
