/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\date-picker-plugin\date-picker-plugin.component.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 9:49:59 pm

 *
*
 */

import { Component } from '@angular/core';
import { FileUploader, Uploader } from '../../../upload';
import { BaseTableFormPluginComponent } from '../../base/base-table-form-plugin.component';
import { UploadPlugin } from '../../interface/available-table-plugin';
@Component({
  templateUrl: './upload-plugin.component.html'
})
export class TableUploadPluginComponent extends BaseTableFormPluginComponent {
  public uploadPlugin: UploadPlugin;
  public fileUploader: FileUploader;
  public model: Uploader;
  public isSingle: boolean;
  constructor() {
    super();
  }

  protected onInitBaseTableForm(): void {
    this.setInitializationPluginState();
    this.setFileUploaderState();
  }

  private setInitializationPluginState(): void {
    this.uploadPlugin = this.tablePluginData.plugin as UploadPlugin;
  }

  private setFileUploaderState(): void {
    if (this.tablePluginData.value) {
      if (this.tablePluginData.value.length) {
        this.uploadPlugin.fileUploader.setFileList(this.tablePluginData.value);
      } else {
        this.uploadPlugin.fileUploader.setFile(this.tablePluginData.value);
      }
      if (!this.uploadPlugin.fileUploader.fileTypeCode) {
        this.uploadPlugin.fileUploader.fileTypeCode =
          this.tablePluginData.value.fileType.code;
      }
      this.formControl.patchValue(
        this.uploadPlugin.fileUploader.fileObjectList
      );
    }
    if (this.uploadPlugin.fileUploader) {
      this.fileUploader = Object.assign({}, this.uploadPlugin.fileUploader);
      this.isSingle =
        this.uploadPlugin.fileUploader.isSingle ||
        this.uploadPlugin.fileUploader.maxFile <= 1;
    }

    if (this.uploadPlugin.model) {
      const { stringUrl, fileTypeCode, options } = this.uploadPlugin.model;
      this.model = new Uploader(stringUrl, fileTypeCode, options);
      this.isSingle =
        this.uploadPlugin.isSingle ||
        !this.model.options ||
        !this.model.options.maxFile ||
        this.model.options.maxFile <= 1;
    }
  }
}
