/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\icon-plugin\icon-plugin.component.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Wednesday, September 15th 2021, 11:56:51 am

 *
*
 */
import { Component } from '@angular/core';
import { BaseTablePluginComponent } from '../../base/base-table-plugin.component';
import { IconPlugin } from '../../interface/available-table-plugin';
@Component({
  templateUrl: './icon-plugin.component.html'
})
export class TableIconPluginComponent extends BaseTablePluginComponent {
  public iconPlugin: IconPlugin;
  constructor() {
    super();
  }

  onInit(): void {
    this.setInitializationPluginState();
  }

  private setInitializationPluginState(): void {
    this.iconPlugin = this.tablePluginData.plugin as IconPlugin;
  }
}
