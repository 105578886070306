/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TextUtils } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public nextModal = false;
  public nextModalType = null;
  public nextModalMsg = null;

  public msg: string;
  public icon: string;
  public title: string;
  public selector: string;
  public options: AlertOptions;
  public type: 'success' | 'info' | 'danger' | 'warn';
  public previousIdMapper: Map<string, string> = new Map();
  constructor(public readonly translateService: TranslateService) { }

  public showErrorOnNextRoute(msg: string): void {
    this.nextModal = true;
    this.nextModalType = 'error';
    this.nextModalMsg = msg;
  }

  public showSuccessOnNextRoute(msg: string): void {
    this.nextModal = true;
    this.nextModalType = 'success';
    this.nextModalMsg = msg;
  }

  public showInfoOnNextRoute(msg: string): void {
    this.nextModal = true;
    this.nextModalType = 'info';
    this.nextModalMsg = msg;
  }

  public showSuccessSavingOnNextRoute(): void {
    this.showSuccessOnNextRoute(
      this.translateService.instant('app.alert.msg.saveSuccess')
    );
  }

  public showSuccessSavingStatusOnNextRoute(): void {
    this.showSuccessOnNextRoute(
      this.translateService.instant('delegation.alert.msg.saveStatusSuccess')
    );
  }

  public showNextModal(): void {
    if (this.nextModalType === 'error') {
      this.showError(this.nextModalMsg);
    } else if (this.nextModalType === 'success') {
      this.showSuccess(this.nextModalMsg);
    } else if (this.nextModalType === 'info') {
      this.showInfo(this.nextModalMsg);
    } else if (this.nextModalType === 'warning') {
      this.showInfo(this.nextModalMsg);
    }
  }

  public showSuccessSaving(): void {
    this.showSuccess(
      this.translateService.instant('app.alert.msg.saveSuccess')
    );
  }

  public showSuccessDelete(): void {
    this.showSuccess(
      this.translateService.instant('app.alert.msg.deleteSuccess')
    );
  }

  public showError(
    msg: string,
    selector?: string,
    options?: AlertOptions
  ): void {
    this.msg = msg;
    this.type = 'danger';
    this.icon = 'fa-times';
    this.options = options;
    this.selector = selector;
    this.title = 'app.alert.title.error';
    this.createAlert();
  }

  public showSuccess(
    msg: string,
    selector?: string,
    options?: AlertOptions
  ): void {
    this.msg = msg;
    this.type = 'success';
    this.icon = 'fa-check-circle';
    this.options = options;
    this.selector = selector;
    this.title = 'app.alert.title.success';
    this.createAlert();
  }

  public showInfo(
    msg: string,
    selector?: string,
    options?: AlertOptions
  ): void {
    this.msg = msg;
    this.type = 'info';
    this.icon = 'fa-info-circle';
    this.options = options;
    this.title = 'app.alert.title.info';
    this.selector = selector;
    this.createAlert();
  }

  public showWarning(
    msg: string,
    selector?: string,
    options?: AlertOptions
  ): void {
    this.msg = msg;
    this.type = 'warn';
    this.icon = 'fa-exclamation-circle';
    this.options = options;
    this.title = 'app.alert.title.info';
    this.selector = selector;
    this.createAlert();
  }

  public createAlert(): void {
    const previousId = this.previousIdMapper.get(this.type);
    if (
      !(
        this.options &&
        this.options.totalAlert === 1 &&
        jQuery('#' + previousId).length === 1
      )
    ) {
      const { selector, msg, type, icon, title } = this;
      const id = TextUtils.generateRandomString();
      selector
        ? jQuery(selector).get(0).scrollIntoView({ behavior: 'smooth' })
        : jQuery('html, body').scrollTop(0);
      jQuery(
        this.generateHtmlAlert(
          id,
          type,
          icon,
          this.translateService.instant(title),
          this.translateService.instant(msg)
        )
      ).insertBefore(selector || '.main-row');

      if (!this.options || (this.options && !this.options.static)) {
        jQuery('#' + id)
          .hide()
          .fadeIn(400)
          .delay(5000)
          .fadeOut(500, () => {
            const alertElement = document.getElementById(id);
            jQuery(alertElement).remove();
          });
      } else {
        jQuery('#' + id)
          .hide()
          .fadeIn(400);
      }

      this.previousIdMapper.set(this.type, id);
    }
  }

  public generateHtmlAlert(
    id: string,
    type: string,
    icon: string,
    title: string,
    msg: string
  ): string {
    return `
      

      <div id="${id}" class="alert alert-${type} alert-dismissible fade show" role="alert">
    <!-- Ikon -->
    <i class="fas ${icon} mr-2"></i>
    <!-- Pesan -->
    <strong>${title}</strong>  ${msg}
    <!-- Tombol close -->
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
    `;
  }
}

interface AlertOptions {
  static?: boolean;
  totalAlert?: number;
}
