/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\core\components\table\plugins\default-header-plugin\default-header-plugin.component.ts
 
 * Created Date: Wednesday, October 13th 2021, 10:55:45 am

 *
*
 */

import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  TemplateRef
} from '@angular/core';
import { TableState } from '../../domain/table-state';
import { TableHeaderPluginData } from '../../interface/table-plugin-data';
import { TableColumnModel } from '../../model/table-column-model';
@Component({
  templateUrl: './default-header-plugin.component.html'
})
export class TableDefaultHeaderPluginComponent
  implements OnInit, AfterViewInit
{
  @Input() tableHeaderPluginData: TableHeaderPluginData;

  public state: TableState;
  public column: TableColumnModel;
  public header: string;
  public tooltip: any;

  public tooltipContent: string | TemplateRef<any>;

  ngOnInit(): void {
    this.setInitializationState();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (
        this.column.header &&
        typeof this.column.header !== 'string' &&
        this.column.header.tooltip
      ) {
        this.tooltipContent =
          (this.column.header.tooltip.callback &&
            this.column.header.tooltip.callback(this.tableHeaderPluginData)) ||
          this.column.header.tooltip.content;
      }
    });
  }

  private setInitializationState(): void {
    this.state = this.tableHeaderPluginData.state;
    this.column = this.tableHeaderPluginData.column;
    this.header =
      typeof this.column.header === 'string'
        ? this.column.header
        : this.column.header.key;

    // tooltip
    if (this.column.header && typeof this.column.header !== 'string') {
      this.tooltip = this.column.header.tooltip;
    }
  }
}
