/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\modules\widget-task-activity\widget-task-activity.module.ts
 
 * Created Date: Thursday, November 25th 2021, 1:47:02 pm

 *
*
 */
import { NgModule } from '@angular/core';
import { AppContentSliderModule } from '../../../../../core/components/app-content-slider/app-content-slider.module';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetTaskActivityComponent } from './widget-task-activity.component';
@NgModule({
  imports: [VendorModule, WidgetCardModule, AppContentSliderModule],
  declarations: [WidgetTaskActivityComponent],
  exports: [WidgetTaskActivityComponent]
})
export class WidgetTaskActivityModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetTaskActivityComponent.moduleCode,
      WidgetTaskActivityComponent,
      'w-100'
    );
  }
}
