/*
 * Filename:  WebContent\src\app\core\components\app-table-x\helpers\add-plugin-formcontrol-to-formgroup.helper.ts
 * Path:  WebContent
 * Created Date: Monday, August 30th 2021, 11:53:00 am

 *

 */
import { FormControl } from '@angular/forms';
import { TablePluginData } from '../interface/table-plugin-data';
export const addPluginFormControlToFormGroup = (
  tablePluginData: TablePluginData,
  formControl: FormControl
): void => {
  const row = tablePluginData.indexI;
  const formArray = tablePluginData.state.model.formArray;
  if (formArray.length <= row) {
    tablePluginData.row.formGroup.addControl(
      createFormControlKey(tablePluginData.column.column.field),
      formControl
    );
    formArray.push(tablePluginData.row.formGroup);
  } else {
    tablePluginData.row.formGroup.addControl(
      createFormControlKey(tablePluginData.column.column.field),
      formControl
    );
  }
};

export const createFormControlKey = (field: string): string => {
  return field
    .split('.')
    .map((f, index) =>
      index === 0 ? f : f.charAt(0).toUpperCase() + f.slice(1, f.length)
    )
    .join('');
};
