
import { AccordionItemModel } from '../model/accordion-item-model';
import { AccordionResponseModel } from '../model/accordion-response-model';
export class AccordionState {
  public isShowMultiple: boolean;
  public isShowButtonExpandCollapse: boolean;
  public isExpandAll: boolean;
  public model: AccordionResponseModel<any>;
  public isView: boolean;
  public accordionItemMap: Map<string, AccordionItemModel<any>>;
  public static create(
    isShowMultiple: boolean,
    isShowControls: boolean,
    isExpandAll: boolean,
    model: AccordionResponseModel<any>,
    isView: boolean
  ): AccordionState {
    const state = new AccordionState();
    state.isShowMultiple = !!isShowMultiple;
    state.isShowButtonExpandCollapse = !!isShowControls;
    state.isExpandAll = isExpandAll || false;
    state.model = model;
    state.isView = !!isView;
    state.accordionItemMap = new Map();
    return state;
  }
}
