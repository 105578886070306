import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Optional
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { take } from 'rxjs/operators';
import * as uploadAction from '../../actions/upload-file.action';
import {
  BaseUploadComponent,
  makeUploaderProvider
} from '../../base/base-upload.component';
import { UploaderHelpers } from '../../helpers/upload-helpers';
import { UploadMapper } from '../../mapper/upload-mapper';
import { UploaderFile } from '../../model';
import { Uploader } from '../../model/uploader';
import { UploadService } from '../../upload.service';
@Component({
  selector: 'app-upload',
  templateUrl: './app-upload.component.html',
  styleUrls: ['./app-upload.component.scss'],
  providers: [...makeUploaderProvider(AppUploadComponent)]
})
export class AppUploadComponent
  extends BaseUploadComponent
  implements AfterViewChecked
{
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    uploadService: UploadService,
    private cdf: ChangeDetectorRef
  ) {
    super('app-upload', controlContainer, elementRef, uploadService);
  }

  ngAfterViewChecked(): void {
    this.cdf.detectChanges();
  }

  public onInitBaseUpload(): void {
    this.uploadService
      .setUploader(this.model, this.formControl)
      .subscribe((uploader: Uploader) => {
        const values = UploadMapper.toValues(uploader);
        this.formControl.patchValue(values);
        this.doSetFileListToFileUploader();
        this.onChange.emit(values);
      });
  }

  public onUploadChange(event: any, index?: number): void {
    typeof index !== 'undefined'
      ? this.uploadService.dispatch(
          new uploadAction.ChangeFile({ fileList: event.target.files, index })
        )
      : this.uploadService.dispatch(
          new uploadAction.AddFile({ fileList: event.target.files })
        );
    event.target.value = null;
  }

  public doDeleteFile(index?: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          typeof index !== 'undefined'
            ? this.uploadService.dispatch(
                new uploadAction.DeleteFile({ index })
              )
            : this.uploadService.dispatch(new uploadAction.BulkDeleteFile());
        }
      });
  }

  public doReupload(uploaderFile: UploaderFile): void {
    this.uploadService.dispatch(new uploadAction.ReUpload({ uploaderFile }));
  }

  public doCancelUpload(uploaderFile: UploaderFile): void {
    this.uploadService.dispatch(
      new uploadAction.CancelUpload({ uploaderFile })
    );
  }

  public doDownloadFile(event: MouseEvent, uploaderFile: UploaderFile): void {
    if (uploaderFile.file) {
      event.preventDefault();
      event.stopPropagation();
      this.downloadService.download(
        UploaderHelpers.getFileUrlWithFileUploader(this.global, uploaderFile),
        uploaderFile.file.fileName
      );
    }
  }
}
