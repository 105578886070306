/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\date-picker-plugin\date-picker-plugin.component.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 9:49:59 pm

 *
*
 */

import { Component } from '@angular/core';
import { BaseTableFormPluginComponent } from '../../base/base-table-form-plugin.component';
import { DatePickerPlugin } from '../../interface/available-table-plugin';

@Component({
  templateUrl: './date-picker-plugin.component.html'
})
export class TableDatePickerPluginComponent extends BaseTableFormPluginComponent {
  public datePickerPlugin: DatePickerPlugin;
  constructor() {
    super();
  }

  protected onInitBaseTableForm(): void {
    this.setInitializationPluginState();
  }

  private setInitializationPluginState(): void {
    this.datePickerPlugin = this.tablePluginData.plugin as DatePickerPlugin;
  }
}
