/*
 * Filename:  WebContent\src\app\core\components\app-table-x\helpers\set-table-record-form-value.helper.ts
 * Path:  WebContent
 * Created Date: Wednesday, August 25th 2021, 5:18:54 pm

 *

 */
import { TableFieldModel } from '../model/table-field-model';
import { TableRecord } from '../model/table-record';
import { TableState } from '../model/table-state';
import { reSetTableRecord } from './re-set-table-record.helper';
export const setTableRecordFormValue = (
  state: TableState,
  tableRecord: TableRecord,
  column: TableFieldModel,
  indexI: number,
  value: any
): void => {
  const record =
    state.store.recordMap.get(JSON.stringify(tableRecord.record)) ||
    tableRecord.record;
  state.store.recordMap.set(
    JSON.stringify(tableRecord.record),
    reSetTableRecord(record, column.field, value)
  );
  state.model.updatedRecordList = state.store.getRecords();
  state.model.lastChangeForm = {
    index: indexI - 1,
    field: column.field
  };
  state.event.emit({
    type: 'CHANGE-RECORD',
    data: {
      record: state.store.recordMap.get(JSON.stringify(tableRecord.record))
    }
  });
};
