import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { BaseComponentComponent } from '../../base/angular/base-component.component';

@Component({
  selector: 'app-content-slider',
  templateUrl: './app-content-slider.component.html',
  styleUrls: ['./app-content-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppContentSliderComponent
  extends BaseComponentComponent
  implements AfterViewInit, OnChanges
{
  @Input() header: string;
  @Input() isShowControl = true;
  @Input() controlPosition: 'TOP' | 'ASIDE' | 'BOTTOM' = 'TOP';
  @Input() isLoading: boolean;
  @Input() isShowShadow: boolean;
  @Input() customClass: string;
  @Output() onScrollbar: EventEmitter<PerfectScrollbarComponent> =
    new EventEmitter();

  public scrollOnStartPosition = [false];
  public scrollOnEndPosition = [false];
  public isShowButtonControls = [false];

  @ContentChild('customHeader') customHeaderTmpl: TemplateRef<any>;
  @ContentChild('customContent') customContentTmpl: TemplateRef<any>;
  @ContentChild('buttonControlLeft') buttonControlLeftTmpl: TemplateRef<any>;
  @ContentChild('buttonControlRight') buttonControlRightTmpl: TemplateRef<any>;

  @ViewChild('mainContentSlider') mainContentSlider: ElementRef;
  @ViewChild('perfectScrollbar') perfectScrollbar: PerfectScrollbarComponent;

  constructor() {
    super('app-content-slider');
  }

  onInit(): void {
    this.setStateReady();
  }

  ngAfterViewInit(): void {
    if (!this.isLoading) {
      setTimeout(() => {
        this.setDisabledButtonScrollState();
      });
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.isLoading && !simpleChanges.isLoading.currentValue) {
      setTimeout(() => {
        this.setDisabledButtonScrollState();
      });
    }
  }

  public doScrollToLeft(): void {
    const element = this.mainContentSlider.nativeElement;
    const parentScrollWidth =
      this.mainContentSlider.nativeElement.parentElement.scrollWidth;
    const scrollLeft = element.scrollLeft;
    element.scrollTo(-(parentScrollWidth - scrollLeft), 0);
  }

  public doScrollToRight(): void {
    const element = this.mainContentSlider.nativeElement;
    const parentScrollWidth =
      this.mainContentSlider.nativeElement.parentElement.scrollWidth;
    const scrollLeft = element.scrollLeft;
    element.scrollTo(parentScrollWidth + scrollLeft, 0);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }

  private setDisabledButtonScrollState(): void {
    const contentSliderBodyChildren =
      this.mainContentSlider.nativeElement.children;
    this.scrollOnStartPosition[0] = true;
    if(this.mainContentSlider.nativeElement.parentElement.scrollWidth!==0){
      this.scrollOnEndPosition[0] =
        this.mainContentSlider.nativeElement.parentElement.scrollWidth >=
        contentSliderBodyChildren.length *
          contentSliderBodyChildren.item(0).scrollWidth;
    }
    this.mainContentSlider.nativeElement.addEventListener(
      'scroll',
      (event: Event & { target: HTMLElement }) => {
        const { scrollLeft, offsetWidth, scrollWidth } = event.target;
        this.scrollOnStartPosition[0] = scrollLeft === 0;
        this.scrollOnEndPosition[0] = scrollLeft + offsetWidth === scrollWidth;
      }
    );
    if (this.perfectScrollbar) {
      this.onScrollbar.emit(this.perfectScrollbar);
    }

    this.isShowButtonControls = [ (this.mainContentSlider.nativeElement.scrollWidth!=0 && this.mainContentSlider.nativeElement.parentElement.scrollWidth!=0)?
      this.mainContentSlider.nativeElement.scrollWidth >
        this.mainContentSlider.nativeElement.parentElement.scrollWidth:true
    ];
  }
}
