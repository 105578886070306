/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\domain\widget-item.ts
 
 * Created Date: Thursday, November 18th 2021, 2:28:44 pm

 *
*
 */

import { EventEmitter } from '@angular/core';
import { UserDashboardWidget } from '../../../../core/bean/user-dashboard-widget';
export class WidgetItem {
  widget: UserDashboardWidget;
  isLoading: boolean;
  loadingChanges: EventEmitter<void> = new EventEmitter();
  isNew: boolean;
  setStateLoading(): void {
    this.isLoading = true;
  }
  setStateReady(): void {
    this.isLoading = false;
    this.loadingChanges.emit();
  }

  public static create(
    widget: UserDashboardWidget,
    isNew: boolean = false
  ): WidgetItem {
    const widgetItem: WidgetItem = new WidgetItem();
    widgetItem.widget = widget;
    widgetItem.isNew = isNew;
    return widgetItem;
  }
}
