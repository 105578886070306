/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\action\table-action.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:16:50 am

 *
*
 */
import { ElementRef } from '@angular/core';
import { Action } from '../../../interfaces/action';
import { TableRow } from '../domain/table-row';
import { TableColumnModel } from '../model/table-column-model';

// TODO Make this constant standard. Do it later
export const FIRST_LOAD_TABLE = `[ TABLE ]: First Load Table`;
export const CHANGE_TABLE_FILTER_PER_PAGE = `[ Table ]: Change Table Filter Per Page`;
export const CHANGE_TABLE_PAGE = `[ TABLE ]: Change Table Page`;
export const SEARCH_TABLE = `[ TABLE ]: Search Table`;
export const RELOAD_TABLE = `[ Table ]: Reload Table`;
export const SORT_TABLE = `[ TABLE ]: Sort Table`;
export const LOAD_TABLE_RECORD_CHILD = `[ TABLE ]: Load Table Record Child`;
// addtional
export const DELETE_TABLE_ROW = `[ Table ]: Delete Table Row`;
export const SELECT_ALL_TABLE_ROW = `[ Table ]: Select All Table Row`;
export const SELECT_TABLE_ROW = `[ Table ]: Select Table Row`;
export const EXPAND_OR_COLLAPSE_TABLE_ROW = `[ TABLE ]: Expand Or Collapse Table Row`;
export const EXPAND_OR_COLLAPSE_ALL_TABLE_ROW = `[ TABLE ]: Expand Or Collapse All Table Row`;
export const SET_STICKY_TABLE_HEADER = `[ TABLE ]: Set Sticky Table Header`;

export class FirstLoadTable implements Action<any> {
  readonly type = FIRST_LOAD_TABLE;
}

export class ChangeTableFilterPerPage implements Action<{ perPage: number }> {
  readonly type = CHANGE_TABLE_FILTER_PER_PAGE;
  constructor(public payload: { perPage: number }) {}
}

export class ChangeTablePage implements Action<{ page: number }> {
  readonly type = CHANGE_TABLE_PAGE;
  constructor(public payload: { page: number }) {}
}

export class SearchTable implements Action<{ keyword: string }> {
  readonly type = SEARCH_TABLE;
  constructor(public payload: { keyword: string }) {}
}

export class ReloadTable implements Action<void> {
  readonly type = RELOAD_TABLE;
}

export class SortTable implements Action<{ column: TableColumnModel }> {
  readonly type = SORT_TABLE;
  constructor(public payload: { column: TableColumnModel }) {}
}

export class DeleteTableRow implements Action<void> {
  readonly type = DELETE_TABLE_ROW;
}

export class SelectAllTableRow implements Action<{ isChecked: boolean }> {
  readonly type = SELECT_ALL_TABLE_ROW;
  constructor(public payload: { isChecked: boolean }) {}
}

export class SelectTableRow
  implements
    Action<{
      row: TableRow;
      isChecked: boolean;
      parent?: TableRow;
    }>
{
  readonly type = SELECT_TABLE_ROW;
  constructor(
    public payload: {
      row: TableRow;
      isChecked: boolean;
      parent?: TableRow;
    }
  ) {}
}

export class LoadTableRowChild implements Action<{ row: TableRow }> {
  readonly type = LOAD_TABLE_RECORD_CHILD;
  constructor(public payload: { row: TableRow }) {}
}

export class ExpandOrCollapseTableRow implements Action<{ row: TableRow }> {
  readonly type = EXPAND_OR_COLLAPSE_TABLE_ROW;
  constructor(public payload: { row: TableRow }) {}
}

export class ExpandOrCollapseAllTableRow
  implements Action<{ action: 'EXPAND' | 'COLLAPSE' }>
{
  readonly type = EXPAND_OR_COLLAPSE_ALL_TABLE_ROW;
  constructor(public payload: { action: 'EXPAND' | 'COLLAPSE' }) {}
}

export class SetStickyTableHeader implements Action<any> {
  readonly type = SET_STICKY_TABLE_HEADER;
  constructor(
    public payload: {
      headerFixedElementRef: ElementRef;
      headerTempElementRef: ElementRef;
    }
  ) {}
}

export type AllTableAction =
  | FirstLoadTable
  | SearchTable
  | SortTable
  | ChangeTablePage
  | SetStickyTableHeader
  | DeleteTableRow
  | SelectTableRow
  | SelectAllTableRow
  | ChangeTableFilterPerPage
  | ExpandOrCollapseTableRow
  | ExpandOrCollapseAllTableRow
  | LoadTableRowChild
  | ReloadTable;
