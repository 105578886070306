import {
  Component,
  ElementRef,
  Input,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
@Component({
  selector: 'app-date-picker',
  templateUrl: './app-date-picker.component.html',
  styleUrls: ['./app-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: makeProvider(AppDatePickerComponent)
})
export class AppDatePickerComponent extends BaseValueAccessorComponent<Date> {
  @Input() private maxDate: Date | string;
  @Input() private minDate: Date | string;

  public minDateValue: NgbDateStruct;
  public maxDateValue: NgbDateStruct;

  private minDateRangeFromCurrentYear = 100;

  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super('app-datepicker', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setDefaultMinDate();
    this.setMinDateValue();
    this.setMaxDateValue();
  }

  private setDefaultMinDate(): void {
    if (!this.minDate) {
      const currentYear = new Date().getFullYear();
      this.minDate = new Date(
        `01/01/${currentYear - this.minDateRangeFromCurrentYear}`
      );
    }
  }

  private setMinDateValue(): void {
    if (this.minDate) {
      const minDate =
        this.minDate instanceof Date
          ? this.minDate
          : this.formGroup.get(this.minDate).value;
      this.minDateValue = this.setMaxDateAndMinDate(minDate);
      if (typeof this.minDate === 'string') {
        this.formGroup.get(this.minDate).valueChanges.subscribe(value => {
          this.minDateValue = this.setMaxDateAndMinDate(value);
        });
      }
    }
  }

  private setMaxDateValue(): void {
    if (this.maxDate) {
      const maxDate =
        this.maxDate instanceof Date
          ? this.maxDate
          : this.formGroup.get(this.maxDate).value;
      this.maxDateValue = this.setMaxDateAndMinDate(maxDate);
      if (typeof this.maxDate === 'string') {
        this.formGroup.get(this.maxDate).valueChanges.subscribe(value => {
          this.maxDateValue = this.setMaxDateAndMinDate(value);
        });
      }
    }
  }

  private setMaxDateAndMinDate(date: Date): NgbDateStruct {
    let value: NgbDateStruct;
    if (date) {
      const newDate = new Date(date);
      value = {
        year: newDate.getFullYear(),
        month: newDate.getMonth() + 1,
        day: newDate.getDate()
      };
    }
    return value;
  }

  public handleFormControlReset(): void {
    this.formControl.reset();
    this.formControl.markAsDirty();
    this.onChange.emit(this.formControl.value);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onDateSelect(date: Date): void {
    const timezoneOffset =
      date.getTimezoneOffset(); /** selisih GMT dlm menit */
    const hours = Math.abs(timezoneOffset / 60);

    const adjustedGMTdateTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      0,
      0
    );
    this.formControl.patchValue(adjustedGMTdateTime);
    this.onChange.emit(adjustedGMTdateTime);
  }
}
