/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\action\select-table-record-action.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:16:22 am

 *
*
 */
import { TableRow } from '../domain/table-row';
import { TableState } from '../domain/table-state';
import { resolveTableRowChildrenList } from '../helper/resolve-table-row-children-list-helper';
export const selectTableRowAction = (
  state: TableState,
  payload: {
    row: TableRow;
    isChecked: boolean;
    parent?: TableRow;
  }
): void => {
  if (state.isMultipleSelect === false) {
    state.selectedRow.setSelectedRowList([payload.row]);
  } else {
    payload.isChecked
      ? setSelectedOrRemoveUnselectedRow(state, payload)
      : setUnselectedOrRemoveSelectedRow(state, payload);
  }
  state.model.selectedRow = state.selectedRow;
  state.setStateReady();
};

const setSelectedOrRemoveUnselectedRow = (
  state: TableState,
  payload: {
    row: TableRow;
    isChecked: boolean;
    parent?: TableRow;
  }
) => {
  if (payload.parent) {
    if (state.selectedRow.isSelectAll) {
      state.selectedRow.removeRecordFromUnSelectedRowList(payload.row);
      payload.parent.selectedRow.removeRecordFromUnSelectedRowList(payload.row);
      if (payload.parent.selectedRow.unSelectedRowList.length === 0) {
        payload.parent.selectedRow.isSelectAll = true;
      }
    } else {
      state.selectedRow.addRowToSelectedRowList(payload.row);
      payload.parent.selectedRow.addRowToSelectedRowList(payload.row);
      if (
        payload.parent.childrenList.length === payload.parent.totalChildrenList
      ) {
        let totalSelected = 0;
        payload.parent.childrenList.forEach(children => {
          if (
            isRecordExists(
              state.selectedRow.getSelectedRecordList(),
              children.record
            )
          ) {
            totalSelected += 1;
          }
        });
        if (totalSelected === payload.parent.childrenList.length) {
          payload.parent.selectedRow.isSelectAll = true;
        }
      } else {
        payload.parent.selectedRow.isSelectAll = false;
      }
    }
  } else {
    if (state.isGroup) {
      payload.row.selectedRow.isSelectAll = payload.isChecked;
      if (
        payload.row.childrenList.length === 0 ||
        payload.row.childrenList.length !== payload.row.totalChildrenList
      ) {
        resolveTableRowChildrenList(state, payload.row).then(value => {
          payload.row.childrenList = value.rowList;
          payload.row.totalChildrenList = value.totalRecord;
          value.rowList.forEach(row => {
            if (
              !isRecordExists(
                payload.row.selectedRow.getSelectedRecordList(),
                row.record
              )
            ) {
              state.selectedRow.addRowToSelectedRowList(row);
              payload.row.selectedRow.addRowToSelectedRowList(row);
            }
          });
        });
      } else {
        payload.row.childrenList.forEach(row => {
          if (state.selectedRow.isSelectAll) {
            if (
              isRecordExists(
                payload.row.selectedRow.getUnSelectedRecordList(),
                row.record
              )
            ) {
              state.selectedRow.removeRecordFromUnSelectedRowList(row);
              payload.row.selectedRow.removeRecordFromUnSelectedRowList(row);
            }
          } else {
            if (
              !isRecordExists(
                payload.row.selectedRow.getSelectedRecordList(),
                row.record
              )
            ) {
              state.selectedRow.addRowToSelectedRowList(row);
              payload.row.selectedRow.addRowToSelectedRowList(row);
            }
          }
        });
      }
    } else {
      state.selectedRow.addRowToSelectedRowList(payload.row);
      if (state.selectedRow.isSelectAll) {
        state.selectedRow.removeRecordFromUnSelectedRowList(payload.row);
      }
    }
  }
};

const setUnselectedOrRemoveSelectedRow = (
  state: TableState,
  payload: {
    row: TableRow;
    isChecked: boolean;
    parent?: TableRow;
  }
) => {
  if (payload.parent) {
    if (state.selectedRow.isSelectAll) {
      state.selectedRow.addRowToUnSelectedRowList(payload.row);
      payload.parent.selectedRow.addRowToUnSelectedRowList(payload.row);
      if (
        payload.parent.selectedRow.unSelectedRowList.length ===
        payload.parent.totalChildrenList
      ) {
        payload.parent.selectedRow.isSelectAll = false;
      }
    } else {
      state.selectedRow.removeRecordFromSelectedRowList(payload.row);
      payload.parent.selectedRow.removeRecordFromSelectedRowList(payload.row);
      if (
        payload.parent.selectedRow.selectedRowList.length <
        payload.parent.totalChildrenList
      ) {
        payload.parent.selectedRow.isSelectAll = false;
      }
    }
  } else {
    if (state.isGroup) {
      payload.row.selectedRow.isSelectAll = payload.isChecked;
      payload.row.childrenList.forEach(children => {
        if (state.selectedRow.isSelectAll) {
          state.selectedRow.addRowToUnSelectedRowList(children);
          payload.row.selectedRow.addRowToUnSelectedRowList(children);
        } else {
          state.selectedRow.removeRecordFromSelectedRowList(children);
          payload.row.selectedRow.removeRecordFromSelectedRowList(children);
        }
      });
    } else {
      if (state.selectedRow.isSelectAll) {
        state.selectedRow.addRowToUnSelectedRowList(payload.row);
      } else {
        state.selectedRow.removeRecordFromSelectedRowList(payload.row);
      }
    }
  }
};

const isRecordExists = (recordList: Array<any>, record: any): boolean => {
  return (
    recordList.findIndex(r => JSON.stringify(r) === JSON.stringify(record)) !==
    -1
  );
};
