<app-popup [header]="model.moduleCode + '.title'">
  <app-tree
    [model]="model"
    (onChange)="doChoose()"
    [isLazy]="isLazy"
  ></app-tree>
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-right">
      <app-button
        color="SECONDARY"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        mode="BYPASS"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button
        (onClick)="doChoose()"
        [disabled]="model.value.length === 0"
        mode="BYPASS"
      >
        {{ 'app.button.choose' | translate }}</app-button
      >
    </div>
  </ng-template>
</app-popup>
