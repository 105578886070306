import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Global } from '../../global/global';
import { ResolveFieldDataPipe } from '../../pipe/resolve-field-data.pipe';
import { TableFieldModel } from './model/table-field-model';
@Injectable({ providedIn: 'root' })
export class AppTableService {
  public arrayDateFormat = [
    this.global.config.parameterModel.dateFormatShort,
    this.global.config.parameterModel.datetimeFormatShort,
    this.global.config.parameterModel.dateFormatLong,
    this.global.config.parameterModel.datetimeFormatLong
  ];
  constructor(
    public global: Global,
    public resolveFieldDataPipe: ResolveFieldDataPipe,
    public datePipe: DatePipe,
    public currencyPipe: CurrencyPipe
  ) {}
  resolveFieldValue(record: object, column: TableFieldModel): any {
    const { format, field, currencyCodeRef, datamap } = column;
    let value = this.resolveFieldDataPipe.transform(record, field, datamap);
    if (value !== null) {
      if (format < 4) {
        value =
          this.datePipe.transform(value, this.arrayDateFormat[format]) || '-';
      } else if (format === 4) {
        const currencyCode =
          currencyCodeRef &&
          this.resolveFieldDataPipe.transform(record, currencyCodeRef) + ' ';
        value =
          this.currencyPipe.transform(value, '', '') +
          ' ' +
          (currencyCode || 'IDR ');
      } else if (format === 15) {
        value = this.currencyPipe.transform(value, '', '');
      }
    }
    return value;
  }
}
