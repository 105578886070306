<app-popup>
  <div class="pppl-wrapper">
    <div class="ppplw-left d-none d-md-block">
      <div class="ppplwl-header">
        <!-- <div class="logo">
          <img  src ='assets/images_portal/compactlogo.png' width="auto" height="auto" />
        </div> -->
      </div>
      <div class="ppplwl-body" style="background-image: url('assets/img/portal/login/login-bg2.png')"></div>
      <div class="ppplwl-footer">
        <h2 class="text-center black">Welcome to <b>PRIMECHECK</b></h2>
      </div>
    </div>
    <div class="ppplw-right">
      <div class="form-header">
        <h2 class="login-title">
          {{ 'portal.popupLogin.title' | translate }}
          <button type="button" class="close outline-none" aria-label="Close" (click)="onClose.emit()">
            <span aria-hidden="true" class="pir pi-times"></span>
          </button>
        </h2>
      </div>
      <div class="form-body">
        <form [formGroup]="formGroup" (keydown.enter)="doLogin()" id="form-login">
          <div class="form-group">
            <label for="email">{{
              'portal.popupLogin.emailOrUserId' | translate
              }}</label>
            <app-text-field formControlName="email" placeholder="{{
                'portal.popupLogin.placeholder.email' | translate
              }}" size="XL"></app-text-field>
          </div>
          <div class="form-group">
            <label for="password">{{
              'portal.popupLogin.password' | translate
              }}</label>
            <app-text-field formControlName="password" type="password" placeholder="{{
                'portal.popupLogin.placeholder.password' | translate
              }}" size="XL"></app-text-field>
          </div>
          <div class="form-group d-flex justify-content-between">
            <div class="form">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="isKeepMeSignIn"
                  formControlName="isKeepMeSignIn" />
                <label class="custom-control-label " for="isKeepMeSignIn">
                  {{ 'portal.popupLogin.rememberMe' | translate }}
                </label>
              </div>
            </div>
            <a href="#" class="underline" (click)="doForgotPassword()">
              {{ 'portal.popupLogin.forgotPassword' | translate }}
            </a>
          </div>
          <div class="form-group">
            <app-captcha *ngIf="global.config.appInfoResponse.isShowCaptcha" formControlName="captch"></app-captcha>
          </div>
        </form>
      </div>
      <div class="form-footer text-center">
        <div class="row d-flex justify-content-end">
          <button class="btn btn-primary  btn-block  col-md-4 " [disabled]="formSaving" (click)="doLogin()">Login<i
            *ngIf="formSaving"  class="fa fa-circle-notch  ml-1 fa-spin" aria-hidden="true"></i></button>
        </div>
        <p class="mt-4 mb-0">
          Belum terdaftar?
          <a href="#" class="ml-2 underline " (click)="doRegister()">
            daftar sekarang
          </a>
        </p>
      </div>
    </div>
  </div>
</app-popup>