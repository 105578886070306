/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\core\components\app-date-picker-x\pipe\resolve-disabled-time.pipe.ts
 
 * Created Date: Thursday, October 7th 2021, 5:04:36 pm

 *
*
 */

import { Pipe, PipeTransform } from '@angular/core';
import { DatePickerTime } from '../model/date-picker-time-model';

@Pipe({
  name: 'resolveDisabledTime'
})
export class ResolveDisabledTimePipe implements PipeTransform {
  transform(
    time: DatePickerTime,
    currentDate: Date,
    minDate: Date,
    maxDate: Date
  ): boolean {
    const current = new Date(currentDate);

    current.setHours(+time.hoursString);
    current.setMinutes(+time.minutesString);
    if (minDate) {
      const min = new Date(minDate);
      return current.getTime() < min.getTime();
    } else if (maxDate) {
      const max = new Date(maxDate);
      return current.getTime() > max.getTime();
    }
    return false;
  }
}
