/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\domain\widget-state.ts
 
 * Created Date: Thursday, November 18th 2021, 11:05:42 am

 *
*
 */

import { Injector } from '@angular/core';
import { WidgetResponseModel } from '../model/widget-response-model';
import { WidgetItem } from './widget-item';

export class WidgetState {
  public isLoading: boolean;
  public isSaving: boolean;
  public isSaved: boolean;
  public isShowWidgetEditable: boolean;
  public injector: Injector;
  public widgetList: WidgetItem[];
  public widgetListTemp: WidgetItem[];
  public model: WidgetResponseModel;
  public isServerSide: boolean;
  public setStateLoading(): void {
    this.isLoading = true;
  }

  public setStateSaving(): void {
    this.isSaving = true;
    this.isSaved = false;
  }

  public setStateReady(): void {
    this.isLoading = false;
    this.isSaving = false;
    this.isSaved = false;
  }

  public static create(
    injector: Injector,
    model: WidgetResponseModel
  ): WidgetState {
    const state = new WidgetState();
    state.injector = injector;
    state.model = model;
    state.isServerSide = !!model.stringUrl;
    return state;
  }
}
