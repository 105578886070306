import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { SensitiveDataService } from 'src/app/core/services/sensitive-data.service';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { User } from '../../core/bean/user';
import { UserRole } from '../../core/bean/user-role';
import { UserRoleLog } from '../../core/bean/user-role-log';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { FileObject, FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { Lang } from './../../core/bean/lang';
import { AccountSettingRequest } from './account-setting.request';
import { AccountSettingResponse } from './account-setting.response';
import { AppPopupChangeEmailComponent } from './app-popup-change-email/app-popup-change-email.component';

@Component({
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountSettingComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<UserRoleLog>;
  public accountSettingResponse: AccountSettingResponse =
    new AccountSettingResponse();
  public accountSettingRequest: AccountSettingRequest =
    new AccountSettingRequest();
  public isPasswordBlock = false;
  public roleOptionList: OptionListModel<UserRole> = new OptionListModel(
    false,
    'role.name'
  );
  public languageOptionList: OptionListModel<Lang> = new OptionListModel(false);
  public newEmail: string;
  public isPhotoChanged = false;
  public isDisable = false;
  public fileUploader: FileUploader = new FileUploader(
    '/account-setting/',
    '',
    this.global.appConstant.fileType.IMG_PROFILE_PICTURE,
    false,
    5
  );

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public sensitiveDataService: SensitiveDataService
  ) {
    super('account-setting', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(255)])
      ],
      oldPassword: [null],
      newPassword: [null],
      confirmNewPassword: [null],
      lang: [null, Validators.compose([Validators.required()])],
      email: [null],
      userRole: [null],
      isActive: [null],
      imgFile: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'role.name',
        header: 'table.column.role',
        customClass: 'text-left'
      },
      {
        field: 'organization.name',
        header: 'table.column.organization',
        customClass: 'text-left'
      },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        format: FieldFormatEnum.ShortDate
      }
    ]);
  }

  public setFormGroup(): void {
    this.isPhotoChanged = true;
    this.httpClientService
      .get<AccountSettingResponse>('/account-setting/index', {})
      .subscribe(accountSettingResponse => {
        this.accountSettingResponse = accountSettingResponse;
        this.accountSettingRequest.user = accountSettingResponse.user;
        const user: User = this.accountSettingResponse.user;
        this.languageOptionList.setRequestValues(
          this.accountSettingResponse.langList
        );
        this.roleOptionList.setRequestValues(user.validUserRoleList);
        const indexRole = user.validUserRoleList.findIndex(
          (uRole: UserRole) => uRole.isDefault === true
        );
        const indexLang = this.accountSettingResponse.langList.findIndex(
          lang => lang.id === user.lang.id
        );
        if (user.imgFile) {
          this.fileUploader.setFileList([user.imgFile]);
        }
        this.formGroup.patchValue({
          name: user.name,
          email: user.email,
          isActive: user.isActive,
          lang: this.accountSettingResponse.langList[indexLang],
          userRole: user.validUserRoleList[indexRole],
          imgFile: this.fileUploader.fileObjectList
        });
        this.isPhotoChanged = false;
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();

    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const { userRole } = this.formGroup.value;

            const accountSettingRequest: AccountSettingRequest = this.global.copyFormAttributeToModel(new User(), this.formGroup);

            this.setStateProcessing();


            if (
              this.formGroup.get('imgFile').value &&
              !Array.isArray(this.formGroup.get('imgFile').value)
            ) {
              /* sudah ada foto sblumnya dan langsung simpan */
              const fileObjectList: FileObject[] = [];
              fileObjectList.push(
                new FileObject(this.formGroup.get('imgFile').value, false)
              );
              accountSettingRequest.imgFile = fileObjectList;
            }

            accountSettingRequest.newEmail =
              this.accountSettingRequest.newEmail;
            accountSettingRequest.userRoleDefaultId =
              userRole != null
                ? userRole.id
                : this.global.userSession.activeUserRole.id;

            const oldPassword = this.formGroup.get('oldPassword').value;
            const newPassword = this.formGroup.get('newPassword').value;

            delete accountSettingRequest['oldPassword'];
            delete accountSettingRequest['newPassword'];
            delete accountSettingRequest['confirmNewPassword'];

            accountSettingRequest.auth = this.sensitiveDataService.encrypt([
              oldPassword,
              newPassword
            ]);

            this.httpClientService
              .post<Response<AccountSettingRequest>>(
                '/account-setting/update',
                accountSettingRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.changeLang(
                    accountSettingRequest.lang.code.toLowerCase(),
                    'pages'
                  );
                  this.global.userSession.user = response.body;
                  this.global.alertService.showSuccessSaving();
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.isPasswordBlock = false;
                this.resetPasswordValue();
                this.setStateReady();
              });
          }
        });
    }
  }

  public resetPasswordValue(): void {
    this.formGroupService.bulkResetFormControl(this.formGroup, [
      'oldPassword',
      'newPassword',
      'confirmNewPassword'
    ]);
  }

  public doChangeEmail(): void {
    this.appPopupService.open(
      AppPopupChangeEmailComponent,
      { accountSettingRequest: this.accountSettingRequest },
      { centered: false, windowClass: 'app-popup-change-email' }
    );
  }

  public doChangePassword(): void {
    if (!this.isPasswordBlock) {
      this.isPasswordBlock = true;
      this.formGroup
        .get('oldPassword')
        .setValidators([Validators.required(), Validators.maxLength(32)]);
      this.formGroup
        .get('newPassword')
        .setValidators([Validators.required(), Validators.password()]);
      this.formGroup
        .get('confirmNewPassword')
        .setValidators([
          Validators.required(),
          Validators.matchPassword('newPassword'),
          Validators.password()
        ]);
    } else {
      this.isPasswordBlock = false;
      this.formGroup.patchValue({
        oldPassword: null,
        newPassword: null,
        confirmNewPassword: null
      });
      this.formGroup.get('oldPassword').clearValidators();
      this.formGroup.get('newPassword').clearValidators();
      this.formGroup.get('confirmNewPassword').clearValidators();
    }
    this.formGroup.get('oldPassword').updateValueAndValidity();
    this.formGroup.get('newPassword').updateValueAndValidity();
    this.formGroup.get('confirmNewPassword').updateValueAndValidity();
  }

  public doDisableButton(event): void {
    if (event) {
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);
    }
  }

  public doCancel(): void {
    this.setFormGroup();
    this.isPasswordBlock = false;
    this.formGroup.patchValue({
      oldPassword: null,
      newPassword: null,
      confirmNewPassword: null
    });
  }
}
