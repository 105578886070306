/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table\helper\resolve-portal-table-record-list-helper.ts
 
 * Created Date: Thursday, September 30th 2021, 11:00:23 am

 *
*
 */

import { TablePageModel } from '../../../../../core/components/app-table/model/table-page-model';
import { HttpClientService } from '../../../../../core/services/http-client.service';
import { ArrayUtils } from '../../../../../core/utils';
import { PortalTableState } from '../domain/portal-table-state';

export const resolvePortalTableRecordList = (
  state: PortalTableState
): Promise<Array<any>> => {
  return new Promise(resolve => {
    if (state.isServerSide) {
      const httpClientService = state.injector.get(HttpClientService);
      httpClientService
        .post(state.stringUrl, state.tableRequest)
        .subscribe((page: TablePageModel<Object>) => {
          state.pagination.totalPage = page.totalRecords;
          resolve(page.records);
        });
    } else {
      let recordList = Array.from(state.model.getRecordList());
      recordList = ArrayUtils.filterArrayByText(
        Array.from(recordList),
        state.tableRequest.keyword,
        'title'
      );
      state.pagination.setTotalRecords(recordList.length);
      const startRow = (state.pagination.page - 1) * state.pagination.perPage;
      const endRow = startRow + state.pagination.perPage;
      resolve(recordList.slice(startRow, endRow));
    }
  });
};
