/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\portal\landing-page\app-landing-page-card.module.ts
 
 * Created Date: Saturday, October 2nd 2021, 12:19:57 pm

 *
*
 */

import { NgModule } from '@angular/core';
import { AppCardModule } from '../../../../core/components/app-card/app-card.module';
import { VendorModule } from '../../../../core/vendor/vendor.module';
import { AppLandingPageCardComponent } from './app-landing-page-card.component';
@NgModule({
  imports: [VendorModule, AppCardModule],
  declarations: [AppLandingPageCardComponent],
  exports: [AppLandingPageCardComponent]
})
export class AppLandingPageCardModule {}
