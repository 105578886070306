export class TreeRequestModel {
  constructor(
    public id: number,
    public name: string,
    public isRoot: boolean,
    public tenantId?: number,
    public first?: number,
    public rows?: number
  ) {}
}
