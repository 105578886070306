/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\default-plugin\default-plugin.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Friday, September 10th 2021, 6:59:23 pm

 *
*
 */

import { NgModule } from '@angular/core';
import { PipesModule } from '../../../../pipe/pipe.module';
import { VendorModule } from '../../../../vendor/vendor.module';
import { TableDefaultPlugin } from './default-plugin.component';
@NgModule({
  imports: [VendorModule, PipesModule],
  declarations: [TableDefaultPlugin],
  exports: [TableDefaultPlugin]
})
export class TableDefaultPluginModule {}
