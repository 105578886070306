import {
  Pipe,
  PipeTransform,
  Renderer2,
  SecurityContext,
  TemplateRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ResolveImgSrc } from '../../../pipe/resolve-img-src.pipe';
import { AppDynamicComponentService } from '../../../services/app-dynamic-component.service';
import { DownloadService } from '../../../services/download.service';
import { TextUtils } from '../../../utils';
import { AppFlagNewComponent } from '../../app-flag-new/app-flag-new.component';
import { AppRatingComponent } from '../../app-rating/app-rating.component';
import { TableFieldModel } from '../../app-table/model/table-field-model';
import { AppTextFieldComponent } from '../../app-text-field/app-text-field.component';
import { AppTooltipWrapperComponent } from '../../app-tooltip-wrapper/app-tooltip-wrapper.component';
import { AppTableService } from '../app-table.service';
import { FieldFormatEnum } from '../model/field-format.enum';
import { TableResponseModel } from '../model/table-response-model';
import { AppTableCheckBoxService } from '../services/app-table-checkbox.service';
import { ResolveColumnClassNamePipe } from './resolve-column-class-name.pipe';
@Pipe({ name: 'resolveColumnValue' })
export class ResolveColumnValuePipe implements PipeTransform {
  constructor(
    public appTableService: AppTableService,
    public renderer2: Renderer2,
    public appDynamicComponent: AppDynamicComponentService,
    public appTableCheckBox: AppTableCheckBoxService,
    private resolveImgSrc: ResolveImgSrc,
    public domSanitizer: DomSanitizer,
    private downloadService: DownloadService,
    private resolveColumnClassNamePipe: ResolveColumnClassNamePipe
  ) {}
  transform(
    record: any,
    column: TableFieldModel,
    model: TableResponseModel<any>,
    tdTemplateRef: TemplateRef<any>
  ): string {
    const { fn, tooltip } = column;

    let value: any;

    if (typeof column.format === 'undefined') {
      const colValue = this.appTableService.resolveFieldValue(record, column);
      if (fn) {
        const convertValue = fn(colValue, record, tdTemplateRef) || '-';
        let textElement: any;
        if (!convertValue.childNodes) {
          textElement = this.renderer2.createText(convertValue);
        } else {
          textElement = convertValue;
        }
        this.renderer2.appendChild(tdTemplateRef, textElement);
      } else if (tooltip) {
        const divElement = document.createElement('div');
        divElement.innerText = colValue || '-';
        this.renderer2.appendChild(tdTemplateRef, divElement);
        if (
          typeof record.showTooltip !== 'undefined' &&
          typeof record.showTooltip === 'boolean'
        ) {
          if (record.showTooltip) {
            this.buildAndAddTooltip(record, column, tdTemplateRef);
          }
        } else {
          this.buildAndAddTooltip(record, column, tdTemplateRef);
        }
      } else {
        value = colValue || '-';
      }
    } else {
      value = this.resolveColumnValueWithFormat(
        record,
        column,
        model,
        tdTemplateRef
      );
    }

    this.doSetCustomClass(tdTemplateRef, column);
    this.doBuildFlag(record, column, tdTemplateRef);
    return value;
  }

  public resolveColumnValueWithFormat(
    record: any,
    column: TableFieldModel,
    model: TableResponseModel<any>,
    tdTemplateRef: TemplateRef<any>
  ): any {
    const { format, field, urlImage, datamap, urlFile } = column;
    const colValue = this.appTableService.resolveFieldValue(record, column);
    if (
      format === FieldFormatEnum.ShortDate ||
      format === FieldFormatEnum.ShortDateAndTime ||
      format === FieldFormatEnum.LongDate ||
      format === FieldFormatEnum.LongDateAndTime ||
      format === FieldFormatEnum.Currency ||
      format === FieldFormatEnum.Integer ||
      format === FieldFormatEnum.IntegerDecimal
    ) {
      return colValue || '-';
    } else if (format === FieldFormatEnum.Html) {
      const divElement = document.createElement('div');
      divElement.innerHTML = colValue || '-';
      this.renderer2.appendChild(tdTemplateRef, divElement);
    } else if (format === FieldFormatEnum.Img) {
      const imageURL =
        this.appTableService.global.config.BACKEND_ADDRESS +
        urlImage +
        '/file/view/' +
        colValue +
        '/' +
        column.fileTypeCode;
      const imgElement = document.createElement('img');
      this.resolveImgSrc
        .transform(imageURL)
        .then(
          url =>
            (imgElement.src = this.domSanitizer.sanitize(
              SecurityContext.URL,
              url
            ))
        );
      imgElement.classList.add('img-thumbnail');
      imgElement.style.width = '100px';
      this.renderer2.appendChild(tdTemplateRef, imgElement);
    } else if (
      format === FieldFormatEnum.DownloadableFile ||
      format === FieldFormatEnum.DownloadablePMFile
    ) {
      const colUrlFile = this.appTableService.resolveFieldDataPipe.transform(
        record,
        urlFile,
        datamap
      );
      const colField = this.appTableService.resolveFieldDataPipe.transform(
        record,
        field,
        datamap
      );
      const fileViewUrl =
        format === FieldFormatEnum.DownloadableFile
          ? '/file/view/'
          : '/file/view-pm/';
      if (Array.isArray(colValue)) {
        const ulElement = document.createElement('ul');
        this.renderer2.addClass(ulElement, 'table-col-file-list');
        colValue.forEach((colVal: object) => {
          const fileTypeCode: string =
            this.appTableService.resolveFieldDataPipe.transform(
              colVal,
              'fileType.code'
            );
          let fileURL =
            this.appTableService.global.config.BACKEND_ADDRESS +
            '/' +
            model.moduleCode +
            fileViewUrl;
          fileURL +=
            format === FieldFormatEnum.DownloadableFile
              ? colVal['uploadedFileName'] + '/' + fileTypeCode || '#'
              : colVal['uploadedFileName'];
          const liElement = this.renderer2.createElement('li');
          const aElement = this.renderer2.createElement('a');
          aElement.href = this.domSanitizer.sanitize(
            SecurityContext.URL,
            fileURL
          );
          aElement.addEventListener('click', event => {
            event.preventDefault();
            event.stopPropagation();
            this.downloadService.download(fileURL, colVal['fileName']);
          });
          const colText = this.renderer2.createText(colVal['fileName'] ? colVal['fileName'] : '-');
          this.renderer2.appendChild(aElement, colText);
          this.renderer2.appendChild(liElement, aElement);
          this.renderer2.appendChild(ulElement, liElement);
        });
        this.renderer2.appendChild(tdTemplateRef, ulElement);
      } else {
        const filePath =
          urlFile.split('.').slice(0, -1).join('.') + '.fileType.code';
        const fileTypeCode: string =
          this.appTableService.resolveFieldDataPipe.transform(record, filePath);
        let fileURL =
          this.appTableService.global.config.BACKEND_ADDRESS +
          '/' +
          model.moduleCode +
          fileViewUrl;
        fileURL +=
          format === FieldFormatEnum.DownloadableFile
            ? colUrlFile + '/' + fileTypeCode || '#'
            : colUrlFile;
        const aElement = this.renderer2.createElement('a');
        aElement.href = this.domSanitizer.sanitize(
          SecurityContext.URL,
          fileURL
        );
        aElement.addEventListener('click', event => {
          event.preventDefault();
          event.stopPropagation();
          this.downloadService.download(fileURL, colField);
        });
        const colText = this.renderer2.createText(colValue ? colValue : '-');
        this.renderer2.appendChild(aElement, colText);
        this.renderer2.appendChild(tdTemplateRef, aElement);
      }
    } else if (format === FieldFormatEnum.ColorStatus) {
      // value without datamap
      const colDataValue = this.appTableService.resolveFieldDataPipe.transform(
        record,
        field
      );
      const spanElement = document.createElement('span');
      const arrayBackgroundColor: Array<string> = [
        '',
        'bg-warning',
        'bg-danger'
      ];
      spanElement.classList.add(
        'text-light',
        'text-center',
        'rounded',
        'px-2',
        'py-1',
        arrayBackgroundColor[+colDataValue - 1]
      );
      const colText = this.renderer2.createText(colValue);
      this.renderer2.appendChild(spanElement, colText);
      this.renderer2.appendChild(tdTemplateRef, spanElement);
    } else if (format === FieldFormatEnum.Rating) {
      this.appDynamicComponent.create(AppRatingComponent, tdTemplateRef, {
        value: colValue,
        isViewOnly: true
      });
      this.renderer2.addClass(tdTemplateRef, 'text-left');
    } else if (format === FieldFormatEnum.EditCols) {
      const textField = this.appDynamicComponent.create(
        AppTextFieldComponent,
        tdTemplateRef,
        { size: 'SM', value: colValue }
      );
      textField.onChange.subscribe((value: string) => {
        record.value = Number(value);
      });
    } else if (format === FieldFormatEnum.CheckBox) {
      const inputID = TextUtils.generateRandomString();
      const divElement = document.createElement('div');
      divElement.classList.add(
        'custom-control',
        'custom-checkbox',
        'text-center',
        'd-inline-block'
      );
      const inputCheckboxElement = document.createElement('input');
      inputCheckboxElement.type = 'checkbox';
      inputCheckboxElement.id = inputID;
      inputCheckboxElement.value = this.appTableCheckBox
        .isChecked(record)
        .toString();
      inputCheckboxElement.checked = this.appTableCheckBox.isChecked(record);
      this.renderer2.listen(inputCheckboxElement, 'change', () => {
        this.appTableCheckBox.checkedItem(record);
        inputCheckboxElement.value = this.appTableCheckBox
          .isChecked(record)
          .toString();
      });
      inputCheckboxElement.classList.add(
        'custom-control-input',
        'checkbox-primary'
      );
      const labelElement = document.createElement('label');
      labelElement.htmlFor = inputID;
      labelElement.classList.add('custom-control-label', 'cursor-pointer');
      this.renderer2.appendChild(divElement, inputCheckboxElement);
      this.renderer2.appendChild(divElement, labelElement);
      this.renderer2.appendChild(tdTemplateRef, divElement);
      this.renderer2.addClass(tdTemplateRef, 'text-center');
    } else if (format === FieldFormatEnum.InputNumber) {
      const inputElement = document.createElement('input');
      inputElement.type = 'number';
      inputElement.value = colValue;
      inputElement.style.margin = '0 auto';
      inputElement.classList.add('form-control', 'col-sm-4', 'col-lg-2');
      this.renderer2.listen(inputElement, 'input', (event: any) => {
        const value = +event.target.value;
        event.target.value = value < 0 ? 0 : value;
      });
      this.renderer2.listen(inputElement, 'change', (event: any) => {
        this.setRecordValueByField(record, field, +event.target.value);
      });
      this.renderer2.appendChild(tdTemplateRef, inputElement);
    }
  }

  public setRecordValueByField(
    record: any,
    field: string,
    value: number
  ): void {
    const splitField = field.split('.');
    if (splitField.length > 1) {
      const firstField = splitField.shift();
      this.setRecordValueByField(
        record[firstField],
        splitField.join('.'),
        value
      );
    } else {
      record[field] = +value;
    }
  }

  public buildAndAddTooltip(
    record: any,
    column: TableFieldModel,
    element: any
  ): void {
    const { tooltip } = column;
    const spanElement = document.createElement('span');
    spanElement.style.position = 'relative';
    spanElement.style.width = '6px';
    spanElement.style.height = '21px';
    spanElement.style.display = 'inlinc-block';
    let tooltipText: string;
    if (!tooltip.stringUrl) {
      tooltipText = this.appTableService.resolveFieldDataPipe.transform(
        record,
        tooltip.field
      );
    }
    const customData = record.id;
    this.appDynamicComponent.create(AppTooltipWrapperComponent, spanElement, {
      stringUrl: tooltip.stringUrl,
      tooltip: tooltipText,
      position: tooltip.position || 'top',
      customData
    });
    this.renderer2.appendChild(element, spanElement);
  }

  public doBuildFlag(
    record: any,
    column: TableFieldModel,
    tdTemplateRef: TemplateRef<any>
  ): void {
    const { oldValueField, field } = column;
    if (oldValueField) {
      const newValue = this.appTableService.resolveFieldDataPipe.transform(
        record,
        field
      );
      const oldValue = this.appTableService.resolveFieldDataPipe.transform(
        record,
        oldValueField
      );
      const spanElement = this.renderer2.createElement('span');
      this.renderer2.setStyle(spanElement, 'position', 'relative');
      this.renderer2.setStyle(spanElement, 'width', '6px');
      this.renderer2.setStyle(spanElement, 'height', '21px');
      this.renderer2.setStyle(spanElement, 'display', 'inline-block');
      this.renderer2.setStyle(spanElement, 'margin-left', '0.2rem');
      this.appDynamicComponent.create(AppFlagNewComponent, spanElement, {
        oldValue,
        newValue,
        titleValueField: oldValueField
      });
      this.renderer2.appendChild(tdTemplateRef, spanElement);
    }
  }

  public doSetCustomClass(tdTemplateRef: any, column: TableFieldModel): void {
    this.resolveColumnClassNamePipe
      .transform(column)
      .split(' ')
      .map((className: string) => {
        if (className) {
          tdTemplateRef.classList.add(className);
        }
      });
  }
}
