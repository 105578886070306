<dashboard-widget-card
  size="XS"
  [isLoading]="model.isLoading"
  customClass="widget-card-rounded"
  customClassBody="d-flex justify-content-center align-items-center mt-3"
  (onClick)="doNavigateToWorklistApproval()"
>
  <div class="cml-2 d-flex align-items-center"
    ><div
      style="
        background: var(--primary-dark);
        width: 20px;
        height: 20px;
        border-radius: 50%;
      "
    ></div
  ></div>
  <div class="flex-1 mx-3">
    <h5 class="mb-2">{{
      'dashboard-widget-waiting-approval.title' | translate
    }}</h5>
    <h2>{{ total }}</h2>
  </div>
  <div class="pvc pv-file-circle-check-alt"></div>
</dashboard-widget-card>
