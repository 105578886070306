/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\model\selected-table-record-model.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 1:38:08 pm

 *
*
 */
export class SelectedTableRecordModel {
  // is checked all merupakan flag ketika checkbox check all di table di checked
  // atau semua record telah dipilih
  isCheckedAll: boolean;
  // selectedRecordList merupakan penampung untuk semua record yang telah dipilih
  selectedRecordList: Array<any> = new Array();
  // unSelectedRecordList merupakan penampung untuk record yang tidak dipilih
  // dengan catatan pertama kali di check all pada table kemudian yang sudah ke select di-uncheck
  unSelectedRecordList: Array<any> = new Array();
  // custom data digunakan untuk menampung filter untuk selected record
  // yang dapat digunakan di backend untuk filter select all berdasarkan customData
  customData: string;
  // keywords merupakan kata kunci pencarian yang ada pada table kemudian recordnya di checAll
  // ini berfungsi untuk filter pada backend
  keywords: string;

  constructor(
    isCheckedAll: boolean,
    selectedRecordList: Array<any>,
    unSelectedRecordList: Array<any>,
    customData: string,
    keywords: string
  ) {
    this.isCheckedAll = isCheckedAll;
    this.selectedRecordList = selectedRecordList;
    this.unSelectedRecordList = unSelectedRecordList;
    this.customData = customData;
    this.keywords = keywords;
  }
}
