<div class="app-card">
  <div widget class="card border-0 mb-0 mt-2" [ngClass]="customClass">
    <div *ngIf="!customLoadingTmpl && (isLoading || isSaving)" class="form-loader">xxx</div>
    <div class="card-header border-0" *ngIf="header" [ngClass]="customClassHeader ? customClassHeader : ''">
      <div class="mr-2 d-flex align-items-center" *ngIf="headerLeftTmpl">
        <ng-container *ngTemplateOutlet="headerLeftTmpl"></ng-container>
      </div>
      <div class="flex-1">
        <h4 class="d-inline-block fw-500" innerHtml="{{ header | translate }}"></h4>
        <h4 class="d-inline-block ml-2 text-muted" *ngIf="detail">
          <span>- {{ detail | translate }}</span>
        </h4>
      </div>
      <div class="ml-2 d-flex-align-items-center" *ngIf="headerRightTmpl">
        <ng-container *ngTemplateOutlet="headerRightTmpl"></ng-container>
      </div>
    </div>

    <div class="card-body mt-0" [ngClass]="[
        header ? '' : 'border-top-0',
        customClassBody ? customClassBody : ''
      ]">
      <ng-container *ngIf="customLoadingTmpl && isLoading">
        <ng-container *ngTemplateOutlet="customLoadingTmpl"></ng-container>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <ng-content></ng-content>
      </ng-container>
    </div>
  </div>
</div>