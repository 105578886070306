import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Officer } from '../../core/bean/officer';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Organization } from './../../core/bean/organization';
@Component({
  templateUrl: './officer.component.html'
})
export class OfficerComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Officer>;
  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('officer', translateService);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      organizationName: [null],
      organizationId: [null]
    });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.organization',
        customClass: 'text-center'
      }
    ]);
  }

  public doOpenPopupTree(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    const userOrganizationId = this.global.userSession.user.organization.id;
    this.appPopupOrganizationService
      .open(userOrganizationId, this.global.userSession.organizationTenant.id)
      .subscribe(organization => {
        const { name: organizationName, id: organizationId } =
          organization.pop();
        this.formGroup.patchValue({ organizationName, organizationId });
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
      });
  }

  public doView(organization: Organization): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('organization', organization);
    this.router.navigate(['/pages/officer/detail/']);
  }
}
