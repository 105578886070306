/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\model\widget-response-model.ts
 
 * Created Date: Thursday, November 18th 2021, 2:11:36 pm

 *
*
 */

import { UserDashboardWidget } from '../../../../core/bean/user-dashboard-widget';
export class WidgetResponseModel {
  private widgetList: Array<UserDashboardWidget>;
  constructor(public stringUrl?: string) {}
  public setWidgetList(widgetList: Array<UserDashboardWidget>): void {
    this.widgetList = widgetList;
  }

  public getWidgetList(): Array<UserDashboardWidget> {
    return this.widgetList || [];
  }
}
