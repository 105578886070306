
import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AppDynamicComponentService } from '../../services/app-dynamic-component.service';
import { AccordionService } from './accordion.service';
import { AccordionItemModel } from './model/accordion-item-model';
const selector = '.accordion-item-content[selector]';
@Directive({ selector })
export class AppAccordionDirective<T> implements OnInit {
  @Input() selector: string;
  @Input() model: AccordionItemModel<T>;
  constructor(
    public accordionService: AccordionService,
    public appDynamicComponentService: AppDynamicComponentService,
    public elementRef: ElementRef,
    public renderer2: Renderer2
  ) {}

  ngOnInit(): void {
    this.buildContent();
  }

  public buildContent(): void {
    const component = this.accordionService.getAccordion(
      this.selector.toLowerCase()
    );
    const divItemElement = this.renderer2.createElement('div');
    this.renderer2.addClass(divItemElement, 'content-item');
    this.renderer2.appendChild(this.elementRef.nativeElement, divItemElement);
    this.appDynamicComponentService.create(component, divItemElement, {
      model: this.model,
      ...this.model.componentInstance
    });
  }
}
