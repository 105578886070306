/*
 * Filename:  WebContent\src\app\core\components\app-table-x\action\delete-table-record-action.ts
 * Path:  WebContent
 * Created Date: Monday, August 30th 2021, 9:59:33 pm

 *

 */
import { TableState } from '../model/table-state';
export const deleteTableRecord = (state: TableState): void => {
  state.checkedRecord.checkedRecordList.forEach(record => {
    const indexOfRecord = state.model.records.findIndex(
      r => JSON.stringify(r) === JSON.stringify(record.record)
    );
    if (indexOfRecord !== -1) {
      state.store.recordMap.delete(
        JSON.stringify(state.model.records[indexOfRecord])
      );
      state.model.records.splice(indexOfRecord, 1);
    }
  });
  state.model.updatedRecordList = state.store.getRecords();
  state.checkedRecord.reset();
};
