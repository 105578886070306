/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\calendar\components\popup-edit-calendar\popup-edit-calendar.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Tuesday, September 21st 2021, 1:45:55 pm

 *
*
 */

import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../vendor/vendor.module';
import { AppAlertXModule } from '../../../app-alert-x/app-alert-x.module';
import { AppButtonModule } from '../../../app-button/app-button.module';
import { AppDatePickerXModule } from '../../../app-date-picker-x/app-date-picker-x.module';
import { AppPopupModule } from '../../../app-popup/app-popup.module';
import { AppTextAreaModule } from '../../../app-text-area/app-text-area.module';
import { PopupEditCalendarComponent } from './popup-edit-calendar.component';
@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppDatePickerXModule,
    AppButtonModule,
    AppTextAreaModule,
    AppAlertXModule
  ],
  declarations: [PopupEditCalendarComponent],
  exports: [PopupEditCalendarComponent]
})
export class PopupEditCalendarModule {}
