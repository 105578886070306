<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="item.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.controls.code.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label"
            >{{ 'item.code' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              formControlName="code"
              minlength="2"
              maxlength="32"
            ></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label"
            >{{ 'item.name' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-field
              formControlName="name"
              maxlength="32"
              type="alphabet"
            ></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'item.item-type' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              [optionList]="itemTypeOptionList"
              formControlName="itemType"
            ></app-combo-box>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label"
            >{{ 'item.unit' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-auto-complete
              formControlName="uom"
              stringUrl="/item/get-uom-list"
              (onChange)="autoCompleteValueChange($event)"
            ></app-auto-complete>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label"
            >{{ 'item.category' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              formControlName="category"
              stringUrl="/item-category"
              header="Category"
              [isLazy]="true"
            ></app-text-tree>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'item.description' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description"></app-text-area>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button
            color="PRIMARY"
            (onClick)="doSave()"
            [disabled]="formSaving"
            >{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
