import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { QcEditAddComponent } from './qc-edit-add.component';
import { QcComponent } from './qc.component';
import { DataTypePipe } from 'src/app/core/shared/DataTypePipe';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { QcSharedModule } from './qc-shared-module';
const routes: Routes = [
  { path: '', component: QcComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: QcEditAddComponent,
    data: { breadcrumb: 'qc.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: QcEditAddComponent,
    data: { breadcrumb: 'qc.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), NgbTimepickerModule, QcSharedModule],
  declarations: [QcComponent,   DataTypePipe]
})
export class QcModule {}
