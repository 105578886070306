/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\date-picker-plugin\date-picker-plugin.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 9:50:09 pm

 *
*
 */

import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../vendor/vendor.module';
import { AppDatePickerXModule } from '../../../app-date-picker-x/app-date-picker-x.module';
import { TableDatePickerPluginComponent } from './date-picker-plugin.component';
@NgModule({
  imports: [VendorModule, AppDatePickerXModule],
  declarations: [TableDatePickerPluginComponent],
  exports: [TableDatePickerPluginComponent]
})
export class TableDatePickerPluginModule {}
