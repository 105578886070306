/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\badge-plugin\badge-plugin.component.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 2:46:46 pm

 *
*
 */

import { Component } from '@angular/core';
import { ArrayUtils } from '../../../../utils';
import { BaseTablePluginComponent } from '../../base/base-table-plugin.component';
import { BadgePlugin } from '../../interface/available-table-plugin';

@Component({
  templateUrl: './badge-plugin.component.html'
})
export class TableBadgePluginComponent extends BaseTablePluginComponent {
  public badgePlugin: BadgePlugin;
  public color: string;
  public text: string;
  public pill: boolean;
  constructor() {
    super();
  }

  protected onInit(): void {
    this.setInitializationBadgePluginState();
    this.setBadgePluginColumn();
    this.requestChangesListener();
  }

  private setInitializationBadgePluginState(): void {
    this.badgePlugin = this.tablePluginData.plugin as BadgePlugin;
    this.pill = this.badgePlugin.pill === false ? this.badgePlugin.pill : true;
  }

  private setBadgePluginColumn(): void {
    this.text =
      this.badgePlugin.callbacks && this.badgePlugin.callbacks.text
        ? this.badgePlugin.callbacks.text(this.tablePluginData)
        : ArrayUtils.resolveFieldData(
            this.record,
            this.badgePlugin.colorField && this.badgePlugin.field
              ? this.badgePlugin.field
              : this.tablePluginData.column.column.field
          );

    this.color =
      this.badgePlugin.callbacks && this.badgePlugin.callbacks.color
        ? this.badgePlugin.callbacks.color(this.tablePluginData)
        : this.badgePlugin.colorField
        ? ArrayUtils.resolveFieldData(this.record, this.badgePlugin.colorField)
        : this.badgePlugin.field
        ? ArrayUtils.resolveFieldData(this.record, this.badgePlugin.field)
        : this.updatedRecord
        ? ArrayUtils.resolveFieldData(
            this.record,
            this.tablePluginData.column.column.field
          )
        : this.text;
  }

  private requestChangesListener(): void {
    this.tablePluginData.column.requestChanges.subscribe(() => {
      this.setBadgePluginColumn();
    });
  }
}
