<app-layout [isLoading]="formLoading">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        *ngIf="!formLoading"
        novalidate
      >
        <app-card
          header="push-notification.detail.general.title"
          tips="push-notification.detail.general.tips"
          class="separator"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">
              {{ 'push-notification.form.templatecode' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-field
                autofocus="true"
                formControlName="code"
                maxLength="32"
              ></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">
              {{ 'push-notification.module.name' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-field
                autofocus="true"
                formControlName="moduleName"
                maxLength="32"
              ></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">
              {{ 'push-notification.module.groupcode' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-field
                autofocus="true"
                formControlName="moduleGroupCode"
                maxLength="32"
              ></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">
              {{ 'official-report.form.description' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-area
                formControlName="description"
                maxLength="512"
                size="LG"
              ></app-text-area>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">
              {{ 'push-notification.form.icon' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                formControlName="icon"
                [optionList]="iconList"
                type="COMBOBOX"
                tooltip="push-notification.icon.tooltip"
              >
                <ng-template #value let-value>
                  <div *ngIf="value" style="display: inline-flex">
                    <em style="font-size: 1.5rem" [ngClass]="value.name"> </em>
                  </div>
                </ng-template>
                <ng-template #option let-data>
                  <em style="font-size: 1.5rem" [ngClass]="data.name"> </em>
                </ng-template>
              </app-dropdown-select>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">
              {{ 'push-notification.form.keyword' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-field
                autofocus="true"
                formControlName="keyword"
                maxLength="512"
                type="code"
              ></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">
              {{ 'push-notification.form.templateName' | translate }}
            </label>
            <div>
              <app-tab bordered="true">
                <app-tab-item
                  header="{{ nt.value.name }}"
                  *ngFor="
                    let nt of getFormArray(formGroup, 'notifTemplate').controls;
                    let i = index
                  "
                  [formGroup]="nt"
                >
                  <div formArrayName="notifTemplateDetail">
                    <div
                      *ngFor="
                        let ntDetail of getFormArray(nt, 'notifTemplateDetail')
                          .controls;
                        let j = index
                      "
                      [formGroup]="ntDetail"
                    >
                      <div class="form-group row text-sm-right required">
                        <label class="col-sm-3 control-label">{{
                          'notification-template.form.subject' | translate
                        }}</label>
                        <div class="col-sm-9 text-left">
                          <app-text-field
                            formControlName="subject"
                            size="LG"
                            maxLength="128"
                          ></app-text-field>
                        </div>
                      </div>
                      <div class="form-group row text-sm-right">
                        <label class="col-sm-3 control-label">{{
                          'notification-template.form.content' | translate
                        }}</label>
                        <div class="col-sm-9 text-left">
                          <app-wysiwyg
                            formControlName="body"
                            size="LG"
                            placeholder="Enter text here..."
                          ></app-wysiwyg>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </app-tab-item>
              </app-tab>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">
              {{ 'push-notification.form.status' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                formControlName="isActive"
                [optionList]="statusList"
                type="COMBOBOX"
              >
              </app-dropdown-select>
            </div>
          </div>
        </app-card>
      </form>
      <div class="button-group button-group-center mt-5">
        <app-button (onClick)="doSave()">
          {{ 'app.button.save' | translate }}
        </app-button>
      </div>
      <ng-template #contentSidebarRight>
        <app-tips></app-tips>
      </ng-template>
    </div>
  </div>
</app-layout>
