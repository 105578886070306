import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { AddressBook } from '../../../bean/address-book';
import { AddressPic } from '../../../bean/address-pic';
import { Organization } from '../../../bean/organization';
import { OptionListModel } from '../../../model/option-list-model';
import { Validators } from '../../../validators';
import { AppPopupTreeOrganizationService } from '../app-popup-tree/app-popup-tree-organization.service';
import { AppPopupUserService } from '../app-popup-user/app-popup-user.service';

@Component({
  templateUrl: './app-popup-address.component.html'
})
export class AppPopupAddressComponent extends BaseComponentComponent {
  public addressBook: AddressBook = new AddressBook();
  public addressBookOptionList: OptionListModel<any> = new OptionListModel(
    true,
    'addressDetail'
  );
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);
  public isAddressChecked = false;
  public isLoadingFilter = false;
  @Input() addressBookList: AddressBook[] = [];
  @Input() isMultiple = true;
  @Output() onChange: EventEmitter<AddressBook[]> = new EventEmitter();

  constructor(
    public appPopupUserService: AppPopupUserService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService,
    public activeModal: NgbActiveModal
  ) {
    super('app-popup-address');
  }

  onInit(): void {
    this.buildFormGroup();
    this.getOptionList();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      organization: [null],
      addressBook: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<AddressBook[]>('/app-popup-address/index', [])
      .subscribe(adressBookList => {
        this.addressBookOptionList.setRequestValues(adressBookList);
        this.addressBookOptionList.searchFieldList = [
          'organization.name',
          'user.name',
          'addressDetail',
          'phone'
        ];
        if (this.addressBookList && this.addressBookList.length !== 0) {
          const addressBookExistingList = adressBookList.filter(addressBook =>
            this.addressBookList
              .map(address => address.id)
              .includes(addressBook.id)
          );
          this.formGroup.patchValue({
            addressBook: addressBookExistingList || []
          });
        }
        this.setStateReady();
      });
  }

  public getOptionList(): void {
    this.httpClientService
      .get<Organization[]>('/app-popup-address/get-option-list')
      .subscribe(response => {
        if (response != null) {
          this.organizationOptionList.setRequestValues(response);
        }
      });
  }

  public doOnChange(event: Organization[]): void {
    this.isLoadingFilter = true;
    const organizationIdList: number[] = [];
    if (event && event.length > 0) {
      event.map(organization => organizationIdList.push(organization.id));
    }
    this.httpClientService
      .post<AddressBook[]>('/app-popup-address/index', organizationIdList)
      .subscribe(adressBookList => {
        this.addressBookOptionList.setRequestValues(adressBookList);
        this.isLoadingFilter = false;
      });
  }

  doChooseOrganization(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(data => {
        this.formGroup.patchValue({
          organization: data[0],
          organizationName: data[0].name
        });
      });
  }

  doSubmit(): void {
    this.addressBookList = this.formGroup.get('addressBook').value;
    if (this.addressBookList && this.addressBookList.length !== 0) {
      this.onChange.emit(this.addressBookList);
    } else {
      this.global.alertService.showError(
        'app-popup-address.validation.chooseAddress',
        '.popup-address'
      );
    }
  }

  public getUserNameList(addressPicList: AddressPic[]): string {
    return addressPicList.map(a => a.user.name).join(', ');
  }
}
