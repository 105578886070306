import { Component,  OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { BaseModuleComponent } from '../core/base/angular/base-module.component';
import { PromiseThemeService } from '../themes/promise/promise-theme.service';
import { Global } from '../core/global/global';
import { AuthenticationService } from '../core/services/authentication.service';
import { Response } from '../core/model/response-model';
import { UserManual } from '../core/bean/user-manual';
import { ResponseStatusModel } from '../core/model/response-status-model';
import { ToastService } from '../core/components/app-toast/app-toast.service';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PagesComponent extends BaseModuleComponent implements  OnDestroy  {

  public showMenu = false;
  public showSetting = false;
  public menus = ['vertical', 'horizontal'];
  public menuOption: string;
  public menuTypes = ['default', 'compact', 'mini'];
  public menuTypeOption: string;
  public settings: Settings;
  public currentTime: Date = new Date();
  public proviousTime = new Date().getTime();
  constructor(
    translateService: TranslateService,
    private authenticationService: AuthenticationService,
    public promiseThemeService: PromiseThemeService,
    public appSettings: AppSettings,
    public router: Router,
    public global: Global,
    public toastService: ToastService
  ) {
    super('pages', translateService);
    this.settings = this.appSettings.settings;
    if (sessionStorage.skin) {
      this.settings.theme.skin = sessionStorage.skin;
    }
  }

  public onInit(): void {
    if (window.innerWidth <= 768) {
      this.settings.theme.showMenu = false;
    }
    this.showMenu = this.settings.theme.showMenu;
    this.menuOption = this.settings.theme.menu;
    this.menuTypeOption = this.settings.theme.menuType;

    document
    .getElementsByTagName('html')
    .item(0)
    .setAttribute('data-theme', 'promise sidebar-small-width');
    this.setHeaderTime();
  }


  ngOnDestroy(): void {
    document.getElementsByTagName('html').item(0).removeAttribute('data-theme');
  }

  public chooseMenu(menu: any): void {
    this.settings.theme.menu = menu;
    this.router.navigate(['/']);
  }

  public chooseMenuType(menuType): void {
    this.settings.theme.menuType = menuType;
    if (menuType === 'mini') {
      jQuery('.menu-item-link').tooltip('enable');
    } else {
      jQuery('.menu-item-link').tooltip('disable');
    }
  }

  public changeTheme(theme): void {
    this.settings.theme.skin = theme;
    sessionStorage.skin = theme;
  }

 

  public showMenuStateChange(showMenu: boolean): void {
    this.settings.theme.showMenu = showMenu;
  }

  public _showMenu(): boolean {
    return window.innerWidth <= 768;
  }



// From layout
public onMouseEnterOnSidebar(): void {
  this.changeSidebar('FULL');
}

public onMouseLeaveFromSidebar(): void {
    //rei_komen  
   this.changeSidebar('SMALL');
}

public changeSidebar(width: 'FULL' | 'SMALL'): void {
  const dataTheme = this.promiseThemeService.getDataTheme();
  let sidebarWidth = dataTheme;
  if (width === 'FULL') {
    sidebarWidth = dataTheme.replace(
      this.promiseThemeService.SIDEBAR_SMALL_WIDTH,
      this.promiseThemeService.SIDEBAR_FULL_WIDTH
    );
  } else {
    sidebarWidth = dataTheme.replace(
      this.promiseThemeService.SIDEBAR_FULL_WIDTH,
      this.promiseThemeService.SIDEBAR_SMALL_WIDTH
    );
  }
  dataTheme !== sidebarWidth &&
    this.promiseThemeService
      .getHtmlElement()
      .setAttribute(this.promiseThemeService.DATA_THEME_NAME, sidebarWidth);
}


public doLogOut(): void {
  this.loadingBlockService.showInfo('app.profile.loggingOut');
  this.authenticationService.logout();
}

public goToAccountSetting(event): void {
  event.preventDefault();
  event.stopPropagation();
  this.router.navigate(['/pages/account-setting']);
}


public downloadUserManual(): void {
  const url = '/user-manual/download-user-manual';
  this.httpClientService
    .get<Response<UserManual>>(url)
    .subscribe(response => {
      if (response.status === ResponseStatusModel.OK) {
        if (response.body.file != null) {
          const stringUrl =
            this.global.config.BACKEND_ADDRESS +
            '/user-manual' +
            '/file/view/' +
            response.body.file.uploadedFileName +
            '/' + this.global.appConstant.fileType.DOC_USER_MANUAL;
          this.downloadService.download(
            stringUrl,
            response.body.file.fileName
          );
        } else {
          this.global.alertService.showError(
            this.global.translateService.instant('user-manual.fileNotExist')
          );
        }
      } else {
        this.global.alertService.showError(response.statusText);
      }
    });
}

private setHeaderTime(): void {
  setInterval(() => {
    let nowInMilliseconds = 0;
    nowInMilliseconds = new Date().getTime() + this.global.deltaTime;
    this.proviousTime = new Date().getTime();
    this.currentTime = new Date(nowInMilliseconds);
  }, 500);
}

public doDownloadUserManual(): void {
  this.loadingBlockService.showInfo('app.msg.processing');
  const url = '/user-manual/download-user-manual';
  this.httpClientService
    .get<Response<UserManual>>(url)
    .subscribe(response => {
      this.loadingBlockService.close();
      if (response.status === ResponseStatusModel.OK) {
        if (response.body.file != null) {
          const stringUrl =
            '/user-manual' +
            '/file/view/' +
            response.body.file.uploadedFileName +
            '/' +
            this.global.appConstant.fileType.DOC_USER_MANUAL;
          this.downloadService.download(
            stringUrl,
            response.body.file.fileName
          );
        } else {
          this.toastService.showError(
            this.global.translateService.instant('user-manual.fileNotExist')
          );
        }
      } else {
        this.toastService.showError(response.statusText);
      }
    });
}

}
