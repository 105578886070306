<div class="card-body app-tab-item-content" [id]="'tab-item-content-' + tabItemId">
  <ng-content></ng-content>
</div>
<li class="nav-item" [attr.id]="tabItemId"
  [ngClass]="tabStoreService.tabState.tabDisabled[tabItemId] ? 'disabled' : ''">
  <a class="nav-link" [ngClass]="tabStoreService.tabState.tabSelected[tabItemId] ? 'active' : ''"
    (click)="doChangeTab($event)">
    {{ header | translate }}
    <em class="pir pi-chevron-right" *ngIf="tabStoreService.tabState.position === 'VERTICAL'"></em>
  </a>
</li>