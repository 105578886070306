/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\action\widget-action.ts
 
 * Created Date: Thursday, November 18th 2021, 11:06:17 am

 *
*
 */

import { Action } from '../../../../core/interfaces/action';

export const FIRST_LOAD_WIDGET = `[ Dashboard Widget ]: First load widget`;
export const SET_WIDGET_EDITABLE = `[ Dashboard Widget ]: Show Widget Editable`;
export const ADD_WIDGET = `[ Dashboard Widget ]: Add Widget`;
export const DELETE_WIDGET = `[ Dashboard Widget ]: Delete Widget`;
export const SAVE_WIDGET = `[ Dashboard Widget ]: Save Widget`;
export const RESTORE_WIDGET = `[ Dashboard Widget ]: Restore Widget`;

export class FirstLoadWidget implements Action<void> {
  public readonly type = FIRST_LOAD_WIDGET;
}

export class SetWidgetEditable implements Action<void> {
  public readonly type = SET_WIDGET_EDITABLE;
}

export class AddWidget implements Action<void> {
  public readonly type = ADD_WIDGET;
}

export class DeleteWidget implements Action<{ index: number }> {
  public readonly type = DELETE_WIDGET;
  public constructor(public payload: { index: number }) {}
}

export class SaveWidget implements Action<void> {
  public readonly type = SAVE_WIDGET;
}

export class RestoreWidget implements Action<void> {
  public readonly type = RESTORE_WIDGET;
}

export type AllWidgetAction =
  | FirstLoadWidget
  | SetWidgetEditable
  | AddWidget
  | DeleteWidget
  | SaveWidget
  | RestoreWidget;
