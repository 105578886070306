/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\app-date-picker-x\action\set-min-or-max-date-action.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Friday, September 17th 2021, 5:30:52 pm

 *
*
 */

import { createMinOrMaxDate } from '../helper/create-min-or-max-date-helper';
import { DatePickerState } from '../model';
export const setDatePickerMinOrMaxDate = (
  state: DatePickerState,
  payload: { minDate: Date | string; maxDate: Date | string }
): void => {
  state.model.minDate = payload.minDate;
  state.model.maxDate = payload.maxDate;
  createMinOrMaxDate(state, 'MIN');
  createMinOrMaxDate(state, 'MAX');
};
