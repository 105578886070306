/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\table-plugins.module.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Sunday, September 12th 2021, 1:56:43 pm

 *
*
 */

import { NgModule } from '@angular/core';
import { TableBadgePluginModule } from './badge-plugin/badge-plugin.module';
import { TableCountPluginModule } from './count-plugin/count-plugin.module';
import { TableCurrencyPluginModule } from './currency-plugin/currency-plugin.module';
import { TableDatePickerPluginModule } from './date-picker-plugin/date-picker-plugin.module';
import { TableDefaultHeaderPluginModule } from './default-header-plugin/default-header-plugin.module';
import { TableDefaultPluginModule } from './default-plugin/default-plugin.module';
import { TableDropDownSelectPluginModule } from './drop-down-select-plugin/drop-down-select-plugin.module';
import { TableHyperlinkPluginModule } from './hyperlink-plugin/hyperlink-plugin.module';
import { TableIconPluginModule } from './icon-plugin/icon-plugin.module';
import { TableImgPluginModule } from './img-plugin/img-plugin.module';
import { TableRatingPluginModule } from './rating-plugin/rating-plugin.module';
import { TableTextAreaPluginModule } from './text-area-plugin/text-area-plugin.module';
import { TableTextFieldPluginModule } from './text-field-plugin/text-field-plugin.module';
import { TableToggleSwitchPluginModule } from './toggle-switch-plugin/toggle-switch-plugin.module';
import { TableUploadPluginModule } from './upload-plugin/upload-plugin.module';
import { TableWysiwygPluginModule } from './wysiwyg-plugin/wysiwyg-plugin.module';
@NgModule({
  exports: [
    // body plugin
    TableDefaultPluginModule,
    TableBadgePluginModule,
    TableCountPluginModule,
    TableCurrencyPluginModule,
    TableDatePickerPluginModule,
    TableDropDownSelectPluginModule,
    TableHyperlinkPluginModule,
    TableImgPluginModule,
    TableRatingPluginModule,
    TableTextAreaPluginModule,
    TableUploadPluginModule,
    TableWysiwygPluginModule,
    TableTextFieldPluginModule,
    TableIconPluginModule,
    TableToggleSwitchPluginModule,
    // header plugin
    TableDefaultHeaderPluginModule
  ]
})
export class TablePluginsModule {}
