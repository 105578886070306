import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export const rotateAnimation = (
  rotateTo = '180deg',
  animateTimeRotated = '250ms'
): AnimationTriggerMetadata => {
  return trigger('rotate', [
    state('default', style({ transform: 'rotate(0)' })),
    state('rotated90ccw', style({ transform: 'rotate(-90deg)' })),
    state('rotated', style({ transform: `rotate(${rotateTo})` })),
    transition('default => rotated90ccw', animate(animateTimeRotated)),
    transition('rotated90ccw => default', animate(animateTimeRotated)),
    transition('rotated90ccw => rotated', animate(animateTimeRotated))
  ]);
};