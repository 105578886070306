/*
 * Filename:  WebContent\src\app\core\components\app-popup\app-popup-progress-bar-download\app-popup-progress-bar-download.module.ts
 * Path:  WebContent
 * Created Date: Tuesday, September 7th 2021, 11:28:15 am

 *
*
 */
import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupProgressBarDownloadComponent } from './app-popup-progress-bar-download.component';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppButtonModule],
  declarations: [AppPopupProgressBarDownloadComponent],
  exports: [AppPopupProgressBarDownloadComponent]
})
export class AppPopupProgressBarDownloadModule {}
