/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\model\table-column-model.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:23:44 am

 *
*
 */
import { AllTablePluginOptions } from '../interface/available-table-plugin';
import { TableColumnCallbacks } from '../interface/TableColumnCallbacks';
import { TableColumnComponentModel } from './table-column-component-model';
import { TableColumnHeaderModel } from './table-column-header-model';
export class TableColumnModel {
  // field mana yang ingin ditampilkan pada table
  field?: string;
  // Text yang akan ditampilkan pada table header.
  header?: string | TableColumnHeaderModel;
  // data yang akan digunakan sebagai referensi value dari column.
  // mis: { keyOne: 'Hello', keyTwo: 'Holla' }. column value adalah: keyOne.
  // maka value yang akan tampil adalah 'Hello'. jadi ditable akan return dataMap[valueDariField]
  dataMap?: object | string;
  // apakah column ingin disort atau tidak
  isSortable?: boolean;
  // Jika tidak ingin column ini masuk ke dalam pencarian table
  isSearchTable?: boolean;
  // class khusus yang akan disisipkan ke table column / td
  className?: string;
  // jika ingin menggunakan component khusus, dapat component ini
  component?: object | TableColumnComponentModel;
  // plugins untuk app table
  plugins?: AllTablePluginOptions | Array<AllTablePluginOptions>;
  // column list
  columnList?: Array<TableColumnModel>;
  // callbacks
  callbacks?: TableColumnCallbacks;
}
