<div class="row">
  <div class="col-sm-12 col-md-5 text-md-left  text-center text-muted" *ngIf="model.isShowPaginationDetail">
    {{ 'app-pagination.showing' | translate }} {{ !!((model.page - 1) *
    model.perPage + 1 > model.totalRecords) ? 0 : (model.page - 1) *
    model.perPage + 1 }} {{'app-pagination.to' | translate }}
    {{ !!(model.page * model.perPage < model.totalRecords) ? model.page * model.perPage : model.totalRecords }}
      {{'app-pagination.of' | translate }} {{ model.totalRecords }} {{ 'app-pagination.entry' | translate }} </div>
      
      <div class="col-sm-12 col-md-7 ">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="model.totalRecords" [(page)]="model.page" [pageSize]="model.perPage"
          [maxSize]="5" (pageChange)="pageChanges($event)" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
      </div>
    </div>
   