/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\date-plugin\index.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Monday, September 13th 2021, 12:05:05 am

 *
*
 */
import { DatePipe } from '@angular/common';
import { Global } from '../../../../global/global';
import { DatePlugin } from '../../interface/available-table-plugin';
import { TablePlugin } from '../../interface/table-plugin';
import { TablePluginData } from '../../interface/table-plugin-data';
export const tableDatePlugin: TablePlugin = {
  name: 'date',
  before: (data: TablePluginData): string => {
    const datePlugin = data.plugin as DatePlugin;
    const datePipe = data.state.injector.get(DatePipe);
    let dateFormat: string;
    if ((!datePlugin.format && !datePlugin.customFormat) || datePlugin.format) {
      const global = data.state.injector.get(Global);
      const dateFormatMap: {
        [key: string]: string;
      } = {
        'short-date': global.config.parameterModel.dateFormatShort,
        'short-date-and-time': global.config.parameterModel.datetimeFormatShort,
        'long-date': global.config.parameterModel.dateFormatLong,
        'long-date-and-time': global.config.parameterModel.datetimeFormatLong
      };
      dateFormat = dateFormatMap[datePlugin.format || 'short-date'];
    } else {
      dateFormat = datePlugin.customFormat;
    }
    return datePipe.transform(data.value, dateFormat) || '-';
  }
};
