export class PMConstant {
  /* t1_pr_status */
  public PR_STATUS_DRAFT = 'DRAFT';
  public PR_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public PR_STATUS_WAITING_VERIFICATION = 'WAITING_VERIFICATION';
  public PR_STATUS_REVISION = 'REVISION';
  public PR_STATUS_REJECTED = 'REJECTED';
  public PR_STATUS_VERIFIED = 'VERIFIED';
  public PR_STATUS_HOLD = 'HOLD';
  public PR_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public PR_STATUS_DONE = 'DONE';
  public PR_STATUS_CANCELED = 'CANCELED';

  /* t2_condition */
  public CONDITION_PROCUREMENT_METHOD = 'PROCUREMENT_METHOD';
  public CONDITION_PROCUREMENT_VALUE = 'PROCUREMENT_VALUE';

  /* t1_procurement_status */
  public PROCUREMENT_STATUS_DRAFT = 'DRAFT';
  public PROCUREMENT_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public PROCUREMENT_STATUS_REVISION = 'REVISION';
  public PROCUREMENT_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public PROCUREMENT_STATUS_FAILED_ASSESSMENT = 'FAILED_ASSESSMENT';
  public PROCUREMENT_STATUS_FAILED = 'FAILED';
  public PROCUREMENT_STATUS_DONE = 'DONE';
  public PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL = 'WAITING_FAILED_APPROVAL';
  public PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL = 'WAITING_CANCEL_APPROVAL';
  public PROCUREMENT_STATUS_CANCELED = 'CANCELED';
  public PROCUREMENT_STATUS_RE_HELD = 'RE_HELD';

  /* t1_term_qualification */
  public TERM_QUALIFICATION_MINIMUM_LIMIT = 'MINIMUM_LIMIT';
  public TERM_QUALIFICATION_SPECIFIC = 'SPECIFIC';

  /* t1_procurement_type */
  public PROCUREMENT_TYPE_CONSTRUCTION = 'CONSTRUCTION';
  public PROCUREMENT_TYPE_NON_CONSTRUCTION = 'NON_CONSTRUCTION';

  /* t1_procurement_reg_type */
  public PROC_REG_TYPE_ANNOUNCEMENT = 'ANC';
  public PROC_REG_TYPE_INVITATION = 'INV';

  /* t1_sow_payment_type */
  public SOW_PAYMENT_TYPE_MONTHLY_PAYMENT = 'MONTHLY_PAYMENT';
  public SOW_PAYMENT_TYPE_FULL_PAYMENT = 'FULL_PAYMENT';
  public SOW_PAYMENT_TYPE_GRADUAL_PAYMENT = 'GRADUAL_PAYMENT';
  public SOW_PAYMENT_TYPE_GRADUAL_PAYMENT_WITH_DP = 'GRADUAL_PAYMENT_WITH_DP';
  public SOW_PAYMENT_TYPE_AS_PER_ORDER = 'AS_PER_ORDER';

  /* t1_billing_method */
  public BILLING_METHOD_PARTIAL = 'PARTIAL';
  public BILLING_METHOD_NON_PARTIAL = 'NON_PARTIAL';

  /* t1_procurement_worklist_status */
  public PROCUREMENT_WORKLIST_STATUS_NEW = 'NEW';
  public PROCUREMENT_WORKLIST_STATUS_DRAFT = 'DRAFT';
  public PROCUREMENT_WORKLIST_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public PROCUREMENT_WORKLIST_STATUS_REVISION = 'REVISION';
  public PROCUREMENT_WORKLIST_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public PROCUREMENT_WORKLIST_STATUS_DONE = 'DONE';
  public PROCUREMENT_WORKLIST_STATUS_WAITING_RESPONSE = 'WAITING_RESPONSE';
  public PROCUREMENT_WORKLIST_STATUS_CLOSED_RESPONSE = 'CLOSED_RESPONSE';
  public PROCUREMENT_WORKLIST_STATUS_HOLD = 'HOLD';
  public PROCUREMENT_WORKLIST_STATUS_CANCELED = 'CANCELED';
  public PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION = 'RE_NEGOTIATION';

  /* t1_bidding_evaluation */
  public BIDDING_EVALUATION_CODE_MERIT_POINT = 'MERIT_POINT';
  public BIDDING_EVALUATION_CODE_PASSING_GRADE = 'PASSING_GRADE';
  public BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM = 'LEAST_COST_SYSTEM';

  /* t1_procurement_reg_vendor_status */
  public PROC_REG_VENDOR_STATUS_NEW = 'NEW';
  public PROC_REG_VENDOR_STATUS_DRAFT = 'DRAFT';
  public PROC_REG_VENDOR_STATUS_VERIFICATION = 'VERIFICATION';
  public PROC_REG_VENDOR_STATUS_REVISION = 'REVISION';
  public PROC_REG_VENDOR_STATUS_ACCEPTED = 'ACCEPTED';
  public PROC_REG_VENDOR_STATUS_DECLINED = 'DECLINED';
  public PROC_REG_VENDOR_STATUS_EXPIRED = 'EXPIRED';
  public PROC_REG_VENDOR_STATUS_HOLD = 'HOLD';
  public PROC_REG_VENDOR_STATUS_CANCELED = 'CANCELED';
  public PROC_REG_VENDOR_STATUS_FAILED = 'FAILED';

  /* t1_procurement_reg_vendor_status */
  public PROC_REG_BUYER_STATUS_UNAVAILABLE = 'UNAVAILABLE';
  public PROC_REG_BUYER_STATUS_VERIFICATION = 'VERIFICATION';
  public PROC_REG_BUYER_STATUS_REVISION = 'REVISION';
  public PROC_REG_BUYER_STATUS_ACCEPTED = 'ACCEPTED';
  public PROC_REG_BUYER_STATUS_DECLINED = 'DECLINED';
  public PROC_REG_BUYER_STATUS_EXPIRED = 'EXPIRED';

  /* t1_procurement_pq_doc_vendor_status */
  public PROC_PQ_DOC_VENDOR_STATUS_AVAILABLE = 'AVAILABLE';
  public PROC_PQ_DOC_VENDOR_STATUS_UNAVAILABLE = 'UNAVAILABLE';
  public PROC_PQ_DOC_VENDOR_STATUS_ACCEPTED = 'ACCEPTED';
  public PROC_PQ_DOC_VENDOR_STATUS_REVISION = 'REVISION';
  public PROC_PQ_DOC_VENDOR_STATUS_DECLINED = 'DECLINED';
  public PROC_PQ_DOC_VENDOR_STATUS_EXPIRED = 'EXPIRED';

  /* t1_procurement_pq_doc_buyer_status */
  public PROC_PQ_DOC_BUYER_STATUS_AVAILABLE = 'AVAILABLE';
  public PROC_PQ_DOC_BUYER_STATUS_UNAVAILABLE = 'UNAVAILABLE';
  public PROC_PQ_DOC_BUYER_STATUS_ACCEPTED = 'ACCEPTED';
  public PROC_PQ_DOC_BUYER_STATUS_REVISION = 'REVISION';
  public PROC_PQ_DOC_BUYER_STATUS_DECLINED = 'DECLINED';

  /* t1_rfp_status */
  public RFP_STATUS_DRAFT = 'DRAFT';
  public RFP_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public RFP_STATUS_WAITING_RESPONSE = 'WAITING_RESPONSE';
  public RFP_STATUS_REVISION = 'REVISION';
  public RFP_STATUS_VERIFIED = 'VERIFIED';
  public RFP_STATUS_CLOSED_RESPONSE = 'CLOSED_RESPONSE';
  public RFP_STATUS_COMPLETED = 'COMPLETED';
  public RFP_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public RFP_STATUS_CANCELED = 'CANCELED';
  public RFP_STATUS_SUBMITTED = 'SUBMITTED';
  public RFP_STATUS_RFQ_PROCESS = 'RFQ_PROCESS';
  public RFP_STATUS_DONE = 'DONE';

  /* t1_scoring_status */
  public SCORING_STATUS_PASSED = 'PASSED';
  public SCORING_STATUS_NOT_PASSED = 'NOT_PASSED';
  public SCORING_STATUS_WAITING_SCORING = 'WAITING_SCORING';

  /* t1_submitted_status */
  public SUBMITTED_STATUS_AVAILABLE = 'AVAILABLE';
  public SUBMITTED_STATUS_UNAVAILABLE = 'UNAVAILABLE';
  public SUBMITTED_STATUS_PARTLY_AVAILABLE = 'PARTLY_AVAILABLE';
  public SUBMITTED_STATUS_WAITING_RESPONSE = 'WAITING_RESPONSE';

  /* t1_oep_status */
  public OEP_STATUS_DRAFT = 'DRAFT';
  public OEP_STATUS_NEW = 'NEW';
  public OEP_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public OEP_STATUS_REVISION = 'REVISION';
  public OEP_STATUS_APPROVED = 'APPROVED';

  /* t1_order_status */
  public ORDER_STATUS_DRAFT = 'DRAFT';
  public ORDER_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public ORDER_STATUS_WAITING_CONFIRMATION = 'WAITING_CONFIRMATION';
  public ORDER_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public ORDER_STATUS_VENDOR_REVISION = 'VENDOR_REVISION';
  public ORDER_STATUS_REVISION = 'REVISION';
  public ORDER_STATUS_DONE = 'DONE';
  public ORDER_STATUS_REJECTED = 'REJECTED';
  public ORDER_STATUS_CANCELED = 'CANCELED';
  public ORDER_STATUS_CLOSED = 'CLOSED';
  public ORDER_STATUS_HOLD = 'HOLD';

  /* t1_order_activity_status */
  public ORDER_ACTIVITY_STATUS_SUBMITTED = 'SUBMITTED';
  public ORDER_ACTIVITY_STATUS_REVISED = 'REVISED';
  public ORDER_ACTIVITY_STATUS_CONFIRMED = 'CONFIRMED';
  public ORDER_ACTIVITY_STATUS_REJECTED = 'REJECTED';
  public ORDER_ACTIVITY_STATUS_RESEND = 'RESEND';
  public ORDER_ACTIVITY_STATUS_CANCELED = 'CANCELED';
  public ORDER_ACTIVITY_STATUS_CLOSED = 'CLOSED';

  /* t1_rfq_status */
  public RFQ_STATUS_DRAFT = 'DRAFT';
  public RFQ_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public RFQ_STATUS_WAITING_RESPONSE = 'WAITING_RESPONSE';
  public RFQ_STATUS_REVISION = 'REVISION';
  public RFQ_STATUS_VERIFIED = 'VERIFIED';
  public RFQ_STATUS_CLOSED_RESPONSE = 'CLOSED_RESPONSE';
  public RFQ_STATUS_COMPLETED = 'COMPLETED';
  public RFQ_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public RFQ_STATUS_CANCELED = 'CANCELED';
  public RFQ_STATUS_SUBMITTED = 'SUBMITTED';
  public RFQ_STATUS_DONE = 'DONE';

  /* t1_aanwijzing_status */
  public AANWIJZING_STATUS_DRAFT = 'DRAFT';
  public AANWIJZING_STATUS_NOT_STARTED = 'NOT_STARTED';
  public AANWIJZING_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public AANWIJZING_STATUS_CLOSED = 'CLOSED';
  public AANWIJZING_STATUS_DONE = 'DONE';
  public AANWIJZING_STATUS_EXTENDED = 'EXTENDED';
  public AANWIJZING_STATUS_CANCELED = 'CANCELED';

  /** t1_participant_role */
  public PARTICIPANT_ROLE_OTHERS = 'OTHERS';
  public PARTICIPANT_ROLE_VENDOR = 'VENDOR';
  public PARTICIPANT_ROLE_INTERNAL = 'INTERNAL';

  /* t1_quotation_status */
  public QUOTATION_STATUS_DRAFT = 'DRAFT';
  public QUOTATION_STATUS_NEW = 'NEW';
  public QUOTATION_STATUS_SUBMITTED = 'SUBMITTED';
  public QUOTATION_STATUS_CLOSED_RESPONSE = 'CLOSED_RESPONSE';
  public QUOTATION_STATUS_COMPLETED = 'COMPLETED';

  /* t2_report_module_group */
  public REPORT_MODULE_GROUP_GENERAL = 'GENERAL_REPORTS';
  public REPORT_MODULE_GROUP_PM = 'PM_REPORTS';
  public REPORT_MODULE_GROUP_VM = 'VM_REPORTS';
  public REPORT_MODULE_GROUP_ESM = 'ESM_REPORTS';
  public REPORT_MODULE_GROUP_CM = 'CM_REPORTS';

  /* t1_document_submission_type */
  public DOCUMENT_SUBMISSION_TYPE_ONE_ENVELOPE = 'ONE_ENVELOPE';
  public DOCUMENT_SUBMISSION_TYPE_TWO_ENVELOPE = 'TWO_ENVELOPE';
  public DOCUMENT_SUBMISSION_TYPE_TWO_STAGE = 'TWO_STAGE';

  /* t1_document_submission_type */
  public DOCUMENT_SUBMISSION_METHOD_ENVELOPE_1 = 'ENVELOPE_1';
  public DOCUMENT_SUBMISSION_METHOD_ENVELOPE_2 = 'ENVELOPE_2';
  public DOCUMENT_SUBMISSION_METHOD_STAGE_1 = 'STAGE_1';
  public DOCUMENT_SUBMISSION_METHOD_STAGE_2 = 'STAGE_2';

  /* t1_doc_retrieval_status */
  public DOC_RETRIEVAL_STATUS_NEW = 'NEW';
  public DOC_RETRIEVAL_STATUS_DONE = 'DONE';
  public DOC_RETRIEVAL_STATUS_HOLD = 'HOLD';
  public DOC_RETRIEVAL_STATUS_CANCELED = 'CANCELED';
  public DOC_RETRIEVAL_STATUS_FAILED = 'FAILED';

  /* t1_schedule_status */
  public SCHEDULE_STATUS_DRAFT = 'DRAFT';
  public SCHEDULE_STATUS_NEW = 'NEW';
  public SCHEDULE_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public SCHEDULE_STATUS_REVISION = 'REVISION';
  public SCHEDULE_STATUS_DONE = 'DONE';

  /* t1_vendor_invitation_status */
  public VENDOR_INVITATION_STATUS_DRAFT = 'DRAFT';
  public VENDOR_INVITATION_STATUS_NEW = 'NEW';
  public VENDOR_INVITATION_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public VENDOR_INVITATION_STATUS_REVISION = 'REVISION';
  public VENDOR_INVITATION_STATUS_DONE = 'DONE';

  /* t1_validity_status */
  public VALIDITY_STATUS_VALID = 'VALID';
  public VALIDITY_STATUS_INVALID = 'INVALID';
  public VALIDITY_STATUS_WAITING_VERIFICATION = 'WAITING_VERIFICATION';

  /* t1_profit_loss_status */
  public PROFIT_LOSS_STATUS_SAVE = 'SAVE';
  public PROFIT_LOSS_STATUS_LOSS = 'LOSS';

  /* t1_vendor_selection_type */
  public VENDOR_SELECTION_TYPE_SINGLE_WINNER = 'SINGLE_WINNER';
  public VENDOR_SELECTION_TYPE_MULTI_WINNER = 'MULTI_WINNER';

  /* t1_price_evaluation_status */
  public PRICE_EVALUATION_STATUS_NEW = 'NEW';
  public PRICE_EVALUATION_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public PRICE_EVALUATION_STATUS_DONE = 'DONE';
  public PRICE_EVALUATION_STATUS_HOLD = 'HOLD';

  /* t2_price_evaluation_method */
  public PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE = 'DEAL_WITH_THIS_PRICE';
  public PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION =
    'DEAL_WITH_THIS_PRICE_AUCTION';
  public PRICE_EVALUATION_METHOD_NEGOTIATION = 'NEGOTIATION';
  public PRICE_EVALUATION_METHOD_NEGOTIATION_AUCTION = 'NEGOTIATION_AUCTION';
  public PRICE_EVALUATION_METHOD_AUCTION = 'AUCTION';

  /* t2_official_report */
  public OFFICIAL_REPORT_CODE_ORT_EVALUATION_ADMINISTRATION_LETTER =
    'ORT_EVALUATION_ADMINISTRATION_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_AANWIJZING_LETTER = 'ORT_AANWIJZING_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_LS_EVALUATION_TECHNICAL_LETTER =
    'ORT_LS_EVALUATION_TECHNICAL_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_MP_PG_EVALUATION_TECHNICAL_LETTER =
    'ORT_MP_PG_EVALUATION_TECHNICAL_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_PRICE_EVALUATION_NON_MERIT_BULK_LETTER =
    'ORT_PRICE_EVALUATION_NON_MERIT_BULK_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_PRICE_EVALUATION_NON_MERIT_ITEM_LETTER =
    'ORT_PRICE_EVALUATION_NON_MERIT_ITEM_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_PRICE_EVALUATION_MERIT_BULK_LETTER =
    'ORT_PRICE_EVALUATION_MERIT_BULK_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_PRICE_EVALUATION_MERIT_ITEM_LETTER =
    'ORT_PRICE_EVALUATION_MERIT_ITEM_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_VENDOR_DESIGNATION_SINGLE_WINNER_LETTER =
    'ORT_VENDOR_DESIGNATION_SINGLE_WINNER_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_VENDOR_DESIGNATION_MULTI_WINNER_LETTER =
    'ORT_VENDOR_DESIGNATION_MULTI_WINNER_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_COMPLIANCE_TEST_LETTER =
    'ORT_COMPLIANCE_TEST_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_VENDOR_REFERENCE_SINGLE_WINNER_LETTER =
    'ORT_VENDOR_REFERENCE_SINGLE_WINNER_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_VENDOR_REFERENCE_MULTI_WINNER_LETTER =
    'ORT_VENDOR_REFERENCE_MULTI_WINNER_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_REFUTATION_SINGLE_WINNER_LETTER =
    'ORT_REFUTATION_SINGLE_WINNER_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_REFUTATION_MULTI_WINNER_LETTER =
    'ORT_REFUTATION_MULTI_WINNER_LETTER';
  public ORT_PURCHASE_REQUEST_LETTER = 'ORT_PURCHASE_REQUEST_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_OPEN_QUOTATION_ATTENDANCE_LIST_LETTER =
    'ORT_OPEN_QUOTATION_ATTENDANCE_LIST_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_SUBMIT_QUOTATION_ADMIN_TECHNICAL_LETTER =
    'ORT_SUBMIT_QUOTATION_ADMIN_TECHNICAL_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_OPEN_QUOTATION_ADMIN_TECHNICAL_LETTER =
    'ORT_OPEN_QUOTATION_ADMIN_TECHNICAL_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_OPEN_QUOTATION_TECHNICAL_PRICE_LETTER =
    'ORT_OPEN_QUOTATION_TECHNICAL_PRICE_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_OPEN_QUOTATION_PRICE_LETTER =
    'ORT_OPEN_QUOTATION_PRICE_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_SUBMIT_QUOTATION_PRICE_LETTER =
    'ORT_SUBMIT_QUOTATION_PRICE_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_SUBMIT_QUOTATION_ADMIN_TECHNICAL_PRICE_LETTER =
    'ORT_SUBMIT_QUOTATION_ADMIN_TECHNICAL_PRICE_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_VENDOR_DESIGNATION_APPOINTMENT_WINNER_LETTER =
    'ORT_VENDOR_DESIGNATION_APPOINTMENT_WINNER_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_VENDOR_INVITATION_LETTER =
    'ORT_VENDOR_INVITATION_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_AGREEMENT_NEGOTIATION_LETTER =
    'ORT_AGREEMENT_NEGOTIATION_LETTER';
  public OFFICIAL_REPORT_CODE_ORT_TOR = 'ORT_TOR';
  public OFFICIAL_REPORT_CODE_ORT_PRINCIPLE_PERMIT = 'ORT_PRINCIPLE_PERMIT';
  public OFFICIAL_REPORT_CODE_ORT_DIRECTORS_DECREE = 'ORT_DIRECTORS_DECREE';
  public OFFICIAL_REPORT_CODE_ORT_OFFICIAL_MEMO = 'ORT_OFFICIAL_MEMO';

  /* t1_bidding_submission_type */
  public BIDDING_SUBMISSION_TYPE_UNIT_PRICE = 'UNIT_PRICE';
  public BIDDING_SUBMISSION_TYPE_TOTAL_PRICE = 'TOTAL_PRICE';

  /* t1_compliance_test_Status */
  public COMPLIANCE_TEST_STATUS_NEW = 'NEW';
  public COMPLIANCE_TEST_STATUS_DRAFT = 'DRAFT';
  public COMPLIANCE_TEST_STATUS_WAITING_REVIEW = 'WAITING_REVIEW';
  public COMPLIANCE_TEST_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public COMPLIANCE_TEST_STATUS_REVISION = 'REVISION';
  public COMPLIANCE_TEST_STATUS_REVISION_BY_COMPLIANCE =
    'REVISION_BY_COMPLIANCE';
  public COMPLIANCE_TEST_STATUS_DONE = 'DONE';

  /* t1_history_status */
  public HISTORY_STATUS_NOT_STARTED = 'NOT_STARTED';
  public HISTORY_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public HISTORY_STATUS_DONE = 'DONE';
  public HISTORY_STATUS_CANCELED = 'CANCELED';
  public HISTORY_STATUS_FAILED = 'FAILED';

  /* t1_compliance_type */
  public COMPLIANCE_TYPE_SELF_ASSESSMENT = 'SELF_ASSESSMENT';
  public COMPLIANCE_TYPE_NON_SELF_ASSESSMENT = 'NON_SELF_ASSESSMENT';

  /* t1_compliance_check_status */
  public COMPLIANCE_CHECK_STATUS_VERIFIED = 'VERIFIED';
  public COMPLIANCE_CHECK_STATUS_NOT_VERIFIED = 'NOT_VERIFIED';
  public COMPLIANCE_CHECK_STATUS_REVISED = 'REVISED';

  /*t1_refutation_status*/
  public REFUTATION_STATUS_NEW = 'NEW';
  public REFUTATION_STATUS_DRAFT = 'DRAFT';
  public REFUTATION_STATUS_SUBMITTED = 'SUBMITTED';
  public REFUTATION_STATUS_CLOSED_RESPONSE = 'CLOSED_RESPONSE';
  public REFUTATION_STATUS_WAITING_RESPONSE = 'WAITING_RESPONSE';
  public REFUTATION_STATUS_HOLD = 'HOLD';
  public REFUTATION_STATUS_CANCELED = 'CANCELED';
  public REFUTATION_STATUS_FAILED = 'FAILED';

  /* t1_refutation_review_status */
  public REFUTATION_REVIEW_STATUS_ACCEPTED = 'ACCEPTED';
  public REFUTATION_REVIEW_STATUS_NOT_ACCEPTED = 'NOT_ACCEPTED';

  /* t2_procurement_method */
  public PROCUREMENT_METHOD_TENDER = 'TENDER';
  public PROCUREMENT_METHOD_DIRECT_SELECTION = 'DIRECT_SELECTION';
  public PROCUREMENT_METHOD_DIRECT_APPOINTMENT = 'DIRECT_APPOINTMENT';
  public PROCUREMENT_METHOD_CONTEST = 'CONTEST';
  public PROCUREMENT_METHOD_DIRECT_PURCHASE = 'DIRECT_PURCHASE';
  public PROCUREMENT_METHOD_DIRECT_PROCUREMENT = 'DIRECT_PROCUREMENT';

  /* t1_direct_purchase_delivery_status */
  public DIRECT_PURCHASE_DELIVERY_STATUS_WAITING_REVIEW = 'WAITING_REVIEW';
  public DIRECT_PURCHASE_DELIVERY_STATUS_DONE = 'DONE';

  /* t1_delivery_status */
  public DELIVERY_STATUS_NOT_STARTED = 'NOT_STARTED';
  public DELIVERY_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public DELIVERY_STATUS_DONE = 'DONE';
  public DELIVERY_STATUS_CANCELED = 'CANCELED';
  public DELIVERY_STATUS_CLOSED = 'CLOSED';
  public DELIVERY_STATUS_HOLD = 'HOLD';

  /* t1 committee type */
  public COMMITTEE_TYPE_PERSONAL = 'PERSONAL';
  public COMMITTEE_TYPE_COMMITTEE = 'COMMITTEE';

  /* t1_pr_type */
  public PURCHASE_TYPE_GENERAL = 'GENERAL';
  public PURCHASE_TYPE_REPEAT_ORDER = 'REPEAT_ORDER';
  public PURCHASE_TYPE_ADDENDUM = 'ADDENDUM';

  /* t1_pr_negotiation_status */
  public PR_NEGOTIATION_STATUS_NEW = 'NEW';
  public PR_NEGOTIATION_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public PR_NEGOTIATION_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public PR_NEGOTIATION_STATUS_REVISION = 'REVISION';
  public PR_NEGOTIATION_STATUS_DONE = 'DONE';
  public PR_NEGOTIATION_STATUS_DRAFT = 'DRAFT';

  /* source contract request */
  public CONTRACT_REQUEST_SOURCE_ORDER = 'ORDER';
  public CONTRACT_REQUEST_SOURCE_PROCUREMENT = 'PROCUREMENT';
  public CONTRACT_REQUEST_SOURCE_CONTRACT = 'CONTRACT';
  public CONTRACT_REQUEST_SOURCE_AGREEMENT_NEGOTIATION =
    'AGREEMENT-NEGOTIATION';
}
