/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table-filter\app-portal-table-filter.component.ts
 
 * Created Date: Friday, October 1st 2021, 9:25:46 am

 *
*
 */

import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
@Component({
  selector: 'app-portal-table-filter',
  templateUrl: './app-portal-table-filter.component.html',
  styleUrls: ['./app-portal-table-filter.component.scss']
})
export class AppPortalTableFilterComponent {
  @Input() public recordList: Array<any>;
  @Input() public activeIndex: number;
  @Output() public onClick: EventEmitter<any> = new EventEmitter();
  @ContentChild('item') itemTmpl: TemplateRef<any>;
  public doClickItem(event: PointerEvent, record: any, index: number): void {
    event.stopPropagation();
    event.preventDefault();
    this.activeIndex = index;
    this.onClick.emit(record);
  }
}
