import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { DashboardDragDropComponent } from './dashboard-drag-drop/dashboard-drag-drop.component';
import { DashboardComponent } from './dashboard.component';
import { WidgetModule } from './widget/widget.module';
import { AppMetabaseComponent } from './app-metabase.component';
export const routes = [
  { path: '', component: DashboardComponent, pathMatch: 'full' }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), WidgetModule],
  declarations: [DashboardComponent, DashboardDragDropComponent, AppMetabaseComponent]
})
export class DashboardModule {}
