import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Fines } from 'src/app/core/bean/fines';
import { AppPopupService } from '../app-popup.service';
import { AppPopupFinesComponent } from './app-popup-fines.component';

@Injectable()
export class AppPopupFinesService {
  constructor(public appPopupService: AppPopupService) {}
  public openPo(
    todo: string,
    amountSource?: number,
    finesList?: Fines[],
    finesEdit?: Fines
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupFinesComponent,
      {
        todo,
        amountSource,
        finesEdit,
        finesList
      },
      { size: 'lg' }
    );
  }
}
