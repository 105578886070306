/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\action\first-load-widget-action.ts
 
 * Created Date: Thursday, November 18th 2021, 11:15:25 am

 *
*
 */

import { WidgetItem } from '../domain/widget-item';
import { WidgetState } from '../domain/widget-state';
import { resolveWidget } from '../helper/resolve-widget-helper';
export const firstLoadWidgetAction = (state: WidgetState): void => {
  resolveWidget(state).then((widgetItemList: WidgetItem[]) => {
    state.widgetList = Array.from(widgetItemList);
    state.widgetListTemp = Array.from(widgetItemList);
    state.setStateReady();
  });
};
