/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\portal\landing-page\revistration-verification.resolver.ts
 
 * Created Date: Sunday, October 3rd 2021, 12:34:22 pm

 *
*
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { HttpClientService } from '../../core/services/http-client.service';
import { PortalResponse } from '../response/portal-response';
@Injectable()
export class RegistrationVerificationResolver
  implements Resolve<PortalResponse>
{
  constructor(
    private httpClientService: HttpClientService,
    private router: Router
  ) {}
  resolve(route: ActivatedRouteSnapshot): Promise<PortalResponse> {
    return new Promise(resolve => {
      this.httpClientService
        .post<Response<PortalResponse>>(
          '/portal/registration-verification',
          route.params.token
        )
        .subscribe((response: Response<PortalResponse>) => {
          response.status === ResponseStatusModel.OK
            ? resolve(response.body)
            : this.router.navigate(['/portal/landing-page/expired-link']);
        });
    });
  }
}
