import { Injectable } from '@angular/core';
import { BroadcastMessage } from '../../bean/broadcast-message';
import { AlertTopService } from '../alert-top/alert-top.service';
import { WebSocketService } from './websocket.service';

@Injectable({ providedIn: 'root' })
export class AlertTopWebsocketService extends WebSocketService<BroadcastMessage> {
  constructor(private alertTopService: AlertTopService) {
    super('broadcast-message');
  }

  protected onReceivedMessage(message: string): Promise<any> {
    return new Promise(resolve => {
      if (message !== 'null') {
        const broadcastMessage = JSON.parse(message);
        resolve(this.alertTopService.showInfo(broadcastMessage));
      }
    });
  }
}
