import { Injectable } from '@angular/core';
import { AppPopupService } from '../app-popup.service';
import { AppPopupCoaComponent } from './app-popup-coa.component';

@Injectable()
export class AppPopupCoaService {
  constructor(public appPopupService: AppPopupService) {}

  public open() {
    return this.appPopupService.open(AppPopupCoaComponent);
  }
}
