<div
  class="app-select"
  [ngClass]="isShowInputControl === false ? 'hide-control' : ''"
>
  <div
    class="app-select-header"
    *ngIf="
      selectAllText ||
      placeholderOptionTmpl ||
      search ||
      sorter ||
      headerFilterTemplate ||
      actionButtonsTmpl ||
      headerButtonsTmpl ||
      headerFilterGroupTemplate
    "
  >
    <div class="app-select-left">
      <app-button
        size="SM"
        *ngIf="headerFilterGroupTemplate"
        (onClick)="isExpandedFilterGroup = !isExpandedFilterGroup"
      >
        Filters
        <em class="pir pi-chevron-circle-down ml-2"></em>
        <!--[@rotate]="
          !isExpandedFilterGroup ? 'default' : 'rotated'
        "-->
      </app-button>
      <ng-container
        *ngIf="
          !headerFilterGroupTemplate &&
          selectAllText &&
          !ISVIEW &&
          optionList.requestValues.length > 0
        "
      >
        <ng-container *ngTemplateOutlet="selectAllTextTemplate"></ng-container>
      </ng-container>
      <div class="app-select-sort" *ngIf="sorter">
        <em class="fas fa-sort-amount-up-alt" (click)="doSort('ASC')"></em>
        <em class="fas fa-sort-amount-down" (click)="doSort('DESC')"></em>
      </div>
      <ng-container *ngTemplateOutlet="headerFilterTemplate"></ng-container>
      <div
        class="app-select-action-buttons"
        *ngIf="
          formControl.value && actionButtonsTmpl && !headerFilterGroupTemplate
        "
      >
        <ng-container *ngTemplateOutlet="actionButtonsTmpl"></ng-container>
      </div>
    </div>
    <div class="app-select-search" *ngIf="search">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">
            <em class="fas fa-search"></em>
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Search"
          aria-label="Search"
          aria-describedby="search"
          (input)="onInputSearch($event.target.value)"
        />
      </div>
    </div>
  </div>
  <div class="as-filter-group-outer" *ngIf="headerFilterGroupTemplate">
    <div
      class="as-filter-group-wrapper lmt-1"
      [@expandedCollapsed]="isExpandedFilterGroup ? 'expanded' : 'collapsed'"
    >
      <ng-container
        *ngTemplateOutlet="headerFilterGroupTemplate"
      ></ng-container>
    </div>
  </div>
  <br *ngIf="!headerFilterGroupTemplate" />
  <div
    class="d-flex align-items-center justify-content-between lmb-1 show-border-top"
    *ngIf="
      headerFilterGroupTemplate &&
      (headerButtonsTmpl || selectAllText || actionButtonsTmpl)
    "
  >
    <div class="flex-start" *ngIf="selectAllText">
      <div class="d-flex align-items-center">
        <ng-container *ngTemplateOutlet="selectAllTextTemplate"></ng-container>
        <div
          class="app-select-action-buttons"
          *ngIf="
            formControl.value && actionButtonsTmpl && headerFilterGroupTemplate
          "
        >
          <ng-container *ngTemplateOutlet="actionButtonsTmpl"></ng-container>
        </div>
      </div>
    </div>
    <div class="flex-end" *ngIf="headerButtonsTmpl">
      <ng-container *ngTemplateOutlet="headerButtonsTmpl"></ng-container>
    </div>
  </div>
  <div class="app-select-body">
    <app-lazy-load
      (onScrollEnd)="onScrollend()"
      [isLoading]="formLoading"
      [maxHeight]="maxHeight"
    >
      <ul class="select-option-list">
        <ng-container *ngIf="ISVIEW || false">
          <li
            class="select-item"
            *ngFor="let option of optionListTemp; let i = index"
            [ngClass]="[
              isShowBorder ? 'show-border' : '',
              isShowHover ? 'show-hover' : ''
            ]"
          >
            <div class="option-text">
              <ng-container
                *ngTemplateOutlet="
                  optionTemplate;
                  context: { $implicit: option, index: i }
                "
              ></ng-container>
            </div>
          </li>
        </ng-container>

        <ng-container *ngIf="!ISVIEW && optionList.requestValues.length > 0">
          <li
            *ngIf="placeholderOptionTmpl"
            class="select-item"
            (click)="insidelabelElement.click()"
            [ngClass]="[
              isShowBorder ? 'show-border' : '',
              isShowHover ? 'show-hover' : ''
            ]"
          >
            <div class="custom-control">
              <label
                [attr.for]="generatedId + 'Placeholder'"
                #insidelabelElement
                (click)="doClickPlaceholderOption($event)"
              >
                <ng-container *ngTemplateOutlet="placeholderOptionTmpl">
                </ng-container>
              </label>
            </div>
          </li>

          <li
            *ngIf="isMultiple && selectAllTemplate"
            class="select-item"
            (click)="insidelabelElement.click()"
            [ngClass]="[
              formControl.value &&
              formControl.value.length === optionList.requestValues.length
                ? 'selected'
                : '',
              isShowBorder ? 'show-border' : '',
              isShowHover ? 'show-hover' : ''
            ]"
          >
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                [attr.id]="generatedId + 'InsideLabel0'"
                (change)="onCheckBoxChangeAll($event)"
                [checked]="
                  formControl.value &&
                  formControl.value.length === optionList.requestValues.length
                "
              />
              <label
                class="custom-control-label"
                [attr.for]="generatedId + 'InsideLabel0'"
                #insidelabelElement
                (click)="doClick($event)"
              >
                <ng-container *ngTemplateOutlet="selectAllTemplate">
                </ng-container>
              </label>
            </div>
          </li>
          <ng-container
            *ngIf="isToggle; then toggleTemplate; else checkboxRadioTemplate"
          ></ng-container>
        </ng-container>

        <ng-container
          *ngIf="
            (optionList &&
              optionList.requestValues &&
              optionList.requestValues.length === 0) ||
            optionListTemp.length === 0
          "
        >
          <div class="lpy-4">
            <div class="book-wrapper pvc pv-file-list"></div>
            <div class="text-no-data">
              <span>
                {{ 'app-select.noDataAvailable' | translate }}
              </span>
              <span>
                {{ 'app-select.letsTryToAddList' | translate }}
              </span>
            </div>
          </div>
        </ng-container>
      </ul>
    </app-lazy-load>
    <div *ngIf="footerTmpl">
      <ng-container *ngTemplateOutlet="footerTmpl"></ng-container>
    </div>
  </div>
</div>

<ng-template #checkboxRadioTemplate>
  <li
    class="select-item"
    *ngFor="let option of optionListTemp; let i = index"
    (click)="labelElement.click()"
    [ngClass]="[
      isMultiple ? 'checkbox' : 'radio',
      (formControl.value
      | appSelectResolveChecked: option:(isMultiple ? 'checkbox' : 'radio'))
        ? 'selected'
        : '',
      isShowBorder ? 'show-border' : '',
      isShowHover ? 'show-hover' : ''
    ]"
  >
    <div
      class="custom-control"
      [ngClass]="isMultiple ? 'custom-checkbox' : 'custom-radio'"
    >
      <input
        [attr.type]="isMultiple ? 'checkbox' : 'radio'"
        class="custom-control-input"
        [attr.id]="generatedId + (i + 1)"
        (change)="
          isMultiple ? onCheckBoxChange(option, $event) : onRadioChange(option)
        "
        [checked]="
          formControl.value
            | appSelectResolveChecked
              : option
              : (isMultiple ? 'checkbox' : 'radio')
        "
      />
      <label
        #labelElement
        class="custom-control-label"
        [attr.for]="generatedId + (i + 1)"
        (click)="doClick($event)"
      >
        <ng-container
          *ngTemplateOutlet="
            optionTemplate;
            context: { $implicit: option, index: i }
          "
        ></ng-container>
      </label>
    </div>
    <div
      *ngIf="contentBottomTmpl"
      [ngClass]="isShowInputControl === false ? '' : 'pl-4'"
    >
      <ng-container
        *ngTemplateOutlet="
          contentBottomTmpl;
          context: { $implicit: option, index: i }
        "
      ></ng-container>
    </div>
  </li>
</ng-template>

<ng-template #toggleTemplate>
  <li
    class="select-item toggle"
    *ngFor="let option of optionListTemp; let i = index"
    [ngClass]="[
      isShowBorder ? 'show-border' : '',
      isShowHover ? 'show-hover' : ''
    ]"
  >
    <span class="item-text">
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: { $implicit: option, index: i }
        "
      ></ng-container>
    </span>
    <div class="app-toggle-switch">
      <input
        #inputElement
        type="checkbox"
        [attr.id]="generatedId + (i + 2)"
        (change)="onCheckBoxChange(option)"
        [checked]="
          formControl.value | appSelectResolveChecked: option:'checkbox'
        "
        [disabled]="
          (formControl.value || []).length <= minSelectItem &&
          inputElement.checked
        "
      />
      <label
        class="custom-toggle-switch-label"
        [attr.for]="generatedId + (i + 2)"
        (click)="doClick($event)"
      >
        <span class="custom-toggle-switch-rounded"></span>
      </label>
    </div>
  </li>
</ng-template>

<ng-template #optionTemplate let-option let-i="index">
  <ng-container
    *ngIf="contentTmpl; then contentTemplate; else defaultTemplate"
  ></ng-container>

  <ng-template #contentTemplate>
    <ng-container
      *ngTemplateOutlet="contentTmpl; context: { $implicit: option, index: i }"
    ></ng-container>
  </ng-template>
  <ng-template #defaultTemplate>
    {{ option | resolveFieldData: optionList.viewPath | translate }}
  </ng-template>
</ng-template>

<ng-template #selectAllTextTemplate>
  <div class="custom-control custom-checkbox mr-3">
    <input
      type="checkbox"
      class="custom-control-input"
      [attr.id]="generatedId + '0'"
      (change)="onCheckBoxChangeAll($event)"
      [indeterminate]="
        formControl.value &&
        formControl.value.length !== optionList.requestValues.length && isSelectAllDisabled === false
      "
      [checked]="
        isSelectAllDisabled === false?formControl.value &&
        formControl.value.length === optionList.requestValues.length : formControl.value &&
        formControl.value.length === optionList.requestValues.length && isSelectAllDisabled === false
      "
    />
    <label class="custom-control-label" [attr.for]="generatedId + '0'">
      {{ selectAllText }}
    </label>
  </div>
</ng-template>
