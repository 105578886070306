/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\pipe\resolve-is-show-check-box.pipe.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Monday, September 13th 2021, 1:20:26 pm

 *
*
 */
import { Pipe, PipeTransform } from '@angular/core';
import { TableRow } from '../domain/table-row';
import { TableState } from '../domain/table-state';
@Pipe({
  name: 'resolveIsShowCheckBox'
})
export class ResolveIsShoWCheckBoxPipe implements PipeTransform {
  transform(state: TableState, tableRow: TableRow): boolean {
    if (state.model.options && state.model.options.checkBoxFn) {
      return !state.model.options.checkBoxFn(tableRow.record);
    }
    return false;
  }
}
