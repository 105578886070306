<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="threshold.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'threshold.form.module' | translate
          }}</label>
          <div class="col-sm-7 text-left">
            {{ condition.moduleGroup.name }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'threshold.form.condition' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ condition.name }}
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="card">
              <div class="card-header">
                <div
                  class="float-left control-label"
                  [ngStyle]="{ 'font-size': '17px' }"
                >
                  {{ 'threshold.form.rangeThreshold' | translate }}
                </div>
                <div class="float-right">
                  <app-button
                    (onClick)="doAdd()"
                    [disabled]="isDisabled || formGroup.invalid"
                    *ngIf="this.global.userSession.activeUserRole.role.code === this.global.appConstant.ROLE_CODE_SUPER_ADMIN"
                  >
                    {{ 'app.button.add' | translate }}
                  </app-button>
                </div>
              </div>
              <div class="card-body">
                <div
                  class="text-center table-responsive custom-table float-none"
                  [ngStyle]="{ margin: '0 auto' }"
                >
                  <table
                    class="table table-striped box-shadow"
                    aria-describedby="Table"
                  >
                    <thead>
                      <tr *ngIf="this.global.userSession.activeUserRole.role.code === this.global.appConstant.ROLE_CODE_SUPER_ADMIN">
                        <th scope="col">No</th>
                        <th scope="col" class="fit">{{
                          'threshold.form.from' | translate
                        }}</th>
                        <th scope="col" class="fit">{{
                          'threshold.form.to' | translate
                        }}</th>
                        <th scope="col" class="fit">{{
                          'threshold.form.isInfinite' | translate
                        }}</th>
                        <th scope="col">Action</th>
                      </tr>
                      
                      <tr *ngIf="this.global.userSession.activeUserRole.role.code !== this.global.appConstant.ROLE_CODE_SUPER_ADMIN">
                        <th scope="col" class="col-2">No</th>
                        <th scope="col" class="col-4">{{
                          'threshold.form.from' | translate
                        }}</th>
                        <th scope="col" class="col-4">{{
                          'threshold.form.to' | translate
                        }}</th>
                        <th scope="col" class="col-2">{{
                          'threshold.form.isInfinite' | translate
                        }}</th>
                      </tr>
                    </thead>
                    <tbody formArrayName="thresholdRangeList">
                      <tr
                        *ngFor="
                          let tr of thresholdRangeList.controls;
                          let i = index;
                          let last = last
                        "
                        [formGroup]="tr"
                      >
                        <td>{{ i + 1 }}.</td>
                        <td>
                          <div>
                            <div class="col-sm-12 text-center">
                              <app-text-field
                                size="XL"
                                type="currency"
                                formControlName="from"
                                maxLength="64"
                              >
                              </app-text-field>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="col-sm-12 text-center">
                              <app-text-field
                                size="XL"
                                type="currency"
                                formControlName="to"
                                maxLength="16"
                                (onInput)="doOnInputMethod(tr, i)"
                                (onChange)="doOnChangeToThresholdRangeList(i)"
                              >
                              </app-text-field>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="col-sm-12 text-center">
                              <input
                                type="checkbox"
                                *ngIf="last"
                                formControlName="isInfinite"
                                aria-label="Is Infinity"
                                (change)="
                                  doIsInfiniteChange(
                                    $event.target.checked,
                                    tr,
                                    i
                                  )
                                "
                              />
                            </div>
                          </div>
                        </td>
                        <td *ngIf="this.global.userSession.activeUserRole.role.code === this.global.appConstant.ROLE_CODE_SUPER_ADMIN">
                          <app-button
                            *ngIf="threshold.thresholdRangeList[i].isDeletable"
                            size="TN"
                            title="{{ 'app.tooltip.delete' | translate }}"
                            (onClick)="doRemove(i)"
                            color="DANGER"
                          >
                            <em class="fas fa-trash"></em>
                          </app-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button
            color="SECONDARY"
            class="mr-1"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button
            (onClick)="doSave()"
            [disabled]="disableSaveButton || formSaving"
          >
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
