/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\icon-plugin\index.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Tuesday, September 14th 2021, 12:23:59 am

 *
*
 */

import { createTableComponent } from '../../helper';
import { IconPlugin } from '../../interface/available-table-plugin';
import { TablePlugin } from '../../interface/table-plugin';
import { TablePluginData } from '../../interface/table-plugin-data';
import { TableIconPluginComponent } from './icon-plugin.component';
export const tableIconPlugin: TablePlugin = {
  name: 'icon',
  before: (tablePluginData: TablePluginData): HTMLElement => {
    const iconPlugin = tablePluginData.plugin as IconPlugin;
    if (
      (iconPlugin.isShowIconFn &&
        iconPlugin.isShowIconFn(tablePluginData.row.record)) ||
      !iconPlugin.isShowIconFn
    ) {
      tablePluginData.element.addEventListener('click', event => {
        event.stopPropagation();
      });
      return createTableComponent(
        tablePluginData,
        TableIconPluginComponent,
        'icon-plugin-content'
      );
    }
  }
};
