import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PrItemReleased } from 'src/app/core/bean/pr-item-released';
import { Sow } from 'src/app/core/bean/sow';
import { SowPaymentTermItem } from 'src/app/core/bean/sow-payment-term-item';
import { AppPopupService } from '../app-popup.service';
import { AppPopupSowComponent } from './app-popup-sow.component';

@Injectable()
export class AppPopupSowService {
  constructor(public appPopupService: AppPopupService) {}
  public openPo(
    todo: string,
    availableAmountSow?: number,
    amount?: number,
    prItemReleasedList?: PrItemReleased[],
    sowPaymentTermLists?: SowPaymentTermItem[],
    sowList?: Sow[],
    sowEdit?: Sow,
    isBlanketOrder?: boolean
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupSowComponent,
      {
        todo,
        availableAmountSow,
        amount,
        prItemReleasedList,
        sowPaymentTermLists,
        sowList,
        sowEdit,
        isBlanketOrder
      },
      { size: 'xl' }
    );
  }
}
