import { CurrencyPipe } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { TextUtils } from '../../utils';
import { Validators } from '../../validators';
import { ActiveDropDown } from '../app-drop-down/active-drop-down.service';
@Component({
  selector: 'app-currency',
  templateUrl: './app-currency.component.html',
  styleUrls: ['./app-currency.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: makeProvider(AppCurrencyComponent)
})
export class AppCurrencyComponent
  extends BaseValueAccessorComponent<any>
  implements OnChanges
{
  numberValue: string;
  generateFormGroup: FormGroup = new FormGroup({});
  public CURRENCY_DIGITS_INFO: string;
  public isFirst = true;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public currency: CurrencyPipe,
    private activeDropDown: ActiveDropDown
  ) {
    super('app-currency', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setViewValue();
    this.setAppCurrency();
    this.maxLength = 16;
    this.doSetCurrencyDigitInfo();
  }

  ngOnChanges(): void {
    console.log(this.formControl);
  }

  doSetCurrencyDigitInfo(): void {
    const CURRENCY_PRECISSION_SCALE =
      this.global.appConstant.core.CURRENCY_PRECISSION_SCALE;
    this.CURRENCY_DIGITS_INFO = `0.${CURRENCY_PRECISSION_SCALE}-${CURRENCY_PRECISSION_SCALE}`;
  }

  setAppCurrency(): void {
    this.setAppCurrencyValue();
    this.formControl.valueChanges.subscribe(() => {
      this.setAppCurrencyValue();
    });
  }

  setViewValue(): void {
    if (this.optionList) {
      let price =
        this.formControl.value &&
        this.currency.transform(
          typeof this.formControl.value.price === 'string'
            ? this.formControl.value.price?.replaceAll(',', '')
            : this.formControl.value.price,
          '',
          '',
          this.CURRENCY_DIGITS_INFO
        );
      let code =
        (this.formControl.value &&
          this.formControl.value.currency &&
          this.formControl.value.currency.code) ||
        this.optionList.getRequestValues()[0]['code'];
      this.numberValue = (price && code && price + ' ' + code) || '-';
      this.formControl.valueChanges.subscribe(value => {
        if (typeof value === 'object') {
          price =
            value &&
            value.price &&
            this.currency.transform(
              value.price,
              '',
              '',
              this.CURRENCY_DIGITS_INFO
            );
          code =
            (value && value.currency && value.currency.code) ||
            this.optionList.getRequestValues()[0]['code'];
          this.numberValue = (price && code && price + ' ' + code) || '-';
        }
      });
    } else {
      let price =
        this.formControl.value.price &&
        this.currency.transform(
          this.formControl.value.price,
          '',
          '',
          this.CURRENCY_DIGITS_INFO
        );
      this.numberValue = price || '-';
      this.formControl.valueChanges.subscribe(value => {
        price = this.currency.transform(
          value,
          '',
          '',
          this.CURRENCY_DIGITS_INFO
        );
        this.numberValue = price || '-';
      });
    }
  }

  setAppCurrencyValue(): void {
    if (this.optionList) {
      this.optionList.responseValueChanges.subscribe(() => {
        const currency = this.generateFormGroup.get('currency').value;
        const price = this.generateFormGroup.get('price').value;
        if (currency && price) {
          this.formControl.patchValue({ price, currency });
        }
      });
    } else {
      // if (this.formControl.value) {
      //   const price = this.generateFormGroup.get('price').value;
      //   if (price) {
      //     this.formControl.patchValue({ price, currency: null });
      //   }
      // }
    }
    this.buildFormGroup();
    this.setFormGroup();
  }

  buildFormGroup(): void {
    if (Object.keys(this.generateFormGroup.controls).length === 0) {
      this.generateFormGroup = this.formBuilder.group({
        currency: [null, Validators.compose([Validators.required('')])],
        price: [null, Validators.required()]
      });
    }
  }

  setFormGroup(): void {
    let optionValue = this.optionList
      ? this.optionList.getRequestValues()[0]
      : null;
    let price: string;
    if (this.formControl.value) {
      const { price: priceValue, currency } = this.formControl.value;
      price = priceValue;
      optionValue = currency || {};
      if (this.isFirst) {
        const uConvertMoneyValue =
          this.global.converterService.uConvertMoney(priceValue);
        price = this.currency.transform(
          uConvertMoneyValue,
          '',
          '',
          this.CURRENCY_DIGITS_INFO
        );
      }
    } else if (
      this.optionList &&
      this.optionList.getRequestValues().length === 1
    ) {
      const currency = this.optionList.getRequestValues()[0];
      optionValue = currency || {};
    }
    this.generateFormGroup.get('currency').patchValue(optionValue);
    this.generateFormGroup.get('price').patchValue(price);
    if (this.disabled || this.formControl.disabled) {
      if (this.formControl.value) {
        this.generateFormGroup.patchValue(this.formControl.value);
      }
      this.generateFormGroup.disable();
    } else {
      this.generateFormGroup.enable();
    }

    if (this.ISVIEW) {
      this.generateFormGroup.setIsView(true);
    }
  }

  handleChange(): void {
    const currency = this.generateFormGroup.get('currency').value;
    const price = this.generateFormGroup.get('price').value;
    if (this.optionList) {
      if (currency && price && !isNaN(+price)) {
        this.formControl.patchValue({ price, currency });
        this.onChange.emit(this.formControl.value);
        this.onInput.emit(this.formControl.value);
      }
    } else {
      if (price && !isNaN(+price)) {
        this.formControl.patchValue({ price, currency });
      }
    }
    if (price === '') {
      this.formControl.patchValue(null);
      this.generateFormGroup.get('price').patchValue(null);
      this.generateFormGroup.get('currency').patchValue(currency);
      this.onChange.emit(this.formControl.value);
      this.onInput.emit(this.formControl.value);
    }
    this.activeDropDown.close();
    this.doSetCurrencyFormat();
  }

  handleInput(event: any): void {
    const strRegex = '[^0-9.]';
    const regex = new RegExp(strRegex, 'g');
    event.target.value = event.target.value.replace(regex, '');
    const totalDot = event.target.value.split('.').length - 1;

    if (event.target.value[0] === '.') {
      event.target.value = event.target.value.slice(1);
    } else {
      if (totalDot === 2) {
        event.target.value = event.target.value.slice(
          0,
          event.target.value.length - 1
        );
      }

      if (totalDot === 1) {
        const CURRENCY_PRECISSION_SCALE =
          this.global.appConstant.core.CURRENCY_PRECISSION_SCALE;
        const decimal = event.target.value.split('.')[1];
        if (decimal.length > CURRENCY_PRECISSION_SCALE) {
          event.target.value = event.target.value.slice(
            0,
            event.target.value.length - 1
          );
        }
      }
    }
    this.handleChange();
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent): void {
    const clipboard = event.clipboardData;
    const pasteText = this.global.converterService.uConvertMoney(
      clipboard.getData('text')
    );
    this.errorCatcher(
      event,
      "Ooopss...!!! You've been paste an invalid Currency Number",
      () => {
        this.currency.transform(pasteText, '', '', this.CURRENCY_DIGITS_INFO);
      }
    );
  }

  public handleFocusOut(event: any): void {
    if (event.target.value !== '') {
      const uConvertMoneyValue = this.global.converterService.uConvertMoney(
        event.target.value
      );
      event.target.value = this.currency.transform(
        uConvertMoneyValue,
        '',
        '',
        this.CURRENCY_DIGITS_INFO
      );
    }

    if (!this.optionList) {
      this.onChange.emit(this.formControl.value);
    }
  }

  public handleFocusIn(event: any): void {
    this.isFirst = false;
    if (event.target.value !== '') {
      const uConvertMoneyValue = this.global.converterService.uConvertMoney(
        event.target.value
      );
      event.target.value = uConvertMoneyValue;
    }
  }

  public errorCatcher(
    event: ClipboardEvent,
    message: string,
    callback: Function
  ): void {
    try {
      callback();
    } catch (ex) {
      event.stopPropagation();
      event.preventDefault();
      this.log.error(message ? message : ex.message);
    }
  }

  doSetCurrencyFormat(): void {
    if (!this.optionList) {
      const formGroup = this.formControl.parent;
      const isView = this.formControl.isView || formGroup.isView;
      if (!isView) {
        const inputElement = jQuery(this.elementRef.nativeElement).find(
          'input'
        )[0];
        inputElement.value = inputElement.value
          ? Number(inputElement.value).toString() !== 'NaN'
            ? this.currency.transform(
                inputElement.value,
                '',
                '',
                this.CURRENCY_DIGITS_INFO
              )
            : inputElement.value
          : null;
        const placeholderDecimal = TextUtils.generateRandomString(
          this.global.appConstant.core.CURRENCY_PRECISSION_SCALE,
          '0'
        );
        if (!inputElement.value) {
          inputElement.placeholder = `0.${placeholderDecimal}`;
        }
        this.formControl.valueChanges.subscribe((value: any) => {
          if (!this.formControl.dirty && value !== null) {
            inputElement.value = value
              ? Number(value).toString() !== 'NaN'
                ? this.currency.transform(
                    value,
                    '',
                    '',
                    this.CURRENCY_DIGITS_INFO
                  )
                : value
              : null;
            if (!value) {
              inputElement.placeholder = `0.${placeholderDecimal}`;
            }
          }
        });
      }
    }
  }
}
