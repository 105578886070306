import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';
import { UserDashboardWidget } from '../../../core/bean/user-dashboard-widget';
import { WidgetGroup } from '../../../core/bean/widget-group';
import { DashboardAddWidgetModel } from './model/dashboard-add-widget-model';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollY: true
};
@Component({
  templateUrl: './popup-add-widget.component.html',
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      multi: true
    }
  ],
  styleUrls: ['./popup-add-widget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopupAddWidgetComponent extends BaseComponentComponent {
  @Output() onChange: EventEmitter<UserDashboardWidget[]> = new EventEmitter();
  @Output() onClose: EventEmitter<void> = new EventEmitter();
  public dashboardAddWidgetModelList: DashboardAddWidgetModel[] = [];

  constructor() {
    super('dashboard');
  }

  onInit(): void {
    this.getWidgetGroupList();
  }

  private getWidgetGroupList(): void {
    this.httpClientService
      .get<WidgetGroup[]>('/dashboard/load-my-available-dashboard')
      .subscribe((dashboardAddWidgetModelList: DashboardAddWidgetModel[]) => {
        this.dashboardAddWidgetModelList = dashboardAddWidgetModelList;
        console.log(dashboardAddWidgetModelList);
        this.setStateReady();
      });
  }
}
