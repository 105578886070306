/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table\app-portal-table.component.ts
 
 * Created Date: Thursday, September 30th 2021, 10:26:39 am

 *
*
 */

import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponentComponent } from '../../../../core/base/angular/base-component.component';
import * as portalTableAction from './action/portal-table-action';
import { AppPortalTableService } from './app-portal-table.service';
import { PortalTableState } from './domain/portal-table-state';
import { PortalTableResponseModel } from './model/portal-table-response-model';
@Component({
  selector: 'app-portal-table',
  templateUrl: './app-portal-table.component.html',
  styleUrls: ['./app-portal-table.component.scss'],
  providers: [AppPortalTableService],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('changeView', [
      state(
        'default',
        style({
          gridRowGap: '24px',
          gridColumnGap: '0'
        })
      ),
      state(
        'grid',
        style({
          gridColumnGap: '40px',
          gridRowGap: '40px'
        })
      ),
      transition('default<=>grid', animate('500ms'))
    ])
  ]
})
export class AppPortalTableComponent extends BaseComponentComponent {
  @Input() model: PortalTableResponseModel<any>;
  @Input() stringUrl: string;
  @Input() isShowButtonChangeView: boolean;
  @Input() isShowPagination = true;
  @Input() isLoading: boolean;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @ContentChild('row') rowTmpl: TemplateRef<any>;
  @ContentChild('headerFilter') headerFilterTmpl: TemplateRef<any>;
  @ContentChild('notFound') notFoundTmpl: TemplateRef<any>;
  @ContentChild('footer') footerTmpl: TemplateRef<any>;

  public state: PortalTableState;
  public keyword = new FormControl(null);

  public isGridView: boolean;

  constructor(private appPortalTableService: AppPortalTableService) {
    super('app-portal-table');
  }

  onInit(): void {
    this.setInitializationState();
    this.modelRequestReloadListener();
    this.setStateReady();
  }

  private setInitializationState(): void {
    this.state = this.appPortalTableService.setState(
      this.model,
      this.stringUrl
    );
    this.appPortalTableService.dispatch(
      new portalTableAction.FirstLoadPortalTable()
    );
  }

  private modelRequestReloadListener(): void {
    this.model.requestReload.subscribe(() => {
      this.appPortalTableService.dispatch(
        new portalTableAction.ReloadPortalTable()
      );
    });
  }

  public onSearch(keyword: string): void {
    this.appPortalTableService.dispatch(
      new portalTableAction.SearchPortaltable({ keyword })
    );
  }

  public doResetKeyword(): void {
    this.keyword.reset();
    this.onSearch('');
  }

  public onChangePage(page: number): void {
    this.appPortalTableService.dispatch(
      new portalTableAction.ChangePortalTablePage({ page })
    );
  }

  public onChangePerPage(perPage: number): void {
    this.appPortalTableService.dispatch(
      new portalTableAction.ChangePortalTablePerPage({ perPage })
    );
  }

  public doClickItem(event: PointerEvent, record: any): void {
    event.preventDefault();
    this.onClick.emit(record);
  }

  public doChangeView(view: 'GRID'): void {
    this.isGridView = view === 'GRID';
  }
}
