<app-popup
  header="app-popup-add-budget-item.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <div
    class="col popup-choose-budget"
    *ngIf="!formLoading"
    [ngStyle]="{
      height: '500px',
      'overflow-x': 'hidden'
    }"
  >
    <app-table-xx
      [model]="tableResponse"
      [isShowCheckBox]="true"
      [isShowPagination]="false"
      stringUrl="/app-popup-add-budget-item/index"
      (onAfterRowCreated)="onAfterRowCreated()"
    >
      <ng-template #headerFilterGroup>
        <form
          class="form-horizontal"
          [formGroup]="formGroup"
          novalidate
          *ngIf="!formLoading"
        >
          <div class="form-group row text-left">
            <label class="col-sm-2 control-label">{{
              'app-popup-add-budget-item.form.department' | translate
            }}</label>
            <div class="col-sm-10 text-left">
              <app-combo-box-tree
                size="XL"
                formControlName="organization"
                stringUrl="/organization"
                header="{{
                  'app-popup-add-budget-item.header.department' | translate
                }}"
                placeholder="{{
                  'app-popup-add-budget-item.placeholder.department' | translate
                }}"
                [isLazy]="true"
              ></app-combo-box-tree>
            </div>
          </div>

          <div class="form-group row text-left">
            <label class="col-sm-2 control-label">{{
              'app-popup-add-budget-item.form.type' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              <app-dropdown-select
                formControlName="budgetType"
                [optionList]="budgetTypeOptionList"
                placeholder="{{
                  'app-popup-add-budget-item.placeholder.type' | translate
                }}"
                type="COMBOBOX"
                size="XL"
              >
              </app-dropdown-select>
            </div>
            <label class="col-sm-1 control-label">{{
              'app-popup-add-budget-item.form.coa' | translate
            }}</label>
            <div class="col-sm-5 text-left">
              <app-dropdown-select
                formControlName="coa"
                stringUrl="/app-popup-add-budget-item/auto-complete-coa-list"
                type="AUTOCOMPLETE"
                size="XL"
              >
              </app-dropdown-select>
            </div>
          </div>

          <div class="form-group row text-left">
            <label class="col-sm-2 control-label">{{
              'app-popup-add-budget-item.form.costCenter' | translate
            }}</label>
            <div class="col-sm-10 text-left">
              <app-dropdown-select
                formControlName="costCenter"
                stringUrl="/app-popup-add-budget-item/auto-complete-cost-center-list"
                type="AUTOCOMPLETE"
                size="XL"
              >
              </app-dropdown-select>
            </div>
          </div>

          <div class="form-group row text-left">
            <label class="col-sm-2 control-label">{{
              'app-popup-add-budget-item.form.period' | translate
            }}</label>
            <div class="col-sm-10 text-left">
              <app-dropdown-select
                formControlName="activityPeriod"
                [optionList]="activityPeriodOptionList"
                placeholder="{{
                  'app-popup-add-budget-item.placeholder.period' | translate
                }}"
                type="COMBOBOX"
                size="XL"
              >
              </app-dropdown-select>
            </div>
          </div>

          <div class="button-group button-group-right mt-5">
            <span
              class="text-primary d-flex align-items-center cursor-pointer"
              (click)="doReset()"
            >
              {{ 'app.button.reset' | translate }}
            </span>
            <app-button
              mode="BYPASS"
              color="PRIMARY"
              [outline]="true"
              (onClick)="doApply()"
              [disabled]="formSaving"
            >
              {{ 'app.button.apply' | translate }}
            </app-button>
          </div>
        </form>
      </ng-template>
    </app-table-xx>
  </div>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button
        class="mr-1"
        color="SECONDARY"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button (onClick)="doChoose()">{{
        'app.button.add' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
