<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="approval.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'approval.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              formControlName="code"
              maxLength="64"
              type="code"
            ></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label"
            >{{ 'approval.form.name' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-field
              formControlName="name"
              maxLength="64"
              type="alphanumeric"
            ></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'approval.form.moduleGroup' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              formControlName="moduleGroup"
              [optionList]="moduleGroupOptionList"
            ></app-combo-box>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'approval.form.organization' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              formControlName="organization"
              stringUrl="/organization"
              header="Organization"
              [tenantId]="global.userSession.organizationTenant.id"
              [isLazy]="true"
            ></app-text-tree>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'approval.form.description' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxLength="512">
            </app-text-area>
          </div>
        </div>
        <hr />
        <div class="mt-3">
          <div class="form-group row mt-3 text-sm-right">
            <label class="col-sm-3 required">{{
              'approval.form.type' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box
                formControlName="type"
                [optionList]="approvalOptionList"
                (onChange)="onChangeApprovalType($event)"
              ></app-combo-box>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 px-5 pt-4">
              <app-approval-path
                [model]="approvalPath"
                (onChoose)="doChooseOfficer()"
                (onEdit)="doOnEdit($event)"
              >
              </app-approval-path>
            </div>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">{{
            'app.button.save' | translate
          }}</app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
