export class AppPopupChooseVendorCondition {
  isVendorType: boolean;
  isCategory: boolean;
  isSegmentation: boolean;
  isKeyword: boolean;
  isLocation: boolean;
  isPerformance: boolean;
  isMinimumContractValue: boolean;
  isTotalContract: boolean;
  isRangeExperience: boolean;
  isDisableFilter: boolean;
  isButtonInvite: boolean;
}
