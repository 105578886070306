<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-xx
        header="tc.index.title"
        [model]="tableResponse"
        stringUrl="/tc/index"
        (onClick)="doEdit($event)"
      >
        <ng-template #headerButtons>
          <app-button
            (onClick)="doAdd()"
            title="{{ 'app.button.add' | translate }}"
            ><em class="fas fa-plus"></em>
            {{ 'app.button.add' | translate }}</app-button
          >
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            (onClick)="doDelete(rowData)"
            title="{{ 'app.tooltip.delete' | translate }}"
          >
            <em class="pir pi-trash-alt"></em>
            {{ 'app.button.delete' | translate }}
          </app-button>
        </ng-template>
      </app-table-xx>
    </div>
  </div>
</app-layout>
