<app-popup
  header="app-popup-address.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <form
    class="form-horizontal popup-address"
    [formGroup]="formGroup"
    novalidate
    *ngIf="!formLoading"
  >
    <div
      *ngIf="isLoadingFilter"
      [ngStyle]="{
        'min-height': '30vh',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
      }"
    >
      <img
        src="assets/img/common/loader-small.gif"
        alt="Loading"
        height="11"
        width="40"
      />
    </div>
    <app-select
      *ngIf="!isLoadingFilter"
      [optionList]="addressBookOptionList"
      [isMultiple]="isMultiple"
      [search]="true"
      isShowBorder="true"
      formControlName="addressBook"
    >
      <ng-template #headerFilter>
        <div class="app-select-filter">
          <div class="form-group">
            <!-- <app-combo-box-tree
              size="XL"
              formControlName="organization"
              stringUrl="/organization"
              (onChange)="doOnChange($event)"
              placeholder="{{
                'app-popup-address.placeholder.department' | translate
              }}"
              header="{{
                    'app-popup-address.header.department | translate
                  }}"
            ></app-combo-box-tree> -->
            <div [ngStyle]="{ 'min-width': '10rem' }">
              <app-dropdown-select
                size="XL"
                formControlName="organization"
                (onChange)="doOnChange($event)"
                showLimitValue="3"
                type="CHOSEN"
                isMultiple="true"
                container="body"
                placeholder="app-popup-address.placeholder.department"
                [optionList]="organizationOptionList"
              >
              </app-dropdown-select>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #content let-data>
        <p>{{ data.organization.name }}</p>
        <h5>{{ getUserNameList(data.addressPicList) }}</h5>
        <p>{{ data.addressDetail }}</p>
        <p>{{ data.phone }}</p>
      </ng-template>
    </app-select>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button color="PRIMARY" (onClick)="doSubmit()">{{
          'app.button.add' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
