<app-popup header="app-popup-pr-item-info.title" [isLoading]="formLoading">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="row" *ngIf="!formLoading">
      <div class="col-sm-4">
        <app-image-slideshow
          [fileList]="fileList"
          [parentModuleCode]="moduleCode"
        >
        </app-image-slideshow>
      </div>
      <div class="col-sm-8">
        <h5 class="separator mb-3">{{
          prItem.itemName ? prItem.itemName : catalogView.name
        }}</h5>
        <h4 *ngIf="isShowBudget && priceRealization" class="mb-2">
          {{ priceRealization | resolveNumberToCurrency }}
          {{
            catalogView?.currencyCode ? catalogView?.currencyCode : currencyCode
          }}
        </h4>
        <h4 *ngIf="isShowBudget && !priceRealization" class="mb-2">
          {{
            (prItem.itemPrice ? prItem.price : catalogView?.price)
              | resolveNumberToCurrency
          }}
          {{
            catalogView?.currencyCode ? catalogView?.currencyCode : currencyCode
          }}
        </h4>

        <div
          *ngIf="
            prItem.catalog !== null &&
            prItem.catalog.catalogType.code ===
              global.appConstant.vm.CATALOG_TYPE_CONTRACT
          "
        >
          <table>
            <tr>
              <td class="text-center text-secondary">
                <em class="fas fa-store"></em><p></p>
              </td>
              <td style="width: 8px"></td>
              <td class="text-left text-secondary">
                {{ catalogView?.vendorName }}<p></p>
              </td>
            </tr>
            <tr>
              <td class="text-center text-secondary">
                <em class="fas fa-map-marker-alt"></em><p></p>
              </td>
              <td></td>
              <td class="text-left text-secondary">
                {{ catalogView?.vendorCityRegionName }}<p></p>
              </td>
            </tr>
            <tr>
              <td class="text-center text-warning">
                <em class="pic pi-star"></em><p></p>
              </td>
              <td></td>
              <td class="text-left text-secondary">
                {{ 'app-popup-pr-item-info.vendorRating' | translate }}&nbsp;{{
                  catalogView?.rating
                }}&nbsp;|&nbsp;
                <app-badge-catalog
                  style="position: absolute"
                  [color]="
                    catalogView.catalogType.code ===
                    global.appConstant.vm.CATALOG_TYPE_VENDOR
                      ? 'INFO'
                      : 'SUCCESS'
                  "
                  >{{
                    catalogView.catalogTypeTranslationKey | translate
                  }}</app-badge-catalog
                ><p></p>
              </td>
            </tr>
          </table>
          <br />
        </div>
        <ng-container
          *ngIf="
            !prItem.catalog ||
            (prItem.catalog &&
              prItem.catalog.catalogType.code !==
                global.appConstant.vm.CATALOG_TYPE_CONTRACT)
          "
        >
          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'app-popup-pr-item-info.itemCode' | translate }}</div
            >
            <div class="tab-content-item-value">
              {{ prItem.itemCode || '-' }}
            </div>
          </div>
          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'app-popup-pr-item-info.uom' | translate }}</div
            >
            <div class="tab-content-item-value">
              {{ prItem.itemUom?.name || '-' }}
            </div>
          </div>
          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'app-popup-pr-item-info.quantity' | translate }}</div
            >
            <div class="tab-content-item-value">
              {{ prItem.quantity | resolveNumberToDecimal }}
            </div>
          </div>
          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'app-popup-pr-item-info.type' | translate }}</div
            >
            <div class="tab-content-item-value">
              <app-badge
                style="position: absolute"
                [color]="
                  prItem.item?.itemType?.code ===
                  global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                    ? 'GOOD'
                    : 'SERVICE'
                "
              >
                {{ prItem.item?.itemType?.name || '-' }}
              </app-badge>
            </div>
          </div>
          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'app-popup-pr-item-info.form.catalog' | translate }}</div
            >
            <div class="tab-content-item-value" *ngIf="prItem.catalog !== null">
              <app-badge-catalog
                style="position: absolute"
                [color]="
                  catalogView.catalogType.code ===
                  global.appConstant.vm.CATALOG_TYPE_VENDOR
                    ? 'INFO'
                    : 'SUCCESS'
                "
              >
                {{ catalogView.catalogTypeTranslationKey | translate }}
              </app-badge-catalog>
            </div>
            <div class="tab-content-item-value" *ngIf="prItem.catalog === null">
              <app-badge-catalog style="position: absolute" color="WARNING">
                {{ 'app-popup-pr-item-info.form.item' | translate }}
              </app-badge-catalog>
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="
            prItem.catalog !== null &&
            prItem.catalog.catalogType.code ===
              global.appConstant.vm.CATALOG_TYPE_CONTRACT
          "
        >
          <br />
          <label class="control-label">{{
            'app-popup-pr-item-info.form.itemContractDetail' | translate
          }}</label>
          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{
                'app-popup-pr-item-info.form.contractNumber' | translate
              }}</div
            >
            <div class="tab-content-item-value">
              {{ catalogContract.contract.code }}
            </div>
          </div>
          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{
                'app-popup-pr-item-info.form.contractPeriode' | translate
              }}</div
            >
            <div class="tab-content-item-value">
              {{ 'app-popup-pr-item-info.form.from' | translate }}
              {{
                catalogContract.contract.startDate
                  | date: global.config.parameterModel.dateFormatShort
              }}
              {{ 'app-popup-pr-item-info.form.until' | translate }}
              {{
                catalogContract.contract.endDate
                  | date: global.config.parameterModel.dateFormatShort
              }}
            </div>
          </div>
          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{
                'app-popup-pr-item-info.form.agreementQuantity' | translate
              }}</div
            >
            <div class="tab-content-item-value">
              {{ catalogContract.quantity | resolveNumberToDecimal }}
            </div>
          </div>
          <ng-container *ngIf="catalogContract.isBulkPrice">
            <div class="form-group row"
              ><div class="col-sm-1 pvc pv-bulk"></div>
              <div class="col" style="padding-top: 2%">
                <strong>{{
                  'app-popup-pr-item-info.form.bulkPriceAvailable' | translate
                }}</strong>
              </div></div
            >
            <div>
              <span>
                {{ 'app-popup-pr-item-info.form.startsFrom' | translate }}
                {{
                  catalogBulkPriceList[0].discountedPrice
                    | resolveNumberToCurrency
                }}
                {{
                  catalogView?.currencyCode
                    ? catalogView?.currencyCode
                    : currencyCode
                }}
                {{ 'app-popup-pr-item-info.form.forMinimumOrder' | translate }}
                {{
                  catalogBulkPriceList[0].minimumOrderTotal
                    | resolveNumberToDecimal
                }}
                pcs
                <app-tooltip
                  position="BOTTOM"
                  style="position: absolute; margin-left: 1%; margin-top: 0.3%"
                >
                  <em class="pir pi-chevron-down"></em>
                  <ng-template #tooltipContent>
                    <div
                      *ngFor="let data of catalogBulkPriceList; let i = index"
                      style="min-width: max-content"
                      [ngStyle]="
                        i + 1 !== catalogBulkPriceList.length
                          ? {
                              'border-bottom': '1px solid var(--gray-400)',
                              padding: '2% 2px 2% 2px'
                            }
                          : { padding: '2% 2px 0 2px' }
                      "
                    >
                      <div>
                        <strong
                          >{{ data.discountedPrice | resolveNumberToCurrency }}
                          {{ catalogView.currencyCode }}</strong
                        >
                      </div>

                      <div style="font-size: 12px">
                        {{ 'app-popup-pr-item-info.form.disc' | translate }}
                        {{ data.discount | resolveNumberToDecimal }}%
                        {{
                          'app-popup-pr-item-info.form.forMinimumOrder'
                            | translate
                        }}
                        {{ data.minimumOrderTotal | resolveNumberToDecimal }}
                        pcs
                      </div>
                    </div>
                  </ng-template>
                </app-tooltip>
              </span>
            </div>
          </ng-container>
        </ng-container>

        <br />
        <label
          ><b>{{
            'app-popup-pr-item-info.specification' | translate
          }}</b></label
        >
        <div *ngIf="prItem.catalog !== null">
          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'app-popup-pr-item-info.weight' | translate }}</div
            >
            <div class="tab-content-item-value">
              {{ prItem.catalog?.weight || '-' }}
            </div>
          </div>
          <div
            class="tab-content-item"
            *ngFor="let data of prItemSpecificationList; let i = index"
          >
            <div class="tab-content-item-label"
              >{{
                data.catalogAttribute.translationKey.module.code.toLowerCase() +
                  '.' +
                  data.catalogAttribute.translationKey.key | translate
              }}
            </div>
            <div
              *ngIf="data.catalogAttribute.formObject.code === 'WYSIWYG'"
              [innerHTML]="doParseValue(data)"
              [ngStyle]="{
                'white-space': 'normal',
                'max-width': '500px',
                'word-break': 'break-word'
              }"
            ></div>
            <div
              class="ml-3"
              *ngIf="data.catalogAttribute.formObject.code === 'DATE-PICKER'"
            >
              {{
                doConvertToDate(data.value)
                  | date: global.config.parameterModel.dateFormatShort
              }}</div
            >
            <div
              class="ml-3"
              *ngIf="data.catalogAttribute.formObject.code === 'TOGGLE-SWITCH'"
            >
              {{
                (data.value === 'true' ? 'view-catalog.yes' : 'view-catalog.no')
                  | translate
              }}</div
            >
            <div
              *ngIf="
                data.catalogAttribute.formObject.code !== 'WYSIWYG' &&
                data.catalogAttribute.formObject.code !== 'DATE-PICKER' &&
                data.catalogAttribute.formObject.code !== 'TOGGLE-SWITCH'
              "
              class="tab-content-item-value"
              [ngStyle]="{
                'white-space': 'normal',
                'max-width': '500px',
                'word-break': 'break-word'
              }"
              >{{ doParseValue(data) }}</div
            >
          </div>
        </div>
        <div *ngIf="prItem.catalog === null" class="tab-content-item">
          <div class="tab-content-item-value">
            <app-wysiwyg
              size="LG"
              formControlName="specification"
              maxLength="32"
            ></app-wysiwyg>
          </div>
        </div>

        <br />
        <div *ngIf="prItem.catalog !== null">
          <label
            ><b>{{
              'app-popup-pr-item-info.description' | translate
            }}</b></label
          >
          <div
            *ngIf="!formLoading"
            readMore
            [ngStyle]="{
              'white-space': 'normal',
              'max-width': '600px',
              'word-break': 'break-word'
            }"
            [innerHTML]="prItem.itemDescription || '-'"
          ></div>
        </div>
      </div>
    </div>
    <br />
    <div *ngIf="isShowBudget && !formLoading">
      <h5 class="separator">{{
        'app-popup-pr-item-info.header-group.budgetInfo' | translate
      }}</h5>
      <!-- <app-table [model]="tableResponse" [isShowSearch]="false"> </app-table> -->
      <app-table-xx [model]="tableResponse" [isShowSearch]="false">
        <ng-template #row let-state>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right" colspan="1">
              {{ 'app-popup-pr-item-info.table.row.totalBudget' | translate }}
            </td>
            <td colspan="99" class="text-right">
              <strong>
                {{ totalBudget | resolveNumberToCurrency }}
                {{ currencyCode }}
              </strong>
            </td>
          </tr>
        </ng-template>
      </app-table-xx>
    </div>
  </form>
</app-popup>
