/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\plugins\html-plugin\index.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Monday, September 13th 2021, 9:54:50 am

 *
*
 */

import { TablePlugin } from '../../interface/table-plugin';
import { TablePluginData } from '../../interface/table-plugin-data';

export const tableHtmlPlugin: TablePlugin = {
  name: 'html',
  before: (tablePluginData: TablePluginData): HTMLElement => {
    const divElement = document.createElement('div');
    divElement.classList.add(
      'html-plugin-content',
      tablePluginData.column.column.field.replace(/./g, '-').toLowerCase()
    );
    divElement.innerHTML = tablePluginData.value;
    return divElement;
  }
};
