import { BaseEntity } from '../base/base-entity';
import { BudgetType } from '../bean/budget-type';
import { Coa } from '../bean/coa';
import { CostCenter } from '../bean/cost-center';
import { Currency } from '../bean/currency';
import { ModuleGroup } from '../bean/module-group';
import { User } from '../bean/user';
import { WorkProgram } from '../bean/work-program';
import { BranchOffice } from './../bean/branch-office';

export class BudgetAllocationEntity extends BaseEntity {
  budgetType: BudgetType = new BudgetType();
  workProgram: WorkProgram = new WorkProgram();
  costCenter: CostCenter = new CostCenter();
  coa: Coa = new Coa();
  branchOffice: BranchOffice = new BranchOffice();
  currency: Currency = new Currency();
  moduleGroup: ModuleGroup = new ModuleGroup();
  user: User = new User();
  totalBudget: number;
  usedBudget: number;
  availableBudget: number;
  bookedBudget: number;
  paidOffBudget: number;
  tempBookedBudget: number;
  remainingPayment: number;
  plannedBudget: number;
  availablePlannedBudget: number;
  activityPeriod: number;
  fromModuleGroupCode: string;
  isManual: boolean;
}
