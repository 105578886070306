/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\pipe\resolve-table-position.pipe.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Friday, September 10th 2021, 6:40:40 pm

 *
*
 */
import { Pipe, PipeTransform } from '@angular/core';
import { PaginationModel } from '../../app-pagination/model/pagination-model';
@Pipe({
  name: 'resolveTableRowPosition'
})
export class ResolveTableRowPositionPipe implements PipeTransform {
  transform(index: number, pagination: PaginationModel): number {
    return index + 1 + (pagination.page - 1) * pagination.perPage;
  }
}
