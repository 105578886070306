import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Module } from './../../core/bean/module';
import { ModuleGroup } from './../../core/bean/module-group';
@Component({
  templateUrl: './module-dependency.component.html'
})
export class ModuleDependencyComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Module>;
  public moduleGroupList: ModuleGroup[];
  public moduleGroupOptionList: OptionListModel<ModuleGroup> =
    new OptionListModel(true);

  constructor(translateService: TranslateService) {
    super('module-dependency', translateService);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.doSetModuleGroupList();
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      moduleGroup: [null]
    });
  }

  public doSetModuleGroupList(): void {
    this.httpClientService
      .get<ModuleGroup[]>('/module-dependency/module-group-list')
      .subscribe(moduleGroupList => {
        this.moduleGroupOptionList.setRequestValues(moduleGroupList);
        this.setStateReady();
      });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.module' },
      {
        field: 'moduleGroupCode',
        header: 'table.column.moduleGroup',
        customClass: 'text-center'
      },
      {
        field: 'inputDependency',
        header: 'table.column.input',
        sortable: false,
        format: FieldFormatEnum.Html
      },
      {
        field: 'outputDependency',
        header: 'table.column.output',
        sortable: false,
        format: FieldFormatEnum.Html
      }
    ]);
  }

  public doEdit(module: Module): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('module', module);
    this.router.navigate(['/pages/module-dependency/edit']);
  }

  public doOnChangeModuleGroup(): void {
    this.tableResponse.setCustomData({
      moduleGroupCode: this.formGroup.get('moduleGroup')?.value?.code
    });
    this.tableResponse.reload();
  }
}
