/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\portal\landing-page\expired-link.component.ts
 
 * Created Date: Sunday, October 3rd 2021, 12:11:27 pm

 *
*
 */

import { Component } from '@angular/core';

@Component({
  templateUrl: './expired-link.component.html'
})
export class ExpiredLinkComponent {}
