import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { File } from '../../core/bean/file';
import { Resource } from '../../core/bean/resource';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { ResourceRequest } from './resource-request';
@Component({
  templateUrl: './resource-edit-add.component.html'
})
export class ResourceEditAddComponent extends BaseModuleComponent {
  public resource: Resource = new Resource();
  public resourceId: number;
  public fileUploader: FileUploader = new FileUploader(
    '/resource/',
    '',
    this.global.appConstant.fileType.IMG_RESOURCE
  );
  public typeOptionList: OptionListModel<any> = new OptionListModel();
  public isReadOnly = false;
  constructor(translateService: TranslateService) {
    super('resource', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doSetTypeOptionList();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.resourceId = this.global.routerParams.get('resourceId');
  }

  public doSetTypeOptionList(): void {
    const optionListValue = [
      { name: 'IMG', value: 'IMG' },
      { name: 'ICO', value: 'ICO' }
    ];
    this.typeOptionList.setRequestValues(optionListValue);
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(128)])
      ],
      height: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(11)])
      ],
      width: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(11)])
      ],
      type: [null, Validators.required()],
      file: [null, Validators.required()],
      imgFile: [new File()],
      isReadOnly: false
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<Resource>(
        '/resource/add-edit',
        new RouteRequestModel(this.todo, this.resourceId)
      )
      .subscribe(resource => {
        if (resource != null) {
          this.fileUploader.setFile(resource.imgFile);
          const types = this.typeOptionList
            .getRequestValues()
            .filter(valueOption => valueOption.value === resource.type)[0];
          this.formGroup.patchValue(resource);
          this.formGroup.patchValue({
            type: types,
            file: this.fileUploader
          });
          this.isReadOnly = resource.isReadOnly;
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.resource = this.global.copyFormAttributeToModel(
              this.resource,
              this.formGroup
            );
            const url =
              this.todo === 'edit' ? '/resource/update' : '/resource/insert';
            const resourceRequest: ResourceRequest = new ResourceRequest();
            resourceRequest.fileObjectList = this.fileUploader.fileObjectList;
            this.resource.type = this.formGroup.get('type').value.value;
            resourceRequest.resource = this.resource;
            this.httpClientService
              .post<Response<Resource>>(url, resourceRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/resource/']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doBack(): void {
    this.router.navigate(['/pages/resource/']);
  }
}
