/*
 * Filename:  WebContent\src\app\core\components\app-table-x\action\expand-or-collapse-all-table-row-action.ts
 * Path:  WebContent
 * Created Date: Friday, August 13th 2021, 11:11:08 am

 *
*
 */
import { resolveTableRecordChildrens } from '../helpers/resolve-table-record-childrens-helper';
import { TableRecord } from '../model/table-record';
import { TableState } from '../model/table-state';
export const expandOrCollapseAllTableRow = (
  state: TableState,
  payload: { action: 'EXPAND' | 'COLLAPSE' }
): void => {
  state.setStateReady();
  state.records.forEach(tableRecord => {
    tableRecord.childrens.length === 0 && tableRecord.setStateLoading();
    if (
      (tableRecord.isExpanded && payload.action === 'COLLAPSE') ||
      (!tableRecord.isExpanded && payload.action === 'EXPAND')
    ) {
      tableRecord.isExpanded = !tableRecord.isExpanded;
    }
    tableRecord.isExpanded &&
      tableRecord.childrens.length === 0 &&
      resolveTableRecordChildrens(state, tableRecord).then(
        (value: { recordList: TableRecord[]; totalRecord: number }) => {
          tableRecord.childrens = value.recordList;
          tableRecord.isShowButtonLoadMore =
            tableRecord.childrens.length < value.totalRecord;
          tableRecord.setStateReady();
        }
      );
  });
};
