/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Global } from '../global/global';
import { Log } from '../services/logger';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  public log: Log = new Log(this);
  constructor(public global: Global) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.log.debug('');
    this.log.debug(
      '%c░░▒▒▓▓%c ' + request.method + ' %c : ' + request.url,
      'background:white; color: blue',
      'background:blue; color: white; ',
      'color: blue'
    );
    this.log.debug(
      '%cToken   : ' +
        (this.global.userSession ? this.global.userSession.token : '-'),
      'color:gray;'
    );
    const isFullUrl = request.url.includes('://');
    const requestUrl: string = isFullUrl
      ? request.url
      : request.url.includes('//')
        ? request.url.replace('//', '/')
        : this.global.config.BACKEND_ADDRESS + request.url;
    if (this.global.userSession) {
      request = request.clone({
        url: requestUrl,
        setHeaders: {
          Authorization: this.global.userSession.token
        }
      });
    } else {
      request = request.clone({
        url: requestUrl
      });
    }
    return next.handle(request);
  }
}
