import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { QcEditAddComponent } from '../qc/qc-edit-add.component';
import { QcFlexyComponent } from './qc-flexy.component';
import { QcSharedModule } from '../qc/qc-shared-module';
const routes: Routes = [
  { path: '', component: QcFlexyComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: QcEditAddComponent,
    data: { breadcrumb: 'qc.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: QcEditAddComponent,
    data: { breadcrumb: 'qc.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), QcSharedModule],
  declarations: [QcFlexyComponent]
})
export class QcFlexyModule {}
