/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\core\components\table\helper\create-th-row-list-helper.ts
 
 * Created Date: Tuesday, October 12th 2021, 5:44:07 pm

 *
*
 */
import { TableColumnModel } from '../model/table-column-model';
export const createTableRowColumnList = (
  columnList: Array<TableColumnModel>,
  initialList: Array<Array<TableColumnModel>>
): Array<Array<TableColumnModel>> => {
  const currentIndex = initialList.length - 1;
  columnList.forEach(column => {
    if (column.columnList) {
      if (currentIndex === initialList.length - 1) {
        initialList.push(column.columnList);
        createTableRowColumnList(column.columnList, initialList);
      } else {
        initialList[currentIndex + 1] = [
          ...initialList[currentIndex + 1],
          ...column.columnList
        ];
      }
    }
  });
  return initialList;
};
