import { FileObject } from 'src/app/core/components/upload';
import { Lang } from '../../core/bean/lang';
import { User } from '../../core/bean/user';

export class AccountSettingRequest {
    userRoleDefaultId: number;
    imgFile: FileObject[] = [];
    // oldPassword: string;
    // newPassword: string;
    newEmail: string;
    auth: string; // oldPassword, newPassword
    name: string;
    lang: Lang;
    user: User;
}
