import { BaseEntity } from '../base/base-entity';
import { Catalog } from '../bean/catalog';
import { CatalogContract } from '../bean/catalog-contract';
import { Item } from '../bean/item';
import { Pr } from '../bean/pr';
import { PrItem } from '../bean/pr-item';
import { PrItemBudget } from '../bean/pr-item-budget';
import { PrItemImage } from '../bean/pr-item-image';
import { Uom } from '../bean/uom';
import { WorkflowModelPrcs } from '../bean/workflow-model-prcs';

export class PrItemEntity extends BaseEntity {
  // Transient
  crudOperation: number;
  receivedQuantity: number;
  remainingQuantity: number;
  totalQuantity: number;
  totalQuantityPrice: number;
  workPlanItemId: number;

  prItemImageList: PrItemImage[];
  prItemBudgetList: PrItemBudget[];
  pr: Pr = new Pr();
  item: Item = new Item();
  catalog: Catalog = new Catalog();
  workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  catalogContract: CatalogContract;
  fromPrItem: PrItem;
  itemUom: Uom;
  price: number;
  totalPrice: number;
  itemPrice: number;
  quantity: number;
  isUsed: boolean;
  specification: string;
  itemCode: string;
  itemName: string;
  itemDescription: string;
}
