/*
 * Filename: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent\src\app\core\components\table\domain\table-column.ts
 * Path: c:\Users\Development\workspace\PRODUCT\FRONTEND-PM-NEW-NEW\WebContent
 * Created Date: Thursday, September 9th 2021, 11:21:43 am

 *
*
 */

import { EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TableColumnModel } from '../model/table-column-model';
export class TableColumn {
  value: any;
  element: HTMLElement;
  formControl: FormControl;
  column: TableColumnModel;
  onChange: EventEmitter<any> = new EventEmitter();
  requestChanges: EventEmitter<void> = new EventEmitter();

  public reload(): void {
    this.requestChanges.emit();
  }

  public changeValue(
    value: any | { value: any; field: string },
    customValue?: any
  ): void {
    this.onChange.emit({ value, customValue });
  }
}
