
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QcEditAddComponent } from './qc-edit-add.component';
import { SharedModule } from '../../core/shared/shared.module';
@NgModule({
  declarations: [
    QcEditAddComponent
  ],
  imports: [
    CommonModule,SharedModule
  ],
  exports: [
    QcEditAddComponent
  ]
})
export class QcSharedModule { }
