<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="module-dependency.title" detail="app.action.{{todo}}"
            [isLoading]="formLoading" [isSaving]="formSaving">
            <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                    'module-dependency.moduleGroup' | translate }}</label>
                <label class="col-sm-4 text-left">{{
                    formGroup.controls.moduleGroup.value }}</label>
            </div>
            <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                    'module-dependency.module' | translate }}</label>
                <label class="col-sm-4 text-left">{{ module.name }}</label>
            </div>

            <br />
            <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                    'module-dependency.dependencyInput' | translate }}</label>
            </div>
            <div>
                <app-pick-list [model]="dependencyInputPickListModel"
                    searchSourcePlaceholder="module-dependency.appPickList.dependencyPlaceHolder"
                    searchTargetPlaceholder="module-dependency.appPickList.dependencyPlaceHolder"
                    sourceTitle="module-dependency.appPickList.dependencySourceTitle"
                    targetTitle="module-dependency.appPickList.dependencyTargetTitle">
                    <!-- <ng-template #source let-dependency>
                        <div style="font-size:14px;float:left;margin:0 px 0px 0 0;">
                            <span style="display:inline-block;margin:2px 0 2px 2px" width="30"></span>
                            {{dependency.name}}
                        </div>
                    </ng-template>
                    <ng-template #target let-menu>
                        <div style="font-size:14px;float:left;margin:0 px 0px 0 0;">
                            <span style="display:inline-block;margin:2px 0 2px 2px" width="30"></span>
                            {{dependency.name}}
                        </div>
                    </ng-template> -->
                </app-pick-list>
            </div>
            <br />
            <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                    'module-dependency.dependencyOutput' | translate }}</label>
            </div>
            <div>
                <app-pick-list [model]="dependencyOutputPickListModel"
                    searchSourcePlaceholder="module-dependency.appPickList.dependencyPlaceHolder"
                    searchTargetPlaceholder="module-dependency.appPickList.dependencyPlaceHolder"
                    sourceTitle="module-dependency.appPickList.dependencySourceTitle"
                    targetTitle="module-dependency.appPickList.dependencyTargetTitle">
                    <!-- <ng-template #source let-dependency>
                        <div style="font-size:14px;float:left;margin:0 px 0px 0 0;">
                            <span style="display:inline-block;margin:2px 0 2px 2px" width="30"></span>
                            {{dependency.name}}
                        </div>
                    </ng-template>
                    <ng-template #target let-dependency>
                        <div style="font-size:14px;float:left;margin:0 px 0px 0 0;">
                            <span style="display:inline-block;margin:2px 0 2px 2px" width="30"></span>
                            {{dependency.name}}
                        </div>
                    </ng-template> -->
                </app-pick-list>
            </div>

            <div class="row justify-content-center mt-5">
                <app-button color="SECONDARY" class="mr-1"
                    (onClick)="doCancel()" [disabled]="formSaving"
                    mode="BYPASS">
                    {{ 'app.button.back' | translate }}
                </app-button>
                <app-button color="PRIMARY" (onClick)="doSave()"
                    [disabled]="formSaving">
                    {{ 'app.button.save' | translate }}
                </app-button>
            </div>
        </app-card>
    </div>
</div>
