import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { ModuleDetail } from '../bean/module-detail';
import { ModuleType } from '../bean/module-type';
import { ModuleGroup } from './../bean/module-group';

export class ModuleEntity extends BaseEntity {
  inputDependency: string;
  outputDependency: string;
  formatNumberGenerator: string;
  moduleList: ModuleGroup[];
  moduleDetailList: ModuleDetail[];
  file: File;
  moduleType: ModuleType;
  name: string;
  isActive: boolean;
  description: string;
  isStage: boolean;
  isStarter: boolean;
  isNumberGenerator: boolean;
  isSchedule: boolean;
  isImport: boolean;
  path: string;
  objectName: string;
  serviceName: string;
  moduleGroupCode: string;
  approvalPath: string;
  isReport: boolean;
  isEmailNotification: boolean;
  mod: ModuleGroup[];
  isVendorResponse: boolean;
  isStartDate: boolean;
  isEndDate: boolean;
  isLocation: boolean;
  isApprove: boolean;
  isReject: boolean;
  isHold: boolean;
  isRevision: boolean;
  isVendorAvailable: boolean;
  hierarchy: number;
}
