<div class="promise-container promise-container-lg">
  <app-card header="About US">
    <div>
      <ul>
        <li>
          <h4 class="caps">Our Mission</h4>
          <p>Work with sincerity and be trusted at any time and any place.
            Integration of words and actions.</p
          >
        </li>
       
      </ul>
    </div>
  </app-card>
</div>
