/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\themes\promise-portal\components\app-portal-table\action\portal-table-action.ts
 
 * Created Date: Thursday, September 30th 2021, 10:45:11 am

 *
*
 */

import { Action } from '../../../../../core/interfaces/action';

export const FIRST_LOAD_PORTAL_TABLE = `[ PORTAL TABLE ]: First Load Portal Table`;
export const SEARCH_PORTAL_TABLE = `[ PORTAL TABLE ]: Search Portal Table`;
export const CHANGE_PORTAL_TABLE_PAGE = `[ PORTAL TABLE ]: Change Portal Table Page`;
export const CHANGE_PORTAL_TABLE_PER_PAGE = `[ PORTAL TABLE ]: Change Portal Table Per Page`;
export const RELOAD_PORTAL_TABLE = `[ PORTAL TABLE ]: Reload Portal Table`;

export class FirstLoadPortalTable implements Action<void> {
  readonly type = FIRST_LOAD_PORTAL_TABLE;
}

export class SearchPortaltable implements Action<{ keyword: string }> {
  readonly type = SEARCH_PORTAL_TABLE;
  constructor(public payload: { keyword: string }) {}
}

export class ChangePortalTablePage implements Action<{ page: number }> {
  readonly type = CHANGE_PORTAL_TABLE_PAGE;
  constructor(public payload: { page: number }) {}
}

export class ChangePortalTablePerPage implements Action<{ perPage: number }> {
  readonly type = CHANGE_PORTAL_TABLE_PER_PAGE;
  constructor(public payload: { perPage: number }) {}
}

export class ReloadPortalTable implements Action<void> {
  readonly type = RELOAD_PORTAL_TABLE;
}

export type AllPortalTableAction =
  | FirstLoadPortalTable
  | SearchPortaltable
  | ChangePortalTablePage
  | ChangePortalTablePerPage
  | ReloadPortalTable;
