/*
 * Filename: c:\Users\ \workspace\WORK S\FRONTEND_PM\WebContent\src\app\pages\dashboard\widget\action\save-widget-action.ts
 
 * Created Date: Thursday, November 18th 2021, 11:34:47 am

 *
*
 */

import { UserDashboardWidget } from '../../../../core/bean/user-dashboard-widget';
import { HttpClientService } from '../../../../core/services/http-client.service';
import { WidgetItem } from '../domain/widget-item';
import { WidgetState } from '../domain/widget-state';
export const saveWidgetAction = (state: WidgetState): void => {
  state.setStateSaving();
  const userDashboardWidgetList: UserDashboardWidget[] = state.widgetList.map(
    (widgetItem: WidgetItem, index: number) => {
      if (widgetItem.isNew) {
        const userDashboardWidget = new UserDashboardWidget();
        userDashboardWidget.group = 'TOP';
        userDashboardWidget.sequence = index + 1;
        userDashboardWidget.widget = widgetItem.widget.widget;
        return userDashboardWidget;
      }
      widgetItem.widget.sequence = index + 1;
      return widgetItem.widget;
    }
  );

  const preLoaderElement = document.getElementById('preloader');
  preLoaderElement.style.display = 'block';
  preLoaderElement.style.background = 'black';
  const preLoaderText = preLoaderElement.querySelector(
    '#preloader-text'
  ) as HTMLElement;
  preLoaderText.style.display = 'none';

  state.injector
    .get(HttpClientService)
    .post('/dashboard/save-my-dashboard', userDashboardWidgetList)
    .subscribe(
      () => {
        state.widgetList.map((widgetItem: WidgetItem) => {
          if (widgetItem.isNew) {
            widgetItem.isNew = false;
          }
          return widgetItem;
        });
        preLoaderElement.style.display = 'none';
        preLoaderElement.style.background = 'transparent';
        preLoaderText.removeAttribute('style');
        state.isSaved = true;
        setTimeout(() => {
          state.widgetListTemp = state.widgetList;
          state.isShowWidgetEditable = false;
          state.setStateReady();
        }, 500);
      },
      () => {
        state.widgetList = state.widgetListTemp;
        state.setStateReady();
      }
    );
};
